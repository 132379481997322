import { environment } from '../environments/environment';

const apiURL = environment.apiURL;
let azureURL: string = "https://atlas.microsoft.com/search/fuzzy/json?api-version=1.0&typeahead=true";


export interface AppUrlConfig {
    apiEndpoint: string;
}

export var azureSearchURL: AppUrlConfig = {
    apiEndpoint: azureURL + "&subscription-key=" + environment.subscriptionKeyAzure
}

export const StudentSignupEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/register/'
};

export const BusinessSignupEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/register/business/'
};

export const SchoolSignupEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/register/school/'
};

export const ResendVerificationEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/resend-verify-email/'
};

export const ChangeParentEmailEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/change-parent-email/'
};

export const AccountVerificationEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts'
};

export const SchoolSearchEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/schools/?search='
};

export const SchoolUserEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/register/teacher/'
};

export const LoginEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/login/'
};

export const LogoutEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/logout/'
};

export const ForgotPasswordEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/forgot-password/'
};

export const ResetPasswordEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/reset-password/?'
};

export const BaseAccountEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/'
};

export const BaseProfileEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/profile/'
};

export const BaseEventEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/events/'
};

export const BusinessPageEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/page/'
};

export const SchoolPageEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/page-clubteam/'
};

export const listPagesEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/page-listing/?qs='
};

export const uniqueProfileUrlEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/uniqueprofile/'
};

export const PageDetailEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/page/'
};

export const NotificationlEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/notifications/'
};

export const PageImageEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/page-profile/'
};

export const AccountVerificationStatusEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/account-verification-status/'
};

export const ProfilePrivacySettingEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/profile-privacy-setting/'
};

export const BlockUserEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/block-user/'
};

export const ReportUserEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/report-user/'
};

export const staticContentEndPoint: AppUrlConfig = {
    apiEndpoint: apiURL + 'api/accounts/get-static-content/'
};
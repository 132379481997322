import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VolunteerComponent } from '../app/volunteer/volunteer.component';
import { StudentComponent } from './student/student.component';
import { HomeComponent } from './home/home.component';
import { BusinessComponent } from './business/business.component';
import { CreateBusinessPageComponent } from './create-business-page/create-business-page.component';
import { CreateNpoPageComponent } from './create-npo-page/create-npo-page.component'; 
import { CreateClubteamPageComponent } from './create-clubteam-page/create-clubteam-page.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileBusinessComponent } from './profile-business/profile-business.component'; 
import { ProfileSchoolComponent } from './profile-school/profile-school.component';
import { SearchProfilesComponent } from './search/search-profiles/search-profiles.component';
import { StudentDashboardComponent } from './profile/student-dashboard/student-dashboard.component'; 
import { LoginTabComponent } from './login/login-tab/login-tab.component';
import { NotificationsComponent } from './profile/notifications/notifications.component';
import { SchoolComponent } from './school/school.component';
import { SearchPageComponent } from './search/search-page/search-page.component';
import { MessageChatComponent } from './profile/message-chat/message-chat.component'; 
import { SidechatComponent } from './profile/message-chat/sidechat/sidechat.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ParentVerificationComponent } from './parent-verification/parent-verification.component';
import { AuthGuard } from './shared/_guards/auth.guard';
import { ProfileSettingComponent } from './profile/profile-setting/profile-setting.component';
import { NeedHelpComponent } from './need-help/need-help.component';
import { OtheruserNeedHelpComponent } from './otheruser-need-help/otheruser-need-help.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ResourcesComponent } from './resources/resources.component';
import { BusinessPageComponent } from './business-page/business-page.component'; 
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InvitationSignupComponent } from './signup/invitation-signup/invitation-signup.component';
import { BusinessListingComponent } from './business-listing/business-listing.component';
import { PageSettingComponent } from './page/page-setting/page-setting.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { ProfileVerificationComponent } from './profile-verification/profile-verification.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { PageExperiencesComponent } from './page/page-experiences/page-experiences.component';
import { BusinessProfileComponent } from './search/business-profile/business-profile.component';
import { StudentProfileComponent } from './search/student-profile/student-profile.component';
import { PageNotFoundComponent } from './error-pages/not-found/not-found.component';
import { StudentSearchComponent } from './search/student-search/student-search.component';
import { SchoolBucketComponent } from './school-bucket/school-bucket.component';
import { SearchExperienceComponent } from './search/search-experience/search-experience.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CommunityGuidelinesComponent } from './community-guidelines/community-guidelines.component';
import { TermsPolicyComponent } from './terms-policy/terms-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PageHelpComponent } from './page/page-help/page-help.component';
import { SearchHelpComponent } from './search/search-help/search-help.component';
import { StudentsComponent  } from './why-signup/students/students.component';
import { OthersComponent  } from './why-signup/others/others.component';

const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'volunteer', component: VolunteerComponent },
  { path: 'student', component: StudentComponent },
  { path: 'business', component: BusinessComponent},
  { path: 'school', component: SchoolComponent},
  { path: 'invitation-signup', component: InvitationSignupComponent},
  { path: 'login', component: LoginTabComponent},
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/profile-business', component: ProfileBusinessComponent, canActivate: [AuthGuard]}, 
  { path: 'page/:page_id/profile-school', component: ProfileSchoolComponent, canActivate: [AuthGuard]},
  { path: 'dashboard', component: StudentDashboardComponent, canActivate: [AuthGuard]},
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/experiences', component: PageExperiencesComponent, canActivate: [AuthGuard]},
  { path: 'message-chat', component: MessageChatComponent, canActivate: [AuthGuard]},
  { path: 'profile/message-chat/chat', component: SidechatComponent, canActivate: [AuthGuard]},
  { path: 'verification', component: AccountVerificationComponent},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'parent-verification', component:ParentVerificationComponent},
  { path: 'profile/setting', component: ProfileSettingComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/setting', component: PageSettingComponent, canActivate: [AuthGuard]},
  { path: 'help', component:NeedHelpComponent},
  { path: 'help/contact/:user_id', component:OtheruserNeedHelpComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/help', component:PageHelpComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id/contact', component:SearchHelpComponent, canActivate: [AuthGuard]},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'faq', component:ResourcesComponent},
  { path: 'create-page', component: BusinessPageComponent, canActivate: [AuthGuard]},
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard]},
  { path: 'page-listing', component: BusinessListingComponent, canActivate: [AuthGuard]},
  { path: 'mental-health', component:MentalHealthComponent},
  { path: 'createbusinesspage', component: CreateBusinessPageComponent, canActivate: [AuthGuard]},
  { path: 'createnpopage', component: CreateNpoPageComponent, canActivate: [AuthGuard]},
  { path: 'create-clubteam-page', component: CreateClubteamPageComponent, canActivate: [AuthGuard]},
  { path: 'profile-verification', component: ProfileVerificationComponent},
  { path: 'help-page', component: HelpPageComponent, canActivate: [AuthGuard]},
  { path: 'school/:id', component: SchoolBucketComponent, canActivate: [AuthGuard]},
  { path: 'profile/:user_id', component: StudentProfileComponent, canActivate: [AuthGuard]},
  { path: 'page/:page_id', component: BusinessProfileComponent, canActivate: [AuthGuard]},
  { path: 'select-search', component: SearchPageComponent, canActivate: [AuthGuard]},
  { path: 'search-page', component: SearchPageComponent, canActivate: [AuthGuard]},
  { path: 'search-profiles', component: SearchProfilesComponent, canActivate: [AuthGuard]},
  { path: 'student-search', component: StudentSearchComponent, canActivate: [AuthGuard]},
  { path: 'search-experiences', component: SearchExperienceComponent, canActivate: [AuthGuard]},
  { path: 'about-us', component: AboutUsComponent},
  { path: 'guidelines', component: CommunityGuidelinesComponent},
  { path: 'terms-of-use', component: TermsPolicyComponent},
  { path: 'privacy-policy', component: PrivacyPolicyComponent},
  { path: 'why-signup-students', component: StudentsComponent},
  { path: 'why-signup-others', component: OthersComponent},
  { path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

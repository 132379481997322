import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})

export class ProfileHeaderComponent implements OnInit {
  modalRef: BsModalRef;
  mediaURL = environment.mediaURL;
  user: any = {};
  verifiers = [];
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  uploadForm: FormGroup;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  profileImage: string;
  profileHeader: string = "";
  @Input() account_verification_status:string;
  @Input() days_left_to_verify:string;
  reportUser:boolean = true;
  public isMinor: boolean = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  public isHeaderToDel: boolean = false;
  public delError: boolean = false;
  delErrorMessage = "Unable to delete the image. Please try again later.";

  constructor(private modalService: BsModalService, private profileService: ProfileService, private router: Router, public formBuilder: FormBuilder) {
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required])
    });
  }


  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.age_group && this.user.age_group == 1) {
      this.isMinor = true;
    } else {
      this.isMinor = false;
    }
    if (this.user.display_picture){
      this.profileImage = this.mediaURL + this.user.display_picture;
    }
    if (this.user.header_image){
      this.profileHeader = this.mediaURL + this.user.header_image;
    }
    this.getVerifiers();
  }

  getVerifiers() {
    this.profileService.listVerifiers()
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      })
  }

  openModal(template: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  openVerifiersModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  openSetting(settingModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(settingModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  showImg(headerImages: TemplateRef<any>) {
    this.modalRef = this.modalService.show(headerImages,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  addHeaderImage(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      model = {
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value,
        'header_img': true,
        'display_pic': false,
      }
      this.profileService.ProfileImage(model, this.user.id)
        .subscribe(response => {
          this.handleRes(response);
        }, error => {
          this.handleError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleRes(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.user.header_image = response.response.header_image;
      localStorage.setItem('user', JSON.stringify(this.user));
      if (this.user.header_image){
        this.profileHeader = this.mediaURL + this.user.header_image;
      }
    }
  }

  protected handleError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  closeDiv(){
    this.account_verification_status = '2'
  }

  addImage(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      model = {
        'document': this.uploadForm.get('document').value,
        'document_name': this.uploadForm.get('document_name').value,
        'display_pic': true,
        'header_img': false,
      }
      this.profileService.ProfileImage(model, this.user.id)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.user.display_picture = response.response.display_picture;
      localStorage.setItem('user', JSON.stringify(this.user));
      if (this.user.display_picture){
        this.profileImage = this.mediaURL + this.user.display_picture;
      }
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  delImageModal(delImage: TemplateRef<any>, header_image: boolean = false) {
    this.delError = false;
    if (header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.modalRef = this.modalService.show(delImage,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  deleteImage(){
    this.profileService.delProfileImage({'header_img': this.isHeaderToDel})
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.modalRef.hide();
        if (this.isHeaderToDel) {
          this.profileHeader = null;
          this.user.header_image = null;
        } else {
          this.profileImage = null;
          this.user.display_picture = null;
        }
        localStorage.setItem('user', JSON.stringify(this.user));
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.modalRef.hide();
      this.delError = false;
    }, 6000);
   }

}

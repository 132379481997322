<section class="profile-head business-section" *ngIf="isClubTeam">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 p-0"
                [ngClass]="{'volunteer': pagesDetails.page_type == 2, 'business': pagesDetails.page_type == 1, 'school': [3,4].indexOf(pagesDetails.page_type) > -1 }">
                <div class="green-sidebarSec">
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <div class="top-sticky">
                    <div class="profile-sec media pb-3">
                        <div class="headerDiv">
                            <img *ngIf="pageHeaderImgName" src="{{pageHeaderImg}}" class="headerImg" alt="header">
                            <img *ngIf="!pageHeaderImgName" src="assets/images/default-header.jpg" class="headerImg" alt="header">
                        </div>
                        <form class="formImg">
                            <img *ngIf="page_Image_name" src="{{page_Image}}" class="profileImg" alt="header">
                            <img *ngIf="!page_Image_name" src="assets/images/default.svg" class="profileImg" alt="header">
                        </form>
                        <form>
                            <div class="media-body ml-md-3">
                                <h1>{{pagesDetails?.page_info?.title}}</h1>
                                <p>{{pagesDetails?.page_info?.address}}</p>
                                <p *ngIf="pagesDetails.club_team" class=""><span class="pipe">|</span><span
                                        class="cursor-pointer" *ngIf="pagesDetails?.club_team?.school"
                                        [routerLink]="['/school/' + pagesDetails?.club_team?.school?.id ]">{{pagesDetails?.club_team?.school?.school_name}}</span>
                                </p>
                                <ul class="d-sm-flex pl-0 mb-1 align-items-center">
                                    <li *ngIf="pagesDetails.website_url">
                                        <a style="text-transform: unset;" [href]="updateLink(pagesDetails?.website_url)"
                                            target="_blank">{{pagesDetails?.website_url}}</a>
                                    </li>
                                    <li *ngIf="pagesDetails.phone_number">
                                        <a href="tel:{{pagesDetails?.phone_number}}">
                                            {{pagesDetails?.phone_number}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
                <tabset class="side-links tabsetSide schoolSide">
                    <tab>
                        <ng-template tabHeading>
                            <img  alt="image" src="assets/images/user.svg" class=" d-md-inline-block d-none" />
                            <span class="d-md-none d-block">Profile</span>
                        </ng-template>
                        <tabset class="topLinks">
                            <tab>
                                <ng-template tabHeading>
                                    <h3>Details</h3>
                                </ng-template>
                                <div class="content-section business-content fullPage">
                                    <div *ngIf="hasData" class="profile-div">
                                        <div class="profile-inner" *ngIf="(profile | json) != '{}'">
                                            <h3>About Us</h3>
                                            <div class="box-div cursor-pointer" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="inner-div" *ngIf="(profile | json) != '{}'"
                                                    (click)="openModal(descriptionTemplate)">
                                                    <ul class="pl-0 d-flex">
                                                        <li *ngIf="profile.profile_keyword_1">
                                                            <p>{{profile.profile_keyword_1}}</p>
                                                        </li>
                                                        <li *ngIf="profile.profile_keyword_2">
                                                            <p>{{profile.profile_keyword_2}}</p>
                                                        </li>
                                                        <li *ngIf="profile.profile_keyword_3">
                                                            <p>{{profile.profile_keyword_3}}</p>
                                                        </li>
                                                    </ul>
                                                    <p class="px-1" style="white-space: pre-wrap;">{{profile.description}}</p>
                                                </div>
                                                <div *ngIf="(profile | json) == '{}'"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>Explain what you do</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner light-shade" *ngIf="teachers?.length > 0">
                                            <h3>Teachers</h3>
                                            <carousel *ngIf="teachers?.length > 0">
                                                <slide *ngFor="let teacher of teachers">
                                                    <div class="box-div inner-exp">
                                                        <div class="experience-div media cursor-pointer" (click)="updateAboutModal(aboutModal, teacher)">
                                                            <img height="130px" width="130px"
                                                                *ngIf="teacher.profile_image"
                                                                src="{{teacher.profile_image}}">
                                                            <div *ngIf="!teacher.profile_image">
                                                                <svg width="130px" height="130px" viewBox="0 0 130 130"
                                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                    <title>Artboard</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Artboard" stroke="none" stroke-width="1"
                                                                        fill="none" fill-rule="evenodd">
                                                                        <rect id="Rectangle" fill="#C0CBD7" x="0" y="0"
                                                                            width="130" height="130" rx="12"></rect>
                                                                        <g id="image1"
                                                                            transform="translate(21.000000, 21.000000)"
                                                                            fill="#FFFFFF" fill-rule="nonzero">
                                                                            <path
                                                                                d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                                                                id="Shape"></path>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="media-body ml-2">
                                                                <h4>{{teacher.first_name}} {{teacher.last_name}}</h4>
                                                                <p>{{teacher.position}}</p>
                                                                <h5>Start
                                                                    <span>{{teacher.start_year}}</span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </slide>
                                            </carousel>
                                        </div>
                                        <div class="profile-inner calenderProfile" *ngIf="events.length > 0">
                                            <div>
                                                <div class="col-md-12 text-center">
                                                    <h3 class="month-title">
                                                        {{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                                </div>
                                                <div class="col-md-12">
                                                    <div [ngSwitch]="view" class="mt-4">
                                                        <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                                            [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                            [activeDayIsOpen]="activeDayIsOpen"
                                                            (dayClicked)="dayClicked($event.day)" [refresh]="refresh">
                                                        </mwl-calendar-month-view>
                                                        <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                                            [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                            [refresh]="refresh">
                                                        </mwl-calendar-week-view>
                                                        <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                                            [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                            [refresh]="refresh">
                                                        </mwl-calendar-day-view>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner" *ngIf="exec_members?.length > 0" >
                                            <h3>Executive Team</h3>
                                            <div class="box-div" slimScroll
                                                [options]="opts" [scrollEvents]="scrollEvents">
                                                <form class="p-4">
                                                    <div class="row align-items-center"
                                                        *ngFor="let member of exec_members">
                                                        <div class="col-md-6 text-right mb-2">
                                                            <label for="">{{member.position_title}}</label>
                                                        </div>
                                                        <div class="col-md-6 mb-2">
                                                            <input readonly type="text" class="form-control"
                                                                value="{{member.full_name}}">
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="profile-inner full-inner member-listing" *ngIf="schoolInvolvementMembers?.length > 0">
                                            <h3>Members</h3>
                                            <h5 class="title">{{schoolInvolvementMembers?.length}} Students</h5>
                                            <div class="box-div d-flex mt-3 flex-wrap border-radius-0">
                                                <div class="student-box color1-border text-center"
                                                    *ngFor="let member of schoolInvolvementMembers">
                                                    <div class="student">
                                                        <img
                                                            src="{{member.user_display_pic? mediaURL + member.user_display_pic : 'assets/images/profile-img.png'}}">
                                                    </div>
                                                    <h4>{{member.title}}</h4>
                                                    <p>{{member.position}}</p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div *ngIf="!hasData"
                                        class="fullPage d-flex align-items-center justify-content-center">
                                        <div class="empty-div">
                                            <p>
                                                No Data Exists
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>
                                    <h3 (click)="updBulletin()">Library</h3>
                                </ng-template>
                                <div class="content-section business-content fullPage">
                                    <div class="profile-div" *ngIf="bulletins?.length > 0">
                                        <div class="profile-inner full-inner">
                                            <h3>Bulletin Board</h3>
                                            <ngx-slick-carousel *ngIf="isLibrary"
                                                class="box-div library-div carousel" #slickModal="slick-carousel"
                                                [config]="slideConfig">
                                                <div *ngFor="let bulletin of bulletins" class="img-cont slide"
                                                    ngxSlickItem (click)="openJobModal(bulletinDetail, bulletin)">
                                                    <div *ngIf="bulletin.document" class="img-div">
                                                        <img *ngIf="isImage(bulletin.document_name)" src="{{bulletin.document}}">
                                                        <img *ngIf="!isImage(bulletin.document_name)" src="assets/images/pdf-icon.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document" class="img-div">
                                                        <img src="assets/images/default.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                </div>
                                            </ngx-slick-carousel>
                                        </div>
                                    </div>
                                    <div *ngIf="!isLibrary"
                                        class="fullPage d-flex align-items-center justify-content-center">
                                        <div class="empty-div">
                                            <p>
                                                No Data Exists
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g fill="none" fill-rule="evenodd">
                                    <rect width="24" height="24" fill="#335175" rx="12"
                                        transform="matrix(-1 0 0 1 24 0)" />
                                    <g fill="#FFF" fill-rule="nonzero">
                                        <path
                                            d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                            transform="translate(7.2 6)" />
                                    </g>
                                </g>
                            </svg>
                        </ng-template>
                        <div class="media-body">
                            <h1 class="settingText">Need Help?</h1>
                        </div>
                        <div class="content-section pt-3">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>
                                        Page Types
                                    </h3>
                                    <div class="box-div">
                                        <div class="inner-div">
                                            <h4 class="bg-text">Click on any of the following page types to view what it
                                                resembles when fully filled
                                                out. These are only examples the information within is not accurate, nor
                                                does
                                                it
                                                represent anybody's real profile. </h4>
                                            <div class="d-flex justify-content-around flex-wrap">
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openStudent(studentModal)">
                                                    <img  alt="image" src="assets/images/student-icon.svg">
                                                    <h5>Student</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openBusiness(businessModal)">
                                                    <img  alt="image" src="assets/images/business-icon.svg">
                                                    <h5>Business</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openVolunteer(volunteerModal)">
                                                    <img  alt="image" src="assets/images/non-icon.svg">
                                                    <h5>Non-Profit</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openSchool(schoolModal)">
                                                    <img  alt="image" src="assets/images/school-icon.svg">
                                                    <h5>School Club</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner mt-md-4">
                                    <app-contact-us-form [displayColClass]="true"></app-contact-us-form>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <!-- <ul>
                            <li class="active">
                                <a [routerLink]="['/page/' + page_id]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                                    true}">
                                    <img  alt="image" src="assets/images/user.svg"  class=" d-md-inline-block d-none"/>
                                    <span class="d-md-none d-block">Profile</span>
                                </a>
                            </li> 
                            <li class="bg-bsns d-md-block d-none">
                                <a [routerLink]="['/page/' + page_id + '/contact']" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: page_id }">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <rect width="24" height="24" fill="#335175" rx="12" transform="matrix(-1 0 0 1 24 0)"/>
                                            <g fill="#FFF" fill-rule="nonzero">
                                                <path d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z" transform="translate(7.2 6)"/>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>  
                        </ul> -->
                </tabset>
            </div>
        </div>
    </div>
</section>
<section class="profile-head business-section" *ngIf="!isClubTeam">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 p-0" [ngClass]="isNPO ? 'volunteer': 'business'">
                <div class="green-sidebarSec">
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <div class="top-sticky">
                    <div class="profile-sec media pb-3">
                        <div class="headerDiv">
                            <img *ngIf="pageHeaderImgName" src="{{pageHeaderImg}}" class="headerImg">
                            <img *ngIf="!pageHeaderImgName" src="assets/images/default-header.jpg" class="headerImg">
                        </div>
                        <form class="formImg">
                            <img *ngIf="page_Image_name" src="{{page_Image}}" class="profileImg">
                            <img *ngIf="!page_Image_name" src="assets/images/p-img.png" class="profileImg">
                        </form>
                        <form>
                            <div class="media-body ml-md-3">
                                <h1>{{pagesDetails?.page_info?.title}}</h1>
                                <p>{{pagesDetails?.page_info?.address}}</p>
                                <p *ngIf="pagesDetails.club_team" class=""><span
                                        class="pipe">|</span>{{pagesDetails?.club_team?.school?.school_name}}</p>
                                <ul class="d-flex pl-0 mb-1 align-items-center">
                                    <li *ngIf="pagesDetails.website_url">
                                        <a style="text-transform: unset;" [href]="updateLink(pagesDetails?.website_url)"
                                            target="_blank">{{pagesDetails?.website_url}}</a>
                                    </li>
                                    <li *ngIf="pagesDetails.phone_number">
                                        <a href="tel:{{pagesDetails?.phone_number}}">
                                            {{pagesDetails?.phone_number}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </form>
                    </div>
                </div>
                <tabset class="side-links tabsetSide schoolSide">
                    <tab>
                        <ng-template tabHeading>
                            <img  alt="image" src="assets/images/user.svg" class=" d-md-inline-block d-none" />
                            <span class="d-md-none d-block">Profile</span>
                        </ng-template>

                        <tabset class="topLinks">
                            <tab>
                                <ng-template tabHeading>
                                    <h3>Details</h3>
                                </ng-template>
                                <div class="content-section business-content">
                                    <div *ngIf="hasData" class="profile-div">
                                        <div class="profile-inner" *ngIf="(profile | json) != '{}'">
                                            <h3>About Us</h3>
                                            <div class="box-div cursor-pointer" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="inner-div" *ngIf="(profile | json) != '{}'"
                                                    (click)="openModal(descriptionTemplate)">
                                                    <ul class="pl-0 d-flex">
                                                        <li *ngIf="profile.profile_keyword_1">
                                                            <p>{{profile.profile_keyword_1}}</p>
                                                        </li>
                                                        <li *ngIf="profile.profile_keyword_2">
                                                            <p>{{profile.profile_keyword_2}}</p>
                                                        </li>
                                                        <li *ngIf="profile.profile_keyword_3">
                                                            <p>{{profile.profile_keyword_3}}</p>
                                                        </li>
                                                    </ul>
                                                    <p class="px-1" style="white-space: pre-wrap;">{{profile.description}}</p>
                                                </div>
                                                <div *ngIf="(profile | json) == '{}'"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>Explain what you do</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner light-shade" *ngIf="ourTeam?.length > 0">
                                            <h3>Our Team</h3>
                                            <carousel *ngIf="ourTeam?.length > 0">
                                                <slide *ngFor="let team_member of ourTeam">
                                                    <div class="box-div inner-exp">
                                                        <div class="experience-div media cursor-pointer"
                                                            (click)="openJobModal(teamTemplateStatic, team_member)">
                                                            <img height="130px" width="130px"
                                                                *ngIf="team_member.profile_image"
                                                                src="{{team_member.profile_image}}">
                                                            <div *ngIf="!team_member.profile_image">
                                                                <svg width="130px" height="130px" viewBox="0 0 130 130"
                                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                    <title>Artboard</title>
                                                                    <desc>Created with Sketch.</desc>
                                                                    <g id="Artboard" stroke="none" stroke-width="1"
                                                                        fill="none" fill-rule="evenodd">
                                                                        <rect id="Rectangle" fill="#C0CBD7" x="0" y="0"
                                                                            width="130" height="130" rx="12"></rect>
                                                                        <g id="image1"
                                                                            transform="translate(21.000000, 21.000000)"
                                                                            fill="#FFFFFF" fill-rule="nonzero">
                                                                            <path
                                                                                d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                                                                id="Shape"></path>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                            <div class="media-body ml-2">
                                                                <h4 class="mt-md-1">{{team_member.first_name}}
                                                                    {{team_member.last_name}}</h4>
                                                                <p>{{team_member.position}}</p>
                                                                <h5>Start
                                                                    <span>{{team_member.start_year}}</span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </slide>
                                            </carousel>
                                            <div *ngIf="ourTeam?.length == 0" class="box-div">
                                                <div
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No Bio for Managers, Admin.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner" *ngIf="jobPostings?.length > 0">
                                            <h3>Employment Postings</h3>
                                            <div *ngIf="jobPostings?.length > 0">
                                                <div class=" listing-box scroll-activity" slimScroll [options]="opts"
                                                    [scrollEvents]="scrollEvents">
                                                    <div class=" business-border business-div p-0 box-div cursor-pointer"
                                                        *ngFor="let job_post of jobPostings"
                                                        (click)="openJobModal(jobTemplateStatic, job_post)">
                                                        <h4 class="color mb-0">{{job_post.title}}</h4>
                                                        <p [innerHtml]="job_post.description"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isNPO && volunteerPostings?.length > 0" class="profile-inner">
                                            <h3>Volunteer Postings</h3>
                                            <div *ngIf="volunteerPostings?.length > 0" >
                                                <div class=" listing-box scroll-activity" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                    <div class="box-div business-border business-div cursor-pointer"
                                                        *ngFor="let volunteer_post of volunteerPostings"
                                                        (click)="openJobModal(volunteerTemplateStatic, volunteer_post)">
                                                        <h4 class="color">{{volunteer_post.title}}</h4>
                                                        <p [innerHtml]="volunteer_post.description"></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner" *ngIf="csrs?.length > 0">
                                            <h3>cause and social responsibility</h3>
                                            <div class="heightDiv box-div business-div m-0 scrollDiv" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div *ngIf="csrs?.length > 0" class="listing-box csrBox">
                                                    <div *ngFor="let csr of csrs" class="box-div no-height cursor-pointer"
                                                        (click)="openJobModal(csrModal, csr)">
                                                        <h4 class="">{{csr.title}}</h4>
                                                        <p>{{csr.description}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner"
                                            *ngIf="showLocation && [3,4].indexOf(pagesDetails.page_type) === -1">
                                            <h3>Location</h3>
                                            <div class="box-div border-radius-0 no-height">
                                                <div class="d-flex flex-wrap">
                                                    <div class="mapContainer">
                                                        <h3 class="addressField"
                                                            title="{{pagesDetails?.page_info?.address}}">
                                                            {{pagesDetails?.page_info?.address}}</h3>
                                                        <div id="mapContainer" class=" mx-0"></div>
                                                    </div>
                                                    <div class="hoursDiv text-md-right">
                                                        <div class="innerHour mb-2"
                                                            *ngFor="let bwh of businessWorkingHours">
                                                            <h4>
                                                                {{bwh.start_weekday | dayOfWeekAsStringTransform}} –
                                                                {{bwh.end_weekday | dayOfWeekAsStringTransform}}</h4>
                                                            <p *ngFor="let time of bwh.timings">
                                                                {{time.start_time | convertFrom24To12Format}} –
                                                                {{time.end_time | convertFrom24To12Format}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!hasData"
                                        class="fullPage d-flex align-items-center justify-content-center">
                                        <div class="empty-div">
                                            <p>
                                                No Data Exists
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>
                                    <h3 (click)="updBulletin()">Library</h3>
                                </ng-template>
                                <div class="content-section business-content fullPage">
                                    <div class="profile-div" *ngIf="bulletins?.length > 0">
                                        <div class="profile-inner full-inner">
                                            <h3>Bulletin Board</h3>
                                            <ngx-slick-carousel *ngIf="isLibrary"
                                                class="box-div library-div carousel" #slickModal="slick-carousel"
                                                [config]="slideConfig">
                                                <div *ngFor="let bulletin of bulletins" class="img-cont slide"
                                                    ngxSlickItem (click)="openJobModal(bulletinDetail, bulletin)">
                                                    <div *ngIf="bulletin.document" class="img-div">
                                                        <img *ngIf="isImage(bulletin.document_name)" src="{{bulletin.document}}">
                                                        <img *ngIf="!isImage(bulletin.document_name)" src="assets/images/pdf-icon.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document" class="img-div">
                                                        <img src="assets/images/default.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                </div>
                                            </ngx-slick-carousel>
                                        </div>
                                    </div>
                                    <div *ngIf="!isLibrary"
                                        class="fullPage d-flex align-items-center justify-content-center">
                                        <div class="empty-div">
                                            <p>
                                                No Data Exists
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </tab>

                    <tab>
                        <ng-template tabHeading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g fill="none" fill-rule="evenodd">
                                    <rect width="24" height="24" fill="#335175" rx="12"
                                        transform="matrix(-1 0 0 1 24 0)" />
                                    <g fill="#FFF" fill-rule="nonzero">
                                        <path
                                            d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                            transform="translate(7.2 6)" />
                                    </g>
                                </g>
                            </svg>
                        </ng-template>
                        <div class="media-body">
                            <h1 class="settingText">Need Help?</h1>
                        </div>
                        <div class="content-section pt-3">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>
                                        Page Types
                                    </h3>
                                    <div class="box-div">
                                        <div class="inner-div">
                                            <h4 class="bg-text">Click on any of the following page types to view what it
                                                resembles when fully filled
                                                out. These are only examples the information within is not accurate, nor
                                                does
                                                it
                                                represent anybody's real profile. </h4>
                                            <div class="d-flex justify-content-around flex-wrap">
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openStudent(studentModal)">
                                                    <img  alt="image" src="assets/images/student-icon.svg">
                                                    <h5>Student</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openBusiness(businessModal)">
                                                    <img  alt="image" src="assets/images/business-icon.svg">
                                                    <h5>Business</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openVolunteer(volunteerModal)">
                                                    <img  alt="image" src="assets/images/non-icon.svg">
                                                    <h5>Non-Profit</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openSchool(schoolModal)">
                                                    <img  alt="image" src="assets/images/school-icon.svg">
                                                    <h5>School Club</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner mt-md-4">
                                    <app-contact-us-form [displayColClass]="true"></app-contact-us-form>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <!-- <ul>
                        <li class="active">
                            <a [routerLink]="['/page/' + page_id]" routerLinkActive="active-link"
                                [routerLinkActiveOptions]="{exact:
                                true}">
                                <img  alt="image" src="assets/images/user.svg" class=" d-md-inline-block d-none" />
                                <span class="d-md-none d-block">Profile</span>
                            </a>
                        </li>
                        <li class="bg-bsns d-md-block d-none">
                            <a [routerLink]="['/page/' + page_id + '/contact']" routerLinkActive="active-link"
                                [routerLinkActiveOptions]="{ exact: false, __change_detection_hack__: page_id }">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g fill="none" fill-rule="evenodd">
                                        <rect width="24" height="24" fill="#335175" rx="12"
                                            transform="matrix(-1 0 0 1 24 0)" />
                                        <g fill="#FFF" fill-rule="nonzero">
                                            <path
                                                d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                                transform="translate(7.2 6)" />
                                        </g>
                                    </g>
                                </svg>
                            </a>
                        </li>
                    </ul> -->
                </tabset>
            </div>
        </div>
    </div>
</section>
<ng-template #csrModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Cause and social responsiblity</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div">
                        <h3>Title
                            <span>{{jobs.title}}</span>
                        </h3>
                        <h3>Detail</h3>
                        <p>{{jobs.description}}</p>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin Board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <carousel>
                <slide>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="inner-modal d-flex align-items-center">
                                <h2>Title</h2>
                                <p>{{jobs.title}}</p>
                            </div>
                            <div class="divDetail">
                                <h2>Attachments & Links</h2>
                                <div class="d-flex mt-3">
                                    <figure *ngIf="jobs.document">
                                        <a *ngIf="!isImage(jobs.document_name)" href="{{jobs.document}}"
                                            target="_blank" class="link-style">{{jobs.document_name}}</a>
                                        <a *ngIf="isImage(jobs.document_name)" href="{{jobs.document}}"
                                            target="_blank">
                                            <img  alt="image" src="{{jobs.document}}">
                                        </a>
                                    </figure>
                                    <div class="uploadDetail ml-3">
                                        <a href="{{jobs.link}}" target="_blank">{{jobs.link}}</a>
                                    </div>
                                </div>
                                <div class="content-detail">
                                    <h2>Details</h2>
                                    <p>{{jobs.description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
        </div>
    </div>
</ng-template>
<ng-template #descriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">About Us</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top 3 Keywords</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <p>{{profile.profile_keyword_1}}</p>
                            </div>
                            <div class="col-md-4">
                                <p>{{profile.profile_keyword_2}}</p>
                            </div>
                            <div class="col-md-4">
                                <p>{{profile.profile_keyword_3}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <p>{{profile.description}}</p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #teamTemplateStatic>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Our Team</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body about-div">
        <carousel>
            <slide>
                <div class="media">
                    <img *ngIf="jobs.profile_image" src="{{jobs.profile_image}}">
                    <div *ngIf="!jobs.profile_image">
                        <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <title>Artboard</title>
                            <desc>Created with Sketch.</desc>
                            <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130" rx="12"></rect>
                                <g id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF"
                                    fill-rule="nonzero">
                                    <path
                                        d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                        id="Shape"></path>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div class="media-body ml-md-3">
                        <h2>Name
                            <span>{{jobs.first_name}} {{jobs.last_name}}</span>
                        </h2>
                        <h2>Position
                            <span>{{jobs.position}}</span>
                        </h2>
                        <h2>Start
                            <span>{{jobs.start_year}}</span>
                        </h2>
                    </div>
                </div>
                <div class="about-detail mt-3">
                    <h2>About</h2>
                    <p>{{jobs.description}}</p>
                </div>
            </slide>
        </carousel>
    </div>
</ng-template>
<ng-template #jobTemplateStatic>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Employment Postings</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Title</h3>
                            </div>
                            <div class="col-6">
                                <h4>{{jobs.title}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Date Posted</h3>
                            </div>
                            <div class="col-6">
                                <h4>{{jobs.created_date|date}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>How to Apply</h3>
                            </div>
                            <div class="col-12">
                                <h4 class="ml-0">{{jobs.how_to_apply}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Description</h3>
                            </div>
                            <div class="col-12">
                                <h4 [innerHtml]="jobs.description" class="ml-0"></h4>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #volunteerTemplateStatic>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Volunteer Postings</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Title
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.title}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Date Posted
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.created_date|date}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Address
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.address}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Start Date
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.start_date}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    End Date
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.end_date}}
                                </h4>
                            </div>
                        </div>
                        <div class="row" *ngIf="jobs.start_time">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Start Time
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.start_time | convertFrom24To12Format}}
                                </h4>
                            </div>
                        </div>
                        <div class="row" *ngIf="jobs.hours">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Hours
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{jobs.hours}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Description
                                </h3>
                            </div>
                            <div class="col-12">
                                <h4 [innerHtml]="jobs.description">
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    How to Apply
                                </h3>
                            </div>
                            <div class="col-12">
                                <h4>
                                    {{jobs.how_to_apply}}
                                </h4>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #studentModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/student-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #businessModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/business-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #volunteerModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/volunteer-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #schoolModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/school-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #aboutModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Our Teachers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body about-div">
        <div class="media">
            <img *ngIf="editTeacher.profile_image" src="{{editTeacher.profile_image}}">
            <div *ngIf="!editTeacher.profile_image">
                <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Artboard</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130" rx="12"></rect>
                        <g id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path
                                d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="media-body ml-md-3">
                <h2>Name
                    <span>{{editTeacher.first_name}} {{editTeacher.last_name}}</span>
                </h2>
                <h2>Position
                    <span>{{editTeacher.position}}</span>
                </h2>
                <h2>Start
                    <span>{{editTeacher.start_year}}</span>
                </h2>
            </div>
        </div>
        <div class="about-detail mt-3">
            <h2>About</h2>
            <p>{{editTeacher.description}}</p>
        </div>
    </div>
</ng-template>
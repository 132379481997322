import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  mainSidebar: boolean = true;
  modalRef: BsModalRef;
  infoSlide: boolean = false;
  inviteForm: FormGroup;
  public submitted: boolean = false;
  public infoDiv = true;

  constructor(private router: Router, private modalService: BsModalService, public formBuilder: FormBuilder, private authService: AuthenticationService) {

    this.inviteForm = this.formBuilder.group({
      invite_code: new FormControl('', Validators.required)
    });

   }
 

  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
  slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.router.navigate(['/dashboard']);
    }
  } 

  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }

  checkCode(isValid: boolean){
    if (isValid) {
      const invite_code = this.inviteForm.get('invite_code').value;
      this.authService.verifyInviteCode(invite_code)
      .subscribe(response => {
        if (response.status === 111) {
          this.inviteForm.get('invite_code').setErrors({ aftersubmit: 'Sorry, the used invitation code is incorrect, please try again with the correct code.' });
        } else if (response.status === 200) {
            this.inviteForm.reset();
            this.submitted = false;
            localStorage.setItem('invited-user', JSON.stringify(response.response))
            this.router.navigate(['/invitation-signup']);
        }
      },
        error => {
          this.inviteForm.get('invite_code').setErrors({ aftersubmit: 'Sorry, the used invitation code is incorrect, please try again with the correct code.' });
        });
    }

  }

}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EventCalendarComponent } from './event-calendar/event-calendar.component'
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { VolunteerComponent } from './volunteer/volunteer.component';
import { StudentComponent } from './student/student.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BusinessComponent } from './business/business.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileStudentComponent } from './profile/profile-student/profile-student.component';
import { SidebarInnerComponent } from './sidebar-inner/sidebar-inner.component';
import { CarouselModule, ModalModule, BsDropdownModule, RatingModule } from 'ngx-bootstrap';
import { ProfileBusinessComponent } from './profile-business/profile-business.component';  
import { ProfileSchoolComponent } from './profile-school/profile-school.component'; 
import { StudentDashboardComponent } from './profile/student-dashboard/student-dashboard.component'; 
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { LoginComponent } from './login/login.component';
import { SearchPageComponent } from './search/search-page/search-page.component';
import { LoginTabComponent } from './login/login-tab/login-tab.component';
import { NotificationsComponent } from './profile/notifications/notifications.component';
import { SchoolComponent } from './school/school.component';
import { HeaderLoginComponent } from './header-login/header-login.component'; 
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BaseService, httpServiceCreator } from './services/base.service';
import { MessageChatComponent } from './profile/message-chat/message-chat.component';
import { SidechatComponent } from './profile/message-chat/sidechat/sidechat.component';
import { GooglePlacesComponent } from './google-places/google-places.component';
import { AccountVerificationComponent } from './account-verification/account-verification.component';
import { AuthInterceptor } from './shared/_interceptors/auth.interceptor';
import { AppConfigService, appInitializer } from './services/app.config.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgSlimScrollModule, SLIMSCROLL_DEFAULTS } from 'ngx-slimscroll';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ParentVerificationComponent } from './parent-verification/parent-verification.component';
import { TabsModule } from 'ngx-bootstrap'; 
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AuthGuard } from './shared/_guards/auth.guard';
import { ProfileSettingComponent } from './profile/profile-setting/profile-setting.component';
import { NeedHelpComponent } from './need-help/need-help.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { EducationComponent } from './profile/education/education.component';
import { WorkExperienceComponent } from './profile/work-experience/work-experience.component';
import { ResourcesComponent } from './resources/resources.component';
import { OurTeamComponent } from './profile-business/our-team/our-team.component';
import { VolunteerExperienceComponent } from './profile/volunteer-experience/volunteer-experience.component';
import { JobPostingComponent } from './profile-business/job-posting/job-posting.component';
import { VolunteerPostingComponent } from './profile-business/volunteer-posting/volunteer-posting.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SchoolInvolvementComponent } from './profile/school-involvement/school-involvement.component';
import { ContactUsFormComponent } from './contact-us-form/contact-us-form.component';
import { AzureMapsComponent } from './azure-maps/azure-maps.component';
import { TimeFormatPipe } from './shared/_pipes/timeConvert';
import { LoaderComponent } from './loader/loader.component';
import { LoaderInterceptor } from './shared/_interceptors/loader.interceptor';
import { LoaderService } from './services/loader.service';
import { BusinessPageComponent } from './business-page/business-page.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { InvitationSignupComponent } from './signup/invitation-signup/invitation-signup.component';
import { BusinessListingComponent } from './business-listing/business-listing.component';
import { ProfileHeaderComponent } from './profile/profile-header/profile-header.component';
import { PageSettingComponent } from './page/page-setting/page-setting.component';
import { PageHeaderComponent } from './page/page-header/page-header.component';
import { PageSidebarComponent } from './page/page-sidebar/page-sidebar.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { CreateBusinessPageComponent } from './create-business-page/create-business-page.component';
import { CreateClubteamPageComponent } from './create-clubteam-page/create-clubteam-page.component';
import { CreateNpoPageComponent } from './create-npo-page/create-npo-page.component';
import { ProfileVerificationComponent } from './profile-verification/profile-verification.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { PageExperiencesComponent } from './page/page-experiences/page-experiences.component';
import { BusinessProfileComponent } from './search/business-profile/business-profile.component';
import { StudentProfileComponent } from './search/student-profile/student-profile.component';
import { PageNotFoundComponent } from './error-pages/not-found/not-found.component';
import { StudentSearchComponent } from './search/student-search/student-search.component';
import { SchoolBucketComponent } from './school-bucket/school-bucket.component';
import { OtheruserNeedHelpComponent } from './otheruser-need-help/otheruser-need-help.component';
import { SearchProfilesComponent } from './search/search-profiles/search-profiles.component';
import { SearchExperienceComponent } from './search/search-experience/search-experience.component';
import { AzureSearchMapsComponent } from './azure-maps/azure-search-maps/azure-search-maps.component';
import { dayOfWeekAsString } from './services/daystostring';
import { NameSearchPipe } from './services/nameSearch';
import { AboutUsComponent } from './about-us/about-us.component';
import { CommunityGuidelinesComponent } from './community-guidelines/community-guidelines.component';
import { TermsPolicyComponent } from './terms-policy/terms-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { PageEventCalendarComponent } from './page-event-calendar/page-event-calendar.component';
import { PageHelpComponent } from './page/page-help/page-help.component';
import { SearchHelpComponent } from './search/search-help/search-help.component';
import { OthersComponent } from './why-signup/others/others.component';
import { StudentsComponent } from './why-signup/students/students.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent, 
    HeaderComponent,
    HomeComponent,
    VolunteerComponent,
    StudentComponent,
    SidebarComponent,
    BusinessComponent,
    ProfileComponent,
    ProfileStudentComponent,
    SidebarInnerComponent,
    ProfileBusinessComponent,  
    ProfileSchoolComponent,
    StudentDashboardComponent,
    LoginComponent,
    SearchPageComponent,
    LoginTabComponent,
    NotificationsComponent,
    SchoolComponent,
    HeaderLoginComponent,  
    MessageChatComponent,
    SidechatComponent,
    GooglePlacesComponent,
    AccountVerificationComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ParentVerificationComponent,
    EventCalendarComponent,
    ProfileSettingComponent,
    NeedHelpComponent,
    ContactUsComponent,
    EducationComponent,
    WorkExperienceComponent,
    ResourcesComponent,
    OurTeamComponent,
    VolunteerExperienceComponent,
    JobPostingComponent,
    VolunteerPostingComponent,
    SchoolInvolvementComponent,
    ContactUsFormComponent,
    AzureMapsComponent,
    TimeFormatPipe,
    LoaderComponent,
    BusinessPageComponent,
    UserProfileComponent,
    InvitationSignupComponent,
    BusinessListingComponent,
    ProfileHeaderComponent,
    PageSettingComponent,
    PageHeaderComponent,
    PageSidebarComponent,
    MentalHealthComponent,
    CreateBusinessPageComponent,
    CreateClubteamPageComponent,
    CreateNpoPageComponent,
    ProfileVerificationComponent,
    HelpPageComponent,
    PageExperiencesComponent,
    BusinessProfileComponent,
    StudentProfileComponent,
    PageNotFoundComponent,
    OtheruserNeedHelpComponent,
    StudentSearchComponent,
    SchoolBucketComponent,
    SearchProfilesComponent,
    SearchExperienceComponent,
    AzureSearchMapsComponent,
    dayOfWeekAsString,
    NameSearchPipe,
    AboutUsComponent,
    CommunityGuidelinesComponent,
    TermsPolicyComponent,
    PrivacyPolicyComponent,
    PageEventCalendarComponent,
    PageHelpComponent,
    SearchHelpComponent,
    OthersComponent,
    StudentsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    RatingModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    BsDropdownModule.forRoot(), 
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgSlimScrollModule,
    NgbModule,
    TabsModule.forRoot(),
    SlickCarouselModule,
    CKEditorModule,
    InfiniteScrollModule,
  ],
  providers: [
    AppConfigService,
    AuthGuard,
    LoaderService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AppConfigService], multi: true },
    { provide: BaseService, useFactory: httpServiceCreator, deps: [HttpClient] },
    {      provide: SLIMSCROLL_DEFAULTS,
      useValue: {
        alwaysVisible: true,
        gridOpacity: '1', barOpacity: '0.5',
        gridBackground: '#fff',
        gridWidth: '6',
        gridMargin: '2px 2px',
        barBackground: '#335175',
        barWidth: '6',
        barMargin: '2px 2px'
      }
    },
  ],
  exports: [
    TimeFormatPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ProfileService } from '../services/profile.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { PageService } from '../services/page.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { environment } from '../../environments/environment';
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'app-profile-school',
  templateUrl: './profile-school.component.html',
  styleUrls: ['./profile-school.component.scss']
})
export class ProfileSchoolComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  modalRef: BsModalRef;
  activeDayIsOpen: boolean = true;
  loggedIn: boolean = true;
  user: any = {};
  editBulletin: any = {};
  editTeacher: any = {};
  profile: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  editExecTeam: any = {};
  public editable: boolean = false;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  bulletins = [];
  teachers = [];
  exec_members = [];
  uploadForm: FormGroup;
  bulletinForm: FormGroup;
  descriptionForm: FormGroup;
  teacherForm: FormGroup;
  execTeamForm: FormGroup;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  maxNum: number = new Date().getFullYear();
  page_id: any;
  is_Page = true;
  pagesDetails: any= {};
  pageVerified: string;
  pageVerificationDaysLeft: string;
  pageType: string;
  pageName: string
  pageProfessionalEmail: string;
  schoolInvolvementMembers = [];
  mediaURL = environment.mediaURL;
  isLibrary: boolean = false;

  constructor(private modal: NgbModal, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder,
    private customValidator: CustomvalidationService, private profileService: ProfileService, private authService: AuthenticationService,
    private route: ActivatedRoute, private pageService: PageService) {

      this.uploadForm = this.formBuilder.group({
        document: [''],
        document_name: ['']
      });
      this.bulletinForm = this.formBuilder.group({
        title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
        description: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
        link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
      });
      this.descriptionForm = this.formBuilder.group({
        profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
        profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
        description: new FormControl('', [Validators.required, Validators.maxLength(1750)]),
        profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
      });
      this.teacherForm = this.formBuilder.group({
        first_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
        last_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
        description: new FormControl('', Validators.maxLength(500)),
        position: new FormControl('', [Validators.required, Validators.maxLength(75)]),
        start_year: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)])
      });
      this.execTeamForm = this.formBuilder.group({
        title: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        name: new FormControl('', [Validators.required, Validators.maxLength(50)])
      });

    }

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.getTeachers();
    this.getExecTeam();
    this.authService.PageDetails(this.page_id)
        .subscribe(response => {
          if (response.status === 200) {
            this.pagesDetails = response.response;
          } else {
            this.router.navigate(['/dashboard']);
          }
        });
    this.getBulletin();
    this.pageService.getPageDescription(this.page_id)
    .subscribe(response => {
      if(response.response['id'] !== undefined){
        this.profile = response.response;
      }
    })
    this.profileService.BusinessVerificationStatus(this.page_id)
    .subscribe(response => {
 
      this.pageVerified = response.response.business_verified
      this.pageVerificationDaysLeft = response.response.days_left
      this.pageType = response.response.page_type
      this.pageName = response.response.page_info.name
      this.pageProfessionalEmail = response.response.page_info.email
    })

    this.profileService.UserSchoolInvolvementMembers(this.page_id).subscribe(response => {
      this.schoolInvolvementMembers = response.response
    })
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive":[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  updBulletin() {
    if (this.bulletins.length > 0) {
      this.isLibrary = true;
      if (!this.slickModal){
        setTimeout(() => {
          if (!this.slickModal.initialized) {
            this.slickModal.initSlick();
          }
        }, 1000);
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    } else {
      this.isLibrary = false;
    }
  }

  disableEdit(){
    this.editable = !this.editable;
  }

  getBulletin(){
    this.pageService.getPageBulletin(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
          this.isLibrary = false;
        }
      });
  }

  getTeachers(){
    this.profileService.getSchoolTeachers(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.teachers = response.response;
        } else {
          this.teachers = [];
        }
      });
  }

  getExecTeam(){
    this.profileService.getSchoolExecTeam(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.exec_members = response.response;
        } else {
          this.exec_members = [];
        }
      });
  }

  onFileSelect(event, doc_type: any) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg","jpeg","png", "JPG","JPEG","PNG", "pdf","doc","docx"];
      if (doc_type == "image"){
        allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
      }
      if(!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        if (doc_type == "image"){
          this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
        } else {
          this.fileUploadMessage = "Only jpg, png, pdf, docx and doc files allowed.";
        }
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if(fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image/Document size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if(!this.fileUploadError && !this.fileUploadSizeError){
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
          )[1];
          this.uploadForm.get('document').setValue(base64String);
          this.uploadForm.get('document_name').setValue(file_name);
        };
      }
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true
    } else {
      return false
    }
  }

  bulletinModal(buletinModal: TemplateRef<any>) {
    this.bulletinForm.reset();
    this.submitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.modalRef = this.modalService.show(buletinModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal'})
    );
  }

  updatebulletinModal(bulletinDetail: TemplateRef<any>, editbulletinModal: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    if (this.editable) {
      this.bulletinForm.controls['description'].setValue(bulletin.description);
      this.bulletinForm.controls['title'].setValue(bulletin.title);
      this.bulletinForm.controls['link'].setValue(bulletin.link);
      this.modalRef = this.modalService.show(editbulletinModal,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal'})
      );
    } else {
      this.modalRef = this.modalService.show(bulletinDetail,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal'})
      );
    }
  }

  openDescModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.submitted = false;
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
      this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1);
      this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2);
      this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3);
    }
    this.modalRef = this.modalService.show(template,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  openAboutModal(addAboutTemplate: TemplateRef<any>) {
    this.teacherForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(addAboutTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateAboutModal(aboutModal: TemplateRef<any>, editAboutTemplate: TemplateRef<any>, teacher: any) {
    this.editTeacher = teacher;
    if (this.editable) {
      this.teacherForm.controls['description'].setValue(teacher.description);
      this.teacherForm.controls['first_name'].setValue(teacher.first_name);
      this.teacherForm.controls['last_name'].setValue(teacher.last_name);
      this.teacherForm.controls['start_year'].setValue(teacher.start_year);
      this.teacherForm.controls['position'].setValue(teacher.position);
      this.modalRef = this.modalService.show(editAboutTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
      );
    } else {
      this.modalRef = this.modalService.show(aboutModal,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
      );
    }
  }

  openExecModal(execTeamTemplate: TemplateRef<any>) {
    this.execTeamForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(execTeamTemplate,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  openUpdExecModal(execTeamTemplate: TemplateRef<any>, member: any) {
    if (this.editable){
      this.execTeamForm.reset();
      this.submitted = false;
      if (Object.keys(member).length !== 0) {
        this.editExecTeam = member;
        this.execTeamForm.controls['title'].setValue(member.position_title);
        this.execTeamForm.controls['name'].setValue(member.full_name);
      }
      this.modalRef = this.modalService.show(execTeamTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
      );
    }
  }

  updateLink(link: string){
    let prevLink = this.bulletinForm.get('link').value;
    if (link.includes('http://') || link.includes('https://')) { 
      return prevLink;
    } else {
      let newLink = 'http://' + prevLink;
      return newLink
    }
  }

  addBulletin(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    if(isValid) {
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {'document': this.uploadForm.get('document').value,
                  'page': this.page_id,
                  'title': this.bulletinForm.get('title').value,
                  'description': this.bulletinForm.get('description').value,
                  'link': link,
                  'document_name': this.uploadForm.get('document_name').value}
      this.pageService.addPageBulletin(model)
      .subscribe(response => {
        this.handleBulletinResponse(response);
      }, error => {
          this.handleBulletinSubmitError(error.error);
      })
    } else {
      this.disableSubmit = false;
    }
  }

  updateBulletin(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editBulletin.document_name;
      if (prev_doc_name !== null){
        doc_name = prev_doc_name;
      }
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {'document': this.uploadForm.get('document').value,
                  'page': this.page_id,
                  'title': this.bulletinForm.get('title').value,
                  'description': this.bulletinForm.get('description').value,
                  'link': link,
                  'document_name': doc_name}
      this.pageService.updatePageBulletin(model, this.editBulletin.id)
      .subscribe(response => {
        this.handleBulletinUpdResponse(response);
      }, error => {
          this.handleBulletinSubmitError(error.error);
      })
    }
  }

  delBulletin(){
    this.pageService.deletePageBulletin(this.editBulletin.id)
    .subscribe(response => {
      this.getBulletin();
      this.modalRef.hide();
      this.editBulletin = {};
      this.bulletinForm.reset();
    })
  }

  removeBulletinDoc(id: any) {
    let model = {'document': null,
                  'page': this.page_id,
                  'title': this.editBulletin['title'],
                  'description': this.editBulletin['description'],
                  'link': this.editBulletin['link'],
                  'document_name': null}
    this.pageService.updatePageBulletin(model, this.editBulletin.id)
      .subscribe(response => {
        this.editBulletin["document"] = null;
        this.editBulletin["document_name"] = null;
      })
  }

  protected handleBulletinResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.bulletins.push(response.response);
        this.updBulletin();
        this.bulletinForm.reset();
        this.uploadForm.reset();
        this.submitted = false;
        this.disableSubmit = false;
    }
  }

  protected handleBulletinUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
        this.getBulletin();
        this.modalRef.hide();
        this.bulletinForm.reset();
        this.uploadForm.reset();
        this.submitted = false;
    }
  }

  protected handleBulletinSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.bulletinForm.contains(field)) {
        this.bulletinForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveDescription(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      let model = {'description': this.descriptionForm.get('description').value,
                  'profile_keyword_1': this.descriptionForm.get('profile_keyword_1').value,
                  'profile_keyword_2': this.descriptionForm.get('profile_keyword_2').value,
                  'profile_keyword_3': this.descriptionForm.get('profile_keyword_3').value,
                  'page': this.page_id}
        this.pageService.addPageDescription(model)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
            this.handleDescSubmitError(error.error);
        })
    }
  }

  updateDescription(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      let model = {'description': this.descriptionForm.get('description').value,
                  'profile_keyword_1': this.descriptionForm.get('profile_keyword_1').value,
                  'profile_keyword_2': this.descriptionForm.get('profile_keyword_2').value,
                  'profile_keyword_3': this.descriptionForm.get('profile_keyword_3').value,
                  'page': this.page_id}
        this.pageService.updatePageDescription(model, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
            this.handleDescSubmitError(error.error);
        })
    }
  }

  protected handleDescResponse(response: any) {
    if (response.status === 111) {
      this.handleDescSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.profile = response.response;
        this.descriptionForm.reset();
        this.submitted = false;
    }
  }

  protected handleDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addSchoolTeacher(isValid: boolean) {
    this.submitted = true;
    if(isValid) {
        let model = {'profile_image': this.uploadForm.get('document').value,
                    'page': this.page_id,
                    'first_name': this.teacherForm.get('first_name').value,
                    'description': this.teacherForm.get('description').value,
                    'last_name': this.teacherForm.get('last_name').value,
                    'position': this.teacherForm.get('position').value,
                    'start_year': this.teacherForm.get('start_year').value,
                    'image_name': this.uploadForm.get('document_name').value}
        this.profileService.addSchoolTeacher(model)
        .subscribe(response => {
          this.handleTeacherResponse(response);
        }, error => {
            this.handleTeacherSubmitError(error.error);
        })
    }
  }

  updateSchoolTeacher(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editTeacher.image_name;
      if (prev_doc_name !== null){
        doc_name = prev_doc_name;
      }
      let model = {'profile_image': this.uploadForm.get('document').value,
                    'page': this.page_id,
                    'first_name': this.teacherForm.get('first_name').value,
                    'description': this.teacherForm.get('description').value,
                    'last_name': this.teacherForm.get('last_name').value,
                    'position': this.teacherForm.get('position').value,
                    'start_year': this.teacherForm.get('start_year').value,
                    'image_name': doc_name}
      this.profileService.updateSchoolTeacher(model, this.editTeacher.id)
      .subscribe(response => {
        this.handleTeacherUpdResponse(response);
      }, error => {
          this.handleTeacherSubmitError(error.error);
      })
    }
  }

  delTeacher(){
    this.profileService.deleteSchoolTeacher(this.editTeacher.id)
    .subscribe(response => {
      this.getTeachers();
      this.modalRef.hide();
      this.editTeacher = {};
      this.teacherForm.reset();
    })
  }

  removeTeacherImage(id: any) {
    let model = {'profile_image': null,
                  'page': this.page_id,
                  'first_name': this.editTeacher['first_name'],
                  'description': this.editTeacher['description'],
                  'last_name': this.editTeacher['last_name'],
                  'start_year': this.editTeacher['start_year'],
                  'position': this.editTeacher['position'],
                  'image_name': null}
    this.profileService.updateSchoolTeacher(model, this.editTeacher.id)
      .subscribe(response => {
        this.editTeacher["profile_image"] = null;
        this.editTeacher["image_name"] = null;
      })
  }

  protected handleTeacherResponse(response: any) {
    if (response.status === 111) {
      this.handleTeacherSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.teachers.push(response.response);
        this.teacherForm.reset();
        this.uploadForm.reset();
        this.submitted = false;
    }
  }

  protected handleTeacherUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleTeacherSubmitError(response.message);
    } else if (response.status === 200) {
        this.getTeachers();
        this.modalRef.hide();
        this.teacherForm.reset();
        this.uploadForm.reset();
        this.submitted = false;
    }
  }

  protected handleTeacherSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.teacherForm.contains(field)) {
        this.teacherForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveExecTeam(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      let model = {'position_title': this.execTeamForm.get('title').value,
                  'page': this.page_id,
                  'full_name': this.execTeamForm.get('name').value}
        this.profileService.addSchoolExecTeam(model)
        .subscribe(response => {
          this.handleExecTeamResponse(response);
        }, error => {
            this.handleExecTeamSubmitError(error.error);
        })
    }
  }

  updateExecTeam(isValid: boolean){
    this.submitted = true;
    if(isValid) {
      let model = {'position_title': this.execTeamForm.get('title').value,
                    'page': this.page_id,
                    'full_name': this.execTeamForm.get('name').value}
        this.profileService.updateSchoolExecTeam(model, this.editExecTeam.id)
        .subscribe(response => {
          this.handleExecTeamResponse(response);
        }, error => {
            this.handleExecTeamSubmitError(error.error);
        })
    }
  }

  delExecTeam(){
    this.profileService.deleteSchoolExecTeam(this.editExecTeam.id)
    .subscribe(response => {
      this.modalRef.hide();
      this.getExecTeam();
      this.editExecTeam = {};
      this.execTeamForm.reset();
    })
  }

  protected handleExecTeamResponse(response: any) {
    if (response.status === 111) {
      this.handleExecTeamSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.getExecTeam();
        this.execTeamForm.reset();
        this.submitted = false;
    }
  }

  protected handleExecTeamSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.execTeamForm.contains(field)) {
        this.execTeamForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

}
 
 <section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img  alt="image" src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student form-section">
                    <app-sidebar></app-sidebar>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center mx-auto">
                            <h1 class="pl-2 pr-2">Forgot Password</h1>
                            <form *ngIf="!submitted" class="" [formGroup]="emailForm" novalidate (ngSubmit)="submit(emailForm.valid)">
                                <div class="form-group gutters-5 row">
                                    <div class="col-md-12">
                                        <label>Email</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="email" class="form-control mr-0" formControlName="email" placeholder="E-mail">
                                        <div class="error-message" *ngIf="emailForm.get('email').hasError('required') && (emailForm.get('email').dirty || emailForm.get('email').touched)">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-submit" [disabled]="!emailForm.valid">Submit</button>
                                </div>
                            </form>
                            <span *ngIf="submitted" class="text-center success-msg d-inline-block">If there is an account associated with email entered, you will receive an email with a link to
                                reset your password.</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
<div class="info-overlay" *ngIf="infoSlide">
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <img  alt="image" src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none">
        <img  alt="image" src="assets/images/info-mobile.svg" class="d-md-none d-block">
    </div>
</div>
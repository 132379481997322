import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { ProfileService } from '../../services/profile.service';
import { IEducationExisting, ISchool, IEducationNew } from '../../shared/education';
import { canadaStates, usStates, collegeType, schoolType } from '../../app.data';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit {
  modalRef: BsModalRef;
  user: any = {};
  disableSubmit: boolean = false;
  educationForm: FormGroup;
  newSchoolForm: FormGroup;
  schoolEdForm: FormGroup;
  collegeEdForm: FormGroup;
  currentEdForm: number = 1;
  nextClicked: boolean = false;
  doneClicked: boolean = false;
  viewDate: Date = new Date();
  showPrev: boolean = false;
  showNext: boolean = true;
  showDone: boolean = false;
  schools: any[] = [];
  schoolId: string;
  public caStates: any[] = canadaStates;
  public usStates: any[] = usStates;
  public schoolTypes: any[] = schoolType;
  public collegeTypes: any[] = collegeType;
  public showCaStates: boolean = true;
  plchldrState: string = "PR";
  plchldrPostal: string = "Postal";
  insText: string = "School"
  isCollegeSelected: boolean = false;
  hideResults: boolean = true;
  edFormSubmitted: boolean = false;
  eduList: IEducationNew[] = [];
  eduEditObj: string = null;
  addNewEdu: boolean = true;
  @Input() editable: boolean = false;
  @Input() account_verification_status: string
  districtValidators: any[] = [Validators.maxLength(50)];
  showDistrict: boolean = true;
  public showAddressError: boolean = false;
  updateAddress: string = '';
  searchPlaces: any[] = [];
  latitude: string;
  longitude: string;
  hidePlaceResults: boolean = true;
  countrySet: string = 'CA';

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService, private authService: AuthenticationService,) {

    this.educationForm = this.formBuilder.group({
      is_college: new FormControl('', [Validators.required,]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    });
    this.newSchoolForm = this.formBuilder.group({
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      district: new FormControl('', this.districtValidators),
      school_type: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
    });
    this.schoolEdForm = this.formBuilder.group({
      interests: new FormControl('', [this.arrayElementLengthValidator]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    }, { validators: this.startEndValidator });
    this.collegeEdForm = this.formBuilder.group({
      minors: new FormControl('', [this.arrayElementLengthValidator]),
      majors: new FormControl('', [this.arrayElementLengthValidator]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    }, { validators: this.startEndValidator });
  }

  startEndValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    return start_date && end_date && start_date > end_date ? {
       'startEndValid': true } : null;
  };

  arrayElementLengthValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let array_str = control.value;
    let isValid = true;
    if (array_str == null || array_str == ''){
      isValid = true;
    } else {
      let arr = array_str.split(',');
      for (var product of arr) {
        if (product.length > 100){
          isValid = false;
          break;
        }
      }
    }
    return !isValid ? { 'arrElemValid': true } : null;
  };

  ngOnInit(): void {
    this.showCaStates = true;
    this.getEducationList();
  }

  eduformInit(){
    this.disableSubmit = false;
    this.educationForm.reset();
    this.newSchoolForm.reset();
    this.collegeEdForm.reset();
    this.schoolEdForm.reset();
    this.currentEdForm = 1;
    this.doneClicked = false;
    this.nextClicked = false;
    this.plchldrState = "PR";
    this.plchldrPostal = "Postal";
    this.insText = "School"
    this.isCollegeSelected = false;
    this.setNextPrevDone(true, false, false);
    this.educationForm.controls['country'].setValue('Canada');
    this.educationForm.controls['is_college'].setValue("false");
    this.districtValidators = [Validators.maxLength(50)];
    this.showDistrict = true;
    this.newSchoolForm.get('district').clearValidators();
    this.newSchoolForm.get('district').setValidators(this.districtValidators);
  }

  eduModal(educationModal: TemplateRef<any>) {
    this.updateAddress = '';
    this.addNewEdu = true;
    this.eduEditObj = null;
    this.showCaStates = true;
    this.eduformInit();
    this.modalRef = this.modalService.show(educationModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  getCurrentEdForm(form_nm: number=1, is_college:boolean=false){
    if (form_nm === 1 || form_nm === 2){
      return (form_nm === this.currentEdForm);
    } else {
      if (this.currentEdForm === 3) {
        if (is_college && this.isCollegeSelected){
          return true;
        } else if (!is_college && !this.isCollegeSelected){
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  setNextPrevDone(next:boolean, prev:boolean, done: boolean){
    this.showNext = next;
    this.showPrev = prev;
    this.showDone = done;
  }

  getNextEd(){
    this.nextClicked = true;
    if (this.currentEdForm === 1 && this.educationForm.valid){
      if (this.schoolId == null){
        this.currentEdForm = 2;
        this.setNextPrevDone(true, true, false);
      } else {
        this.currentEdForm = 3;
        this.setNextPrevDone(false, true, true);
      }
      this.nextClicked = false;
    } else if (this.currentEdForm === 2 && this.newSchoolForm.valid){
      this.currentEdForm = 3;
      this.setNextPrevDone(false, true, true);
      this.nextClicked = false;
    }
  }

  getPrevEd(){
    if (this.currentEdForm === 2){
      this.currentEdForm = 1;
      this.setNextPrevDone(true, false, false);
    } else if (this.currentEdForm === 3){
      if (this.schoolId == null){
        this.currentEdForm = 2;
        this.setNextPrevDone(true, true, false);
      } else {
        this.currentEdForm = 1;
        this.setNextPrevDone(true, false, false);
      }
    }
  }

  getDoneClicked(){
    if (this.isCollegeSelected && this.collegeEdForm.valid){
      this.doneClicked = false;
      this.addEducation();
    } else if (!this.isCollegeSelected && this.schoolEdForm.valid){
      this.doneClicked = false;
      this.addEducation();
    } else {
      this.doneClicked = true;
    }
  }

  changeCountry(e){
    this.educationForm.controls['state'].setValue(null);
    this.educationForm.controls['school_name'].setValue('');
    this.schoolId = null;
    this.schools = [];
    if (e.target.value == "USA"){
      this.showCaStates = false;
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
    } else {
      this.showCaStates = true;
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
    }
  }

  changeSchoolCollege(e){
    this.newSchoolForm.controls['school_type'].setValue('');
    this.educationForm.controls['school_name'].setValue('');
    this.schoolEdForm.controls['interests'].setValue('');
    this.collegeEdForm.controls['majors'].setValue('');
    this.collegeEdForm.controls['minors'].setValue('');
    this.schoolId = null;
    this.schools = [];
    if (e.target.value === "true") {
      this.insText = 'College/University'; 
      this.isCollegeSelected = true;
      this.districtValidators = [Validators.maxLength(50)];
      this.showDistrict = false;
    } else {
      this.insText = 'School'
      this.isCollegeSelected = false;
      this.districtValidators = [Validators.required, Validators.maxLength(50)];
      this.showDistrict = true;
    }
    this.newSchoolForm.get('district').clearValidators();
    this.newSchoolForm.get('district').setValidators(this.districtValidators);
  }

  changeState(e){
    this.educationForm.controls['school_name'].setValue('');
    this.schoolId = null;
    this.schools = [];
  }

  searchSchool(e){
    let key = e.target.value;
    this.schoolId = null;
    if (key !== null && key.length > 0) {
      this.schools = [];
      let state = this.educationForm.get('state').value;
      if (state == null) {
        state = "";
      }
      let country = this.educationForm.get('country').value;
      let is_college = this.educationForm.get('is_college').value;
      let params = key.toLowerCase() + '&country=' + country.toLowerCase();
      params += '&state=' + state.toLowerCase() + '&is_college=' + is_college;
      this.authService.searchSchool(params)
      .subscribe(response => {
        if (response.status == 200){
          this.schools = response.response;
          if (this.schools.length > 0) {
            this.hideResults = false;
          } else {
            this.hideResults = true;
          }
        }
      });
    } else {
      this.hideResults = true;
      this.schools = [];
    }
  }

  selectSchool(school) {
    this.hideResults = true;
    this.educationForm.controls['school_name'].setValue(school.school_name);
    this.schoolId = school.id;
    this.schools = [];
  }

  focusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.schools = [];
    }, 1000);
  }

  getDateString(_date: Date) {
    return _date.getFullYear().toString() + '-' 
      + (_date.getMonth() + 1).toString().padStart(2, "0") 
      + '-' + _date.getDate().toString().padStart(2, "0");
  }

  setEdInterfaceValue(iEducation){
    if (this.isCollegeSelected){
      iEducation.majors = this.collegeEdForm.controls['majors'].value.split(',');
      iEducation.minors = this.collegeEdForm.controls['minors'].value.split(',');
      iEducation.start_date = this.getDateString(this.collegeEdForm.controls['start_date'].value);
      iEducation.end_date = this.getDateString(this.collegeEdForm.controls['end_date'].value);
    } else {
      let interest = this.schoolEdForm.controls['interests'].value;
      if (this.schoolEdForm.controls['interests'].value) {
        interest = this.schoolEdForm.controls['interests'].value.split(',');
      }
      iEducation.interests = interest;
      iEducation.start_date = this.getDateString(this.schoolEdForm.controls['start_date'].value);
      iEducation.end_date = this.getDateString(this.schoolEdForm.controls['end_date'].value);
    }
    return iEducation;
  }

  addEducation(is_update:boolean = false) {
    this.edFormSubmitted = true;
    this.disableSubmit = true;
    if (this.schoolId) {
      let iEducation: IEducationExisting = {
        'school': parseInt(this.schoolId),
        'start_date': this.getDateString(this.viewDate),
        'end_date': this.getDateString(this.viewDate)
      };
      iEducation = this.setEdInterfaceValue(iEducation);
      if (is_update){
        this.profileService.updateExistingEducation(iEducation, this.eduEditObj)
          .subscribe(response => {
            this.handleEduResponse(response);
          }, error => {
            this.handleEduSubmitError(error.error);
          });
      } else {
        this.profileService.addExistingEducation(iEducation)
        .subscribe(response => {
          this.handleEduResponse(response);
        }, error => {
          this.handleEduSubmitError(error.error);
        });
      }
    } else {
      let iSchool: ISchool = {
        'school_name': this.educationForm.controls['school_name'].value,
        'address': this.newSchoolForm.controls['address'].value,
        'city': this.newSchoolForm.controls['city'].value,
        'country': this.educationForm.controls['country'].value,
        'state': this.educationForm.controls['state'].value,
        'school_category': this.newSchoolForm.controls['school_type'].value,
        'district': this.newSchoolForm.controls['district'].value,
        'zip_code': this.newSchoolForm.controls['zip_code'].value,
        'is_college': this.isCollegeSelected,
        'latitude': this.latitude,
        'longitude': this.longitude
      }
      let iEducation: IEducationNew = {
        'school': iSchool,
        'start_date': this.getDateString(this.viewDate),
        'end_date': this.getDateString(this.viewDate)
      }
      iEducation = this.setEdInterfaceValue(iEducation);
      if (is_update){
        this.profileService.updateNewEducation(iEducation, this.eduEditObj)
          .subscribe(response => {
            this.handleEduResponse(response);
          }, error => {
            this.handleEduSubmitError(error.error);
          });
      } else{
        this.profileService.addNewEducation(iEducation)
          .subscribe(response => {
            this.handleEduResponse(response);
          }, error => {
            this.handleEduSubmitError(error.error);
          });
        }
    }
  }

  protected handleEduResponse(response: any) {
    if (response.status === 111) {
      this.handleEduSubmitError(response.message);
    } else if (response.status === 200) {
        this.disableSubmit = false;
        this.modalRef.hide();
        this.eduEditObj = null;
        this.edFormSubmitted = false;
        this.getEducationList();
    }
  }

  protected handleEduSubmitError(data: any) {
    this.edFormSubmitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      const formList: FormGroup[] = [this.educationForm,this.newSchoolForm,  this.schoolEdForm, this.collegeEdForm]
      formList.forEach((formGroup: FormGroup) => {
        if (formGroup.contains(field)) {
          formGroup.setErrors({aftersubmit: true});
        }
      });
    });
  }

  getEducationList(){
    this.profileService.listEducation()
    .subscribe(response => {
      if(response.response.length > 0){
          this.eduList = response.response;
        } else {
          this.eduList = [];
        }
      });
  }

  updateEducationPopup(edPopup: TemplateRef<any>, staticEdPopup: TemplateRef<any>, ed_id: string) {
    let showModal;
    if (this.editable){
       showModal = edPopup;
    } else {
      showModal = staticEdPopup;
    }
    this.profileService.RetreiveEducation(ed_id)
      .subscribe(response => {
        if(response.response.id && response.status == 200 ){
            let educationObj = response.response;
            this.eduformUpdate(educationObj);
            this.eduEditObj = educationObj.id;
            this.addNewEdu = false;
            this.modalRef = this.modalService.show(
              showModal, Object.assign({}, { 
                class: 'modal-dialog-centered custom-modal'}));
          } else {
            this.getEducationList();
          }
        });
  }

  eduformUpdate(response){
    this.eduformInit();

    this.educationForm.controls['school_name'].setValue(response.school.school_name);
    this.educationForm.controls['state'].setValue(response.school.state);
    this.schoolId = response.school.id;
    this.educationForm.controls['country'].setValue(response.school.country);
    let start_date;
    let end_date;
    if (this.editable){
      start_date = new Date(response.start_date);
      end_date = new Date(response.end_date)
    } else {
      start_date = response.start_date;
      end_date = response.end_date
    }
    if (response.school.is_college){
      this.educationForm.controls['is_college'].setValue("true");
      this.insText = "College/University";
      this.isCollegeSelected = true;
      this.collegeEdForm.controls['start_date'].setValue(start_date);
      this.collegeEdForm.controls['end_date'].setValue(end_date);
      this.collegeEdForm.controls['majors'].setValue(response.majors.toString());
      this.collegeEdForm.controls['minors'].setValue(response.minors.toString());
    } else {
      this.educationForm.controls['is_college'].setValue("false");
      this.insText = "School";
      this.isCollegeSelected = false;
      this.schoolEdForm.controls['start_date'].setValue(start_date);
      this.schoolEdForm.controls['end_date'].setValue(end_date);
      if (response.interests) {
        this.schoolEdForm.controls['interests'].setValue(response.interests.toString());
      } else {
        this.schoolEdForm.controls['interests'].setValue(response.interests);
      }
    }
    
    if (response.school.country == 'Canada'){
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
    } else {
      this.plchldrState = "State";
      this.plchldrPostal = "Zip Code";
    }    

  }

  getUpdateClicked(isDelete:boolean=false){
    if (isDelete){
      this.deleteEducation();
      this.doneClicked = false;
      return;
    }
    if (this.isCollegeSelected && this.collegeEdForm.valid){
      this.doneClicked = false;
      this.addEducation(true);
    } else if (!this.isCollegeSelected && this.schoolEdForm.valid){
      this.doneClicked = false;
      this.addEducation(true);
    } else {
      this.doneClicked = true;
    }
  }

  deleteEducation() {
    let model = {
      'is_deleted': true
    }
    this.profileService.deleteEducation(model, this.eduEditObj)
      .subscribe(response => {
        this.modalRef.hide();
        this.eduEditObj = null;
        this.getEducationList();
      });
  }

  closeModal(){
    this.modalRef.hide();
    this.eduEditObj = null;
  }

  setResultHide(e){ 
    this.hidePlaceResults = e;
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.newSchoolForm.controls['address'].setValue(null);
      this.showAddressError = true;
    } else {
      this.newSchoolForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    } 
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let zip_code = '';
    let address = place.address.freeformAddress.split(",")[0];
    this.updateAddress = address;
    this.newSchoolForm.controls['address'].setValue(address);
    this.newSchoolForm.controls['city'].setValue(place.address.municipality);
    if (place.address.extendedPostalCode && this.countrySet == 'CA'){
      zip_code = place.address.extendedPostalCode;
    } else {
      zip_code = place.address.postalCode;
    }
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    if (zip_code && zip_code.length > 20) {
      zip_code = place.address.postalCode;
    }
    this.newSchoolForm.controls['zip_code'].setValue(zip_code);
    this.educationForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

}

<section class="profile-head business-section">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 school p-0">
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <app-page-header [page]="pagesDetails"></app-page-header>
                <app-page-sidebar [page]="pagesDetails"></app-page-sidebar>
                <div class="row m-0" *ngIf="pageVerified != '3' ">
                    <div class="col-md-12 p-0">
                        <div class="edit-div d-flex  flex-end align-items-center">
                            <p>Edit Mode</p>
                            <label class="switch mb-0 ml-3">
                                <input class="switch-input" type="checkbox" (change)="disableEdit()" />
                                <span class="switch-label" data-on="On" data-off="Off"></span>
                                <span class="switch-handle"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <tabset class="topLinks">
                    <tab>
                        <ng-template tabHeading>
                            <h3>Details</h3>
                        </ng-template>
                        <div class="content-section school-content">
                            <div class="profile-div">
                                <div class="profile-inner bioSection3">
                                    <h3>About Us
                                        <a *ngIf="(profile | json) == '{}' &&  pageVerified != '3' "
                                            href="javascript:void()" class="float-right"
                                            (click)="openDescModal(descriptionTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable && (profile | json) != '{}'"
                                            class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="box-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                        <div class="inner-div cursor-pointer" *ngIf="(profile | json) != '{}'"
                                            (click)="openDescModal(editDescriptionTemplate)">
                                            <ul class="pl-0 d-flex">
                                                <li *ngIf="profile.profile_keyword_1">
                                                    <p>{{profile.profile_keyword_1}}</p>
                                                </li>
                                                <li *ngIf="profile.profile_keyword_2">
                                                    <p>{{profile.profile_keyword_2}}</p>
                                                </li>
                                                <li *ngIf="profile.profile_keyword_3">
                                                    <p>{{profile.profile_keyword_3}}</p>
                                                </li>
                                            </ul>
                                            <p class="px-1" style="white-space: pre-wrap;">{{profile.description}}</p>
                                        </div>
                                        <div *ngIf="(profile | json) == '{}'"
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                            <p>Explain what you do</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner light-shade">
                                    <h3>Teachers
                                        <a *ngIf="!editable && pageVerified != '3' " href="javascript:void()"
                                            class="float-right" (click)="openAboutModal(addAboutTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <carousel *ngIf="teachers?.length > 0">
                                        <slide *ngFor="let teacher of teachers">
                                            <div class="box-div inner-exp">
                                                <div class="experience-div media cursor-pointer"
                                                    (click)="updateAboutModal(aboutModal, editAboutTemplate, teacher)">
                                                    <img height="130px" width="130px" *ngIf="teacher.profile_image"
                                                        src="{{teacher.profile_image}}">
                                                    <div *ngIf="!teacher.profile_image">
                                                        <svg width="130px" height="130px" viewBox="0 0 130 130"
                                                            version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <title>Artboard</title>
                                                            <desc>Created with Sketch.</desc>
                                                            <g id="Artboard" stroke="none" stroke-width="1" fill="none"
                                                                fill-rule="evenodd">
                                                                <rect id="Rectangle" fill="#C0CBD7" x="0" y="0"
                                                                    width="130" height="130" rx="12"></rect>
                                                                <g id="image1"
                                                                    transform="translate(21.000000, 21.000000)"
                                                                    fill="#FFFFFF" fill-rule="nonzero">
                                                                    <path
                                                                        d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                                                        id="Shape"></path>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div class="media-body ml-2">
                                                        <h4>{{teacher.first_name}} {{teacher.last_name}}</h4>
                                                        <p>{{teacher.position}}</p>
                                                        <h5>Start
                                                            <span>{{teacher.start_year}}</span>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </slide>
                                    </carousel>
                                    <div *ngIf="teachers?.length == 0" class="box-div p-0 ">
                                        <div
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center py-0 mt-4">
                                            <p>Add any Teachers</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner calenderProfile">
                                    <app-page-event-calendar [pageId]="page_id" [isEditable]="editable">
                                    </app-page-event-calendar>
                                </div>
                                <div class="profile-inner">
                                    <h3>Executive Team
                                        <a *ngIf="!editable && pageVerified != '3' " href="javascript:void();"
                                            class="float-right" (click)="openExecModal(execTeamTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="box-div" *ngIf="exec_members?.length > 0" slimScroll [options]="opts"
                                        [scrollEvents]="scrollEvents">
                                        <form class="p-4">
                                            <div class="row align-items-center" [ngClass]="{ 'cursor-pointer': editable }" *ngFor="let member of exec_members"
                                                (click)="openUpdExecModal(execTeamTemplate, member)">
                                                <div class="col-md-6 text-right mb-2">
                                                    <label for="">{{member.position_title}}</label>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <input readonly type="text" class="form-control"
                                                        value="{{member.full_name}}">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div *ngIf="exec_members?.length == 0"
                                        class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                        <p>
                                            Add students who are in executive positions
                                        </p>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner member-listing">
                                    <h3>Members</h3>
                                    <h5 class="title">{{schoolInvolvementMembers?.length}} Students</h5>
                                    <div class="box-div d-flex mt-3 flex-wrap">
                                        <div class="student-box color1-border text-center"
                                            *ngFor="let member of schoolInvolvementMembers">
                                            <div class="student">
                                                <img
                                                    src="{{member.user_display_pic? mediaURL + member.user_display_pic : 'assets/images/profile-img.png'}}">
                                            </div>
                                            <h4>{{member.title}}</h4>
                                            <p>{{member.position}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <h3 (click)="updBulletin()">Library</h3>
                        </ng-template>
                        <div class="content-section business-content fullPage">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>Bulletin Board
                                        <a *ngIf="!editable" href="javascript:void()" class="float-right"
                                            (click)="bulletinModal(buletinModal)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                    </h3>
                                    <ngx-slick-carousel *ngIf="isLibrary"
                                        class="box-div library-div carousel" #slickModal="slick-carousel"
                                        [config]="slideConfig">
                                        <div *ngFor="let bulletin of bulletins" class="img-cont slide" ngxSlickItem
                                            (click)="updatebulletinModal(bulletinDetail, editbulletinModal, bulletin)">
                                            <div *ngIf="bulletin.document" class="img-div">
                                                <img *ngIf="isImage(bulletin.document_name)" src="{{bulletin.document}}">
                                                <img *ngIf="!isImage(bulletin.document_name)" src="assets/images/pdf-icon.svg">
                                                <p>{{bulletin.title}}</p>
                                            </div>
                                            <div *ngIf="!bulletin.document" class="img-div">
                                                <img src="assets/images/default.svg">
                                                <p>{{bulletin.title}}</p>
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                    <div *ngIf="!isLibrary"
                                        class="library-div empty-div box-div text-center h-100 d-flex align-items-center justify-content-center">
                                        <p>Place any forms, meeting notes, awards here</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</section>
<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin Board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-modal d-flex align-items-center">
                        <h2>Title</h2>
                        <p>{{editBulletin.title}}</p>
                    </div>
                    <div class="divDetail">
                        <h2>Attachments & Links</h2>
                        <div class="d-flex mt-3">
                            <figure *ngIf="editBulletin.document">
                                <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank" class="link-style">{{editBulletin.document_name}}</a>
                                <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank">
                                    <img  alt="image" src="{{editBulletin.document}}">
                                </a>
                            </figure>
                            <div class="uploadDetail ml-3">
                                <a href="{{editBulletin.link}}" target="_blank">{{editBulletin.link}}</a>
                            </div>
                        </div>
                        <div class="content-detail">
                            <h2>Details</h2>
                            <p>{{editBulletin.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #buletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event, 'file')">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('required') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addBulletin(bulletinForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #editbulletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" [readonly]="!editable" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div *ngIf="editable || editBulletin.document" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add file</label>
                        <label *ngIf="!editable">File</label>
                    </div>
                    <div *ngIf="editable && !editBulletin.document" class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event, 'file')">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editBulletin.document" class="col-md-10">

                        <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank"
                            class="link-style">{{editBulletin.document_name}}</a>
                        <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank">
                            <img  alt="image" src="{{editBulletin.document}}" class="uploaded-img">
                        </a>
                        <a href="javascript:void()" *ngIf="editable" (click)="removeBulletinDoc(editBulletin.id)">
                            <img  alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>
                <div *ngIf="editable || editBulletin.link" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add Link</label>
                        <label *ngIf="!editable">Link</label>
                    </div>
                    <div *ngIf="editable" class="col-md-10">
                        <input type="text" class="form-control modal-control" [readonly]="!editable" placeholder="Link"
                            formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                    <div *ngIf="!editable" class="col-md-10">
                        <a href={{editBulletin.link}} target="_blank" class="link-style">{{editBulletin.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('required') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="updateBulletin(bulletinForm.valid)">
            Update
        </button>
        <button class="btn btn-footer" (click)="delBulletin()">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #descriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">About Us</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top 3 Keywords</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control  " placeholder="Keyword 1"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control " placeholder="Keyword 2"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">

                                <input type="text" class="form-control  " placeholder="Keyword 3"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" placeholder="Description"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 1750 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">About Us</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top 3 Keywords</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control  " placeholder="First Skill"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control  " placeholder="Second Skill"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control  " placeholder="Third Skill"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [readonly]="!editable" formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 1750 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="updateDescription(descriptionForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #aboutModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Our Teachers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body about-div">
        <div class="media">
            <img *ngIf="editTeacher.profile_image" src="{{editTeacher.profile_image}}">
            <div *ngIf="!editTeacher.profile_image">
                <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Artboard</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130" rx="12"></rect>
                        <g id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path
                                d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="media-body ml-md-3">
                <h2>Name
                    <span>{{editTeacher.first_name}} {{editTeacher.last_name}}</span>
                </h2>
                <h2>Position
                    <span>{{editTeacher.position}}</span>
                </h2>
                <h2>Start
                    <span>{{editTeacher.start_year}}</span>
                </h2>
            </div>
        </div>
        <div class="about-detail mt-3">
            <h2>About</h2>
            <p>{{editTeacher.description}}</p>
        </div>
    </div>
</ng-template>
<ng-template #addAboutTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Teachers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="teacherForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>First Name
                            <input type="text" class="form-control" formControlName="first_name"
                                placeholder="First Name">
                            <div class="error-message"
                                *ngIf="teacherForm.get('first_name').hasError('required') && (teacherForm.get('first_name').dirty || teacherForm.get('first_name').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="teacherForm.get('first_name').hasError('maxlength') && (teacherForm.get('first_name').dirty || teacherForm.get('first_name').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>Last Name
                            <input type="text" class="form-control" formControlName="last_name" placeholder="Last Name">
                            <div class="error-message"
                                *ngIf="teacherForm.get('last_name').hasError('required') && (teacherForm.get('last_name').dirty || teacherForm.get('last_name').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="teacherForm.get('last_name').hasError('maxlength') && (teacherForm.get('last_name').dirty || teacherForm.get('last_name').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title Of Position</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" placeholder="Title Of Position"
                            formControlName="position">
                        <div class="error-message"
                            *ngIf="teacherForm.get('position').hasError('required') && (teacherForm.get('position').dirty || teacherForm.get('position').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="teacherForm.get('position').hasError('maxlength') && (teacherForm.get('position').dirty || teacherForm.get('position').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Start Date</label>
                    </div>
                    <div class="col-md-3">
                        <input type="number" class="form-control" placeholder="Start Date Year"
                            formControlName="start_year">
                        <div class="error-message"
                            *ngIf="teacherForm.get('start_year').hasError('required') && (teacherForm.get('start_year').dirty || teacherForm.get('start_year').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="(teacherForm.get('start_year').hasError('min') || teacherForm.get('start_year').hasError('max')) && (teacherForm.get('start_year').dirty || teacherForm.get('start_year').touched)">
                            Please enter valid Year.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Upload Image</label>
                    </div>
                    <div class="col-md-9">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event, 'image')">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea type="text" class="form-control" placeholder="Description"
                            formControlName="description"></textarea>
                        <div class="error-message"
                            *ngIf="teacherForm.get('description').hasError('required') && (teacherForm.get('description').dirty || teacherForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="teacherForm.get('description').hasError('maxlength') && (teacherForm.get('description').dirty || teacherForm.get('description').touched)">
                            Max 500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="addSchoolTeacher(teacherForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editAboutTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Teachers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="teacherForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>First Name
                            <input type="text" class="form-control" formControlName="first_name"
                                placeholder="First Name">
                            <div class="error-message"
                                *ngIf="teacherForm.get('first_name').hasError('required') && (teacherForm.get('first_name').dirty || teacherForm.get('first_name').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="teacherForm.get('first_name').hasError('maxlength') && (teacherForm.get('first_name').dirty || teacherForm.get('first_name').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>Last Name
                            <input type="text" class="form-control" formControlName="last_name" placeholder="Last Name">
                            <div class="error-message"
                                *ngIf="teacherForm.get('last_name').hasError('required') && (teacherForm.get('last_name').dirty || teacherForm.get('last_name').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="teacherForm.get('last_name').hasError('maxlength') && (teacherForm.get('last_name').dirty || teacherForm.get('last_name').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title Of Position</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" placeholder="Title Of Position"
                            formControlName="position">
                        <div class="error-message"
                            *ngIf="teacherForm.get('position').hasError('required') && (teacherForm.get('position').dirty || teacherForm.get('position').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="teacherForm.get('position').hasError('maxlength') && (teacherForm.get('position').dirty || teacherForm.get('position').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Start Date</label>
                    </div>
                    <div class="col-md-3">
                        <input type="number" class="form-control" placeholder="Start Date Year"
                            formControlName="start_year">
                        <div class="error-message"
                            *ngIf="teacherForm.get('start_year').hasError('required') && (teacherForm.get('start_year').dirty || teacherForm.get('start_year').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="(teacherForm.get('start_year').hasError('min') || teacherForm.get('start_year').hasError('max')) && (teacherForm.get('start_year').dirty || teacherForm.get('start_year').touched)">
                            Please enter valid Year.
                        </div>
                    </div>
                </div>
                <div *ngIf="editable || editTeacher.profile_image" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Upload Image</label>
                        <label *ngIf="!editable">Image</label>
                    </div>
                    <div *ngIf="editable && !editTeacher.profile_image" class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event, 'file')">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editTeacher.profile_image" class="col-md-10">
                        <a *ngIf="isImage(editTeacher.image_name)" href="{{editTeacher.profile_image}}" target="_blank">
                            <img  alt="image" src="{{editTeacher.profile_image}}" class="uploaded-img">
                        </a>
                        <a href="javascript:void()" *ngIf="editable" (click)="removeTeacherImage(editTeacher.id)">
                            <img  alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea type="text" class="form-control" placeholder="Description"
                            formControlName="description"></textarea>
                        <div class="error-message"
                            *ngIf="teacherForm.get('description').hasError('required') && (teacherForm.get('description').dirty || teacherForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="teacherForm.get('description').hasError('maxlength') && (teacherForm.get('description').dirty || teacherForm.get('description').touched)">
                            Max 500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="delTeacher()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateSchoolTeacher(teacherForm.valid)">
            Update
        </button>
    </div>
</ng-template>

<ng-template #execTeamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">EXECUTIVE TEAM</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="execTeamForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Position</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control " placeholder="Title of position"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="execTeamForm.get('title').hasError('required') && (execTeamForm.get('title').dirty || execTeamForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="execTeamForm.get('title').hasError('maxlength') && (execTeamForm.get('title').dirty || execTeamForm.get('title').touched)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Name</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control " placeholder="Full Name" formControlName="name">
                        <div class="error-message"
                            *ngIf="execTeamForm.get('name').hasError('required') && (execTeamForm.get('name').dirty || execTeamForm.get('name').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="execTeamForm.get('name').hasError('maxlength') && (execTeamForm.get('name').dirty || execTeamForm.get('name').touched)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" *ngIf="!editable" (click)="saveExecTeam(execTeamForm.valid)">
            Save
        </button>
        <button class="btn btn-footer" *ngIf="editable" (click)="updateExecTeam(execTeamForm.valid)">
            Update
        </button>
        <button class="btn btn-footer" *ngIf="editable" (click)="delExecTeam()">
            Delete
        </button>
    </div>
</ng-template>
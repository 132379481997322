import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { Router } from '@angular/router';
import { PasswordService } from '../services/password.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  emailForm: FormGroup;
  submitted = false;
  infoSlide: boolean = false;
  constructor(private customValidator: CustomvalidationService, public formBuilder: FormBuilder, private router: Router, private passwordService: PasswordService) {

    this.emailForm = formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)])
    })

   }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.router.navigate(['dashboard']);
    }
  }

  
  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }
  submit(isValid: boolean) {
    if(isValid) {
      this.submitted = true;
      this.passwordService.forgotPassword(this.emailForm.get('email').value)
      .subscribe(response => {
        if (response.status === 111) {
          this.submitted = true;          
        } else if (response.status === 200) {
          this.submitted = true;
          this.emailForm.reset();
        }
      })
    }
  }

}

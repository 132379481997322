<h3>Contact Us</h3>
<div class="box-div">
    <form [formGroup]="contactUsForm" novalidate>
        <div class="row">
            <div class="form-group" [ngClass]="{'col-md-4': displayColClass}">
                <label>Full Name <span>*</span></label>
                <input [readonly]="hideNameEmail" type="text" class="form-control" placeholder="Enter Full Name" formControlName="name">
                <div class="error-message" *ngIf="contactUsForm.get('name').hasError('required') && (contactUsForm.get('name').dirty || contactUsForm.get('name').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('name').hasError('maxlength') && (contactUsForm.get('name').dirty || contactUsForm.get('name').touched)">
                    Max 50 characters allowed.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('name').hasError('aftersubmit')">
                    {{contactUsForm.controls.name.errors.aftersubmit}}
                </div>
            </div>
            <div class="form-group" [ngClass]="{'col-md-4': displayColClass}">
                <label>Email <span>*</span></label>
                <input [readonly]="hideNameEmail" type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                <div class="error-message" *ngIf="contactUsForm.get('email').hasError('required') && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('email').hasError('pattern') && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched)">
                    Please enter your email in a valid format.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('email').hasError('aftersubmit')">
                    {{contactUsForm.controls.email.errors.aftersubmit}}
                </div>
            </div>
            <div class="form-group" [ngClass]="{'col-md-4': displayColClass}" >
                <label>Subject <span>*</span></label>
                <input type="text" class="form-control" placeholder="Enter Subject" formControlName="subject">
                <div class="error-message" *ngIf="contactUsForm.get('subject').hasError('required') && (contactUsForm.get('subject').dirty || contactUsForm.get('subject').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('subject').hasError('maxlength') && (contactUsForm.get('subject').dirty || contactUsForm.get('subject').touched)">
                    Max 100 characters allowed.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('subject').hasError('aftersubmit')">
                    {{contactUsForm.controls.subject.errors.aftersubmit}}
                </div>
            </div>
            <div class="form-group" [ngClass]="{'col-md-12': displayColClass}">
                <label>Message <span>*</span></label>
                <textarea class="form-control" placeholder="Type your Message Here" formControlName="message"></textarea>
                <div class="error-message" *ngIf="contactUsForm.get('message').hasError('required') && (contactUsForm.get('message').dirty || contactUsForm.get('message').touched || submitted)">
                    This field is required.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('message').hasError('maxlength') && (contactUsForm.get('message').dirty || contactUsForm.get('message').touched)">
                    Max 500 characters allowed.
                </div>
                <div class="error-message" *ngIf="contactUsForm.get('message').hasError('aftersubmit')">
                    {{contactUsForm.controls.message.errors.aftersubmit}}
                </div>
            </div>
            <div class="form-group col-md-12 text-center" [ngClass]="{'col-md-12': displayColClass, 'text-center': displayColClass}">
                <button class="btn-submit mt-md-0 mt-3" (click)="submitContactUs(successModal)" [disabled]="disableSubmit">Submit</button>
            </div>
        </div>
    </form>
</div>

<ng-template #successModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Contact us form submitted</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg" alt="image">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div text-left">
                    <h3>Thank you for connecting with us!</h3>
                    <p>One of our representatives will get in touch with you shortly.</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import { Component, OnInit, EventEmitter } from '@angular/core';  
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { SearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Map, AuthenticationType } from 'azure-maps-control';
import * as atlas from 'azure-maps-control';

@Component({
  selector: 'app-search-profiles',
  templateUrl: './search-profiles.component.html',
  styleUrls: ['./search-profiles.component.scss']
})
export class SearchProfilesComponent implements OnInit { 
  mediaURL = environment.mediaURL;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  searchForm: FormGroup;
  latitude: string;
  longitude: string;
  hidePlaceResults: boolean = true;
  public showAddressError: boolean = false;
  submitted: boolean = false;
  searchPlaces: any[] = [];
  businessProfiles: any[] = [];
  npoProfiles: any[] = [];
  schoolProfiles: any[] = [];
  updateAddress: string = '';
  searchKeys: any[] = [];
  businessNextPage: any;
  npoNextPage: any;
  schoolNextPage: any;
  totalbusinessProfiles: any;
  totalnpoProfiles: any;
  totalschoolProfiles: any;
  loading: boolean = false;
  placeHolder: string = "My Location";
  activeTab: string = "business";
  key: string = environment.subscriptionKeyAzure;
  map: any;
  showLocation: boolean = true;
  keywordsErr: boolean = false;

  constructor(private searchService: SearchService, public formBuilder: FormBuilder, private authService: AuthenticationService) {
    this.searchForm = this.formBuilder.group({
      search_key: new FormControl('', [Validators.maxLength(50)]),
      location: new FormControl('', Validators.maxLength(200))
    });
  }

  ngOnInit(): void {
    this.profilePrivacy();
    this.initiateSearch();
    this.searchMap();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }

  profilePrivacy(){
    this.authService.ProfilePrivacySettingGet()
    .subscribe(response => {
      if (response.response.length > 0) {
        this.showLocation = response.response[0].location_display;
        if (!this.showLocation) {
          this.placeHolder = "Full Address";
        }
      }
    })
  }

  initiateSearch() {
    let params = this.setParams();
    this.businessProfileSearch(params);
    this.npoProfileSearch(params);
    this.schoolProfileSearch(params);
  }

  businessProfileSearch(params: any) {
    this.searchService.businessProfileSearch("2", params)
    .subscribe(data => {
      this.businessProfiles = data.response.results;
      this.businessNextPage = data.response.next;
      this.totalbusinessProfiles = data.response.total_count;
      this.onTabChange(this.activeTab);
    })
  }

  npoProfileSearch(params: any) {
    this.searchService.businessProfileSearch("1", params)
    .subscribe(data => {
      this.npoProfiles = data.response.results;
      this.npoNextPage = data.response.next;
      this.totalnpoProfiles = data.response.total_count;
      this.onTabChange(this.activeTab);
    })
  }

  schoolProfileSearch(params: any) {
    this.searchService.schoolProfileSearch(params)
    .subscribe(data => {
      this.schoolProfiles = data.response.results;
      this.schoolNextPage = data.response.next;
      this.totalschoolProfiles = data.response.total_count;
      this.onTabChange(this.activeTab);
    })
  }

  setParams() {
    let params = "?search=" + this.searchKeys.join("+");
    if (this.latitude && this.longitude) {
      params += "&lat=" + this.latitude + "&lng=" + this.longitude;
    }
    return params;
  }

  search(isValid: boolean) {
    this.submitted = true;
    if (this.searchForm.get('search_key').value || this.updateAddress.length > 0) {
      let search_key = this.searchForm.get('search_key').value;
      if (search_key !== "" && search_key !== null && search_key.trim().length > 0 && !(this.showLocation == false && this.updateAddress.length == 0)){
        if (this.searchKeys.length < 3) {
          this.keywordsErr = false;
          this.searchKeys.push(this.searchForm.get('search_key').value);
        } else {
          this.keywordsErr = true;
          this.focusOutErrMsg();
        }
        this.searchForm.reset();
      }
      if (!this.showLocation && !this.updateAddress) {
        return;
      }
      this.submitted = false;
      this.initiateSearch();
    }
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.showAddressError = true;
      this.latitude = null;
      this.longitude= null;
    } else {
      this.showAddressError = false;
      this.latitude = null;
      this.longitude= null;
    }
  }

  delKey(key: string) {
    if (key) {
      let index = this.searchKeys.findIndex(obj => obj == key);
      this.searchKeys.splice(index, 1);
      if (this.searchKeys.length == 0) {
        this.updateAddress = "";
        this.latitude = null;
        this.longitude= null;
        this.map.markers.clear();
      }
    } else {
      this.searchKeys = [];
      this.updateAddress = "";
      this.latitude = null;
      this.longitude= null;
      this.map.markers.clear();
    }
    this.initiateSearch();
  }

  setResultHide(e){
    this.hidePlaceResults = e;
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    this.updateAddress = place.address.freeformAddress;
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

  onScrollDown(){
    if (this.activeTab == "business") {
      this.onBusinessScrollDown();
    } else if (this.activeTab == "npo") {
      this.onNpoScrollDown();
    } else if (this.activeTab == "school") {
      this.onSchoolScrollDown();
    }
  }

  onBusinessScrollDown() {
    if (this.businessNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.businessNextPage;
      this.searchService.businessProfileSearch("2", params)
        .subscribe(data => {
          this.businessProfiles = this.businessProfiles.concat(data.response.results);
          this.businessNextPage = data.response.next;
          this.loading = false;
          this.onTabChange(this.activeTab);
        })
    }
  }

  onNpoScrollDown() {
    if (this.npoNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.npoNextPage;
      this.searchService.businessProfileSearch("1", params)
        .subscribe(data => {
          this.npoProfiles = this.npoProfiles.concat(data.response.results);
          this.npoNextPage = data.response.next;
          this.loading = false;
          this.onTabChange(this.activeTab);
        })
    }
  }

  onSchoolScrollDown() {
    if (this.schoolNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.schoolNextPage;
      this.searchService.schoolProfileSearch(params)
        .subscribe(data => {
          this.schoolProfiles = this.schoolProfiles.concat(data.response.results);
          this.schoolNextPage = data.response.next;
          this.loading = false;
          this.onTabChange(this.activeTab);
        })
    }
  }
  onTabChange(tab: any){
    this.activeTab = tab;
    this.map.markers.clear();
    if (tab === "school") {
      this.addSchoolMarkers();
    } else if (tab === "business") {
      this.addBusinessMarkers(this.businessProfiles);
    } else if (tab === "npo") {
      this.addBusinessMarkers(this.npoProfiles);
    }
  }
  searchMap(){
  this.map = new atlas.Map('mapContainer',
  {
    center: [-79.347015, 43.651070],
    zoom: 11,
    language: 'en-US',
    showLogo: false,
    showFeedbackLink: false,
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: this.key
    }
  });
  }

  addBusinessMarkers(profiles: any){
    profiles.forEach(element => {
      this.map.events.add('ready', () => {
        var marker = new atlas.HtmlMarker({
          color: '#D25557',
          position: [element.business_info.longitude, element.business_info.latitude]
        });
        this.map.markers.add(marker);
        let popup = new atlas.Popup({
          pixelOffset: [0, -30],
          closeButton: false
        });
        let content = '<div style="padding:10px">' + element.business_info.title + '</div>';
        let coordinate = [element.business_info.longitude, element.business_info.latitude];
        const _this = this;
        this.map.events.add('mouseover', marker, function (e) {
          popup.setOptions({
            content: content,
            position: coordinate
          });
          popup.open(_this.map);
        });
        this.map.events.add('mouseleave', marker, function (){
          popup.close();
        });
        this.map.setCamera({
          center: [element.business_info.longitude, element.business_info.latitude],
          zoom: 11
        });
      });
    });
  }

  addSchoolMarkers(){
    this.schoolProfiles.forEach(element => {
      this.map.events.add('ready', () => {
        var marker = new atlas.HtmlMarker({
          color: '#D25557',
          position: [element.details.longitude, element.details.latitude]
        });
        this.map.markers.add(marker);
        let popup = new atlas.Popup({
          pixelOffset: [0, -30],
          closeButton: false
        });
        let content = '<div style="padding:10px">' + element.school_name + '</div>';
        let coordinate = [element.details.longitude, element.details.latitude];
        const _this = this;
        this.map.events.add('mouseover', marker, function (e) {
          popup.setOptions({
            content: content,
            position: coordinate
          });
          popup.open(_this.map);
        });
        this.map.events.add('mouseleave', marker, function (){
          popup.close();
        });
        this.map.setCamera({
          center: [element.details.longitude, element.details.latitude],
          zoom: 11
        });
      });
    });
  }

  focusOutFunction() {
    setTimeout(() => {
      this.hidePlaceResults = true;
      this.searchPlaces = [];
    }, 1000);
  }

  focusOutErrMsg() {
    setTimeout(() => {
      this.keywordsErr = false;
    }, 6000);
  }

}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { PageService } from '../../services/page.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  mediaURL = environment.mediaURL;
  modalRef: BsModalRef;
  public settingShow = true;
  public showSubmitMsg: boolean = false;
  submitMsg: string;
  invites = [];
  inviteObj: any = {};
  user: any = {};
  notifications = [];
  notifCount: any = 0;
  inviteCount: any = 0;

  constructor(private pageService: PageService, private modalService: BsModalService,
     private authservice: AuthenticationService, private profileService: ProfileService) {
      this.getNotifCount();
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.getAdminInvites();
    this.getNotifications();
    this.markNotifications();
  }

  getNotifCount(){
    let params = "?count=" + true;
    this.profileService.getUserNotifCount(params)
    .subscribe(response => {
      if (response.status === 200) {
        this.notifCount = response.response.notifs;
        this.inviteCount = response.response.invite_count;
      } else {
        this.notifCount = 0;
        this.inviteCount = 0;
      }
    });
  }

  getAdminInvites(){
    this.pageService.getUserInvites()
    .subscribe(response => {
      if(response.response.length > 0){
        this.invites = response.response;
      } else {
        this.invites = [];
      }
    })
  }

  getNotifications(){
    this.authservice.notifications()
    .subscribe(response => {
      if(response.response.length > 0) {
        this.notifications = response.response;
      } else {
        this.notifications = [];
      }
    })
  }

  markNotifications(){
    this.profileService.markNotifications()
    .subscribe(response => {})
  }



  actionModal(actionModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(actionModal,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  inviteAction(actionModal: TemplateRef<any>, adminInvite: any, accept:boolean=false) {
    this.submitMsg = "";
    this.showSubmitMsg = false;
    this.inviteObj = adminInvite;
    if (this.inviteObj.id) {
      this.pageService.inviteResponse(this.inviteObj.id, accept, this.inviteObj)
      .subscribe(response => {
        this.handleResponse(response);
        if (accept) {
          this.actionModal(actionModal);
        }
      }, error => {
        this.submitMsg = "Something went wrong. Please try again later.";
        this.showSubmitMsg = true;
        this.actionModal(actionModal);
      });
    }
  }

  handleResponse(response: any){
    if (response.status === 200) {
      let index = this.invites.indexOf(this.inviteObj);
      this.invites.splice(index, 1);
      let resp = response.response;
      this.notifications.unshift(resp.notif);
    } else {
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    }
   }

}

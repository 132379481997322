<div class="desktop-header">
    <form class="form-inline ml-auto" [formGroup]="loginForm" novalidate (ngSubmit)="login(resendModal, parentEmailModal)">
        <p>Already a Member?</p>
        <div class="form-group">
            <input type="email" class="form-control mr-0" formControlName="email" placeholder="E-mail">
            <div class="error-message" *ngIf="loginForm.get('email').hasError('required') && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                This field is required.
            </div>
            <div class="error-message" *ngIf="loginForm.get('email').hasError('pattern') && (loginForm.get('email').dirty || loginForm.get('email').touched)">
                Please enter your email in a valid format.
            </div>
        </div>
        <div class="form-group">
            <input type="password" class="form-control" formControlName="password" placeholder="Password">
            <div class="error-message" *ngIf="loginForm.get('password').hasError('required') && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                This field is required.
            </div>
            <div class="error-message" *ngIf="submitted && reqMessage">
                {{reqMessage}}
            </div>
        </div>
        <div class="form-group">
            <input type="submit" class="btn btn-submit" value="Log In">
        </div>
        <div class="form-group">
            <a [routerLink]="['/forgot-password']" class="forgot-link">Forgot Password ?</a>
        </div>
        <div class="form-group d-block d-md-none">
            <h4>Don’t have an account?
                <a [routerLink]="['/']"> Sign Up</a>
            </h4>
        </div>
    </form> 
</div>
<ng-template #resendModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Pending account activation</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div">
                    <h3>Uh oh! Your account has not been activated. You must verify
                        the email sent to your inbox to activate your Account!</h3>
                    <p>{{resendEmailMessage}}</p>
                    <div class="w-100 text-center">
                        <button (click)="resendVerification()" class="common-button">Re-send verification</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #parentEmailModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Pending parent approval</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div">
                    <h3>A confirmation email has been sent to your Parent/Guardian at {{parentEmail}}. Please get your Parent’s/Guardian’s
                        approval to access your account.</h3>
                    <p>{{resendEmailMessage}}</p>
                    <button (click)="resendVerification(true)" class="common-button mr-3">Re-send Email to Parent</button>
                    <button (click)="closeModal()" class="common-button ghost">
                        <a [routerLink]="['/parent-verification']">Change Parent Email</a>
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-template>
import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { PageService } from '../../services/page.service';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/profile.service';

const mediaURL = environment.mediaURL;

@Component({
  selector: 'app-page-setting',
  templateUrl: './page-setting.component.html',
  styleUrls: ['./page-setting.component.scss']
})
export class PageSettingComponent implements OnInit {
  modalRef: BsModalRef;
  page_id: string;
  pagesDetails: any = {};
  adminInvites = [];
  isOwner:boolean = false;
  user: any = {};
  updatedDetails : any = {};
  p_data: any = {};
  inviteObj: any = {};
  addAdminForm: FormGroup;
  public submitted: boolean = false;
  public infoDiv = true;
  public infoDiv1 = true;
  public isSuccess: boolean = false;
  AddressForm: FormGroup;
  newBusineesNoForm: FormGroup;
  newContactEmailForm: FormGroup;
  plchldrState = "Province";
  plchldrPostal = "Postal";
  countrySet: string = 'CA';
  public partialSubmitted: boolean = false;
  public isReadOnly: boolean = false;
  public showAddressError: boolean = false;
  updateAddress: string = '';
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  public showSubmitMsg: boolean = false;
  submitMsg: string;
  pendingOwnerReq = 0;
  pageHeader: string = "";
  public is_submitted: boolean = false;
  public disableSubmit: boolean = false;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  url = mediaURL;
  businessVerified: string;
  businessVerificationDaysLeft: string;
  latitude: string;
  longitude: string;

  constructor(private profileService: ProfileService, private modalService: BsModalService,private route: ActivatedRoute ,private authService: AuthenticationService,
    private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private pageService: PageService) {

      this.newBusineesNoForm = this.formBuilder.group({
        businessnumber: new FormControl('', [Validators.required, Validators.maxLength(15)])
      });

      this.newContactEmailForm = this.formBuilder.group({
        contact_email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)])
      });

      this.AddressForm = this.formBuilder.group({
        address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        country: new FormControl('', Validators.required),
        state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
        zip_code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
        });
      this.AddressForm.controls['country'].setValue('Canada', {onlySelf: true});

      this.addAdminForm = this.formBuilder.group({
        email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
        first_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
        last_name: new FormControl('', [Validators.required, Validators.maxLength(50)])
      });

     }


  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user')); 
      this.user = currentUser
    }
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.authService.PageDetails(this.page_id)
        .subscribe(response => {
            if (response.status === 200) {
                this.pagesDetails = response.response;
                if (this.pagesDetails.owner === this.user.id) {
                  this.isOwner = true;
                  this.getAdminInvites();
                }
            } else {
              this.router.navigate(['/dashboard']);
            }
        });

        this.profileService.BusinessVerificationStatus(this.page_id)
        .subscribe(response => {
          this.businessVerified = response.response.business_verified
          this.businessVerificationDaysLeft = response.response.days_left
      
        })        
  }

  getAdminInvites(){
    this.pageService.getPageAdminInvites(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
        this.adminInvites = response.response;
        this.pendingOwnerReq = this.adminInvites.filter(obj => obj.status === 1 && obj.invite_type === 1).length;
      } else {
        this.adminInvites = [];
      }
    })
  }

  
  emailModal(changeEmail: TemplateRef<any>) {
    this.updateAddress = '';
    this.newBusineesNoForm.reset();
    this.newContactEmailForm.reset();
    this.AddressForm.reset();
    this.partialSubmitted = false;
    this.AddressForm.controls['country'].setValue('Canada', {onlySelf: true});
    this.modalRef = this.modalService.show(
      changeEmail,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  adminActionsModal(actionModal: TemplateRef<any>, adminInvite: any) {
    this.inviteObj = adminInvite;
    this.pendingOwnerReq = this.adminInvites.filter(obj => obj.status === 1 && obj.invite_type === 1).length;
    this.modalRef = this.modalService.show(actionModal,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  OpenPage(pageid: string, pagetype: string){
    if (pagetype === 'business'){
      this.router.navigate(['page/'+pageid +'/profile-business']);
    } else 
    {
      this.router.navigate(['page/'+pageid +'/profile-school']);
    }
  }

  deleteModal(deletePage: TemplateRef<any>) {
    this.modalRef = this.modalService.show(deletePage,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  adminModal(addAdmin: TemplateRef<any>) {
    this.addAdminForm.reset();
    this.submitted = false;
    this.modalRef = this.modalService.show(addAdmin,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  LeavePage(){
      this.p_data = this.pagesDetails; 
      this.p_data['page_admins'] = this.p_data['page_admins'].filter((item) => item.id !== this.user.id);
      if (this.pagesDetails.business){
        this.authService.UpdatePageBusiness(this.p_data, this.page_id)
        .subscribe((response:any) => {
          this.pagesDetails=response.response
          this.router.navigate(['/dashboard']);
        });}
      else if(this.pagesDetails.club_team){
        
        this.p_data.club_team.school = this.p_data.club_team.school.id
        this.authService.UpdatePageBusiness(this.p_data, this.page_id)
        .subscribe((response:any) => {
          this.pagesDetails=response.response
          this.router.navigate(['/dashboard']);
        });
      }
  }

  DeletePage(){
      this.p_data = this.pagesDetails; 
      this.p_data['is_deleted'] = true
      if (this.pagesDetails.business){
        this.authService.UpdatePageBusiness(this.p_data, this.page_id)
        .subscribe((response:any) => {
          this.pagesDetails=response.response
          this.router.navigate(['/dashboard']);
        });}
      else if(this.pagesDetails.club_team){
        
        this.p_data.club_team.school = this.p_data.club_team.school.id
        this.authService.UpdatePageBusiness(this.p_data, this.page_id)
        .subscribe((response:any) => {
          this.pagesDetails=response.response
          this.router.navigate(['/dashboard']);
        });
      }
  }

  createAdmin(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {'page': this.page_id,
                  'email': this.addAdminForm.get('email').value.toLowerCase(),
                  'first_name': this.addAdminForm.get('first_name').value,
                  'last_name': this.addAdminForm.get('last_name').value}
      this.pageService.addAdmin(model)
      .subscribe(response => {
        if (response.status === 111) {
          let data = response.message;
          const fields = Object.keys(data || {});
          fields.forEach(field => {
            if (this.addAdminForm.contains(field)) {
              this.addAdminForm.get(field).setErrors({ aftersubmit: data[field][0] });
            }
          });
        } else if (response.status === 200) {
          this.adminInvites.push(response.response);
          this.modalRef.hide();
          this.submitted = false;
          this.addAdminForm.reset();
        } else {
          this.addAdminForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.addAdminForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
  }

  cancelRequest() {
    this.modalRef.hide();
    if (this.inviteObj.id) {
      this.pageService.cancelPageAdmin(this.inviteObj.id, this.inviteObj)
      .subscribe(response => {
        this.isSuccess = true;
        this.submitMsg = "Request has been cancelled successfully.";
        this.handleCnclResponse(response, this.inviteObj);
      }, error => {
        this.isSuccess = false;
        this.submitMsg = "Something went wrong. Please try again later.";
        this.showSubmitMsg = true;
        this.fadeOutSubmitMsg();
      });
    }
  }

  handleCnclResponse(response: any, verifierObj: any){
    if (response.status === 111) {
      this.isSuccess = false;
      this.submitMsg = "The requested user has already responded to your request. Please refresh";
      this.showSubmitMsg = true;
    } else if (response.status === 200) {
      this.isSuccess = true;
      this.showSubmitMsg = true;
      let index = this.adminInvites.indexOf(verifierObj);
      this.adminInvites.splice(index, 1);
      if (verifierObj.invite_type === 1) {
        this.adminInvites.push(response.response);
      }
    } else {
      this.isSuccess = false;
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    }
    this.fadeOutSubmitMsg();
   }

  deleteAdmin() {
    this.modalRef.hide();
    if (this.inviteObj.id) {
      this.pageService.deletePageAdmin(this.inviteObj.id, this.inviteObj)
      .subscribe(response => {
        this.isSuccess = true;
        this.submitMsg = "Admin has been deleted successfully.";
        this.handleDelResponse(response, this.inviteObj);
      }, error => {
        this.isSuccess = false;
        this.submitMsg = "Something went wrong. Please try again later.";
        this.showSubmitMsg = true;
        this.fadeOutSubmitMsg();
      });
    }
  }

  handleDelResponse(response: any, verifierObj: any){
    if (response.status === 111) {
      this.isSuccess = false;
      this.submitMsg = "The requested user has already responded to your request. Please refresh";
      this.showSubmitMsg = true;
    } else if (response.status === 200) {
      this.showSubmitMsg = true;
      let index = this.adminInvites.indexOf(verifierObj);
      this.adminInvites.splice(index, 1);
    } else {
      this.isSuccess = false;
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    }
    this.fadeOutSubmitMsg();
   }

  ownerInvite() {
    this.modalRef.hide();
    if (this.inviteObj.id) {
      this.pageService.transferPageOwnership(this.inviteObj.id, this.inviteObj)
      .subscribe(response => {
        this.isSuccess = true;
        this.submitMsg = "Request sent successfully...";
        this.handleInviteResponse(response, this.inviteObj);
      }, error => {
        this.isSuccess = false;
        this.submitMsg = "Something went wrong. Please try again later.";
        this.showSubmitMsg = true;
        this.fadeOutSubmitMsg();
      });
    }
  }

  handleInviteResponse(response: any, verifierObj: any){
    if (response.status === 111) {
      this.isSuccess = false;
      this.submitMsg = "The requested user has already responded to your request. Please refresh";
      this.showSubmitMsg = true;
    } else if (response.status === 200) {
      this.showSubmitMsg = true;
      let index = this.adminInvites.indexOf(verifierObj);
      this.adminInvites.splice(index, 1);
      this.adminInvites.push(response.response);
      this.pendingOwnerReq = 1;
    } else {
      this.isSuccess = false;
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    }
    this.fadeOutSubmitMsg();
   }

  fadeOutSubmitMsg() {
    setTimeout( () => {
      this.showSubmitMsg = false;
      this.submitMsg = "";
    }, 6000);
   }
   
  addNewBusinessNumber(isValid: boolean) {
    this.submitted = true;
    if (isValid){
      let businessnumber = this.newBusineesNoForm.get('businessnumber').value;
      let modelObj = Object.assign({}, this.pagesDetails);
      modelObj.business.business_number = businessnumber;
      this.authService.UpdatePageBusiness(modelObj,this.pagesDetails.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          this.newBusineesNoForm.get('businessnumber').setErrors({ aftersubmit: response.message['new_number'] });
        } else if (response.status === 200) {
          this.pagesDetails = response.response;
          this.submitted = false;
          this.modalRef.hide();
          this.newBusineesNoForm.reset();
        } else {
          this.newBusineesNoForm.get('businessnumber').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.newBusineesNoForm.get('businessnumber').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
   }

   addNewContactEmail(isValid: boolean) {
    this.submitted = true;
    if (isValid){
      let contactemail = this.newContactEmailForm.get('contact_email').value;
      let model = Object.assign({}, this.pagesDetails);
      model.professional_email = contactemail;
      if (this.pagesDetails.club_team){
        if (this.pagesDetails.club_team.school?.id) {
          model.club_team.school = this.pagesDetails.club_team.school.id;
        } else {
          model.club_team.school = this.pagesDetails.club_team.school;
        }
      }
      this.authService.UpdatePageBusiness(model,this.pagesDetails.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          this.newContactEmailForm.get('contact_email').setErrors({ aftersubmit: response.message['new_email'] });
        } else if (response.status === 200) {
          this.pagesDetails = response.response;
          this.submitted = false;
          this.modalRef.hide();
          this.newContactEmailForm.reset();
        } else {
          this.newContactEmailForm.get('contact_email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.newContactEmailForm.get('contact_email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
   }

   changeCountry(e){
    if (e.target.value == "USA"){
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
    } else {
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
    }
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.AddressForm.controls['address'].setValue(null);
      this.showAddressError = true;
      this.isReadOnly = false;
    } else {
      this.AddressForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    } 
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){ 
    this.hidePlaceResults = e;
  }

  ValidateForm(isValid: boolean){
    if (!isValid){
      this.partialSubmitted = true;
    }
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let zip_code = '';
    let address = place.address.freeformAddress.split(",")[0];
    this.updateAddress = address;
    this.AddressForm.controls['address'].setValue(address);
    this.AddressForm.controls['city'].setValue(place.address.municipality);
    if (place.address.extendedPostalCode && this.countrySet=='CA'){
      zip_code = place.address.extendedPostalCode;
    } else {
      zip_code = place.address.postalCode;
    }
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    if (zip_code && zip_code.length > 20) {
      zip_code = place.address.postalCode;
    }
    this.AddressForm.controls['zip_code'].setValue(zip_code);
    this.AddressForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
    this.isReadOnly = true;
  }

  NewAddress(isValid: boolean) {
    this.partialSubmitted = true;
    if (isValid) {
      this.pagesDetails.business.address = this.AddressForm.get('address').value;
      this.pagesDetails.business.country = this.AddressForm.get('country').value;
      this.pagesDetails.business.state = this.AddressForm.get('state').value;
      this.pagesDetails.business.city = this.AddressForm.get('city').value;
      this.pagesDetails.business.zip_code = this.AddressForm.get('zip_code').value;
      if (this.latitude && this.longitude) {
        this.pagesDetails.business.longitude = this.longitude;
        this.pagesDetails.business.latitude = this.latitude;
      }
      this.authService.UpdatePageBusiness(this.pagesDetails,this.pagesDetails.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          this.AddressForm.get('address').setErrors({ aftersubmit: response.message['new address'] });
        } else if (response.status === 200) {
          this.pagesDetails = response.response;
          this.partialSubmitted = false;
          this.modalRef.hide();
          this.AddressForm.reset();
        } else {
          this.AddressForm.get('address').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.AddressForm.get('address').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
  }

}

<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 student d-md-block d-none">
                <div class="green-sidebarSec">
                    <div class="setting-div mt-2" [ngClass]="{ '': settingShow, show: !settingShow}">
                        <div class="setting-inner">
                            <h3>Settings</h3>
                            <img  alt="image" src="assets/images/cross.png" alt="" class="close-btn"
                                (click)="settingShow = !settingShow">
                            <form action="">
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck1">Block
                                            User</label>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck">Report
                                            User</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <app-profile-header></app-profile-header>
                <app-sidebar-inner></app-sidebar-inner>
                <div class="row m-0">
                    <div class="col-md-12 p-0">
                        <h2 class="settingText text-center d-md-block d-none">Messenger</h2>
                        <div class="content-section p-0 mt-md-3">
                            <div *ngIf="conversations.length > 0" class="profile-div" [ngClass]="{'showMsg': showMobileChatBox}">
                                <div class="returnDiv">
                                    <a href="javascript:void();" (click)="removeChat();">Return</a>
                                </div>
                                <div class="message-sidebar">
                                    <div class="form-group">
                                        <input type="text" placeholder="Search" class="form-control"
                                            [(ngModel)]="searchQuery">
                                    </div>
                                    <div *ngIf="conversations.length > 0" class="msg-listing ">
                                        <div *ngFor="let conversation of conversations | nameSearch: searchQuery"
                                            class="media cursor-pointer" [ngClass]="{ read : conversation.is_read}"
                                            (click)="openChatBox(conversation)">
                                            <img
                                                src="{{conversation.user_info.display_pic? mediaURL + conversation.user_info.display_pic : 'assets/images/p-img.png'}}">
                                            <div class="media-body ml-md-3 ml-2">
                                                <h2>{{conversation.user_info.name}}</h2><small *ngIf="conversation.recent_message">{{conversation.modified_date | date: 'yyyy/MM/dd h:mm a'}}</small>
                                                <p *ngIf="conversation.recent_message">{{conversation.recent_message}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="message-section">
                                    <div class="mobileMsg"> 
                                        <div class="top-div d-flex align-items-center justify-content-between"
                                            [ngClass]="{ '': msgSetting, show: !msgSetting}">
                                            <div class="info-div d-flex align-items-center">
                                                <a class="cursor-pointer d-flex align-items-center" [routerLink]="['/profile', currentConversation.user_info?.id]">
                                                    <img  alt="image" src="{{currentConversation?.user_info?.display_pic? mediaURL + currentConversation?.user_info?.display_pic : 'assets/images/p-img.png'}}" class="profile cursor-pointer">
                                                    <h2>{{currentConversation?.user_info?.name}}</h2>
                                                </a>
                                            </div>
                                            <img  alt="image" src="assets/images/more-icon.svg" alt=""
                                                class="float-right cursor-pointer" (click)="msgSetting = !msgSetting">
                                            <div class="msg-overlay">
                                                <div class="msg-setting">
                                                    <h2 class="d-md-none d-block">Settings <a href="javascript:void();"
                                                            (click)="msgSetting = !msgSetting" class="float-right"><img
                                                                src="assets/images/cross.png" alt=""></a></h2>
                                                    <ul class="pl-0 mb-0">
                                                        <li>
                                                            <a class="cursor-pointer"
                                                                (click)="deleteModal(deleteConversation)">Delete
                                                                Conversation</a>
                                                        </li>
                                                        <li *ngIf="checkSetting">
                                                            <a class="cursor-pointer"
                                                                (click)="toSelectMessages()">Delete Messages</a>
                                                        </li>
                                                        <li *ngIf="!checkSetting">
                                                            <a class="cursor-pointer"
                                                                (click)="toSelectMessages(true)">Cancel</a>
                                                        </li>
                                                        <li *ngIf="!checkSetting">
                                                            <a class="cursor-pointer"
                                                                (click)="deleteModal(deleteMessages)">Delete</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat-section pl-3 pr-3" [ngClass]="{ '': checkSetting, showCheck: !checkSetting}" #scrollMe >
                                            <div *ngFor="let message of messages" class="msg-div" [ngClass]="{'sender': !message.is_sender, 'receiver': message.is_sender}">
                                                <div class="msg-content">
                                                    <p>{{message.text}}</p>
                                                    <small>{{message.created_date | date: 'yyyy/MM/dd h:mm a'}}</small>
                                                </div>
                                                <div class="custom-control custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" id="customCheck"
                                                        name="privacy" (change)="selectMessages($event, message)">
                                                    <label class="custom-control-label" for="customCheck"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="chat-div">
                                            <form [formGroup]="messageForm" novalidate>
                                                <div class="form-group mb-md-3 mb-0 position-relative">
                                                    <input type="text" class="form-control" placeholder="Type Here"
                                                        formControlName="text">
                                                    <button class="sendBtn"
                                                        (click)="sendText(messageForm.valid)"></button>
                                                    <p *ngIf="showMsg" class="alert alert-danger mt-2">{{submitMsg}}</p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="conversations.length == 0"
                                class="msg-listing fullPage d-flex align-items-center justify-content-center">
                                <div class="empty-div text-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="103" height="78"
                                        viewBox="0 0 103 78">
                                        <g fill="#C1CAD5">
                                            <path
                                                d="M56.52 21.2H8.48c-4.69.014-8.482 3.82-8.48 8.51v26.84c.001 2.246.896 4.4 2.487 5.985C4.077 64.12 6.234 65.007 8.48 65h6.72v9.53c0 3.85 2.016 4.564 4.48 1.6L28.94 65h27.58c2.246.007 4.402-.88 5.993-2.465C64.104 60.949 65 58.796 65 56.55V29.71c.002-4.69-3.79-8.496-8.48-8.51z" />
                                            <path
                                                d="M89.28 0H43.2C35.988 0 30 5.858 30 13.07v1.73h26.53c8.2 0 14.87 6.546 14.87 14.75V51h7.764l7.8 9.302c2.474 2.95 4.488 2.22 4.474-1.63l-.038-7.826c6.214-1.054 11-6.644 11-13.136V13.07C102.4 5.858 96.492 0 89.28 0z" />
                                        </g>
                                    </svg>
                                    <h4>
                                        no message
                                    </h4>
                                    <p>
                                        You have not sent or received
                                        any message yet.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #deleteConversation>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Conversation</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>Are you sure you want to delete this conversation ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delConversation()">
            Yes
        </button>
    </div>
</ng-template>

<ng-template #deleteMessages>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Message(s)</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>Are you sure you want to delete selected message(s) ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delMessages()">
            Yes
        </button>
    </div>
</ng-template>
<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 search-sidebar">
                <div class="green-sidebarSec">

                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <div class="return-div "><a href="javascript:void();" class="d-md-none d-block">Return</a>
                </div>
                <div class="col-md-12">
                    <h1>Pages</h1>
                    <div class="filterDiv">
                        <form [formGroup]="searchForm" novalidate>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control search-field mb-md-0 mb-3"
                                            placeholder="Search By Keyword" formControlName="search_key">
                                        <div class="error-message"
                                            *ngIf="searchForm.get('search_key').hasError('maxlength') && (searchForm.get('search_key').dirty || searchForm.get('search_key').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                        <div class="error-message" *ngIf="keywordsErr">
                                            Max 3 search keywords allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center justify-content-between"
                                        (focusout)="focusOutFunction()">
                                        <label>
                                            Search By:
                                        </label>
                                        <app-azure-maps (change)="changeAddress($event)" [updateAddress]="updateAddress"
                                            (setAddress)="getAddress($event)" [placeHolder]="placeHolder"
                                            (setHideResult)="setResultHide($event)">
                                        </app-azure-maps>
                                    </div>
                                    <div class="error-message" *ngIf="!showLocation && !updateAddress && submitted">
                                        Please select location or enable location from your privacy settings
                                    </div>
                                    <div *ngIf="!hidePlaceResults && searchPlaces.length > 0" class="col-md-12">
                                        <div class="option-div">
                                            <div class="listing" *ngFor="let place of searchPlaces"
                                                (click)="selectPlace(place)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                                    viewBox="0 0 15 20">
                                                    <path fill="none" stroke="#505759" stroke-width="1.4"
                                                        d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                                </svg>
                                                <div class="detail">
                                                    <h6>{{place.address.freeformAddress}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <button type="submit" (click)="search(searchForm.valid)"
                                        class="common-btn mt-3">Submit</button>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="searchKeys.length > 0" class="d-sm-flex align-items-center flex-wrap">
                            <ul class="d-flex pl-0 mb-0  mt-md-3 tagListing">
                                <li *ngFor="let searchKey of searchKeys">
                                    <a href="javascript:void();">{{searchKey}} </a>
                                    <span class="float-right cursor-pointer" (click)="delKey(searchKey)">x</span>
                                </li>
                            </ul>
                            <button class="common-btn ghost mt-3" (click)="delKey('')">Clear Filter</button>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-md-12 search-sidebar h-auto mh-auto">
                    </div>
                    <div class="d-md-flex searchScroll w-100" infiniteScroll [infiniteScrollDistance]="1"
                        [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" [scrollWindow]="true">
                        <div class="col-md-7 mt-3">
                            <tabset class="side-links tabsetSide">
                                <tab (selectTab)="onTabChange('business')">
                                    <ng-template tabHeading>
                                        Business
                                    </ng-template>
                                    <div *ngIf="businessProfiles.length > 0" class="search-div mt-3">
                                        <p class="title">{{totalbusinessProfiles}} Result(s)</p>
                                        <span>Showing {{businessProfiles.length}}</span>
                                        <div *ngFor="let businessProfile of businessProfiles"
                                            class="search-inner d-md-flex ">
                                            <div [routerLink]="['/page/' + businessProfile.id]"
                                                class="imgDiv cursor-pointer">
                                                <img
                                                    src="{{businessProfile.display_pic? mediaURL + businessProfile.display_pic : 'assets/images/default.svg'}}">
                                            </div>
                                            <div class="search-side ml-lg-3 mr-lg-2">
                                                <a [routerLink]="['/page/' + businessProfile.id]">
                                                    {{businessProfile.business_info.title}}</a>
                                                <h3>{{businessProfile.business_info.address}}</h3>
                                                <div class="searchDetail">
                                                    <h3>Experiences</h3>
                                                    <ul class="pl-0 mb-0 d-flex justify-content-between">
                                                        <li>
                                                            <h4><span>{{businessProfile.business_info.jobs}}</span>Available
                                                            </h4>
                                                        </li>
                                                        <li>
                                                            <h4><span>{{businessProfile.business_info.verified_experiences}}</span>Verified
                                                            </h4>
                                                        </li>
                                                        <li>
                                                            <h4>{{businessProfile.business_info.distance}} Km Away</h4>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="businessProfiles.length == 0"
                                        class="next-listing text-center mt-4 mb-4">
                                        <h5>No Result(s) Found</h5>
                                    </div>
                                    <div *ngIf="totalbusinessProfiles > 3 && totalbusinessProfiles > businessProfiles.length"
                                        class="next-listing text-center mt-4 mb-4">
                                        <h5>Scroll to view next 3</h5>
                                        <img alt="image" src="assets/images/arrow-down.png" alt="" id="nextBtn">
                                    </div>
                                </tab>
                                <tab (selectTab)="onTabChange('npo')">
                                    <ng-template tabHeading>
                                        NPO
                                    </ng-template>
                                    <div *ngIf="npoProfiles.length > 0" class="search-div mt-3">
                                        <p class="title">{{totalnpoProfiles}} Result(s)</p>
                                        <span>Showing {{npoProfiles.length}}</span>
                                        <div *ngFor="let npoProfile of npoProfiles" class="search-inner d-md-flex  ">
                                            <div [routerLink]="['/page/' + npoProfile.id]"
                                                class="imgDiv cursor-pointer">
                                                <img
                                                    src="{{npoProfile.display_pic? mediaURL + npoProfile.display_pic : 'assets/images/default.svg'}}">
                                            </div>
                                            <div class="search-side ml-lg-3 mr-lg-2">
                                                <a [routerLink]="['/page/' + npoProfile.id]">
                                                    {{npoProfile.business_info.title}}</a>
                                                <h3>{{npoProfile.business_info.address}}</h3>
                                                <div class="searchDetail">
                                                    <h3>Experiences</h3>
                                                    <ul class="pl-0 mb-0 d-flex justify-content-between">
                                                        <li>
                                                            <h4><span>{{npoProfile.business_info.jobs}}</span>Available
                                                            </h4>
                                                        </li>
                                                        <li>
                                                            <h4><span>{{npoProfile.business_info.verified_experiences}}</span>Verified
                                                            </h4>
                                                        </li>
                                                        <li>
                                                            <h4>{{npoProfile.business_info.distance}} Km Away</h4>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="npoProfiles.length == 0" class="next-listing text-center mt-4 mb-4">
                                        <h5>No Result(s) Found</h5>
                                    </div>
                                    <div *ngIf="totalnpoProfiles > 3 && totalnpoProfiles > npoProfiles.length"
                                        class="next-listing text-center mt-4 mb-4">
                                        <h5>Scroll to view next 3</h5>
                                        <img alt="image" src="assets/images/arrow-down.png" alt="" id="nextBtn">
                                    </div>
                                </tab>
                                <tab (selectTab)="onTabChange('school')">
                                    <ng-template tabHeading>
                                        Schools
                                    </ng-template>
                                    <div *ngIf="schoolProfiles.length > 0" class="search-div mt-3">
                                        <p class="title">{{totalschoolProfiles}} Result(s)</p>
                                        <span>Showing {{schoolProfiles.length}}</span>
                                        <div *ngFor="let schoolProfile of schoolProfiles"
                                            class="search-inner d-md-flex">
                                            <div [routerLink]="['/school/' + schoolProfile.id]"
                                                class="imgDiv cursor-pointer">
                                                <img
                                                    src="{{schoolProfile.display_picture? schoolProfile.display_picture : 'assets/images/default.svg'}}">
                                            </div>
                                            <div class="search-side ml-lg-3 mr-lg-2">
                                                <a [routerLink]="['/school/' + schoolProfile.id]">
                                                    {{schoolProfile.school_name}}</a>
                                                <h3>{{schoolProfile.address}}</h3>
                                                <div class="searchDetail">
                                                    <h3>About School</h3>
                                                    <ul class="pl-0 mb-0 d-flex justify-content-between">
                                                        <li>
                                                            <h4><span>{{schoolProfile.details.clubs}}</span>Clubs</h4>
                                                        </li>
                                                        <li>
                                                            <h4><span>{{schoolProfile.details.teams}}</span>Teams</h4>
                                                        </li>
                                                        <li>
                                                            <h4>{{schoolProfile.details.distance}} Km Away</h4>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="schoolProfiles.length == 0" class="next-listing text-center mt-4 mb-4">
                                        <h5>No Result(s) Found</h5>
                                    </div>
                                    <div *ngIf="totalschoolProfiles > 3 && totalschoolProfiles > schoolProfiles.length"
                                        class="next-listing text-center mt-4 mb-4">
                                        <h5>Scroll to view next 3</h5>
                                        <img alt="image" src="assets/images/arrow-down.png" alt="" id="nextBtn">
                                    </div>
                                </tab>
                            </tabset>
                        </div>
                        <div class="col-md-5">
                            <div class="mapContainerDiv">
                                <div id="mapContainer" class=" mx-0"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
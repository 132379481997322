<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img src="assets/images/connect-us-logo.svg" class="mx-auto" alt="image">
                        <img src="assets/images/testing_phase.svg" class="testingIcon" alt="image">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img src="assets/images/help-icon.svg"  alt="image" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section form-section help-section">
                    <app-sidebar></app-sidebar>
                    <div class="content-section p-4">
                        <div class="profile-div">
                            <h2 class="top-title">Need Help? </h2>
                            <div class="profile-inner full-inner mt-md-4">
                                <app-contact-us-form [displayColClass]="false"></app-contact-us-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="info-overlay" *ngIf="infoSlide" >
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img src="assets/images/cross-modal.svg" alt="image">
        </button>
        <img src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none" alt="image">
        <img src="assets/images/info-mobile.svg" class="d-md-none d-block" alt="image"> 
    </div>
</div>
<section class="home-signup" (click)="formClick()">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center ">
                    <div class="centerDiv text-center w-100">
                        <img src="assets/images/connect-us-logo.svg" class="mx-auto" alt="image">
                        <img src="assets/images/testing_phase.svg" class="testingIcon" alt="image">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student form-section">
                    <div class="side-links">
                        <ul class="">
                            <li class=" ">
                                <a [routerLink]="['/dashboard']" class=" " routerLinkActive="student student-border">
                                    <img src="assets/images/calender-icon.svg" class="d-none d-md-inline-block" alt="image">
                                    <span class="d-md-none d-block">Calendar</span>
                                </a>
                            </li>
                            <li class=" create-link ">
                                <a [routerLink]="['/create-page']" class=" " routerLinkActive="student student-border">
                                    <img src="assets/images/add-icon.svg" class="d-none d-md-inline-block" alt="image">
                                    <span class="d-md-none d-block">Add Page</span>
                                </a>
                            </li>
                            <li class=" d-none d-md-inline-block">
                                <a [routerLink]="['/help-page']" routerLinkActive="student student-border">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <rect width="24" height="24" fill="#335175" rx="12"
                                                transform="matrix(-1 0 0 1 24 0)" />
                                            <g fill="#FFF" fill-rule="nonzero">
                                                <path
                                                    d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                                    transform="translate(7.2 6)" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center mx-auto">
                            <h1>Business</h1>
                            <form [hidden]="!firstStep" [formGroup]="firstStepForm" novalidate (ngSubmit)="save(firstStepForm.valid, successModal)">
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <label for="first">Organization Details  <span>*</span></label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" placeholder="Title of Organization" class="form-control" formControlName="organization_title">
                                        <div class="error-message" *ngIf="firstStepForm.get('organization_title').hasError('required') && (firstStepForm.get('organization_title').dirty || firstStepForm.get('organization_title').touched || partialSubmitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('organization_title').hasError('whitespace') && (!firstStepForm.get('organization_title').hasError('required')) && (firstStepForm.get('organization_title').dirty || firstStepForm.get('organization_title').touched || partialSubmitted)">
                                            Please enter valid data
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('organization_title').hasError('maxlength') && (firstStepForm.get('organization_title').dirty || firstStepForm.get('organization_title').touched)">
                                            Max 125 characters allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group gutters-5 row">
                                    <div class="col-md-12">
                                        <select class="form-control custom-select" placeholder="Country" formControlName="country" (change)="changeCountry($event)">
                                            <option selected>Canada</option>
                                            <option>USA</option>
                                        </select>
                                        <div class="error-message" *ngIf="firstStepForm.get('country').hasError('required') && (firstStepForm.get('country').dirty || firstStepForm.get('country').touched || partialSubmitted)">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group gutters-5 row">
                                    <div class="col-md-12">
                                        <app-azure-maps [updateAddress]="updateAddress" [countrySet]="countrySet" (change)="changeAddress($event)" (setAddress)="getAddress($event)"
                                            (setHideResult)="setResultHide($event)"></app-azure-maps>
                                        <div *ngIf="!hidePlaceResults" class="col-md-12">
                                            <div class="option-div">
                                                <div class="listing" *ngFor="let place of searchPlaces" (click)="selectPlace(place)">
                                                    <svg _ngcontent-dsr-c176="" width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink">
                                                        <title _ngcontent-dsr-c176="">Artboard</title>
                                                        <desc _ngcontent-dsr-c176="">Created with Sketch.</desc>
                                                        <g _ngcontent-dsr-c176="" id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect _ngcontent-dsr-c176="" id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130" rx="12"></rect>
                                                            <g _ngcontent-dsr-c176="" id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                                                <path _ngcontent-dsr-c176="" d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                                                    id="Shape"></path>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                    <div class="detail">
                                                        <h6>{{place.address.freeformAddress}}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="text" [hidden]="true" placeholder="Full Address" class="form-control" formControlName="address">
                                        <div class="error-message" *ngIf="firstStepForm.get('address').hasError('required') && (showAddressError || partialSubmitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('address').hasError('whitespace') && (!firstStepForm.get('address').hasError('required')) && (firstStepForm.get('address').dirty || firstStepForm.get('address').touched || partialSubmitted)">
                                            Please enter valid data
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('address').hasError('maxlength') && (firstStepForm.get('address').dirty || firstStepForm.get('address').touched)">
                                            Max 200 characters allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group gutters-5">
                                    <div class="col-md-12">
                                        <input type="text" placeholder="Unit #" class="form-control" formControlName="unit_number">
                                        <div class="error-message" *ngIf="firstStepForm.get('unit_number').hasError('maxlength') && (firstStepForm.get('unit_number').dirty || firstStepForm.get('unit_number').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-6">
                                        <input type="text" placeholder="City" class="form-control" formControlName="city">
                                        <div class="error-message" *ngIf="firstStepForm.get('city').hasError('required') && (firstStepForm.get('city').dirty || firstStepForm.get('city').touched || partialSubmitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('city').hasError('whitespace') && (!firstStepForm.get('city').hasError('required')) && (firstStepForm.get('city').dirty || firstStepForm.get('city').touched || partialSubmitted)">
                                            Please enter valid data
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('city').hasError('maxlength') && (firstStepForm.get('city').dirty || firstStepForm.get('city').touched)">
                                            Max 20 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-md-6 ">
                                        <input type="text" placeholder="{{plchldrPostal}}" class="form-control" formControlName="zip_code">
                                        <div class="error-message" *ngIf="firstStepForm.get('zip_code').hasError('required') && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched || partialSubmitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('zip_code').hasError('whitespace') && (!firstStepForm.get('zip_code').hasError('required')) && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched || partialSubmitted)">
                                            Please enter valid data
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('zip_code').hasError('maxlength') && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched)">
                                            <span *ngIf="plchldrPostal==='Postal'">Please enter your postal code in a valid format.</span>
                                            <span *ngIf="plchldrPostal==='ZIP Code'">Please enter your Zip code in a valid format.</span>
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('zip_code').hasError('minlength') && (firstStepForm.get('zip_code').dirty || firstStepForm.get('zip_code').touched)">
                                            <span *ngIf="plchldrPostal==='Postal'">Please enter your postal code in a valid format.</span>
                                            <span *ngIf="plchldrPostal==='ZIP Code'">Please enter your Zip code in a valid format.</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <input type="text" placeholder="{{plchldrState}}" class="form-control" formControlName="state">
                                        <div class="error-message" *ngIf="firstStepForm.get('state').hasError('required') && (firstStepForm.get('state').dirty || firstStepForm.get('state').touched || partialSubmitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('state').hasError('whitespace') && (!firstStepForm.get('state').hasError('required')) && (firstStepForm.get('state').dirty || firstStepForm.get('state').touched || partialSubmitted)">
                                            Please enter valid data
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('state').hasError('maxlength') && (firstStepForm.get('state').dirty || firstStepForm.get('state').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <label>Contact & Verification</label>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control mb-2" placeholder="Contact Email" formControlName="professional_email"
                                         (focusout)="validateEmail()">
                                        <div class="error-message" *ngIf="firstStepForm.get('professional_email').hasError('maxlength') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched)">
                                            Max 100 characters allowed.
                                        </div>
                                        <div class="error-message" *ngIf="firstStepForm.get('professional_email').hasError('pattern') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched)">
                                            Please enter your email in a valid format.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="firstStepForm.get('professional_email').hasError('invalid_format') && (firstStepForm.get('professional_email').dirty || firstStepForm.get('professional_email').touched)">
                                            This domain name cannot be used.
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row gutters-5">
                                    <div class="col-10">
                                        <input type="text" placeholder="Business Number" class="form-control" formControlName="business_number">
                                        <div class="error-message" *ngIf="firstStepForm.get('business_number').hasError('maxlength') && (firstStepForm.get('business_number').dirty || firstStepForm.get('business_number').touched)">
                                            Max 15 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-2">
                                        <img src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv" class="cursor-pointer" alt="image">
                                        <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                                            <div class="info-div">
                                                <p>Business Number is solely used for the Sign-Up process to verify the Business.
                                                    It will not be publicized anywhere on ConnectUs.Today. You have 30 days – or
                                                    until you verify a student’s experience – to add this number through
                                                    Settings.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-submit" (click)="ValidateForm(firstStepForm.valid)">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #successModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Page Created</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg" alt="image">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div">
                    <h3>Page created successfully</h3>
                    <div class="w-100 text-center">
                        <button  class="common-button">To Dashboard</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<div class="info-overlay" *ngIf="infoSlide" >
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img src="assets/images/cross-modal.svg" alt="image">
        </button>
        <img src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none" alt="image">
        <img src="assets/images/info-mobile.svg" class="d-md-none d-block" alt="image"> 
    </div>
</div>
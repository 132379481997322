<section class="profile-head notification-section">
    <div class="container">
        <div class="row">
            <div class="col-md-2 student p-0">
                <div class="green-sidebarSec">
                    <div class="setting-div mt-2">
                        <div class="setting-inner" [ngClass]="{ '': settingShow, show: !settingShow}">
                            <h3>Settings</h3>
                            <img  alt="image" src="assets/images/cross.png" alt="" class="close-btn"
                                (click)="settingShow = !settingShow">
                            <form action="">
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck1">Block
                                            User</label>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck">Report
                                            User</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10  p-0">
                <app-profile-header></app-profile-header>
                <app-sidebar-inner></app-sidebar-inner>
                <tabset class="topLinks">
                    <tab>
                        <ng-template tabHeading>
                            <h3>All Notifications <span *ngIf="notifCount > 0" class="count">{{notifCount}}</span></h3>
                        </ng-template>
                        <div class="content-section fullPage pt-md-4 pt-3">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>All Notifications</h3>
                                    <div class="box-div" *ngFor="let notification of notifications">
                                        <div class="notification-row media align-items-center"
                                            [ngClass]="{'new': !notification.is_read, 'cursor-pointer': notification.notify_type == 4}"
                                            [routerLink]="notification.notify_type == 4 ? ['/page/' + notification.page + '/experiences' ] : []">
                                            <img *ngIf="notification.notify_type !== 6" src="{{notification.page_info.display_pic? mediaURL + notification.page_info.display_pic : 'assets/images/default.svg'}}"
                                                class="imgPic">
                                            <img *ngIf="notification.notify_type == 6" src="assets/images/logo.svg"
                                                class="imgPic">
                                            <div class="media-body ml-3">
                                                <h3><span>{{notification.message}}</span></h3>
                                                <h4>{{notification.page_info.title}} <span class="pipe">|</span>
                                                    <span>{{notification.created_date | date: 'yyyy/MM/dd h:mm a'}}</span></h4>
                                                <p>{{notification.page_info.school_name}}<span
                                                        *ngIf="!notification.page_info.school_name">{{notification.page_info.address}}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=" empty-div text-center" *ngIf="notifications?.length == 0">
                                        <p>No New Notifications</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <h3>Requests <span *ngIf="inviteCount > 0" class="count">{{inviteCount}}</span></h3>
                        </ng-template>
                        <div class="content-section fullPage pt-md-4 pt-3">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>Requests</h3>
                                    <div class="box-div" *ngFor="let invite of invites">
                                        <div class="notification-row new media align-items-center">
                                            <img  alt="image" src="{{invite.page_info.display_pic? mediaURL + invite.page_info.display_pic : 'assets/images/default.svg'}}"
                                                class="imgPic">
                                            <div class="media-body ml-3">
                                                <h3>{{invite.page_info.owner}} has invited you to be <span
                                                        *ngIf="invite.invite_type == 1">the Owner</span><span
                                                        *ngIf="invite.invite_type == 2">an Administrator</span></h3>
                                                <h4>{{invite.page_info.title}} <span class="pipe">|</span>
                                                    <span>{{invite.created_date | date}}</span></h4>
                                                <p>{{invite.page_info.school_name}}<span
                                                        *ngIf="!invite.page_info.school_name">{{invite.page_info.address}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <button class="common-btn mr-2 mt-3"
                                            (click)="inviteAction(inviteModal, invite, true)">Accept</button>
                                        <button class="common-btn mt-3 ghost"
                                            (click)="inviteAction(inviteModal, invite)">Decline</button>
                                    </div>
                                    <div class="empty-div text-center" *ngIf="invites?.length == 0">
                                        <p>No New Requests</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>

                </tabset>
            </div>
        </div>
    </div>
</section>
<ng-template #inviteModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">CONGRATULATIONS!</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="inner-box">
                <p *ngIf="showSubmitMsg">{{submitMsg}}</p>
                <p *ngIf="!showSubmitMsg">You are now an <span *ngIf="inviteObj.invite_type == 1">Owner</span><span
                        *ngIf="inviteObj.invite_type == 2">Administrator</span> of this Page. You may verify experiences,
                         edit the Page details, and access the Page settings. This page has been added 
                         and is accessible from your Home page. </p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            OK
        </button>
    </div>
</ng-template>
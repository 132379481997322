import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-search-help',
  templateUrl: './search-help.component.html',
  styleUrls: ['./search-help.component.scss']
})
export class SearchHelpComponent implements OnInit {
  page_id: any;
  pagesDetails: any = {};
  user: any = {};
  isRelated: boolean = false;

  constructor(private modalService: BsModalService, private router: Router, private route: ActivatedRoute, private authService: AuthenticationService) { }
  modalRef: BsModalRef;
  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.getpageDetails();
  }
  openStudent(studentModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(studentModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openVolunteer(volunteerModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(volunteerModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openSchool(schoolModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolModal, Object.assign({}, { class: 'profile-modal' }));
  }

  getpageDetails() {
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        if (response.status === 200) {
          this.pagesDetails = response.response;
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
  }

}

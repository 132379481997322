<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center ">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img  alt="image" src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student-dash student">
                    <div class="side-links">
                        <ul class="">
                            <li class="active ">
                                <a [routerLink]="['/dashboard']" class="" routerLinkActive="student student-border">
                                    <img  alt="image" src="assets/images/calender-icon.svg" class="d-none d-md-inline-block">
                                    <span class="d-md-none d-block">Calendar</span>
                                </a>
                            </li>
                            <li *ngIf="isSenior" class=" bg-bsns create-link ">
                                <a [routerLink]="['/create-page']" class=" " routerLinkActive="student student-border">
                                    <img  alt="image" src="assets/images/add-icon.svg" class="d-none d-md-inline-block">
                                    <span class="d-md-none d-block">Add Page</span>
                                </a>
                            </li>
                            <li class="bg-bsns d-none d-md-inline-block">
                                <a [routerLink]="['/help-page']" routerLinkActive="student student-border">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <rect width="24" height="24" fill="#335175" rx="12"
                                                transform="matrix(-1 0 0 1 24 0)" />
                                            <g fill="#FFF" fill-rule="nonzero">
                                                <path
                                                    d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                                    transform="translate(7.2 6)" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="alertSection position-relative mb-4 "
                        *ngIf="account_verification_status == '3' && !isMinor">
                        <p>Your Resume has been frozen. Please verify your identity through settings to reactivate it.
                        </p>
                        <button [routerLink]="['/profile/setting']" class="common-btn ghost mt-2 mr-2">Complete
                            now</button>
                        <button (click)="closeDiv()" class="common-btn mt-2">Later</button>
                    </div>
                    <div class="  home-inner">
                        <div class="box-div d-md-flex d-none" *ngIf="user.user_role == '2'">
                            <img *ngIf="profileImageHeader_name" src="{{profileImageHeader}}" class="profileImg" alt="">
                            <img *ngIf="!profileImageHeader_name" src="assets/images/p-img.png" class="profileImg"
                                alt="">
                            <div class="sidedash">
                                <h1>Happy {{day}},
                                    <span>{{user.first_name}}</span>
                                </h1>
                                <a [routerLink]="['/profile']" *ngIf="user.user_role == '2'">Go to Profile</a>
                            </div>
                        </div>
                        <div class="content-section pl-md-3 pr-md-3 d-md-block d-none sliderDash pt-5"
                            *ngIf="pagetitle">
                            <carousel>
                                <slide *ngFor="let pagee of pageslist">
                                    <div class="profile-div">
                                        <div class="profile-inner full-inner">
                                            <h3>My Pages <span class="">
                                                    ({{pageslist.length}})</span> <button
                                                    class="common-btn float-right ghost mb-2"
                                                    (click)="openBusiness(businessModal)">View All</button></h3>
                                            <div class="box-div inner-exp"
                                                [ngClass]="{'business-border': pagee.page_type == 1,'volunteer-border': pagee.page_type == 2,'school-border': [3,4].indexOf(pagee.page_type) > -1}">
                                                <div class="experience-div media">
                                                    <img *ngIf="!pagee.display_pic" src="assets/images/p-img.png">
                                                    <img *ngIf="pagee.display_pic" src="{{url}}{{pagee.display_pic}}">
                                                    <div class="media-body ml-2 mt-1">
                                                        <h4>{{pagee.page_info.title}}
                                                            <span
                                                                *ngIf="pagee.business && pagee.page_info.address">{{pagee.page_info.address.split(',')[0]}}</span>
                                                            <span
                                                                *ngIf="pagee.club_team && pagee.page_info.school_name">{{pagee.page_info.school_name}}</span>
                                                        </h4>
                                                        <button *ngIf="pagee.business" class="common-btn ghost"
                                                            (click)="OpenPage(pagee.page_info.instance_id, 'business');">Go
                                                            to Page</button>
                                                        <button *ngIf="pagee.club_team" class="common-btn ghost"
                                                            (click)="OpenPage(pagee.page_info.instance_id, 'club_team');">Go
                                                            to Page</button>
                                                        <button class="common-btn ghost ml-2"
                                                            (click)="OpenPageSettings(pagee.page_info.instance_id); modalRef.hide()">Go
                                                            to Settings</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                            </carousel>
                        </div>
                        <div class="p-3 mt-md-2">
                            <div class="row text-center">
                                <event-cal [isEditable]="false" [isDashboard]="true" class="w-100"></event-cal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #studentModal>
    <div class="modal-header p-0">
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                <div ngxSlickItem *ngFor="let gears of gears" class="slide">
                    <img  alt="image" src="{{ gears.img }}" alt="" width="100%">
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</ng-template>
<div class="onBoard-overlay desktop" *ngIf="firstStudentLogin">
    <div class="onBoard-div">
        <button type="button" class="close-btn float-right" (click)="closeSlide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let gears of gears" class="slide">
                <img  alt="image" src="{{ gears.img }}" alt="" width="100%">
            </div>
        </ngx-slick-carousel>
    </div>
</div>

<div class="onBoard-overlay onMobile" *ngIf="firstStudentLogin">
    <div class="onBoard-div">
        <button type="button" class="close-btn float-right" (click)="closeSlide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let gears of gearsMobile" class="slide">
                <img  alt="image" src="{{ gears.img }}" alt="" width="100%">
            </div>
        </ngx-slick-carousel>
    </div>
</div>
<ng-template #businessModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">All Pages</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="content-section pl-md-3 pr-md-3 pt-3">
                <div class="profile-div">
                    <div class="profile-inner mb-3" *ngFor="let pagee of pageslist">
                        <div class="box-div inner-exp"
                            [ngClass]="{'business-border': pagee.page_type == 1,'volunteer-border': pagee.page_type == 2,'school-border': [3,4].indexOf(pagee.page_type) > -1}">
                            <div class="experience-div media">
                                <img *ngIf="!pagee.display_pic" src="assets/images/p-img.png">
                                <img *ngIf="pagee.display_pic" src="{{url}}{{pagee.display_pic}}">
                                <div class="media-body ml-2 mt-1">
                                    <h4>{{pagee.page_info.title}}
                                        <!-- <a class="float-right"><img  alt="image" src="assets/images/setting.svg" class="setting-icon"
                                                (click)="OpenPageSettings(pagee.page_info.instance_id); modalRef.hide()"></a> -->
                                        <span
                                            *ngIf="pagee.business && pagee.page_info.address">{{pagee.page_info.address.split(',')[0]}}</span>
                                        <span
                                            *ngIf="pagee.club_team && pagee.page_info.school_name">{{pagee.page_info.school_name}}</span>
                                    </h4>
                                    <button *ngIf="pagee.business" class="common-btn ghost"
                                        (click)="OpenPage(pagee.page_info.instance_id, 'business'); modalRef.hide()">Go
                                        to Page</button>
                                    <button *ngIf="pagee.club_team" class="common-btn ghost"
                                        (click)="OpenPage(pagee.page_info.instance_id, 'club_team'); modalRef.hide()">Go
                                        to Page</button>
                                    <button class="common-btn ghost ml-2"
                                        (click)="OpenPageSettings(pagee.page_info.instance_id); modalRef.hide()">
                                        Go to Settings
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="onBoard-overlay noticeOverlay" *ngIf="user.age_group_updated">
    <div class="onBoard-div">
        <div class="noticeDiv" *ngIf="stepOne">
            <h3>CONGRATS, CONNECTUS.TODAY GRAD!</h3>
            <p>
                Every year, on April 21, we take all of the students who will be turning 14 within the year and graduate
                them to a Full-Access User (a.k.a. users who have full access to all features within our Platform). We
                have noticed that you are turning 14 years old this year, so you, too, will be able to use the full
                site!

            </p>
            <p>
                Before using ConnectUs.Today as a Full-Access User, we must ask that you Read and Accept the Terms of
                Use and Privacy Policy again with a Parent/Guardian, so that you understand how your Terms and Privacy
                as a user is changing.
            </p>
            <div class="text-center">
                <button class="common-btn" (click)="showTwo();">Next</button>
            </div>
        </div>
        <div class="noticeDiv" *ngIf="stepTwo">
            <h3>TERMS OF USE / PRIVACY POLICY CLICKWRAP</h3>
            <p>
                Please Read and Accept the following Terms of Use and Privacy Policy with a Parent/Guardian, so that you
                understand how your Terms and Privacy as a user is changing. If you do not Accept, you will only have
                access to Account Settings where you may either Delete your Account or Read through the Terms and
                Privacy Policy to Accept.
            </p>
            <form [formGroup]="termsAcceptForm">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
                            (change)="onCheckboxChange($event)">
                        <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
                            Policy</label>
                    </div>
                    <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
                        This field is required.
                    </div>
                </div>
            </form>
            <div class="text-center">
                <button class="common-btn ghost mr-3" (click)="showThree();">Logout</button>
                <button class="common-btn" (click)="submitTermsAcceptForm()">Next</button>
            </div>
        </div>
        <div class="noticeDiv" *ngIf="stepThree">
            <h3>YOU ARE ABOUT TO LOG OUT!</h3>
            <h3 class="text-left">PLEASE NOTE</h3>
            <p>
                If you Log Out, you understand that you will only have Access to your Account Settings to either Review
                and Accept the Terms of Use and Privacy Policy, or to Delete your Account.</p>
            <h3 class="text-left">
                YOU WILL NOT HAVE ACCESS TO THE REST OF YOUR ACCOUNT UNTIL YOU ACCEPT THE TERMS OF USE AND PRIVACY
                POLICY.
            </h3>
            <p>
                Click Log Out to continue, or click the Back button to return to the previous screen.
            </p>
            <form [formGroup]="termsAcceptForm">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
                            (change)="onCheckboxChange($event)">
                        <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
                            Policy</label>
                    </div>
                    <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
                        This field is required.
                    </div>
                </div>
            </form>
            <div class="text-center">
                <button class="common-btn ghost mr-3" (click)="goBack()">Go Back</button>
                <button class="common-btn" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>
<div class="info-overlay" *ngIf="infoSlide">
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <img  alt="image" src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none">
        <img  alt="image" src="assets/images/info-mobile.svg" class="d-md-none d-block">
    </div>
</div>
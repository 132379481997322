import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Component, OnInit, TemplateRef, Input, EventEmitter   } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors, Form } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { IVolunteerPosting } from '../../shared/jobPosting'
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'volunteer-posting',
  templateUrl: './volunteer-posting.component.html',
  styleUrls: ['./volunteer-posting.component.scss']
})
export class VolunteerPostingComponent implements OnInit {
  @Input() page_id: string = null;
  @Input() editable: boolean = false;
  @Input() pageVerified:string;
  disableSubmit: boolean = false;
  volunteerPostings: IVolunteerPosting[] = [];
  npoAddress: string = '';
  volunteerObj: IVolunteerPosting = null;
  modalRef: BsModalRef;
  updateAddress: string = '';
  volunteerPostForm: FormGroup;
  maxNum: number = 5;
  addNewVolunteer: boolean = false;
  editVolunteerID: string = null;
  submitted: boolean = false;
  restrictedWebsites: any[];
  public editor = ClassicEditor;
  invalidWeb: string = '';
  viewDate: Date = new Date();
  hideResults: boolean = true;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  searchPlaces: any[] = [];

  selected_time = {
    
  };
  meridian = true;

  toggleMeridian() {
      this.meridian = !this.meridian;
  }

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService, private customValidator: CustomvalidationService) {
    this.volunteerPostForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      how_to_apply: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(2250), this.websiteLinkValidator]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      start_time: new FormControl(),
      hours: new FormControl('', [Validators.min(0), Validators.max(200)]),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl(),
    }, { validators: this.startEndValidator });
   }

   resetEndDate(){
    this.volunteerPostForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.volunteerPostForm.controls['start_date'].setValue(null);
  }

  volunteerFormInit(){
    this.volunteerPostForm.reset();
    this.submitted = false;
    this.updateAddress = this.npoAddress;
    this.volunteerPostForm.controls.address.setValue(this.npoAddress);
    this.selected_time = {}
  }

  ngOnInit(): void {
    this.getVolunteerList();
    this.getRestrictedWebsites();
    this.editor;
    this.updateAddress = this.npoAddress;
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }

  startEndValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const start_date = control.get('start_date').value;
    const end_date = control.get('end_date').value;
    return (start_date && end_date) && start_date > end_date ? {
       'startEndValid': true } : null;
  };

  timeValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let isValid = true;
    let hours = control.value;
    const regex = new RegExp(/^(?:\d|[01]\d|2[0-3]):[0-5]\d$/);
    isValid = regex.test(hours);
    if (hours==null || hours==''){
      isValid = true; 
    }
    return !isValid ? { 'timeValid': true } : null;
  };

  websiteLinkValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let description = control.value;
    this.invalidWeb = '';
    if (description == null){
      return null;
    }
    let regexr = new RegExp(/<a([^>]*?)href\s*=\s*(['"])([^\2]*?)\2\1*>/g);
    let links_used = description.match(regexr);
    links_used = links_used || [];
    let isValid = true;
    let invalidLinks: string[] = [];
    links_used.forEach((Link: string) => {
      if (this.restrictedWebsites.some(web => Link.includes(web))) {
        invalidLinks.push(Link);
        isValid = false;
      }
    })
    if (!isValid){
      this.invalidWeb = invalidLinks.join();
    } else {
      this.invalidWeb = '';
    }
    return !description || !isValid ? { 'linksValid': true } : null;
  };

  updateDescription(description: any){
    let regexr = new RegExp(/<a([^>]*?)href\s*=\s*(['"])([^\2]*?)\2\1*>/g);
    let links_used = description.match(regexr);
    links_used = links_used || [];
    links_used.forEach((Link: string) => {
      let prev_url = Link.match(this.customValidator.urlRegex1);
      if (prev_url && prev_url[0].indexOf('http://') == -1) {
        let c_prev_url = 'href="' + prev_url[0] + '"';
        let new_url = 'href="http://' + prev_url[0] + '"';
        description = description.replace(c_prev_url, new_url);
      }
    })
    return description;
  }

  getDateString(_date: Date) {
    if (_date==null){
      return null;
    }
    return _date.getFullYear().toString() + '-' 
      + (_date.getMonth() + 1).toString().padStart(2, "0") 
      + '-' + _date.getDate().toString().padStart(2, "0");
  }

  addUpdateVolunteerClicked(is_update: boolean = false, isDelete:boolean = false){
    if (!this.volunteerPostForm.valid){
      this.submitted = true;
      return
    }
    this.disableSubmit = true;
    this.submitted = false;
    let start_time;
    let time_dict = this.volunteerPostForm.controls['start_time'].value;
    if (time_dict==null || Object.keys(time_dict).length == 0){
      start_time = null;
    } else {
      if (!('minute' in time_dict)){
        time_dict['minute'] = 0;
      }
      start_time = time_dict.hour.toString() + ":" +  time_dict.minute.toString();
    }
    let description = this.updateDescription(this.volunteerPostForm.controls['description'].value);
    let volunteerObjSubmit: IVolunteerPosting = {
        'title': this.volunteerPostForm.controls['title'].value,
        'description': description,
        'how_to_apply': this.volunteerPostForm.controls['how_to_apply'].value,
        'address': this.volunteerPostForm.controls['address'].value,
        'is_deleted': isDelete,
        'start_date': this.getDateString(this.volunteerPostForm.get('start_date').value),
        'end_date': this.getDateString(this.volunteerPostForm.get('end_date').value),
        'start_time': start_time,
        'hours': this.volunteerPostForm.controls['hours'].value,
        'page': this.page_id
    }
    if (is_update){
      this.profileService.updateVolunteerJob(volunteerObjSubmit, this.editVolunteerID)
        .subscribe(response => {
          this.handleVolunteerResponse(response);
        }, error => {
          this.handleVolunteerSubmitError(error.error);
        });
    } else{
      this.profileService.addVolunteerJob(volunteerObjSubmit)
        .subscribe(response => {
          this.handleVolunteerResponse(response);
        }, error => {
          this.handleVolunteerSubmitError(error.error);
        });
    }
  }

  protected handleVolunteerResponse(response: any) {
    if (response.status === 111) {
      this.handleVolunteerSubmitError(response.message);
    } else if (response.status === 200) {
        this.disableSubmit = false;
        this.modalRef.hide();
        this.getVolunteerList();
        this.editVolunteerID = null;
        this.volunteerObj = null;
        this.volunteerFormInit();
        
    }
  }

  protected handleVolunteerSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.volunteerPostForm.contains(field)) {
        this.volunteerPostForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  volunteerFormUpdate(volunteerUpdateObj: IVolunteerPosting){
    this.volunteerPostForm.controls['title'].setValue(volunteerUpdateObj.title);
    this.volunteerPostForm.controls['description'].setValue(volunteerUpdateObj.description);
    this.volunteerPostForm.controls['how_to_apply'].setValue(volunteerUpdateObj.how_to_apply);
    this.volunteerPostForm.controls['start_date'].setValue(new Date(volunteerUpdateObj.start_date));
    if (volunteerUpdateObj.end_date){
      this.volunteerPostForm.controls['end_date'].setValue(new Date(volunteerUpdateObj.end_date));
    }
    this.volunteerPostForm.controls['address'].setValue(volunteerUpdateObj.address);
    this.updateAddress = volunteerUpdateObj.address;
    let start_time = volunteerUpdateObj.start_time;
    if (start_time!=null){
      let hour_minute = start_time.split(":");
      this.selected_time = {
        'hour': parseInt(hour_minute[0]),
        'minute': parseInt(hour_minute[1])
      }
    } else {
      this.selected_time = {};
    }
    this.volunteerPostForm.controls['start_time'].setValue(this.selected_time);
    this.volunteerPostForm.controls['hours'].setValue(volunteerUpdateObj.hours);
    this.editVolunteerID = volunteerUpdateObj.id.toString();
    this.addNewVolunteer = false;
  }

  updateVolunteerModal(volunteerModal: TemplateRef<any>, volunteerModalStatic: TemplateRef<any>, volunteer_id: string) {
    let ShowModal = null;
    if (this.editable){
       ShowModal = volunteerModal;
    } else {
      ShowModal = volunteerModalStatic;
    }
    this.profileService.RetreiveVolunteerJob(volunteer_id)
      .subscribe(response => {
        if(response.response.id && response.status == 200 ){
            this.volunteerObj = response.response;
            this.volunteerFormUpdate(this.volunteerObj);
            this.modalRef = this.modalService.show(
              ShowModal, Object.assign({}, { 
                class: 'modal-dialog-centered custom-modal'}));
          } else {
            this.getVolunteerList();
          }
        });
  }

  openVolunteerModal(volunteerModal: TemplateRef<any>) {
    this.addNewVolunteer = true;
    this.editVolunteerID = null;
    this.volunteerObj = null;
    this.volunteerFormInit();
    this.modalRef = this.modalService.show(volunteerModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  getVolunteerList(){
    this.profileService.listVolunteerJobs(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.volunteerPostings = response.response;
        } else {
          this.volunteerPostings = [];
        }
      });
  }

  getRestrictedWebsites(){
    this.profileService.listRestricted()
    .subscribe(response => {
      if(response.response.length > 0){
        this.restrictedWebsites = response.response.map(
          function (wb) { return wb.url; });
        } else {
          this.restrictedWebsites = [];
        }
      });
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.volunteerPostForm.controls['address'].setValue(null);
    } else {
      this.volunteerPostForm.controls['address'].setValue(e.target.value);
    }
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){
    this.hideResults = e;
  }

  formClick(){
    this.hideResults = true;
  }

  selectPlace(place){
    // use place.postion for lat long => "position":{"lat":33.99555,"lon":-118.47603}
    let lat = place.position.lat; // Use later
    let lon = place.position.lon; // Use later
    this.updateAddress = place.address.freeformAddress;
    this.volunteerPostForm.controls['address'].setValue(place.address.freeformAddress);
    this.hideResults = true;
    this.searchPlaces = [];
  }

}

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Component, OnInit, TemplateRef, Input, EventEmitter  } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { IJobPosting } from '../../shared/jobPosting'
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'job-posting',
  templateUrl: './job-posting.component.html',
  styleUrls: ['./job-posting.component.scss']
})
export class JobPostingComponent implements OnInit {

  @Input() page_id: string = null;
  @Input() editable: boolean = false;
  @Input() pageVerified: string;
  disableSubmit: boolean = false;
  jobPostings: IJobPosting[] = [];
  jobObj: IJobPosting = null;
  modalRef: BsModalRef;
  jobPostForm: FormGroup;
  maxNum: number = 5;
  addNewJob: boolean = false;
  editJobID: string = null;
  submitted: boolean = false;
  restrictedWebsites: any[];
  public editor = ClassicEditor;
  invalidWeb: string = '';
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService, private customValidator: CustomvalidationService) {
    this.jobPostForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      how_to_apply: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(2250), this.websiteLinkValidator]),
    });
   }

  jobFormInit(){
    this.jobPostForm.reset();
    this.submitted = false;
  }

  ngOnInit(): void {
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    this.getJobList(); 
    this.getRestrictedWebsites();
    this.editor
  }

  websiteLinkValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
    let description = control.value;
    this.invalidWeb = '';
    if (description == null){
      return null;
    }
    let regexr = new RegExp(/<a([^>]*?)href\s*=\s*(['"])([^\2]*?)\2\1*>/g);
    let links_used = description.match(regexr);
    links_used = links_used || [];
    let isValid = true;
    let invalidLinks: string[] = [];
    links_used.forEach((Link: string) => {
      if (this.restrictedWebsites.some(web => Link.includes(web))) {
        invalidLinks.push(Link);
        isValid = false;
      }
    })
    if (!isValid){
      this.invalidWeb = invalidLinks.join();
    } else {
      this.invalidWeb = '';
    }
    return !description || !isValid ? { 'linksValid': true } : null;
  };

  updateDescription(description: any){
    let regexr = new RegExp(/<a([^>]*?)href\s*=\s*(['"])([^\2]*?)\2\1*>/g);
    let links_used = description.match(regexr);
    links_used = links_used || [];
    links_used.forEach((Link: string) => {
      let prev_url = Link.match(this.customValidator.urlRegex1);
      if (prev_url && prev_url[0].indexOf('http://') == -1) {
        let c_prev_url = 'href="' + prev_url[0] + '"';
        let new_url = 'href="http://' + prev_url[0] + '"';
        description = description.replace(c_prev_url, new_url);
      }
    })
    return description;
  }

  addUpdateJobClicked(is_update: boolean = false, isDelete:boolean = false){
    if (!this.jobPostForm.valid){
      this.submitted = true;
      return
    }
    this.disableSubmit = true;
    this.submitted = false;
    let description = this.updateDescription(this.jobPostForm.controls['description'].value);
    let jobObjSubmit: IJobPosting = {
        'title': this.jobPostForm.controls['title'].value,
        'description': description,
        'how_to_apply': this.jobPostForm.controls['how_to_apply'].value,
        'is_deleted': isDelete,
        'page': this.page_id
    }
    if (is_update){
      this.profileService.updateJob(jobObjSubmit, this.editJobID)
        .subscribe(response => {
          this.handleJobResponse(response);
        }, error => {
          this.handleJobSubmitError(error.error);
        });
    } else{
      this.profileService.addJob(jobObjSubmit)
        .subscribe(response => {
          this.handleJobResponse(response);
        }, error => {
          this.handleJobSubmitError(error.error);
        });
    }
  }

  protected handleJobResponse(response: any) {
    if (response.status === 111) {
      this.handleJobSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.getJobList();
        this.editJobID = null;
        this.jobObj = null;
        this.jobFormInit();
        this.disableSubmit = false;
        
    }
  }

  protected handleJobSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.jobPostForm.contains(field)) {
        this.jobPostForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  jobFormUpdate(jobUpdateObj: IJobPosting){
    this.jobPostForm.controls['title'].setValue(jobUpdateObj.title);
    this.jobPostForm.controls['description'].setValue(jobUpdateObj.description);
    this.jobPostForm.controls['how_to_apply'].setValue(jobUpdateObj.how_to_apply);
    this.editJobID = jobUpdateObj.id.toString();
    this.addNewJob = false;
  }

  updateJobModal(jobModal: TemplateRef<any>, jobModalStatic: TemplateRef<any>, job_post: any) {
    let ShowModal = null;
    let job_id = job_post.id;
    if (this.editable){
       ShowModal = jobModal;
       this.profileService.RetreiveJob(job_id)
      .subscribe(response => {
        if(response.response.id && response.status == 200 ){
            this.jobObj = response.response;
            this.jobFormUpdate(this.jobObj);
            this.modalRef = this.modalService.show(
              ShowModal, Object.assign({}, { 
                class: 'modal-dialog-centered custom-modal'}));
          } else {
            this.getJobList();
          }
        });
    } else {
      this.jobObj = job_post;
      ShowModal = jobModalStatic;
      this.modalRef = this.modalService.show(
        ShowModal, Object.assign({}, { 
          class: 'modal-dialog-centered custom-modal'}));
    }
  }

  openJobModal(jobModal: TemplateRef<any>) {
    this.addNewJob = true;
    this.editJobID = null;
    this.jobObj = null;
    this.jobFormInit();
    this.modalRef = this.modalService.show(jobModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  getJobList(){
    this.profileService.listJobs(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.jobPostings = response.response;
        } else {
          this.jobPostings = [];
        }
      });
  }

  getRestrictedWebsites(){
    this.profileService.listRestricted()
    .subscribe(response => {
      if(response.response.length > 0){
        this.restrictedWebsites = response.response.map(
          function (wb) { return wb.url; });
        } else {
          this.restrictedWebsites = [];
        }
      });
  }

}

<section class="profile-head helpsection">
        <div class="container">
            <div class="row">
                <div class="col-md-2 col-xl-2 student p-0">
                </div>
                <div class="col-md-10 col-xl-10 p-0">
                        <div class="top-sticky">
                                <div class="profile-sec media pb-3">
                                    <div class="headerDiv">
                                            <img *ngIf="profileHeader_name" src="{{profileHeader}}" class="headerImg">
                                            <img *ngIf="!profileHeader_name" src="assets/images/default-header.jpg" class="headerImg">
                                    </div>
                                    <form class="formImg">
                                            <img *ngIf="profileImage_name" src="{{profileImage}}" class="profileImg">
                                        <img *ngIf="!profileImage_name" src="assets/images/p-img.png" class="profileImg">
                                    </form>
                                    <div class="media-body ml-md-3">
                                        <h1>{{user.first_name}} {{user.last_name}}</h1>
                                        <ul class="d-flex pl-0 d-none mb-0">
                                            <li (click)="openSetting(settingModal)">
                                                <a href="javascript:void();">Settings</a>
                                            </li>
                                            <li class="cursor-pointer" *ngIf="user.user_role == '2' && user.age_group > 1 && loggedInUser.user_role == '2' && loggedInUser.age_group > 1">
                                                <a *ngIf="isUserBlocked" (click)="openMsgModal(noMessage)">
                                                    Message
                                                </a>
                                                <a *ngIf="!isUserBlocked" (click)="sendMessage()">
                                                    Message
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    <div class="side-links">
                            <ul>
                                <li class="active">
                                    <a [routerLink]="['/profile', user.id]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                                        true}">
                                        <img src="assets/images/user.svg"  class=" d-md-inline-block d-none"/>
                                        <span class="d-md-none d-block">Profile</span>
                                    </a>
                                </li>
                                <li class="bg-bsns d-md-block d-none">
                                    <a [routerLink]="['/help/contact/', user.id]" class="active-link" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                                        true}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <g fill="none" fill-rule="evenodd">
                                                <rect width="24" height="24" fill="#335175" rx="12" transform="matrix(-1 0 0 1 24 0)"/>
                                                <g fill="#FFF" fill-rule="nonzero">
                                                    <path d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z" transform="translate(7.2 6)"/>
                                                </g>
                                            </g>
                                        </svg>
                                        
                                    </a>
                                </li>  
                            </ul>
                        </div>
                    <div class="media-body">
                        <h1 class="settingText">Need Help?</h1>
                    </div>
                    <div class="content-section pt-3">
                        <div class="profile-div">
                            <div class="profile-inner full-inner">
                                <h3>
                                    Page Types
                                </h3>
                                <div class="box-div">
                                    <div class="inner-div">
                                        <h4 class="bg-text">Click on any of the followingPage Typesto view what it
                                            resembles when fully filled
                                            out. These are only examples; the information within is not accurate, nor does
                                            it
                                            represent anybody's real profile. </h4>
                                        <div class="d-flex justify-content-around flex-wrap">
                                            <div class="boxInner cursor-pointer text-center" (click)="openStudent(studentModal)">
                                                <img src="assets/images/student-icon.svg">
                                                <h5>Student</h5>
                                            </div>
                                            <div class="boxInner cursor-pointer text-center" (click)="openBusiness(businessModal)">
                                                <img src="assets/images/business-icon.svg">
                                                <h5>Business</h5>
                                            </div>
                                            <div class="boxInner cursor-pointer text-center" (click)="openVolunteer(volunteerModal)">
                                                <img src="assets/images/non-icon.svg">
                                                <h5>Non-Profit</h5>
                                            </div>
                                            <div class="boxInner cursor-pointer text-center" (click)="openSchool(schoolModal)">
                                                <img src="assets/images/school-icon.svg">
                                                <h5>School Club</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="profile-inner full-inner mt-md-4">
                                    <h3>Contact Us</h3>
                                    <div class="box-div">
                                        <form [formGroup]="contactUsForm" novalidate>
                                            <div class="row">
                                                <div class="form-group" [ngClass]="{'col-md-4': displayColClass}">
                                                    <label>Full Name</label>
                                                    <input [readonly]="hideNameEmail" type="text" class="form-control" placeholder="Enter Full Name" formControlName="name">
                                                    <div class="error-message" *ngIf="contactUsForm.get('name').hasError('required') && (contactUsForm.get('name').dirty || contactUsForm.get('name').touched || submitted)">
                                                        This field is required.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('name').hasError('maxlength') && (contactUsForm.get('name').dirty || contactUsForm.get('name').touched)">
                                                        Max 50 characters allowed.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('name').hasError('aftersubmit')">
                                                        {{contactUsForm.controls.name.errors.aftersubmit}}
                                                    </div>
                                                </div>
                                                <div class="form-group" [ngClass]="{'col-md-4': displayColClass}">
                                                    <label>Email</label>
                                                    <input [readonly]="hideNameEmail" type="email" class="form-control" placeholder="Enter Email" formControlName="email">
                                                    <div class="error-message" *ngIf="contactUsForm.get('email').hasError('required') && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched || submitted)">
                                                        This field is required.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('email').hasError('pattern') && (contactUsForm.get('email').dirty || contactUsForm.get('email').touched)">
                                                        Please enter your email in a valid format.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('email').hasError('aftersubmit')">
                                                        {{contactUsForm.controls.email.errors.aftersubmit}}
                                                    </div>
                                                </div>
                                                <div class="form-group" [ngClass]="{'col-md-4': displayColClass}" >
                                                    <label>Subject</label>
                                                    <input type="text" class="form-control" placeholder="Enter Subject" formControlName="subject">
                                                    <div class="error-message" *ngIf="contactUsForm.get('subject').hasError('required') && (contactUsForm.get('subject').dirty || contactUsForm.get('subject').touched || submitted)">
                                                        This field is required.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('subject').hasError('maxlength') && (contactUsForm.get('subject').dirty || contactUsForm.get('subject').touched)">
                                                        Max 100 characters allowed.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('subject').hasError('aftersubmit')">
                                                        {{contactUsForm.controls.subject.errors.aftersubmit}}
                                                    </div>
                                                </div>
                                                <div class="form-group" [ngClass]="{'col-md-12': displayColClass}">
                                                    <label>Message</label>
                                                    <textarea class="form-control" placeholder="Type your Message Here" formControlName="message"></textarea>
                                                    <div class="error-message" *ngIf="contactUsForm.get('message').hasError('required') && (contactUsForm.get('message').dirty || contactUsForm.get('message').touched || submitted)">
                                                        This field is required.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('message').hasError('maxlength') && (contactUsForm.get('message').dirty || contactUsForm.get('message').touched)">
                                                        Max 500 characters allowed.
                                                    </div>
                                                    <div class="error-message" *ngIf="contactUsForm.get('message').hasError('aftersubmit')">
                                                        {{contactUsForm.controls.message.errors.aftersubmit}}
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-12 text-center" [ngClass]="{'col-md-12': displayColClass, 'text-center': displayColClass}">
                                                    <button class="btn-submit mt-md-0 mt-3" (click)="submitContactUs(successModal)" [disabled]="disableSubmit">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <ng-template #successModal>
                                        <div class="modal-header">
                                            <h4 class="modal-title pull-left">Contact us form submitted</h4>
                                            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                                                <img src="assets/images/cross-modal.svg">
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="common-div text-left">
                                                        <h3>Thank you for connecting with us!</h3>
                                                        <p>One of our representatives will get in touch with you shortly.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <ng-template #settingModal>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Settings</h4>
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-body">
            <div class="modal-box">
                <form [formGroup]="blockReportForm">
                    <div class="d-flex align-items-center">
                        <div class="form-group mr-5">
                            <div class="custom-control custom-checkbox" *ngIf="isUserBlocked">
                                <input type="checkbox" class="custom-control-input" id="block"
                                    formControlName="block_user" (click)="blockUser();" >
                                <label class="custom-control-label" for="block">Unblock User</label>
                            </div>
                            <div class="custom-control custom-checkbox" *ngIf="!isUserBlocked">
                                <input type="checkbox" class="custom-control-input" id="block"
                                    formControlName="block_user" (click)="blockUser();">
                                <label class="custom-control-label" for="block">Block User</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="report"
                                    formControlName="report_user" (click)="reportReason();">
                                <label class="custom-control-label" for="report">Report User</label>
                            </div>
                        </div>
                    </div>
                    <div class="reportShow" *ngIf="reportUser">
                        <div class="form-group">
                            <label>Reason</label>
                            <select class="form-control custom-select" formControlName="report_reason">
                                    <option value=1>Hate Speech</option>
                                    <option value=2>Bullying and harrassment of yourself or others</option>
                                    <option value=3>Direct threats</option>
                                    <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)</option>                                                
                                    <option value=5>Other</option>
                            </select>
                        </div>
                        <div class="error-message" *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="form-group">
                            <label>Description</label>
                            <textarea class="form-control" formControlName="report_description"></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-footer" (click)="modalRef.hide()">
                Decline
            </button> 
            <button class="btn btn-footer" (click)="blockUnblockUser(user.id)">
                Save
            </button>
        </div>
    </ng-template>
    <ng-template #businessModal>
        <div class="modal-body p-2">
            <div class="modal-header p-0">
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-box">
                <img src="assets/images/business-profile.png">
            </div>
        </div>
    </ng-template>
    <ng-template #volunteerModal>
        <div class="modal-body p-2">
            <div class="modal-header p-0">
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-box">
                <img src="assets/images/volunteer-profile.png">
            </div>
        </div>
    </ng-template>
    <ng-template #schoolModal>
        <div class="modal-body p-2">
            <div class="modal-header p-0">
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-box">
                <img src="assets/images/school-profile.png">
            </div>
        </div>
    </ng-template>
    <ng-template #studentModal>
        <div class="modal-body p-2">
            <div class="modal-header p-0">
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-box">
                <img src="assets/images/student-profile.png">
            </div>
        </div>
    </ng-template>

    <ng-template #settingModal>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Settings</h4>
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-box">
                    <form>
                        <div class="d-flex align-items-center">
                            <div class="form-group mr-5">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="block">
                                    <label class="custom-control-label" for="block">Block User</label>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="report">
                                    <label class="custom-control-label" for="report">Report User</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-footer">
                    Save
                </button>
                <button class="btn btn-footer">
                    Decline
                </button>
            </div>
        </ng-template>

        <ng-template #noMessage>
            <div class="modal-header">
                <h4 class="modal-title pull-left">Send Message</h4>
                <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                    <img src="assets/images/cross-modal.svg">
                </button>
            </div>
            <div class="modal-body">
                <div class="modal-box">
                    <p>{{messageError}}</p>
                </div>
            </div>
        </ng-template>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login-tab',
  templateUrl: './login-tab.component.html',
  styleUrls: ['./login-tab.component.scss']
})
export class LoginTabComponent implements OnInit {
  infoSlide:boolean = false;
  constructor() { }
  
  


  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
  slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };


  ngOnInit(): void {
  }
  
  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }


}

import { Component, OnInit, TemplateRef , EventEmitter} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { studentIntroImages, studentIntroImagesMobile, studentIntroImagesLess13, studentIntroImagesMobileLess13,
        businessIntroImages, businessIntroImagesMobile, volunteerIntroImages, volunteerIntroImagesMobile,
        schoolIntroImages, schoolIntroImagesMobile } from '../../app.data';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { LoginService } from '../../services/login.service';
import { first } from 'rxjs/operators';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { ProfileService } from '../../services/profile.service';

const mediaURL = environment.mediaURL;

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss'],
    providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})

export class StudentDashboardComponent implements OnInit {
  loggedIn: boolean = true;
  termsAcceptForm: FormGroup;
  firstStudentLogin: boolean = false;
  is_terms_accepted: boolean = false;
  isMinor: boolean = false;
  isSenior: boolean = false;
  user: any = {};
  modalRef: BsModalRef;
  gears = [];
  gearsMobile = [];
  pageslist: any = []
  pagetitle: string;
  pagecountry: string;
  pagestate: string;
  pagepic: string;
  page_id: string;
  pagetype: string;
  pagetypebusiness: any;
  params: string = "";
  instance_id: string;
  profileImageHeader: string;
  profileImageHeader_name: string;
  url = mediaURL
  day: string = "";
  pagepic_name: string;
  account_verification_status: string;
  stepOne: boolean = true;
  stepTwo: boolean = false;
  infoSlide: boolean = false;
  stepThree: boolean = false;
  studentAge: boolean = false;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  slideConfig: any = [];

  // slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "infinite": false,};

  constructor(private profileService: ProfileService, private loginService: LoginService, public formBuilder: FormBuilder, private authService: AuthenticationService, private router: Router, private modalService: BsModalService) {
    this.termsAcceptForm = this.formBuilder.group({
      terms: new FormControl('', [Validators.required]),
    });
  }

  myFunction() {
    setTimeout(() => {
     this.slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true, };
    }, 2000);
  }

  slideConfigMob = { "slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    this.day = new Date().toLocaleString('en-us', { weekday: 'long' })
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      this.profileImageHeader = mediaURL + this.user.display_picture;
      this.profileImageHeader_name = this.user.display_picture;
      if (this.user.age_group == 1) {
        this.isMinor = true;
      } else {
        this.isMinor = false;
      }
      if (this.user.age_group == 3) {
        this.isSenior = true;
      } else {
        this.isSenior = false;
      }
      if (this.user.user_role == "2") {
        if (this.user.age_group == 1) {
          this.gears = studentIntroImagesLess13;
          this.gearsMobile = studentIntroImagesMobileLess13;
        } else {
          this.gears = studentIntroImages;
          this.gearsMobile = studentIntroImagesMobile;
        }
      } else if (this.user.user_role == "3") {
        this.gears = schoolIntroImages;
        this.gearsMobile = schoolIntroImagesMobile;
      } else if (this.user.user_role == "4") {
        this.gears = volunteerIntroImages;
        this.gearsMobile = volunteerIntroImagesMobile;
      } else {
        this.gears = businessIntroImages;
        this.gearsMobile = businessIntroImagesMobile;
      }
      if (currentUser.first_login) {
        this.firstStudentLogin = true;
      }
    } else {
      this.router.navigate(['/']);
    }
    this.authService.listPages(this.params)
      .subscribe(response => {
        this.pageslist = response.response;
        if (this.pageslist.length > 0) {
          this.pagetitle = response.response[0].page_info.title
          this.pagecountry = response.response[0].page_info.country
          this.pagestate = response.response[0].page_info.state
          this.page_id = response.response[0].page_info.instance_id
          this.pagetypebusiness = response.response[0].business
          this.pagepic = mediaURL + response.response[0].display_pic
          this.pagepic_name = response.response[0].display_pic
        }
      });

    this.authService.AccountVerificationStatus()
      .subscribe(response => {
        this.account_verification_status = response.response.account_verified
      });
  }
  closeDiv() {
    this.account_verification_status = '2'
  }
  openHelp(studentModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(studentModal, Object.assign({}, { class: 'profile-modal modal-dialog-centered' }));
  }


  openInfo() {
    this.infoSlide = true;
  }
  hideInfo() {
    this.infoSlide = false;
  }

  closeSlide() {
    this.firstStudentLogin = false;
    this.user.first_login = false;
    localStorage.setItem('user', JSON.stringify(this.user));
  }

  OpenPage(pageid: string, pagetype: string) {
    if (pagetype === 'business') {
      this.router.navigate(['page/' + pageid + '/profile-business']);
    } else {
      this.router.navigate(['page/' + pageid + '/profile-school']);
    }

  }

  OpenPageSettings(pageid: string) {
    this.router.navigate(['page/' + pageid + '/setting']);
  }

  redirectToProfile() {
    if (this.user.user_role == "2") {
      this.router.navigate(['/profile']);
    } else if (this.user.user_role == "3") {
      this.router.navigate(['/profile-school']);
    } else {
      this.router.navigate(['/profile-business']);
    }
  }

  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'custom-modal modal-dialog-centered business-modal' }));
  }
  showTwo() {
    this.stepOne = false;
    this.stepTwo = true;
  }
  showThree() {
    this.stepTwo = false;
    this.stepThree = true;
  }
  goBack() {
    this.stepTwo = true;
    this.stepThree = false;
  }
  logout() {
    this.loginService.userLogout()
      .subscribe(
        data => {
          localStorage.removeItem('user');
          window.location.href = '/';
          this.loadPublicToken();
        },
        error => { }
      )
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
      .pipe(first())
      .subscribe(
        data => {
        },
        error => {

        });
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.is_terms_accepted = true;
    } else {
      this.is_terms_accepted = false;
    }
  }

  submitTermsAcceptForm() {
    if (this.is_terms_accepted) {
      var data = { 'is_terms_accepted': this.is_terms_accepted }
      this.profileService.AcceptTerms(data, this.user.id).subscribe(response => {
        this.handleResponse(response);
      })
    }
  }

  handleResponse(response: any) {
    if (response.status === 200) {
        this.user.age_group_updated = response.response.age_group_updated;
        this.user.age_group = response.response.age_group;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
    }
  }

}

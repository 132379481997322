<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <img src="assets/images/Main-Page-Search.jpg" class="mx-auto" alt="Image banner">
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student form-section">
                    <app-sidebar></app-sidebar>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center mx-auto">
                            <h1 class="pl-2 pr-2">Parent Verification</h1>
                            <div >
                                <form [formGroup]="changeEmailForm" novalidate >
                                    <div class="form-group gutters-5 row">
                                        <div class="col-md-12">
                                            <label>Enter Your Email ID</label>
                                        </div>
                                        <div class="col-md-12">
                                            <input type="email" class="form-control mb-2" placeholder="Enter Your Email ID" formControlName="email">
                                            <div class="error-message" *ngIf="changeEmailForm.get('email').hasError('required') && (changeEmailForm.get('email').dirty || changeEmailForm.get('email').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message" *ngIf="changeEmailForm.get('email').hasError('aftersubmit')">
                                                {{changeEmailForm.controls.email.errors.aftersubmit}}
                                            </div>
                                            <div class="error-message" *ngIf="changeEmailForm.get('email').hasError('pattern') && (changeEmailForm.get('email').dirty || changeEmailForm.get('email').touched)">
                                                Please enter your email in a valid format.
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="form-group gutters-5 row">
                                        <div class="col-md-12">
                                            <label>Enter Password</label>
                                        </div>
                                        <div class="col-md-12">
                                            <input type="password" class="form-control mb-2" placeholder="Enter Password" formControlName="password">
                                            <div class="error-message" *ngIf="changeEmailForm.get('password').hasError('required') && (changeEmailForm.get('password').dirty || changeEmailForm.get('password').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message" *ngIf="changeEmailForm.get('password').hasError('aftersubmit')">
                                                {{changeEmailForm.controls.password.errors.aftersubmit}}
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="form-group gutters-5 row">
                                        <div class="col-md-12">
                                            <label>Enter Parent's Email ID</label>
                                        </div>
                                        <div class="col-md-12">
                                            <input type="email" class="form-control mb-2" placeholder="Enter Parent's Email ID" formControlName="parent_email">
                                            <div class="error-message" *ngIf="changeEmailForm.get('parent_email').hasError('required') && (changeEmailForm.get('parent_email').dirty || changeEmailForm.get('parent_email').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message" *ngIf="changeEmailForm.get('parent_email').hasError('aftersubmit')">
                                                {{changeEmailForm.controls.parent_email.errors.aftersubmit}}
                                            </div>
                                            <div class="error-message" *ngIf="changeEmailForm.get('parent_email').hasError('pattern') && (changeEmailForm.get('parent_email').dirty || changeEmailForm.get('parent_email').touched)">
                                                Please enter the email in a valid format.
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="form-group gutters-5">
                                        <div class="col-md-12">
                                            <button (click)="changeParentMail(successModal)" type="submit" class="btn btn-submit">Confirm</button>
                                        </div>
                                    </div>
                                </form>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #successModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Parent Email Changed</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="common-div text-left">
                    <h3>Your parent email ID has been changed successfully.</h3>
                    <p>A confirmation email has been sent to your Parent/Guardian at {{parentEmail}}. Please get your Parent’s/Guardian’s approval to access your account.</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<section class="verification-screen d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-md-9 mx-auto">
                <div class="verify-inner py-3">
                    <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto" alt="Image banner">
                </div>
                <div class="button-div mt-4 text-center mb-4">
                    <h2 class="text-center" *ngIf="isResponse && isValid && !pageVerif">Thank you for your Feedback.</h2>
                    <h2 class="text-center" *ngIf="isResponse && isValid && pageVerif">Thank you, Your Page has been verified!</h2>
                    <h2 class="text-center" *ngIf="isResponse && !isValid">Link provided is expired or invalid.</h2>
                    <h2 class="text-center" *ngIf="!isResponse">Please wait.....</h2>
                </div>
            </div>
        </div>
    </div>
</section>

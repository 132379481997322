import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { unqiueProfile } from '../shared/uniqueProfile';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  loggedIn : boolean =false;
  user: any = {};
  user_profile_exist: boolean =false;
  profileForm: FormGroup;
  submitted: boolean = false;
  public searchSubmitted: boolean = false;
  isDisabled :boolean = true;
  minDate = new Date();
  maxDate = new Date();
  public infoDiv = true;

  profile: unqiueProfile = {
    user_id : this.user.id,
    date_of_birth : undefined
  };

  constructor(public formBuilder: FormBuilder, public authService :AuthenticationService, private router: Router) {

    this.profileForm = this.formBuilder.group({
      first_name: new FormControl({value: '', disabled: true}, [Validators.required]),
      last_name: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(50)]),
      date_of_birth: new FormControl('', Validators.required),
      email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(100)]),
    });

   }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      if (this.user.user_role === 2){
          this.isDisabled =true
          this.router.navigate(['/dashboard']);
      }
      this.profile.date_of_birth = this.user.date_of_birth;
      let dob = this.user.date_of_birth.split("-");
      let yob = Number(dob[0]);
      this.minDate = new Date(yob, 0, 1);
      this.maxDate = new Date(yob, 11, 31);
      this.profileForm.controls['first_name'].setValue(this.user.first_name, {onlySelf: true});
      this.profileForm.controls['last_name'].setValue(this.user.last_name, {onlySelf: true});
      this.profileForm.controls['date_of_birth'].setValue(this.user.date_of_birth, {onlySelf: true});
      this.profileForm.controls['email'].setValue(this.user.email, {onlySelf: true});
    }
  }

  getDateString(_date: Date) {
    if(_date == null){
      return null;
    }
    return _date.getFullYear().toString() + '-' 
      + (_date.getMonth() + 1).toString().padStart(2, "0") 
      + '-' + _date.getDate().toString().padStart(2, "0");
  }

  save(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let dob = this.getDateString(new Date(this.profileForm.get('date_of_birth').value));
      this.profile.user_id = this.user.id;
      this.profile.date_of_birth = dob;
      this.authService.setupUniqueProfile(this.profile)
      .subscribe(response => {
        this.handleResponse(response);
      },
        error => {
          this.handleSubmitError(error.error);
        });
    }
    else{
      this.searchSubmitted = true;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.profileForm.reset();
        this.submitted = false;
        this.user.user_role = response.response.user_role;
        this.user.date_of_birth = response.response.date_of_birth;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.href='/dashboard';
    } else {
      this.profileForm.reset();
      this.submitted = false;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.profileForm.contains(field)) {
        this.profileForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

}

<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 student p-0">
                <div class="green-sidebarSec">
                    <div class="setting-div mt-2">
                        <div class="setting-inner">
                            <h3>Settings</h3>
                            <img src="assets/images/cross.png"  alt="image" class="close-btn"
                                (click)="settingShow = !settingShow">
                            <form action="">
                                <div class="form-group mb-2 mt-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck1">Block
                                            User</label>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck">Report
                                            User</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <div class="top-sticky">
                    <div class="profile-sec media pb-3">
                        <div class="headerDiv">
                            <img *ngIf="headerImage_name" src="{{headerImage}}" class="profileImg" alt="image">
                            <img *ngIf="!headerImage_name" src="assets/images/p-img.png" class="profileImg" alt="image">

                        </div>
                        <form class="formImg">
                            <img *ngIf="displayImage_name" src="{{displayImage}}" class="profileImg" alt="image">
                            <img *ngIf="!displayImage_name" src="assets/images/p-img.png" class="profileImg" alt="image">
                        </form>
                        <div class="media-body ml-md-3">
                            <h1>
                                {{user.first_name}}
                            </h1>
                            <button *ngIf="user.user_role == '2'" class="common-btn mt-2" (click)="redirectToProfile()">Go to Profile</button>
                            <a [routerLink]="['/create-page']" class="common-btn mt-2 ml-2 ghost d-inline-block" >Add Page</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="settingText">My Pages</h1>
                        <div class="content-section pl-md-3 pr-md-3 pt-3 fullPage">
                            <div class="profile-div">
                                <div class="profile-inner mb-3" *ngFor="let pagee of pageslist">
                                    <div class="box-div inner-exp"
                                        [ngClass]="{'business-border': pagee.page_type == 1,'volunteer-border': pagee.page_type == 2,'school-border': [3,4].indexOf(pagee.page_type) > -1}">
                                        <div class="experience-div media">
                                            <img *ngIf="pagee.display_pic" src="{{url}}{{pagee.display_pic}}"
                                                class="profileImg" alt="image">
                                            <img *ngIf="!pagee.display_pic" src="assets/images/p-img.png"
                                                class="profileImg" alt="image">
                                            <div class="media-body ml-2 mt-1">
                                                <h4>{{pagee.page_info.title}}
                                                    <a class="float-right"><img src="assets/images/setting.svg"
                                                            class="setting-icon" alt="image"
                                                            (click)="OpenPageSettings(pagee.page_info.instance_id)"></a><span>{{pagee.page_info.state}},
                                                        {{pagee.page_info.country}}</span></h4>
                                                <button *ngIf="pagee.business" class="common-btn ghost mt-md-0 mt-2"
                                                    (click)="OpenPage(pagee.page_info.instance_id, 'business')">Go to
                                                    Page</button>
                                                <button *ngIf="pagee.club_team" class="common-btn ghost mt-md-0 mt-2"
                                                    (click)="OpenPage(pagee.page_info.instance_id, 'club_team')">Go to
                                                    Page</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner" *ngIf="pageslist?.length == 0">
                                    <div class="empty-div text-center">
                                        <p>Uh Oh! You have no <span *ngIf="user.user_role != '2'">resume or</span> page set up. Go to the Main Page and click the
                                             "Add Page" Tab. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
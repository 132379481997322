import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from '../../../environments/environment';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { ProfileService } from '../../services/profile.service';

const mediaURL = environment.mediaURL;

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  page_id: string;
  pagesDetail: any = {};
  user: any = {};
  page_Image: string = "";
  modalRef: BsModalRef;
  uploadForm: FormGroup;
  public submitted: boolean = false;
  public disableSubmit: boolean = false;
  public isOwner: boolean = false;
  public isHeaderToDel: boolean = false;
  public delError: boolean = false;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  delErrorMessage = "Unable to delete the image. Please try again later.";
  pageHeaderImg: string = "";
  public settingShow = true;
  detailForm: any = {};
  pageVerified: string;
  pageVerificationDaysLeft: string;
  pageType: string;
  pageName: string
  pageProfessionalEmail: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  @Input() page: any = {};

  constructor(private profileService: ProfileService, private route: ActivatedRoute, private authService: AuthenticationService, private router: Router, private modalService: BsModalService,
    public formBuilder: FormBuilder, private customValidator: CustomvalidationService) {
    this.uploadForm = this.formBuilder.group({
      document: new FormControl('', [Validators.required]),
      document_name: new FormControl('', [Validators.required])
    });

    this.detailForm = this.formBuilder.group({
        link : new FormControl('', Validators.pattern(this.customValidator.urlRegex)),
        phone_number : new FormControl('', [Validators.maxLength(15)]),
        contact_email : new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),     
    });
  }

  ngOnInit(): void {

    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser
    }
    this.page_id = this.route.snapshot.paramMap.get('page_id')
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        this.pagesDetail = response.response;
        if (this.pagesDetail.display_pic) {
          this.page_Image =  mediaURL + this.pagesDetail.display_pic;
        }
        if (this.pagesDetail.header_img) {
          this.pageHeaderImg =  mediaURL + this.pagesDetail.header_img;
        }
        if (this.pagesDetail.owner === this.user.id) {
          this.isOwner = true;
        } else {
          this.isOwner = false;
        }
      });
    
      this.profileService.BusinessVerificationStatus(this.page_id)
      .subscribe(response => {
        this.pageVerified = response.response.business_verified
        this.pageVerificationDaysLeft = response.response.days_left
        this.pageType = response.response.page_type
        this.pageName = response.response.page_info.name
        this.pageProfessionalEmail = response.response.page_info.email
      })
    
  }

  resendProEmail(){
    this.profileService.ResendProEmail(this.page_id)
    .subscribe(response => {

    })
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  openModal(template: TemplateRef<any>) {
    this.uploadForm.reset();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.submitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
 
  openDetail(editModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(editModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  onFileSelect(event) {
    this.uploadForm.get('document').setValue(null);
    this.imageChangedEvent = '';
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      this.uploadForm.get('document_name').setValue(file_name);
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Document size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        this.imageChangedEvent = event;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.uploadForm.get('document').setValue(event.base64);
  }

  loadImageFailed(){
    console.log("-=-=-=-Failed to load image");
  }

  addNewDetails(isValid: boolean) {
    this.submitted = true;
    if (isValid){
      let phone_number = this.detailForm.get('phone_number').value;
      let link = this.detailForm.get('link').value;
      let contact_email = this.detailForm.get('contact_email').value;
      let model = Object.assign({}, this.pagesDetail);
      if (phone_number){
        model.phone_number = phone_number
      }
      if (link){
        model.website_url = link
      }
      if (contact_email){
        model.professional_email = contact_email
      }
      if (this.pagesDetail.club_team){
        if (this.pagesDetail.club_team.school?.id) {
          model.club_team.school = this.pagesDetail.club_team.school.id;
        } else {
          model.club_team.school = this.pagesDetail.club_team.school;
        }
      }
      this.authService.UpdatePageBusiness(model,this.pagesDetail.id)
      .subscribe((response:any) => {
        if (response.status === 111) {
          const fields = Object.keys(response.message || {});
          if (fields.indexOf("new_email") > -1) {
            this.detailForm.get('contact_email').setErrors({ aftersubmit: response.message['new_email'][0] });
          }
          if (fields.indexOf("LINK") > -1) {
            this.detailForm.get('website_url').setErrors({ aftersubmit: response.message['LINK'] });
          }
        } else if (response.status === 200) {
          this.pagesDetail = response.response;
          this.submitted = false;
          this.detailForm.reset();
          this.modalRef.hide();
        } else {
          this.detailForm.get('website_url').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.detailForm.get('website_url').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
  }

   addImage(isValid: boolean, type:string) {
    this.submitted = true;
    this.disableSubmit = true;
    let model = {}
    if (isValid) {
      if (type === 'header'){
        model = {
          'document': this.uploadForm.get('document').value,
          'document_name': this.uploadForm.get('document_name').value,
          'display_pic': false,
          'header_img': true,
        }
      } else {
        model = {
          'document': this.uploadForm.get('document').value,
          'document_name': this.uploadForm.get('document_name').value,
          'display_pic': true,
          'header_img': false,
        }
      }
      this.authService.PageImg(model, this.page_id)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
      this.pagesDetail.display_pic = response.response.display_picture;
      this.pagesDetail.header_img = response.response.header_image;
      if (this.pagesDetail.header_img) {
        this.pageHeaderImg =  mediaURL + this.pagesDetail.header_img;
      }
      if (this.pagesDetail.display_pic) {
        this.page_Image =  mediaURL + this.pagesDetail.display_pic;
      }
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.uploadForm.contains(field)) {
        this.uploadForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  delImageModal(delImage: TemplateRef<any>, header_image: boolean = false) {
    this.delError = false;
    if (header_image) {
      this.isHeaderToDel = true;
    } else {
      this.isHeaderToDel = false;
    }
    this.modalRef = this.modalService.show(delImage,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  deleteImage(){
    this.profileService.delPageImage({'header_img': this.isHeaderToDel}, this.page_id)
    .subscribe(response => {
      this.handleDelResponse(response);
    }, error => {
      this.delError = true;
      this.fadeOutErrorMsg();
    })
  }
  
  showImg(headerImages: TemplateRef<any>) {
    this.modalRef = this.modalService.show(headerImages,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  handleDelResponse(response: any){
    if (response.status === 111) {
      this.delError = true;
      this.fadeOutErrorMsg();
    } else if (response.status === 200) {
        this.modalRef.hide();
        if (this.isHeaderToDel) {
          this.pageHeaderImg = null;
        } else {
          this.page_Image = null;
        }
    } else {
      this.delError = true;
      this.fadeOutErrorMsg();
    }
  }

  fadeOutErrorMsg() {
    setTimeout( () => {
      this.modalRef.hide();
      this.delError = false;
    }, 6000);
  }

}

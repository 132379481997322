import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicy: any =  "privacy_policy";
  privacyPolicyContent: any;

  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authService.staticContent(this.privacyPolicy)
    .subscribe(response => {
      this.privacyPolicyContent = response
    });
  }

}

<h3>SCHOOL INVOLVEMENT
    <a href="javascript:void()" class="float-right" *ngIf="!editable && account_verification_status != '3'"
        (click)="expModal(expTemplate)">
        <img  alt="image" src="assets/images/plus-icon.png" alt="">
    </a>
    <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
        <img  alt="image" src="assets/images/icEdit.svg">
    </a>
</h3>
<div class="scroll-activity" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
    <div class="box-div inner-exp cursor-pointer" *ngFor="let experience of schoolInvolvements"
        (click)="updateExpModal(editExpTemplate, experience)">
        <div class="experience-div media school-tile" [ngClass]="{'position-relative' : experience.approver_name && experience.verified_date}">
            <img (click)="redirectPage(experience);$event.stopPropagation()"
                src="{{experience.page_display_pic? mediaURL + experience.page_display_pic : 'assets/images/default.svg'}}">
            <div class="media-body ml-2">
                <h4>{{experience.position}}</h4>
                <p>{{experience.title}}</p>
                <h5 *ngIf="experience.start_date != experience.end_date">Start
                    <span>{{experience.start_date}}</span> End
                    <ng-template
                        [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                        [ngIfElse]="currentTemp">
                        <span>{{experience.end_date}}</span></ng-template>
                </h5>
                <h5 *ngIf="experience.start_date == experience.end_date">Date
                    <span>{{experience.start_date}}</span>
                </h5>
                <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                    <img alt="image" src="assets/images/verify-resume.svg">
                </p>
            </div>
        </div>
        <div class="side-col">
            <ul class="d-flex pl-0">
                <li class="school-tile">
                    <p>{{experience.skill_1}}</p>
                </li>
                <li class="school-tile">
                    <p>{{experience.skill_2}}</p>
                </li>
                <li class="school-tile">
                    <p>{{experience.skill_3}}</p>
                </li>
            </ul>
            <p>{{experience.main_role}}
            </p>
        </div>
    </div>
</div>
<div *ngIf="schoolInvolvements?.length == 0"
    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
    <p>Add School Involvement to your resume – this can be verified if you tag the Page and if the Page exists on ConnectUs.Today</p>
</div>

<ng-template #editExpTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">SCHOOL INVOLVEMENT</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="schoolInvUpdForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="experience-div media w-100">
                    <div *ngIf="!editable">
                        <img
                            src="{{editSchoolInv.page_display_pic? mediaURL + editSchoolInv.page_display_pic : 'assets/images/default.svg'}}">
                    </div>
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>School Club or Team</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Title of Club/Team"
                                            [readonly]="!editable || editSchoolInv.page" formControlName="title"
                                            (keydown)="searchOrg($event, true)" (focusout)="focusOutFunction()">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('title').hasError('required') && (schoolInvUpdForm.get('title').dirty || schoolInvUpdForm.get('title').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('title').hasError('maxlength') && (schoolInvUpdForm.get('title').dirty || schoolInvUpdForm.get('title').touched)">
                                            Max 60 characters allowed.
                                        </div>
                                        <div *ngIf="!hideResults && editable && !editSchoolInv.page" class="col-md-12">
                                            <div class="option-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                                <div *ngFor="let clubsAndTeam of clubsAndTeams">
                                                    <div (click)="selectOrg(clubsAndTeam, true)" class="media p-2">
                                                        <img
                                                            src="{{clubsAndTeam.display_pic? mediaURL + clubsAndTeam.display_pic : 'assets/images/default.svg'}}">
                                                        <div class="media-body ml-2">
                                                            <p class="pl-2">{{clubsAndTeam.page_title}},
                                                                {{clubsAndTeam.school_name}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Position"
                                            [readonly]="!editable || editSchoolInv.page" formControlName="position">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('position').hasError('required') && (schoolInvUpdForm.get('position').dirty || schoolInvUpdForm.get('position').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('position').hasError('maxlength') && (schoolInvUpdForm.get('position').dirty || schoolInvUpdForm.get('position').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <p *ngIf="editSchoolInv.approver_name && editSchoolInv.verified_date && !editable"
                                    class="verifiedText"><img  alt="image" src="assets/images/verify-resume.svg">Verified by
                                    <span>{{editSchoolInv.approver_name}}</span> on
                                    <span>{{editSchoolInv.verified_date | date: 'yyyy-MM-dd'}} </span></p>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>City</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="City"
                                    [readonly]="!editable || editSchoolInv.page" formControlName="city">
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('city').hasError('required') && (schoolInvUpdForm.get('city').dirty || schoolInvUpdForm.get('city').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('city').hasError('maxlength') && (schoolInvUpdForm.get('city').dirty || schoolInvUpdForm.get('city').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>
                                    <span *ngIf="editSchoolInv.start_date != editSchoolInv.end_date" style="color: #335175;" class="mr-2">Start</span>
                                    <span *ngIf="editSchoolInv.start_date == editSchoolInv.end_date" style="color: #335175;" class="mr-2">Date</span>
                                    <input type="text" class="form-control" *ngIf="!editable || editSchoolInv.page"
                                        readonly value="{{editSchoolInv.start_date}}">
                                    <input type="text" class="form-control" bsDatepicker
                                        *ngIf="editable && !editSchoolInv.page" [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="schoolInvUpdForm.get('start_date').hasError('required') && (schoolInvUpdForm.get('start_date').dirty || schoolInvUpdForm.get('start_date').touched || submitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 col-6" *ngIf="editSchoolInv.start_date != editSchoolInv.end_date">
                                <label>End
                                    <input type="text" class="form-control" *ngIf="!canEditEndDate()" readonly
                                        value="{{editSchoolInv.end_date}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="canEditEndDate()"
                                        [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="schoolInvUpdForm.errors?.invalidEndDate && (schoolInvUpdForm.touched || schoolInvUpdForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Learned</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable"
                                            formControlName="skill_1">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_1').hasError('required') && (schoolInvUpdForm.get('skill_1').dirty || schoolInvUpdForm.get('skill_1').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_1').hasError('maxlength') && (schoolInvUpdForm.get('skill_1').dirty || schoolInvUpdForm.get('skill_1').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable"
                                            formControlName="skill_2">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_2').hasError('required') && (schoolInvUpdForm.get('skill_2').dirty || schoolInvUpdForm.get('skill_2').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_2').hasError('maxlength') && (schoolInvUpdForm.get('skill_2').dirty || schoolInvUpdForm.get('skill_2').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable"
                                            formControlName="skill_3">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_3').hasError('required') && (schoolInvUpdForm.get('skill_3').dirty || schoolInvUpdForm.get('skill_3').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('skill_3').hasError('maxlength') && (schoolInvUpdForm.get('skill_3').dirty || schoolInvUpdForm.get('skill_3').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control"
                                    [readonly]="!editable"
                                    formControlName="main_role"> </textarea>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('main_role').hasError('required') && (schoolInvUpdForm.get('main_role').dirty || schoolInvUpdForm.get('main_role').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('main_role').hasError('maxlength') && (schoolInvUpdForm.get('main_role').dirty || schoolInvUpdForm.get('main_role').touched)">
                                    Max 750 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Summary of Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control"
                                    [readonly]="!editable"
                                    formControlName="personal_details"> </textarea>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('personal_details').hasError('required') && (schoolInvUpdForm.get('personal_details').dirty || schoolInvUpdForm.get('personal_details').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('personal_details').hasError('maxlength') && (schoolInvUpdForm.get('personal_details').dirty || schoolInvUpdForm.get('personal_details').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="canEdit()">
        <button class="btn btn-footer" (click)="delSchoolInv()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateSchoolInv(schoolInvUpdForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #expTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">SCHOOL INVOLVEMENT</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!formShow" [formGroup]="searchForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>City</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="City" formControlName="city">
                        <div class="error-message"
                            *ngIf="searchForm.get('city').hasError('required') && (searchForm.get('city').dirty || searchForm.get('city').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="searchForm.get('city').hasError('maxlength') && (searchForm.get('city').dirty || searchForm.get('city').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>School Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Enter Title of Club/Team"
                            formControlName="title" (keydown)="searchOrg($event)" (focusout)="focusOutFunction()">
                        <div class="error-message"
                            *ngIf="searchForm.get('title').hasError('required') && (searchForm.get('title').dirty || searchForm.get('title').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="searchForm.get('title').hasError('maxlength') && (searchForm.get('title').dirty || searchForm.get('title').touched)">
                            Max 60 characters allowed.
                        </div>
                        <div *ngIf="!hideResults" class="col-md-9">
                            <div class="option-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                <div *ngFor="let clubsAndTeam of clubsAndTeams">
                                    <div (click)="selectOrg(clubsAndTeam)" class="media p-2">
                                        <img
                                            src="{{clubsAndTeam.display_pic? mediaURL + clubsAndTeam.display_pic : 'assets/images/default.svg'}}">
                                        <div class="media-body ml-2">
                                            <p class="pl-2">{{clubsAndTeam.page_title}},
                                                {{clubsAndTeam.school_name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Position</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Type Here" formControlName="position">
                        <div class="error-message"
                            *ngIf="searchForm.get('position').hasError('required') && (searchForm.get('position').dirty || searchForm.get('position').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="searchForm.get('position').hasError('maxlength') && (searchForm.get('position').dirty || searchForm.get('position').touched)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Dates</label>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Start
                                    <input type="text" class="form-control modal-control modal-field"
                                        placeholder="Start Date" bsDatepicker [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <a href="javascript:void(0);" (click)="resetStartDate()"><img
                                            src="assets/images/plus-icon.png" class="img-cross"></a>
                                    <div class="error-message"
                                        *ngIf="searchForm.get('start_date').hasError('required') && (searchForm.get('start_date').dirty || searchForm.get('start_date').touched || partialSubmitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6">
                                <label for="">End
                                    <input type="text" class="form-control modal-control modal-field"
                                        placeholder="End Date" bsDatepicker [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <a href="javascript:void(0);" (click)="resetEndDate()"><img
                                            src="assets/images/plus-icon.png" class="img-cross"></a>
                                    <div class="error-message"
                                        *ngIf="searchForm.errors?.invalidEndDate && (searchForm.touched || searchForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="formShow && !previewShow" [formGroup]="schoolInvForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top Skills Learned</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_1">
                                <div class="error-message"
                                    *ngIf="schoolInvForm.get('skill_1').hasError('required') && (schoolInvForm.get('skill_1').dirty || schoolInvForm.get('skill_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvForm.get('skill_1').hasError('maxlength') && (schoolInvForm.get('skill_1').dirty || schoolInvForm.get('skill_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_2">
                                <div class="error-message"
                                    *ngIf="schoolInvForm.get('skill_2').hasError('required') && (schoolInvForm.get('skill_2').dirty || schoolInvForm.get('skill_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvForm.get('skill_2').hasError('maxlength') && (schoolInvForm.get('skill_2').dirty || schoolInvForm.get('skill_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_3">
                                <div class="error-message"
                                    *ngIf="schoolInvForm.get('skill_3').hasError('required') && (schoolInvForm.get('skill_3').dirty || schoolInvForm.get('skill_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvForm.get('skill_3').hasError('maxlength') && (schoolInvForm.get('skill_3').dirty || schoolInvForm.get('skill_3').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Main Role</label>
                    </div>
                    <div class="col-md-10">
                        <textarea class="form-control" formControlName="main_role"> </textarea>
                        <div class="error-message"
                            *ngIf="schoolInvForm.get('main_role').hasError('required') && (schoolInvForm.get('main_role').dirty || schoolInvForm.get('main_role').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="schoolInvForm.get('main_role').hasError('maxlength') && (schoolInvForm.get('main_role').dirty || schoolInvForm.get('main_role').touched)">
                            Max 750 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Personal Details</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="personal_details"> </textarea>
                        <div class="error-message"
                            *ngIf="schoolInvForm.get('personal_details').hasError('required') && (schoolInvForm.get('personal_details').dirty || schoolInvForm.get('personal_details').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="schoolInvForm.get('personal_details').hasError('maxlength') && (schoolInvForm.get('personal_details').dirty || schoolInvForm.get('personal_details').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="previewShow" class="content-section p-md-2 p-0">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <img alt="image" src="{{pageImage? mediaURL + pageImage : 'assets/images/default.svg'}}">
                                <div class="media-body ml-2">
                                    <h4>
                                        <span class="mr-3 title">Club/Team</span>{{this.searchForm.get('title').value}}
                                    </h4>
                                    <p>
                                        <span
                                            class="mr-3 title">Position</span>{{this.searchForm.get('position').value}}
                                    </p>
                                    <h5 *ngIf="!exp_end_one_day">
                                        <span class="mr-3 title">Dates</span>Start
                                        <span>{{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}</span>
                                        End
                                        <span *ngIf="!this.searchForm.get('end_date').value">Current</span>
                                        <span>{{this.searchForm.get('end_date').value | date: 'yyyy-MM-dd'}}</span>
                                    </h5>
                                    <h5 *ngIf="exp_end_one_day">
                                        <span class="mr-3 title">Dates</span>Date
                                        <span>{{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="!formShow" class="btn btn-footer" (click)="showForm(searchForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="formShow && !previewShow" (click)="showForm(searchForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="formShow && !previewShow" (click)="showPreview(schoolInvForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="showPreview(schoolInvForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="addSchoolInv()">
            <span *ngIf="!schoolId">Save</span><span *ngIf="schoolId">Send</span>
        </button>
    </div>
</ng-template>
<ng-template #currentTemp>
    <span>Current</span>
</ng-template>
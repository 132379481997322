import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseAccountEndPoint, BaseProfileEndPoint } from '../app.config';
import { UserProfile } from '../shared/userProfile';
import { UserSkill } from '../shared/userSkill';
import { UserBook } from '../shared/userBook';
import { IEducationNew, IEducationExisting } from '../shared/education';
import { ITeam } from '../shared/team';
import { IJobPosting, IVolunteerPosting } from '../shared/jobPosting';
import { IContactUs } from '../shared/contactUs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private baseAccountsUrl: string = BaseAccountEndPoint.apiEndpoint;
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;
  private baseChatUrl: string = environment.apiURL + 'api/chats/';
  
  constructor(private http: BaseService) { }

  addDescription(userProfile: UserProfile) {
    return this.http.post(this.baseAccountsUrl + 'add-description/', userProfile);
  }

  getDescription(id: string) {
    return this.http.get(this.baseAccountsUrl + 'userprofile/' + id + '/');
  }

  updateDescription(userProfile: UserProfile, id: string) {
    return this.http.put(this.baseAccountsUrl + 'profile/update/' + id + '/', userProfile);
  }

  deleteDescription(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'profile/update/' + id + '/');
  }

  addSkill(userSkill: any) {
    return this.http.post(this.baseAccountsUrl + 'add-skill/', userSkill);
  }

  getSkills(id: string) {
    return this.http.get(this.baseAccountsUrl + 'user-skills/' + id + '/');
  }

  updateSkill(userSkill: any, id: string) {
    return this.http.put(this.baseAccountsUrl + 'skill/update/' + id + '/', userSkill);
  }

  deleteSkill(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'skill/update/' + id + '/');
  }

  addInterest(userSkill: any) {
    return this.http.post(this.baseAccountsUrl + 'add-interest/', userSkill);
  }

  getInterests(id: string) {
    return this.http.get(this.baseAccountsUrl + 'user-interests/' + id + '/');
  }

  updateInterest(userSkill: any, id: string) {
    return this.http.put(this.baseAccountsUrl + 'interest/update/' + id + '/', userSkill);
  }

  deleteInterest(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'interest/update/' + id + '/');
  }

  addBook(userBook: UserBook) {
    return this.http.post(this.baseAccountsUrl + 'add-book/', userBook);
  }

  getBooks(id: string) {
    return this.http.get(this.baseAccountsUrl + 'user-books/' + id + '/');
  }

  updateBook(userBook: UserBook, id: string) {
    return this.http.put(this.baseAccountsUrl + 'book/update/' + id + '/', userBook);
  }

  deleteBook(id: string) {
    return this.http.delete(this.baseAccountsUrl + 'book/update/' + id + '/');
  }

  addBulletin(userBulletin: any) {
    return this.http.post(this.baseProfileUrl + 'add-bulletin/', userBulletin);
  }

  getBulletin(id: string) {
    return this.http.get(this.baseProfileUrl + 'user-bulletin/' + id + '/');
  }

  updateBulletin(userBulletin: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'bulletin/update/' + id + '/', userBulletin);
  }

  deleteBulletin(id: string) {
    return this.http.delete(this.baseProfileUrl + 'bulletin/update/' + id + '/');
  }

  addCSR(userCSR: any) {
    return this.http.post(this.baseProfileUrl + 'add-csr/', userCSR);
  }

  getCSR(id: string) {
    return this.http.get(this.baseProfileUrl + 'csr/' + id + '/');
  }

  updateCSR(userCSR: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'csr/update/' + id + '/', userCSR);
  }

  deleteCSR(id: string) {
    return this.http.delete(this.baseProfileUrl + 'csr/update/' + id + '/');
  }

  addSchoolTeacher(userCSR: any) {
    return this.http.post(this.baseProfileUrl + 'add-teacher/', userCSR);
  }

  getSchoolTeachers(id: string) {
    return this.http.get(this.baseProfileUrl + 'teachers/' + id + '/');
  }

  updateSchoolTeacher(userCSR: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'teacher/update/' + id + '/', userCSR);
  }

  deleteSchoolTeacher(id: string) {
    return this.http.delete(this.baseProfileUrl + 'teacher/update/' + id + '/');
  }

  addUserActivity(userActivity: any) {
    return this.http.post(this.baseProfileUrl + 'add-activity/', userActivity);
  }

  getUserActivities(id: string) {
    return this.http.get(this.baseProfileUrl + 'activities/' + id + '/');
  }

  updateUserActivity(userActivity: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'activity/update/' + id + '/', userActivity);
  }

  deleteUserActivity(id: string) {
    return this.http.delete(this.baseProfileUrl + 'activity/update/' + id + '/');
  }
  
  addNewEducation(eduObj: IEducationNew) {
      return this.http.post(this.baseProfileUrl + 'student-education/create-new/', eduObj);
  }

  addExistingEducation(eduObj: IEducationExisting) {
    return this.http.post(this.baseProfileUrl + 'student-education/create-existing/', eduObj);
  }
  
  listEducation() {
    return this.http.get(this.baseProfileUrl + 'student-education/list/');
  }

  otherStudentlistEducation(pk) {
    return this.http.get(this.baseProfileUrl + 'other-student-education/list/' + pk + '/');
  }

  deleteEducation(eduObj: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'student-education/delete/' +  id + '/', eduObj);
  }

  updateExistingEducation(eduObj: IEducationExisting, id: string) {
    return this.http.put(this.baseProfileUrl + 'student-education/update-existing/' +  id + '/', eduObj);
  }

  updateNewEducation(eduObj: IEducationNew, id: string) {
    return this.http.put(this.baseProfileUrl + 'student-education/update-new/' +  id + '/', eduObj);
  }

  RetreiveEducation(id: string) {
    return this.http.get(this.baseProfileUrl + 'student-education/' +  id + '/');
  }

  searchOrg(type: string, params: any) {
    return this.http.get(this.baseAccountsUrl + 'organizations/' + type + '/?search=' + params);
  }

  searchOrgPage(type: string, params: any) {
    return this.http.get(this.baseAccountsUrl + 'organization-pages/' + type + '/?search=' + params);
  }

  addUserWorkExp(UserWorkExp: any) {
    return this.http.post(this.baseProfileUrl + 'add-work-experience/', UserWorkExp);
  }

  getUserWorkExp(type: string) {
    return this.http.get(this.baseProfileUrl + 'work-experience/' + type + '/');
  }
  getOtherUserWorkExp(type: string, user_id: any) {
    return this.http.get(this.baseProfileUrl + 'otheruser-work-experience/' + type + '/user/' + user_id + '/');
  }

  updateUserWorkExp(UserWorkExp: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'work-experience/update/' + id + '/', UserWorkExp);
  }

  deleteUserWorkExp(id: string) {
    return this.http.delete(this.baseProfileUrl + 'work-experience/update/' + id + '/');
  }
  
  listTeam(id: string) {
    return this.http.get(this.baseProfileUrl + 'business-team/' + id + '/');
  }

  updateTeam(teamObj: ITeam, id: string) {
    return this.http.put(this.baseProfileUrl + 'business/team/' +  id + '/', teamObj);
  }

  addTeam(teamObj: ITeam) {
    return this.http.post(this.baseProfileUrl + 'business/team/', teamObj);
  }

  RetreiveTeam(id: string) {
    return this.http.get(this.baseProfileUrl + 'business/team/' +  id + '/');
  }

  addSchoolExecTeam(execTeam: any) {
    return this.http.post(this.baseProfileUrl + 'add-executive-team/', execTeam);
  }

  getSchoolExecTeam(id: string) {
    return this.http.get(this.baseProfileUrl + 'executive-team/list/' +  id + '/');
  }

  updateSchoolExecTeam(execTeam: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'executive-team/update/' + id + '/', execTeam);
  }

  deleteSchoolExecTeam(id: string) {
    return this.http.delete(this.baseProfileUrl + 'executive-team/update/' + id + '/');
  }

  listJobs(id: string) {
    return this.http.get(this.baseProfileUrl + 'job-postings/' + id + '/');
  }

  updateJob(jobObj: IJobPosting, id: string) {
    return this.http.put(this.baseProfileUrl + 'job-posting/' +  id + '/', jobObj);
  }

  addJob(jobObj: IJobPosting) {
    return this.http.post(this.baseProfileUrl + 'job-posting/', jobObj);
  }

  RetreiveJob(id: string) {
    return this.http.get(this.baseProfileUrl + 'job-posting/' +  id + '/');
  }

  listRestricted() {
    return this.http.get(this.baseProfileUrl + 'list-restricted/');
  }

  listFAQs() {
    return this.http.get(this.baseProfileUrl + 'list-faqs/');
  }

  listVolunteerJobs(id: string) {
    return this.http.get(this.baseProfileUrl + 'volunteer-postings/' +  id + '/');
  }

  updateVolunteerJob(jobObj: IVolunteerPosting, id: string) {
    return this.http.put(this.baseProfileUrl + 'volunteer-posting/' +  id + '/', jobObj);
  }

  addVolunteerJob(jobObj: IVolunteerPosting) {
    return this.http.post(this.baseProfileUrl + 'volunteer-posting/', jobObj);
  }

  RetreiveVolunteerJob(id: string) {
    return this.http.get(this.baseProfileUrl + 'volunteer-posting/' +  id + '/');
  }

  addSchoolInvolvement(UserSchoolInv: any) {
    return this.http.post(this.baseProfileUrl + 'add-school-involvement/', UserSchoolInv);
  }

  getSchoolInvolvement() {
    return this.http.get(this.baseProfileUrl + 'school-involvement/list/');
  }

  getOtherUserSchoolInvolvement(pk: any) {
    return this.http.get(this.baseProfileUrl + 'otheruser-school-involvement/list/' + pk + '/');
  }

  updateSchoolInvolvement(UserSchoolInv: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'school-involvement/update/' + id + '/', UserSchoolInv);
  }

  deleteSchoolInvolvement(id: string) {
    return this.http.delete(this.baseProfileUrl + 'school-involvement/update/' + id + '/');
  }
  
  contactUsPost(contactObj: IContactUs) {
    return this.http.post(this.baseAccountsUrl + 'contact-us/', contactObj);
  }

  searchClubTeam(params: any) {
    return this.http.get(this.baseAccountsUrl + 'search-club-team/?search=' + params);
  }

  searchClubTeamPage(params: any) {
    return this.http.get(this.baseAccountsUrl + 'school-pages/?search=' + params);
  }

  changePassword(obj: any) {
    return this.http.put(this.baseAccountsUrl + 'change-password/', obj);
  }

  verifyPassword(password: string) {
    return this.http.post(this.baseAccountsUrl + 'verify-password/', {password: password});
  }

  changeEmailReq(email: string) {
    return this.http.post(this.baseAccountsUrl + 'change-email/', {new_email: email});
  }

  verifyOTP(otp: string, id: string) {
    return this.http.put(this.baseAccountsUrl + 'verify-otp/' + id + '/', {otp: otp});
  }

  createVerifier(obj: any) {
    return this.http.post(this.baseAccountsUrl + 'add-verifier/', obj);
  }

  listVerifiers() {
    return this.http.get(this.baseAccountsUrl + 'verifiers/');
  }

  listOtherProfileVerifiers(user_id) {
    return this.http.get(this.baseAccountsUrl + 'verifiers/user/' + user_id + '/');
  }

  deleteVerifier(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'delete-verifier/' + id + '/', obj);
  }

  resendVerification(id: any) {
    return this.http.get(this.baseAccountsUrl + 'resend-verification/' + id + '/');
  }

  ProfileImage(data:any , id: any) {
    return this.http.put(this.baseAccountsUrl + 'user-profile/' + id + '/', data);
  }

  BusinessVerificationStatus(id: any) {
    return this.http.get(this.baseAccountsUrl + 'business-verification-status/' + id + '/');
  }

  ResendProEmail(id: any) {
    return this.http.get(this.baseAccountsUrl + 'resend-proemail/' + id + '/');
  }

  OtherUserProfile(id:any){
    return this.http.get(this.baseAccountsUrl + 'other-user-profile/' + id);
  }
  CheckUserBlockStatus(id:any){
    return this.http.get(this.baseAccountsUrl + 'check-user-block-status/' + id);
  }

  UserSchoolInvolvementMembers(page_id:any){
    return this.http.get(this.baseProfileUrl + 'get-schoolinvolvement-members/' + page_id);
  }

  AcceptTerms(data: any, id: any) {
    return this.http.put(this.baseAccountsUrl + 'approve-terms-policies/' + id + '/', data);
  }

  getUserNotifCount(params: string = "") {
    return this.http.get(this.baseProfileUrl + 'get-notif-count/' + params);
  }

  markNotifications() {
    return this.http.get(this.baseProfileUrl + 'mark-notification/');
  }

  getConversations() {
    return this.http.get(this.baseChatUrl + 'conversations/');
  }

  createConversation(obj: any) {
    return this.http.post(this.baseChatUrl + 'create-conversation/', obj);
  }

  getRecentConversation() {
    return this.http.get(this.baseChatUrl + 'get-recent-conversation/');
  }

  getConversation(id: any) {
    return this.http.get(this.baseChatUrl + 'get-conversation/' + id + '/');
  }

  sendMessage(obj: any) {
    return this.http.post(this.baseChatUrl + 'send-message/', obj);
  }

  deleteConversation(id: any) {
    return this.http.put(this.baseChatUrl + 'delete-conversation/' + id + '/', {});
  }

  deleteMessages(id: any, obj: any) {
    return this.http.put(this.baseChatUrl + 'delete-messages/' + id + '/', obj);
  }

  getReferralCount() {
    return this.http.get(this.baseAccountsUrl + 'get-referral-count/');
  }

  delPageImage(data:any , id: any) {
    return this.http.put(this.baseAccountsUrl + 'page-image/' + id + '/', data);
  }

  delProfileImage(data:any) {
    return this.http.put(this.baseAccountsUrl + 'profile-image/', data);
  }
}

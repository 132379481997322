<section class="profile-head helpsection needHelpSection">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 student p-0">
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <app-profile-header></app-profile-header>
                <app-sidebar-inner></app-sidebar-inner> 
                <div class="media-body">
                    <h1 class="settingText">Need Help?</h1>
                </div>
                <div class="content-section pt-3">
                    <div class="profile-div">
                        <div class="profile-inner full-inner">
                            <h3>
                                Page Types
                            </h3>
                            <div class="box-div">
                                <div class="inner-div">
                                    <h4 class="bg-text">Click on any of the following page types to view what it
                                        resembles when fully filled
                                        out. These are only examples the information within is not accurate, nor does
                                        it
                                        represent anybody's real profile. </h4>
                                    <div class="d-flex justify-content-around flex-wrap">
                                        <div class="boxInner cursor-pointer text-center" (click)="openStudent(studentModal)">
                                            <img  alt="image" src="assets/images/student-icon.svg">
                                            <h5>Student</h5>
                                        </div>
                                        <div class="boxInner cursor-pointer text-center" (click)="openBusiness(businessModal)">
                                            <img  alt="image" src="assets/images/business-icon.svg">
                                            <h5>Business</h5>
                                        </div>
                                        <div class="boxInner cursor-pointer text-center" (click)="openVolunteer(volunteerModal)">
                                            <img  alt="image" src="assets/images/non-icon.svg">
                                            <h5>Non-Profit</h5>
                                        </div>
                                        <div class="boxInner cursor-pointer text-center" (click)="openSchool(schoolModal)">
                                            <img  alt="image" src="assets/images/school-icon.svg">
                                            <h5>School Club</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="profile-inner full-inner mt-md-4">
                            <app-contact-us-form [displayColClass]="true"></app-contact-us-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #studentModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/student-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #businessModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/business-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #volunteerModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/volunteer-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #schoolModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img  alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img  alt="image" src="assets/images/school-profile.png">
        </div>
    </div>
</ng-template>
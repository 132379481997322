import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { first } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { ProfileService } from '../services/profile.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mediaURL = environment.mediaURL;
  loggedIn: boolean = false;
  public settingShow = true;
  user: any = {};
  notifCount: any = 0;
  messageCount: any = 0;
  isNotifUrl: boolean = false;
  isMsgUrl: boolean = false;
  messages = [];
  notifications = [];

  constructor(private loginService: LoginService, private router: Router, private profileService: ProfileService) {
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    }
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.getNotifCount();
        if (location.pathname.indexOf('notifications') > 0){
          this.isNotifUrl = true;
          this.notifCount = 0;
        } else {
          this.isNotifUrl = false;
        }
        if (location.pathname.indexOf('message-chat') > 0){
          this.isMsgUrl = true;
        } else {
          this.isMsgUrl = false;
        }
      }
    });
  }

  ngOnInit(): void {}

  logout() {
    this.loginService.userLogout()
        .subscribe(
            data => {
                localStorage.removeItem('user');
                window.location.href='/';
                this.loadPublicToken();
            },
            error => {}
        )
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
        .pipe(first())
        .subscribe(
            data => {
            },
            error => {

            });
  }

  getNotifCount(){
    if (this.loggedIn){
      this.profileService.getUserNotifCount()
      .subscribe(response => {
        if (response.status === 200) {
          this.notifCount = response.response.notif_count;
          this.messageCount = response.response.message_count;
          this.messages = response.response.messages;
          this.notifications = response.response.notifications;
          let approval_status = response.response.approval_status;
          if (this.user.approval_status !== approval_status) {
            this.user.approval_status = approval_status;
            localStorage.setItem('user', JSON.stringify(this.user));
          }
        } else {
          this.notifCount = 0;
          this.messageCount = 0;
          this.messages = [];
          this.notifications = [];
        }
      });
    } else {
      this.notifCount = 0;
      this.messageCount = 0;
    }
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ForgotPasswordEndPoint, ResetPasswordEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private forgotPasswordUrl: string = ForgotPasswordEndPoint.apiEndpoint;
  private resetPasswordUrl: string = ResetPasswordEndPoint.apiEndpoint;

  constructor(private http: BaseService) { }

  forgotPassword(email: string) {
    return this.http.post(this.forgotPasswordUrl, { email: email });
  }

  resetPassword(params: any, confirm_password: string, password: string) {
      return this.http.put(this.resetPasswordUrl + params, { password: password, confirm_password: confirm_password });
  }

}

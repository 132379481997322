<h3>Employment Postings
    <a *ngIf="!editable && pageVerified != '3'" href="javascript:void()" class="float-right" (click)="openJobModal(jobTemplate)">
        <img  alt="image" src="assets/images/plus-icon.png" alt="">
    </a>
    <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
        <img  alt="image" src="assets/images/icEdit.svg">
    </a>
</h3>
<ng-template [ngIf]="jobPostings?.length > 0" [ngIfElse]="emptyBlock">
    <div class=" listing-box scroll-activity " slimScroll [options]="opts" [scrollEvents]="scrollEvents">
        <div class="business-border business-div p-0 box-div cursor-pointer"  *ngFor="let job_post of jobPostings" (click)="updateJobModal(jobTemplate, jobTemplateStatic, job_post)">
            <h4 class="color mb-0">{{job_post.title}}</h4>
            <p [innerHtml]="job_post.description"></p>
        </div>
    </div>
</ng-template>
<ng-template #emptyBlock>
    <div class="box-div">
        <div class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
            <p>You can add any Student Job Openings</p>
        </div>
    </div>
</ng-template>
<ng-template #jobTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Employment Posting</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="jobPostForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" placeholder="Title" formControlName="title">
                        <div class="error-message" *ngIf="jobPostForm.get('title').hasError('required') && (jobPostForm.get('title').dirty || jobPostForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="jobPostForm.get('title').hasError('maxlength') && (jobPostForm.get('title').dirty || jobPostForm.get('title').touched)">
                            Max 75 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="jobPostForm.get('title').hasError('aftersubmit')">
                            {{jobPostForm.controls.title.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <ckeditor tagName="textarea" [editor]="editor" formControlName="description" [config]="{ link: {addTargetToExternalLinks: true}, toolbar: [ 'link', 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}">
                        </ckeditor>
                        <div class="error-message" *ngIf="jobPostForm.get('description').hasError('required') && (jobPostForm.get('description').dirty || jobPostForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="jobPostForm.get('description').hasError('maxlength') && (jobPostForm.get('description').dirty || jobPostForm.get('description').touched)">
                            Max 2250 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="jobPostForm.get('description').hasError('aftersubmit')">
                            {{jobPostForm.controls.description.errors.aftersubmit}}
                        </div>
                        <div class="error-message" *ngIf="jobPostForm.get('description').hasError('linksValid') && (jobPostForm.get('description').dirty || jobPostForm.get('description').touched || submitted)">
                            Invalid links in Description: {{invalidWeb}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>How to Apply</label>
                    </div>
                    <div class="col-md-12">
                        <textarea type="text" class="form-control" placeholder="How to Apply" formControlName="how_to_apply"></textarea>
                        <div class="error-message" *ngIf="jobPostForm.get('how_to_apply').hasError('required') && (jobPostForm.get('how_to_apply').dirty || jobPostForm.get('how_to_apply').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="jobPostForm.get('how_to_apply').hasError('maxlength') && (jobPostForm.get('how_to_apply').dirty || jobPostForm.get('how_to_apply').touched)">
                            Max 500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="addNewJob" (click)="addUpdateJobClicked()" [disabled]="disableSubmit" class="btn btn-footer">
            Save
        </button>
        <button *ngIf="!addNewJob" (click)="addUpdateJobClicked(true)" [disabled]="disableSubmit" class="btn btn-footer">
            Update
        </button>
        <button *ngIf="!addNewJob" (click)="addUpdateJobClicked(true, true)" [disabled]="disableSubmit" class="btn btn-footer">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #jobTemplateStatic>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Employment Postings</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Title</h3>
                            </div>
                            <div class="col-6">
                                <h4>{{jobObj.title}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Date Posted</h3>
                            </div>
                            <div class="col-6">
                                <h4>{{jobObj.created_date|date}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>How to Apply</h3>
                            </div>
                            <div class="col-12">
                                <h4 class="ml-0">{{jobObj.how_to_apply}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Description</h3>
                            </div>
                            <div class="col-12">
                                <h4 [innerHtml]="jobObj.description" class="ml-0"></h4>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>
import { Component, OnInit, NgZone, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthenticationService } from '../services/authentication.service';
import { Business } from '../shared/business';
import { BusinessPage } from '../shared/businessPage';
import { User } from '../shared/user';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';

@Component({
  selector: 'app-create-npo-page',
  templateUrl: './create-npo-page.component.html',
  styleUrls: ['./create-npo-page.component.scss']
})
export class CreateNpoPageComponent implements OnInit {

  modalRef: BsModalRef;
  firstStep: boolean = true;
  firstStepForm: FormGroup;
  public submitted: boolean = false;
  public successOverlay: boolean = false;
  public isReadOnly: boolean = false;
  public showAddressError: boolean = false;
  public partialSubmitted: boolean = false;
  public infoDiv = true;
  plchldrState = "Province";
  plchldrPostal = "Postal";
  enteredEmail: string;
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  infoSlide: boolean = false;
  updateAddress: string = '';
  countrySet: string = 'CA';
  professional_email: string = '';
  user;

  business: Business = {
    organization_title: '',
    address: '',
    unit_number: undefined,
    country: '',
    organization_type: 1,
    state: '',
    city: '',
    zip_code: '',
    business_number: undefined,
    professional_email: undefined,
    latitude: undefined,
    longitude: undefined
  };

  businessPage: BusinessPage = {
    business : this.business,
    professional_email : this.professional_email
  };


  constructor(public zone: NgZone, private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService,
    private modalService: BsModalService) {
    if (localStorage.getItem('user')) {
      var user_json = JSON.parse(localStorage.getItem('user'));
      this.user=user_json.id;
      if (user_json.age_group !== 3) {
        this.router.navigate(['/dashboard']);
      }
    }
    this.firstStepForm = this.formBuilder.group({
      organization_title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(7)]),
      business_number: new FormControl('', Validators.maxLength(15)),
      unit_number: new FormControl('', Validators.maxLength(50)),
      professional_email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
    });
    this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
    
  }

  




  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
  slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

  ngOnInit(): void {
  }

  
  openInfo() {
    this.infoSlide = true;
  }
  hideInfo() {
    this.infoSlide = false;
  }


  changeCountry(e){
    if (e.target.value == "USA"){
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
      this.firstStepForm.controls['zip_code'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(5)]);
    } else {
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
    }
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.firstStepForm.controls['address'].setValue(null);
      this.showAddressError = true;
      this.isReadOnly = false;
    } else {
      this.firstStepForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    } 
  }

  getAddrComponent(place, componentTemplate) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }

    return;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
    city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'long_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  save(isValid: boolean, successModal: TemplateRef<any>) {
    this.submitted = true;
    if (isValid) {
      this.business.organization_title = this.firstStepForm.get('organization_title').value;
      this.business.address = this.firstStepForm.get('address').value;
      this.business.unit_number = this.firstStepForm.get('unit_number').value;
      this.business.country = this.firstStepForm.get('country').value;
      this.business.state = this.firstStepForm.get('state').value;
      this.business.city = this.firstStepForm.get('city').value;
      this.business.zip_code = this.firstStepForm.get('zip_code').value;
      this.business.business_number = this.firstStepForm.get('business_number').value;
      this.business.professional_email = this.firstStepForm.get('professional_email').value;
      this.businessPage.professional_email = this.business.professional_email;
      this.authService.businessPage(this.businessPage)
      .subscribe(response => {
        this.handleResponse(response, successModal);
      },
        error => {
          this.handleSubmitError(error.error);
        });
    }

  }

  protected handleResponse(response: any, successModal:TemplateRef<any>) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.successOverlay = true;
        this.firstStepForm.reset();
        this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.submitted = false;
        this.partialSubmitted = false;
        this.firstStepForm.controls['address'].setValue(null);
        this.router.navigate(['/dashboard']);   
    }
    else{
      this.firstStepForm.reset();
      this.partialSubmitted = false;
      this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
      this.submitted = false;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    let firstFormErr = false;
    fields.forEach(field => {
      if (this.firstStepForm.contains(field)) {
        this.firstStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
        firstFormErr = true;
      } 
    });
  }

  ValidateForm(isValid: boolean){
    if (isValid){
      this.firstStep = false;
    } else {
      this.partialSubmitted = true;
    }

  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){ 
    this.hidePlaceResults = e;
  }

  formClick(){
    this.hidePlaceResults = true;
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let address = place.address.freeformAddress.split(",")[0];
    this.updateAddress = address;
    let zip_code = '';
    this.firstStepForm.controls['address'].setValue(address);
    this.firstStepForm.controls['city'].setValue(place.address.municipality);
    if (place.address.extendedPostalCode && this.countrySet=='CA'){
      zip_code = place.address.extendedPostalCode;
    } else {
      zip_code = place.address.postalCode;
    }
    if (lat && lon) {
      this.business.latitude = lat;
      this.business.longitude = lon;
    }
    this.firstStepForm.controls['zip_code'].setValue(zip_code);
    this.firstStepForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
    this.isReadOnly = true;
  }

  validateEmail(){
    let email = this.firstStepForm.get('professional_email').value;
    if (email && email.length > 0 && this.firstStepForm.controls['professional_email'].valid) {
      this.authService.validateEmail({"professional_email": email})
      .subscribe(response => {
        if (response.status === 111) {
          this.firstStepForm.controls['professional_email'].setErrors({'invalid_format': true});
        }
      })
    }
  }

}

<div class="footer-inner" *ngIf="loggedIn"></div>
<footer>
    <div class="container-fuild">
        <div class="row">
            <div class="col-md-2 logo-div pl-5">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="160" height="79" viewBox="0 0 160 79">
                    <defs>
                        <path id="path-2" fill-rule="evenodd" d="M16.09411621 2c0-1.10383004.89616966-2 2-2h3.90586853c1.10383034 0 2 .89616996 2 2v1.85786104c1.68829918.37586212 3.28041649 1.0125022 4.73292542 1.86598253l1.2469139-1.24691343c.78052521-.78052568 2.04790116-.78052568 2.82842637 0l2.76186752 2.7618661c.7805252.7805252.7805252 2.04790115 0 2.8284273l-1.16282272 1.16282273c.92515945 1.46648312 1.63113784 3.0879612 2.071064 4.81701565h1.56868743c1.10382843 0 2 .89616966 2 2v3.90586853c0 1.10383034-.89617157 2-2 2h-1.45702743c-.401474 1.79042816-1.08649064 3.472435-2.00468445 4.99505997l.98477936.98477936c.78052902.7805252.78052902 2.04790115 0 2.82842636l-2.76186371 2.76186752c-.78052521.7805252-2.04790116.7805252-2.82842827 0l-.95552254-.95552444c-1.5300064.94187164-3.22237778 1.64009857-5.02431488 2.04125976v1.3911934c0 1.10382842-.89616966 2-2 2h-3.90586853c-1.10383034 0-2-.89617158-2-2v-1.58010865c-1.69934463-.46526337-3.29074573-1.19742966-4.72745418-2.14920426l-1.25238324 1.25238419c-.7805252.7805214-2.04790115.7805252-2.82842827 0l-2.76186561-2.76186752c-.7805252-.78052521-.7805233-2.04790306 0-2.82842636l1.46659231-1.4665928c-.77830171-1.39303207-1.36533642-2.9096527-1.72491503-4.51324653H2.0470543c-1.10382843 0-2-.89616966-2-2v-3.90586853c0-1.10383034.89617157-2 2-2h2.3302703c.39308976-1.54497051.99859523-3.0040512 1.78308152-4.3434143l-1.6364231-1.63642312c-.7805214-.7805252-.78052522-2.04790115 0-2.82842827l2.76186751-2.76186561c.78052521-.7805252 2.04790307-.7805233 2.82842637 0l1.53556633 1.5355668c1.36165237-.86270046 2.85580444-1.53081846 4.444273-1.9657235V2z"
                        />
                        <mask id="mask-3" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
                            <rect width="90" height="79" x="0" y="0" fill="black" />
                            <use fill="white" xlink:href="#path-2" />
                        </mask>
                        <ellipse id="ellipse-5" cx="20" cy="20" rx="7" ry="7" />
                        <mask id="mask-6" x="-25" y="-10" maskContentUnits="userSpaceOnUse" maskUnits="userSpaceOnUse">
                            <rect width="90" height="79" x="-25" y="-10" fill="white" />
                            <use fill="black" xlink:href="#ellipse-5" />
                        </mask>
                    </defs>
                    <g>
                        <text font-size="0" style="white-space:pre">
                            <tspan x="1.846" y="76" fill="rgb(255,255,255)" font-family="Arial" font-size="12" font-weight="400" style="white-space:pre;text-transform:none">ConnectUs.Today © 2020</tspan>
                        </text>
                        <ellipse cx="45" cy="30" fill="#339999" rx="30" ry="30" />
                        <g transform="translate(25.00001144 10.00000381)">
                            <use fill="rgb(77,114,157)" xlink:href="#path-2" />
                            <g mask="url(#mask-3)">
                                <path fill="#339999" fill-rule="evenodd" d="M20 14.66666667L40 10v20l-20-4.66666667V14.66666667z" />
                                <g>
                                    <use fill="rgb(119,181,102)" xlink:href="#ellipse-5" />
                                    <use fill-opacity="0" stroke="rgb(51,81,117)" stroke-linecap="butt" stroke-linejoin="miter" stroke-width="6" mask="url(#mask-6)"
                                        xlink:href="#ellipse-5" />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

            </div>
            <div class="col-lg-4 col-md-6">
                <div class="footer-nav d-flex">
                    <div class="footerbar">
                        <h4>About</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/about-us']">About Us</a>
                            </li> 
                            <li>
                                <a [routerLink]="['/guidelines']">Community Guidlines</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Policies</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
                            </li>
                            <li>
                                <a [routerLink]="['/terms-of-use']">Terms of Use</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Resources</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/faq']" routerLinkActive="active">FAQ</a>
                            </li>
                            <li>
                                <a [routerLink]="['/mental-health']">Mental Health</a>
                            </li>
                        </ul>
                    </div>
                    <div class="footerbar">
                        <h4>Why Sign Up?</h4>
                        <ul class="pl-0">
                            <li>
                                <a [routerLink]="['/why-signup-students']">Students</a>
                            </li>
                            <li>
                                <a [routerLink]="['/why-signup-others']">Others</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
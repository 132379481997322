 

<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img  alt="image" src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 p-0">
                <div class="login-section"> 
                    <app-sidebar></app-sidebar>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center w-100">
                           <app-login></app-login>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
</section> 
<div class="info-overlay" *ngIf="infoSlide" >
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <img  alt="image" src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none">
        <img  alt="image" src="assets/images/info-mobile.svg" class="d-md-none d-block"> 
    </div>
</div>
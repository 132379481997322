import { Component, OnInit, EventEmitter, TemplateRef } from '@angular/core';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SearchService } from '../../services/search.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { AuthenticationType } from 'azure-maps-control';
import * as atlas from 'azure-maps-control';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-search-experience',
  templateUrl: './search-experience.component.html',
  styleUrls: ['./search-experience.component.scss']
})
export class SearchExperienceComponent implements OnInit {
  mediaURL = environment.mediaURL;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  updateAddress: string = '';
  latitude: string;
  longitude: string;
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  jobs: any[] = [];
  volunteerJobs: any[] = [];
  searchForm: FormGroup;
  submitted: boolean = false;
  searchKeys: any[] = [];
  activeTab: string = "job";
  key: string = environment.subscriptionKeyAzure;
  map: any;
  Latitude: any;
  Longitude: any;
  jobNextPage: any;
  volunteerNextPage: any;
  loading: boolean = false;
  totalJobs: any;
  totalVolunteer: any;
  modalRef: BsModalRef;
  jobList: any;
  jobDetails: any;
  showLocation: boolean = true;
  placeHolder: string = "My Location";
  keywordsErr: boolean = false;

  constructor(private authService: AuthenticationService, private searchService: SearchService, public formBuilder: FormBuilder, private modalService: BsModalService) {
    this.searchForm = this.formBuilder.group({
      search_key: new FormControl('', [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      location: new FormControl('', Validators.maxLength(200))
    });
    this.searchForm.controls['location'].setValue('My Location');

   }

  slideConfigMob = {"slidesToShow": 3, "slidesToScroll": 3, "dots": false, "adaptiveHeight": true, "autoPlay": true, "vertical": true,"arrows":false,
  "verticalSwiping": true, "infinte":true,};
  ngOnInit(): void {
    let params = this.setParams();
    this.initiateSearch()
    this.searchMap();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }
  initiateSearch(){
    let params = this.setParams();
    this.profilePrivacy();
    this.searchExperienceEmployment(params)
    this.searchExperienceVolunteer(params)
  }

  profilePrivacy(){
    this.authService.ProfilePrivacySettingGet()
    .subscribe(response => {
      if (response.response.length > 0) {
        this.showLocation = response.response[0].location_display;
        if (!this.showLocation) {
          this.placeHolder = "Full Address";
        }
      }
    })
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    this.updateAddress = place.address.freeformAddress;
    if (lat && lon) {
      this.latitude = lat;
      this.longitude = lon;
    }
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }
  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.latitude = null;
      this.longitude= null;
    } else {
      this.latitude = null;
      this.longitude= null;
    }
  }
  setResultHide(e){
    this.hidePlaceResults = e;
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  search(isValid: boolean) {
    this.submitted = true;
    if (this.searchForm.get('search_key').value || this.updateAddress.length > 0) {
      let search_key = this.searchForm.get('search_key').value;
      if (search_key !== "" && search_key !== null && search_key.trim().length > 0 && !(this.showLocation == false && this.updateAddress.length == 0)){
        if (this.searchKeys.length < 3) {
          this.keywordsErr = false;
          this.searchKeys.push(this.searchForm.get('search_key').value);
        } else {
          this.keywordsErr = true;
          this.focusOutErrMsg();
        }
        this.searchForm.reset();
      }
      if (!this.showLocation && !this.updateAddress) {
        return;
      }
      this.submitted = false;
      this.initiateSearch();
    }
  }

  setParams() {
    let params = "?search=" + this.searchKeys.join("+");
    if (this.latitude && this.longitude) {
      params += "&lat=" + this.latitude + "&lng=" + this.longitude;
    }
    return params;
  }
  searchExperienceEmployment(params: any){
  this.searchService.searchExperienceEmployment(params)
  .subscribe(response => {
    this.jobs = response.response.results;
    this.jobNextPage = response.response.next;
    this.totalJobs = 0;

    for (var eachVal of response.response.results){
      this.totalJobs += eachVal.employment_info.jobs_details.length;
    }
    
    this.onTabChange(this.activeTab);
  });
}

searchExperienceVolunteer(params: any){
  this.searchService.searchExperienceVolunteer(params)
  .subscribe(response => {
    this.volunteerJobs = response.response.results;
    this.volunteerNextPage = response.response.next;
    this.totalVolunteer = 0;
    
    for (var eachVal of response.response.results){
      this.totalVolunteer += eachVal.employment_info.jobs_details.length;
    }

    this.onTabChange(this.activeTab);
  });
}

noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

delKey(key: string) {
  if (key) {
    let index = this.searchKeys.findIndex(obj => obj == key);
    this.searchKeys.splice(index, 1);
    if (this.searchKeys.length == 0) {
      this.updateAddress = "";
    }
  } else {
    this.searchKeys = [];
    this.updateAddress = "";
  }
  this.initiateSearch();
}

searchMap(){
  this.map = new atlas.Map('mapContainer',
  {
    center: [-79.347015, 43.651070],
    zoom: 11,
    language: 'en-US',
    showLogo: false,
    showFeedbackLink: false,
    authOptions: {
      authType: AuthenticationType.subscriptionKey,
      subscriptionKey: this.key
    }
  });
  }

  addMarkers(jobs){
      jobs.forEach(element => {
        var marker = new atlas.HtmlMarker({
          color: '#D25557',
          text: '',
          position: [element.employment_info.longitude, element.employment_info.latitude]
        });
        this.map.markers.add(marker);
        let popup = new atlas.Popup({
          pixelOffset: [0, -30],
          closeButton: false
        });
        let content = '<div style="padding:10px">' + element.employment_info.title + '</div>';
        let coordinate = [element.employment_info.longitude, element.employment_info.latitude];
        const _this = this;
        this.map.events.add('mouseover', marker, function (e) {
          popup.setOptions({
            content: content,
            position: coordinate
          });
          popup.open(_this.map);
        });
        this.map.events.add('mouseleave', marker, function (){
          popup.close();
        });
        this.map.setCamera({
          center: [element.employment_info.longitude, element.employment_info.latitude],
          zoom: 11 
        });
    });
  }

  onScrollDown(){
    if (this.activeTab == "job") {
      this.onJobScrollDown();
    } else if (this.activeTab == "volunteer") {
      this.onVolunteerScrollDown();
    }
  }

  onJobScrollDown() {
    if (this.jobNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.jobNextPage;
      this.searchService.searchExperienceEmployment(params)
        .subscribe(data => {
          this.jobs = this.jobs.concat(data.response.results);
          this.jobNextPage = data.response.next;
          this.loading = false;
        })
    }
  }

  onVolunteerScrollDown() {
    if (this.volunteerNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setParams();
      params = params + '&page=' + this.volunteerNextPage;
      this.searchService.searchExperienceVolunteer(params)
        .subscribe(data => {
          this.volunteerJobs = this.volunteerJobs.concat(data.response.results);
          this.volunteerNextPage = data.response.next;
          this.loading = false;
        })
    }
  }

  onTabChange(tab: any){
    this.activeTab = tab;
    this.map.markers.clear();
    if (tab === "job") {
      this.addMarkers(this.jobs);
    } else if (tab === "volunteer") {
      this.addMarkers(this.volunteerJobs);
  }
}

  jobInfoModal(jobinfo: TemplateRef<any>, employment_info:any) {
    if (employment_info.jobs_count > 0) {
      this.jobList = []
      this.jobDetails = []
      const page_id = employment_info.page;
      this.searchService.getJobList(page_id)
      .subscribe(response => {
        this.jobList = response.response.jobs;
      });
      this.modalRef = this.modalService.show(jobinfo,
        Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
      );
    }
  }

  volunteerJobInfoModal(jobinfo: TemplateRef<any>, page_id:any) {
    this.jobList = []
    this.jobDetails = []
    this.searchService.getVolunteerJobList(page_id)
    .subscribe(response => {
      this.jobList = response.response.jobs;
    });
    this.modalRef = this.modalService.show(jobinfo,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

jobTitleModal(jobinfo: TemplateRef<any>, job_id:any) {
  this.jobList = []
  this.jobDetails = []
  this.searchService.getJobDetails(job_id)
  .subscribe(response => {
    this.jobDetails = response.response.jobs;
  });
  this.modalRef = this.modalService.show(jobinfo,
    Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
  );
}

volunteerJobTitleModal(jobinfo: TemplateRef<any>, job_id:any) {
  this.jobList = []
  this.jobDetails = []
  this.searchService.getVolunteerJobDetails(job_id)
  .subscribe(response => {
    this.jobDetails = response.response.jobs;
  });
  this.modalRef = this.modalService.show(jobinfo,
    Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
  );
}

closeModal(jobinfo: TemplateRef<any>) {
  this.modalRef.hide();
}

focusOutFunction() {
  setTimeout(() => {
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }, 1000);
}

focusOutErrMsg() {
  setTimeout(() => {
    this.keywordsErr = false;
  }, 6000);
}

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { User } from '../../shared/user';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../../services/customvalidation.service';

@Component({
  selector: 'app-invitation-signup',
  templateUrl: './invitation-signup.component.html',
  styleUrls: ['./invitation-signup.component.scss']
})
export class InvitationSignupComponent implements OnInit {
  resendEmailMessage: string = '';
  mainSidebar: boolean = false;
  firstStep: boolean = true;
  secondStep: boolean = false;
  firstStepForm: FormGroup;
  secondStepForm: FormGroup;
  maxNum: number = new Date().getFullYear();
  public submitted: boolean = false;
  public invalidDate: boolean = false;
  public isMinor: boolean = false;
  public successOverlay: boolean = false;
  public dob: string;
  public age: number;
  infoSlide: boolean = false;
  public partialSubmitted: boolean = false;
  invalidEmail: boolean = false;
  invitedUser: any = {};
  backGroundClass = "student";
  submitErrorMsg: string = null;
  public submitError: boolean = false;

  user: User = {
    first_name: '',
    last_name: '',
    email: '',
    user_role : 3,
    password : '',
    is_terms_accepted : false,
    parent_email : undefined,
    date_of_birth: '',
    student_referral: undefined
  };

  error_messages = {
    'year_of_birth': [
      { type: 'required', message: 'This field is required.' },
      { type: 'min', message: 'Please enter valid Year.' },
      { type: 'max', message: 'Max ' + this.maxNum + ' is allowed.' }
    ]
  }

  constructor(private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService) {
    if (localStorage.getItem('user')) {
      this.router.navigate(['/dashboard']);
    }
    if (localStorage.getItem('invited-user') === null) {
      this.router.navigate(['/']);
    }
    this.firstStepForm = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      confirm_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      year_of_birth: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)]),
    }, {
      validators: this.checkEmail.bind(this)
    });
    this.secondStepForm = this.formBuilder.group({
      parent_email: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      confirm_password: new FormControl('', Validators.required),
      student_referral: new FormControl('')
    }, {
      validators: this.checkPassword.bind(this)
    });
   }

  ngOnInit(): void {
    this.invitedUser = JSON.parse(localStorage.getItem('invited-user'));
    if (this.invitedUser.invitation_code.indexOf("BUS") >= 0) {
      this.backGroundClass = "business";
      this.user.user_role = 5;
    } else if (this.invitedUser.invitation_code.indexOf("NPO") >= 0) {
      this.backGroundClass = "volunteer";
      this.user.user_role = 4;
    } else {
      this.backGroundClass = "school";
      this.user.user_role = 3;
    }
  }

  checkDate() {
    if (this.firstStepForm.get('year_of_birth').value !== "" && this.firstStepForm.get('year_of_birth').value !== null) {
      this.dob = (this.firstStepForm.get('year_of_birth').value).toString() + '-1-1';
      if(new Date(this.dob.replace(/-/g, "/")) > new Date()){
        this.invalidDate = true;
      } else {
        this.invalidDate = false;
      }
    }
  }

  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }

  clickNext(isValid: boolean){
    this.invalidEmail = false;
    if (isValid) {
      if (this.firstStepForm.get('email').value.toLowerCase() == this.invitedUser.email.toLowerCase()) {
        this.secondStep = true;
        this.firstStep = false;
        let dob = new Date(this.dob.replace(/-/g, "/"));
        let currentYear = new Date().getFullYear();
        const newDate = currentYear + "/1/1";
        const prevDate = (currentYear - 2) + "/12/31";
        const ckDate = currentYear + "/4/21";
        let compareDate = new Date(newDate);
        let comparePrevDate = new Date(prevDate);
        let checkDate = new Date(ckDate);
        let minorDob = new Date(dob.setFullYear(dob.getFullYear() + 13));
        if (comparePrevDate < minorDob && minorDob < compareDate) {
          if (new Date() < checkDate) {
            this.isMinor = true;
            this.secondStepForm.controls['parent_email'].setValidators([Validators.required,Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]);
          } else {
            this.isMinor = false;
          }
        } else if (compareDate < minorDob || compareDate.getTime() === minorDob.getTime()) {
            this.isMinor = true;
            this.secondStepForm.controls['parent_email'].setValidators([Validators.required,Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]);
        } else {
          this.isMinor = false;
        }
      } else {
        this.invalidEmail = true;
      }
    } else {
      this.partialSubmitted = true;
    }

  }

  disableOverlay(){
    this.successOverlay = false;
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirm_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  checkEmail(formGroup: FormGroup) {
    const { value: email } = formGroup.get('email');
    const { value: confirmEmail } = formGroup.get('confirm_email');
    return email === confirmEmail ? null : { emailNotMatch: true };
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.user.is_terms_accepted = true;
    } else {
      this.user.is_terms_accepted = false;
    }
  }

  save(isValid: boolean) {
    this.submitted = true;
    if (this.user.is_terms_accepted && isValid) {
      this.user.first_name = this.firstStepForm.get('firstname').value;
      this.user.last_name = this.firstStepForm.get('lastname').value;
      this.user.email = this.firstStepForm.get('email').value.toLowerCase();
      this.user.password = this.secondStepForm.get('password').value;
      if (this.secondStepForm.get('parent_email').value !== "" && this.secondStepForm.get('parent_email').value !== null) {
        this.user.parent_email = this.secondStepForm.get('parent_email').value;
      } else {
        this.user.parent_email = undefined;
      }
      this.user.date_of_birth = this.dob;
      this.user.is_terms_accepted = true;
      const param = '?invite_id=' + this.invitedUser.id;
      this.authService.invitedUserSignup(this.user, param)
      .subscribe(response => {
        this.handleResponse(response);
      },
        error => {
          this.handleSubmitError(error.error);
        });
    }

  }

  protected handleResponse(response: any) {
    this.invalidEmail = false;
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.successOverlay = true;
        this.firstStep = true;
        this.secondStep = false;
        this.firstStepForm.reset();
        this.secondStepForm.reset();
        this.submitted = false;
        this.partialSubmitted = false;
    } else {
      this.firstStep = true;
      this.secondStep = false;
      this.firstStepForm.reset();
      this.secondStepForm.reset();
      this.submitted = false;
      this.partialSubmitted = false;
      if (response.status === 112) {
        this.submitErrorMsg = response.message;
      } else {
        this.submitErrorMsg = "Something went wrong. Please try again later";
      }
      this.submitError = true;
      this.hideMessage();
    }
  }

  hideMessage() {
    setTimeout(() => {
      this.submitError = false;
    }, 6000);
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    let firstFormErr = false;
    fields.forEach(field => {
      if (this.firstStepForm.contains(field)) {
        this.firstStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
        firstFormErr = true;
      } else if (this.secondStepForm.contains(field)) {
        this.secondStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
    if (firstFormErr) {
      this.firstStep = true;
      this.secondStep = false;
    } else {
      this.firstStep = false;
      this.secondStep = true;
    }
  }

}

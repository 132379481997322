<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img  alt="image" src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student">
                    <app-sidebar></app-sidebar>
                    <div class=" d-flex align-items-center home-inner" *ngIf="mainSidebar">
                        <div class="box-div text-center">
                            <p>Not a Member?</p>
                            <h1>Let's Get Started! Select Who You Are:</h1>
                            <div class="inner-div mt-4 mb-4">
                                <ul class="pl-0">
                                    <li>
                                        <a [routerLink]="['/student']">Student</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/business']">Business</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/volunteer']">Non-Profit Organization</a>
                                    </li>
                                    <li>
                                        <a [routerLink]="['/school']">School Club or Team</a>
                                    </li>
                                </ul>
                            </div>
                            <h2 class="d-flex align-items-center">
                                Have an Access Code?
                                <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv"
                                    class="cursor-pointer ml-1">
                            </h2>
                            <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                                <div class="info-div">
                                    <p>If you have received an invitation to become an Administrator from a Page owner through your email, copy the Code found in your email into this text field to speed up the sign-up process. If you already have an account, please inform the Page owner that they should resend the invite to your existing account’s email.</p>
                                </div>
                            </div>
                            <form [formGroup]="inviteForm" novalidate (ngSubmit)="checkCode(inviteForm.valid)">
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="Type Here"
                                        formControlName="invite_code">
                                    <!-- <input type="submit" class="submit-btn"> -->
                                    <div class="error-message"
                                        *ngIf="inviteForm.get('invite_code').hasError('aftersubmit')">
                                        {{inviteForm.controls.invite_code.errors.aftersubmit}}
                                    </div>
                                    <button type="submit" class="btn submit-btn"></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="info-overlay" *ngIf="infoSlide">
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <img  alt="image" src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none">
        <img  alt="image" src="assets/images/info-mobile.svg" class="d-md-none d-block">
    </div>
</div>
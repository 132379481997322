import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PageService } from '../../services/page.service';
import { environment } from '../../../environments/environment';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-page-experiences',
  templateUrl: './page-experiences.component.html',
  styleUrls: ['./page-experiences.component.scss']
})
export class PageExperiencesComponent implements OnInit {
  mediaURL = environment.mediaURL;
  page_id: any;
  pagesDetails: any = {};
  user: any = {};
  experience: any = {};
  approvedExperiences = [];
  pendingExperiences = [];
  showSubmitMsg: boolean = false;
  isSuccess: boolean = false;
  submitMsg: string;
  declineForm: FormGroup;
  modalRef: BsModalRef;
  url: string = ""

  constructor(private router: Router, private modalService: BsModalService, private route: ActivatedRoute, public formBuilder: FormBuilder, private authService: AuthenticationService, private pageService: PageService) {
    this.declineForm = this.formBuilder.group({
      rejection_reason: new FormControl('', [Validators.maxLength(250)]),
    });
  }

  ngOnInit(): void {
    this.url = this.mediaURL
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.getpageDetails();
  }

  getpageDetails() {
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        if (response.status === 200) {
          this.pagesDetails = response.response;
          if ([1, 2].indexOf(this.pagesDetails?.page_type) > -1) {
            this.getPageExperiences(this.pagesDetails.page_type);
          } else if ([3, 4].indexOf(this.pagesDetails?.page_type) > -1) {
            this.getschoolPageExperiences();
          }
        } else {
          this.router.navigate(['/dashboard']);
        }
      });
  }

  getPageExperiences(exp_type: any) {
    this.pageService.getPageExperiences(this.page_id, exp_type)
      .subscribe(response => {
        if (response.status === 200) {
          let experiences = response.response;
          this.approvedExperiences = experiences.filter(obj => obj.verification_status === 2);
          this.pendingExperiences = experiences.filter(obj => obj.verification_status === 1);
        }
      });
  }

  getschoolPageExperiences() {
    this.pageService.getSchoolPageExperiences(this.page_id)
      .subscribe(response => {
        if (response.status === 200) {
          let experiences = response.response;
          this.approvedExperiences = experiences.filter(obj => obj.verification_status === 2);
          this.pendingExperiences = experiences.filter(obj => obj.verification_status === 1);
        }
      });
  }

  expModal(experienceApproveTemplate: TemplateRef<any>, expTemplate: TemplateRef<any>, experience: any) {
    if (this.pagesDetails.business.business_number == "") {
      this.modalRef = this.modalService.show(experienceApproveTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    }
    else {
      this.experience = experience;
      this.declineForm.reset();
      this.modalRef = this.modalService.show(expTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    }

  }

  approveReq(experienceApproveTemplate: TemplateRef<any>, exp: any, approve: boolean) {
    if ((this.pagesDetails.page_type == 1 || this.pagesDetails.page_type == 2) && (this.pagesDetails.business.business_number == null || this.pagesDetails.business.business_number == "")) {
      this.modalRef = this.modalService.show(experienceApproveTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    }
    else {
      let params = exp.page + '/' + exp.id + '/?approve=' + approve;
      if ([1, 2].indexOf(this.pagesDetails?.page_type) > -1) {
        this.pageService.verifyPageExperiences(exp, params)
          .subscribe(response => {
            this.handleResponse(response);
          }, error => {
            this.handleError(error);
          });
      } else if ([3, 4].indexOf(this.pagesDetails?.page_type) > -1) {
        this.pageService.verifyClubTeam(exp, params)
          .subscribe(response => {
            this.handleResponse(response);
          }, error => {
            this.handleError(error);
          });
      }
    }

  }

  declineReq(isValid: boolean) {
    if (isValid) {
      let params = this.experience.page + '/' + this.experience.id + '/?approve=false';
      let model = { 'rejection_reason': this.declineForm.get('rejection_reason').value }
      if ([1, 2].indexOf(this.pagesDetails?.page_type) > -1) {
        this.pageService.verifyPageExperiences(model, params)
          .subscribe(response => {
            this.handleResponse(response);
            this.modalRef.hide();
            this.declineForm.reset();
          }, error => {
            this.handleError(error);
            this.modalRef.hide();
            this.declineForm.reset();
          });
      } else if ([3, 4].indexOf(this.pagesDetails?.page_type) > -1) {
        this.pageService.verifyClubTeam(model, params)
          .subscribe(response => {
            this.handleResponse(response);
            this.modalRef.hide();
            this.declineForm.reset();
          }, error => {
            this.handleError(error);
            this.modalRef.hide();
            this.declineForm.reset();
          });
      }
    }
  }

  fadeOutSubmitMsg() {
    setTimeout(() => {
      this.showSubmitMsg = false;
      this.submitMsg = "";
    }, 6000);
  }

  handleResponse(response: any) {
    if (response.status === 111) {
      this.isSuccess = false;
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    } else if (response.status === 200) {
      let resp = response.response;
      let index = this.pendingExperiences.findIndex(obj => obj.id === resp.id);
      this.pendingExperiences.splice(index, 1);
      if (resp.verification_status === 2) {
        this.approvedExperiences.push(resp);
      }
    } else {
      this.isSuccess = false;
      this.submitMsg = response.message;
      this.showSubmitMsg = true;
    }
  }

  handleError(error: any) {
    this.isSuccess = false;
    this.submitMsg = "Something went wrong. Please try again later.";
    this.showSubmitMsg = true;
    this.fadeOutSubmitMsg();
  }

}

<header *ngIf="!loggedIn">
    <nav class="navbar navbar-expand-md navbar-light container-fluid pl-md-5">
        <a class="navbar-brand" href="/">
            <img src="assets/images/new-logo.svg" class="new-logo" alt="logo" /> 
        </a>
        <div class="collapse navbar-collapse">
            <div class="ml-auto d-none d-md-block">
                <app-login></app-login>
            </div>
        </div>
        <div class="d-md-none d-flex">
            <div>
                <a [routerLink]="['/contact-us']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="none" fill-rule="evenodd">
                            <rect width="24" height="24" fill="#577795" rx="12" transform="matrix(-1 0 0 1 24 0)" />
                            <g fill="#FFF" fill-rule="nonzero">
                                <path
                                    d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                    transform="translate(7.2 6)" />
                            </g>
                        </g>
                    </svg>
                </a>
            </div>
        </div>
    </nav>
</header>
<div *ngIf="loggedIn" class="d-block d-md-none mobile-header">
    <ul class="d-flex">
        <li>
            <a [routerLink]="['/dashboard']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
                    <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                        <circle cx="12.52" cy="12.52" r="12.1" fill="#000" fill-opacity="0" fill-rule="nonzero"
                            stroke="#c2cbd6" stroke-width="2.42" />
                        <g fill="#c2cbd6">
                            <path d="M13.196 2.932L13.196 13.196 2.932 13.196z"
                                transform="translate(4.05 4.05) scale(1 -1) rotate(45 27.533 0)" />
                            <path d="M4.399 7.331H11.73V14.662H4.399z" transform="translate(4.05 4.05)" />
                        </g>
                    </g>
                </svg>
            </a>
        </li>
        <li>
            <a [routerLink]="['/search-page']">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
                    <g fill="none" stroke="#c2cbd6" transform="translate(1.25 1)">
                        <circle cx="12.52" cy="12.52" r="12.1" fill="#000" fill-opacity="0" stroke-width="2.42" />
                        <g fill="#c2cbd6" stroke-width=".827">
                            <path
                                d="M5.869 0C2.633 0 0 2.633 0 5.869c0 3.236 2.633 5.868 5.869 5.868 3.236 0 5.868-2.632 5.868-5.868S9.105 0 5.87 0zm0 10.654c-2.639 0-4.786-2.147-4.786-4.785 0-2.639 2.147-4.786 4.786-4.786 2.638 0 4.785 2.147 4.785 4.786 0 2.638-2.147 4.785-4.785 4.785z"
                                transform="matrix(-1 0 0 1 19.78 5.26)" />
                            <path
                                d="M13.168 12.402l-3.106-3.106c-.212-.212-.554-.212-.766 0-.212.211-.212.554 0 .766l3.106 3.106c.106.106.244.159.383.159.138 0 .277-.053.383-.16.211-.21.211-.554 0-.765z"
                                transform="matrix(-1 0 0 1 19.78 5.26)" />
                        </g>
                    </g>
                </svg>
            </a>
        </li>
        <li class="position-relative">
            <a [routerLink]="['/notifications']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
                    <g fill="none" fill-rule="evenodd" transform="translate(1.75 1)">
                        <circle cx="12.52" cy="12.52" r="12.1" fill="#000" fill-opacity="0" fill-rule="nonzero"
                            stroke="#c2cbd6" stroke-width="2.42" />
                        <path fill="#c2cbd6"
                            d="M7.599 9.86c-.002-2.726 2.208-4.936 4.933-4.936 2.726 0 4.936 2.21 4.936 4.936v3.803s3.341 3.932 2.905 4.37c-.437.436-15.27.436-15.706 0-.436-.438 2.934-4.369 2.934-4.369L7.6 9.86z" />
                        <ellipse cx="12.52" cy="18.47" fill="#c2cbd6" fill-rule="nonzero" rx="1.745" ry="1.31" />
                        <ellipse cx="12.52" cy="5.36" fill="#c2cbd6" fill-rule="nonzero" rx="1" ry="1.31" />
                    </g>
                </svg>
                <span class="count" *ngIf="notifCount > 0 && !isNotifUrl">{{notifCount}}</span>
            </a>
        </li>
        <li *ngIf="user.user_role == '2' && user.age_group > 1" class="position-relative">
            <a [routerLink]="['/message-chat']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
                    <g fill="none" fill-rule="evenodd" transform="translate(1.5 1)">
                        <circle cx="12.52" cy="12.52" r="12.1" fill="#000" fill-opacity="0" fill-rule="nonzero"
                            stroke="#c2cbd6" stroke-width="2.42" />
                        <g fill="#c2cbd6">
                            <path
                                d="M3.025 0h8.47c1.336 0 2.42 1.084 2.42 2.42v5.143c0 1.335-1.084 2.42-2.42 2.42h-8.47c-1.336 0-2.42-1.085-2.42-2.42V2.42C.605 1.084 1.689 0 3.025 0z"
                                transform="translate(5.26 6.47)" />
                            <path
                                d="M6.582 9.816L3.104 13.31V9.816c0-1.285-.12-2.33 1.159-2.33.864 0 4.252.453 4.65 1.158.192.34-2.331 1.172-2.331 1.172z"
                                transform="translate(5.26 6.47)" />
                        </g>
                    </g>
                </svg>
                <span class="count" *ngIf="messageCount > 0 && !isMsgUrl">{{messageCount}}</span>
            </a>
        </li>
        <li>
            <a [routerLink]="user.age_group == 3 || user.user_role != '2' ? ['/page-listing'] : ['/profile']" routerLinkActive="active">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
                    <g fill="none" fill-rule="evenodd" transform="translate(1 1)">
                        <circle cx="12.52" cy="12.52" r="12.1" fill="#000" fill-opacity="0" fill-rule="nonzero"
                            stroke="#c2cbd6" stroke-width="2.42" />
                        <circle cx="12.52" cy="8.89" r="3.63" fill="#c2cbd6" fill-rule="nonzero" />
                        <path fill="#c2cbd6"
                            d="M18.57 19.175H6.47s.04-1.957 1.344-3.64c1.001-1.29 3.015-2.41 4.706-2.41 1.688 0 3.705 1.132 4.706 2.42 1.308 1.684 1.344 3.63 1.344 3.63z" />
                    </g>
                </svg>
            </a>
        </li>
    </ul>
</div>

<header *ngIf="loggedIn">
    <nav class="navbar navbar-expand-md navbar-light container-fluid pl-md-5">
        <a class="navbar-brand" href="/dashboard">
            <img src="assets/images/logo.svg" class="logo" alt="logo" />
            <span class="logo-content header-img">
                <a [routerLink]="['/select-search']">
                    Search <img src="assets/images/search-icon-top.svg" alt="logo">
                </a>
            </span>
        </a>
        <div class="collapse navbar-collapse login-header">
            <div class="ml-auto d-none d-md-block">
                <ul class="d-flex align-items-center mb-0">
                    <li *ngIf="user.user_role == '2' && user.age_group > 1">
                        <div class="btn-group" dropdown>
                            <button id="button-basic2" dropdownToggle type="button"
                                class="dropdown-toggle custom-dropdown p-0" aria-controls="dropdown-basic">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="26" height="26" viewBox="0 0 24 24">
                                    <defs>
                                        <ellipse id="ellipse-1" cx="10" cy="10" rx="10" ry="10" />
                                        <mask id="mask-2" x="-2" y="-2" maskContentUnits="userSpaceOnUse"
                                            maskUnits="userSpaceOnUse">
                                            <rect width="24" height="24" x="-2" y="-2" fill="white" />
                                            <use fill="black" xlink:href="#ellipse-1" />
                                        </mask>
                                    </defs>
                                    <g transform="translate(2 2)">
                                        <g>
                                            <use fill="rgb(51,81,117)" xlink:href="#ellipse-1" />
                                            <use fill-opacity="0" stroke="rgb(194,203,214)" stroke-linecap="butt"
                                                stroke-linejoin="miter" stroke-width="4" mask="url(#mask-2)"
                                                xlink:href="#ellipse-1" />
                                        </g>
                                        <g>
                                            <path fill="rgb(194,203,214)" fill-rule="evenodd"
                                                d="M6.5 5h7.00002c1.10383005 0 2 .89616995 2 2v4.250015c0 1.10383005-.89616995 2-2 2H6.5c-1.10383005 0-2-.89616995-2-2V7c0-1.10383005.89616995-2 2-2z" />
                                            <path fill="rgb(194,203,214)" fill-rule="evenodd"
                                                d="M9.43941 13.11251475L6.56499792 16.00002v-2.88750525c0-1.06243835-.09948344-1.9250035.95813736-1.9250035.71427347 0 3.51372406.37374788 3.84328783.95646213.15841949.28010754-1.92701311.96854137-1.92701311.96854137z" />
                                        </g>
                                    </g>
                                </svg>
                                <span class="count" *ngIf="messageCount > 0 && !isMsgUrl">{{messageCount}}</span>
                            </button>
                            <div id="dropdown-basic2" *dropdownMenu
                                class="dropdown-menu dropdown-menu-right notificationDiv" role="menu"
                                aria-labelledby="button-basic2">
                                <h4>Messages</h4>
                                <ul class="pl-0 mb-0 msgList" *ngIf="messages.length > 0">
                                    <li class="cursor-pointer" *ngFor="let message of messages"
                                        [routerLink]="['/message-chat']">
                                        <div class="media">
                                            <img src="{{message.sender_data.display_pic? mediaURL + message.sender_data.display_pic : 'assets/images/p-img.png'}}"
                                                alt="image">
                                            <div class="media-body ml-2">
                                                <h5><span style="font-weight: 600;">{{message.sender_data.name}}
                                                    </span><a href="javascript:void();">{{message.text}}</a></h5>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <p *ngIf="messages.length == 0" class="errorText">No messages</p>
                                <div class="text-center">
                                    <a [routerLink]="['/message-chat']" class="common-button ghost">View All</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="mx-3">
                        <div class="btn-group" dropdown>
                            <button id="button-basic2" dropdownToggle type="button"
                                class="dropdown-toggle custom-dropdown p-0" aria-controls="dropdown-basic">
                                <svg _ngcontent-vcv-c206="" xmlns="http://www.w3.org/2000/svg" width="28" height="27"
                                    viewBox="0 0 28 27">
                                    <g _ngcontent-vcv-c206="" fill="none" fill-rule="evenodd"
                                        transform="translate(1.75 1)">
                                        <circle _ngcontent-vcv-c206="" cx="12.52" cy="12.52" r="12.1" fill="#000"
                                            fill-opacity="0" fill-rule="nonzero" stroke="#c2cbd6" stroke-width="2.42">
                                        </circle>
                                        <path _ngcontent-vcv-c206="" fill="#c2cbd6"
                                            d="M7.599 9.86c-.002-2.726 2.208-4.936 4.933-4.936 2.726 0 4.936 2.21 4.936 4.936v3.803s3.341 3.932 2.905 4.37c-.437.436-15.27.436-15.706 0-.436-.438 2.934-4.369 2.934-4.369L7.6 9.86z">
                                        </path>
                                        <ellipse _ngcontent-vcv-c206="" cx="12.52" cy="18.47" fill="#c2cbd6"
                                            fill-rule="nonzero" rx="1.745" ry="1.31"></ellipse>
                                        <ellipse _ngcontent-vcv-c206="" cx="12.52" cy="5.36" fill="#c2cbd6"
                                            fill-rule="nonzero" rx="1" ry="1.31"></ellipse>
                                    </g>
                                </svg>
                                <span class="count" *ngIf="notifCount > 0 && !isNotifUrl">{{notifCount}}</span>
                            </button>
                            <div id="dropdown-basic2" *dropdownMenu
                                class="dropdown-menu dropdown-menu-right notificationDiv" role="menu"
                                aria-labelledby="button-basic2">
                                <h4>Notifications</h4>
                                <ul class="pl-0 mb-0 msgList" *ngIf="notifications.length > 0">
                                    <li class="cursor-pointer" *ngFor="let notification of notifications"
                                        [routerLink]="['/notifications']">
                                        <div class="media">
                                            <img *ngIf="notification.notify_type !== 6"
                                                src="{{notification.page_info.display_pic? mediaURL + notification.page_info.display_pic : 'assets/images/default.svg'}}">
                                            <img *ngIf="notification.notify_type == 6" src="assets/images/logo.svg">
                                            <div class="media-body ml-2">
                                                <h5><a href="javascript:void();">{{notification.message}}</a></h5>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <p *ngIf="notifications.length == 0" class="errorText">No notifications</p>
                                <div class="text-center">
                                    <a [routerLink]="['/notifications']" class="common-button ghost">View All</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="btn-group" dropdown>
                            <button id="button-basic" dropdownToggle type="button"
                                class="dropdown-toggle custom-dropdown p-0" aria-controls="dropdown-basic">

                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" class="borderSvg">
                                    <g>
                                        <ellipse cx="15" cy="15" fill="rgb(51,81,117)" rx="15" ry="15" />
                                        <path fill="#c2cbd6" fill-rule="evenodd"
                                            d="M7.5 6.5h15c.82787254 0 1.5.67212746 1.5 1.5s-.67212746 1.5-1.5 1.5h-15C6.67212746 9.5 6 8.82787254 6 8s.67212746-1.5 1.5-1.5z" />
                                        <path fill="#c2cbd6" fill-rule="evenodd"
                                            d="M7.5 13.5h15c.82787254 0 1.5.67212746 1.5 1.5s-.67212746 1.5-1.5 1.5h-15c-.82787254 0-1.5-.67212746-1.5-1.5s.67212746-1.5 1.5-1.5z" />
                                        <path fill="#c2cbd6" fill-rule="evenodd"
                                            d="M7.5 20.5h15c.82787254 0 1.5.67212746 1.5 1.5s-.67212746 1.5-1.5 1.5h-15c-.82787254 0-1.5-.67212746-1.5-1.5s.67212746-1.5 1.5-1.5z" />
                                    </g>
                                </svg>
                            </button>
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                                aria-labelledby="button-basic">
                                <li role="menuitem">
                                    <a class="dropdown-item" [routerLink]="['/profile']"
                                        *ngIf="user.user_role == '2'">Profile</a>
                                </li>
                                <li role="menuitem">
                                    <a class="dropdown-item" [routerLink]="['/profile/setting']">Settings</a>
                                </li>
                                <li role="menuitem">
                                    <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">Logout</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-md-none d-flex">
            <div>
                <a [routerLink]="['/help']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="none" fill-rule="evenodd">
                            <rect width="24" height="24" fill="#577795" rx="12" transform="matrix(-1 0 0 1 24 0)" />
                            <g fill="#FFF" fill-rule="nonzero">
                                <path
                                    d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                    transform="translate(7.2 6)" />
                            </g>
                        </g>
                    </svg>
                </a>
            </div>
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="dropdown-toggle custom-dropdown p-0 ml-2"
                    aria-controls="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g>
                            <ellipse cx="10" cy="10" fill="rgb(51,81,117)" rx="10" ry="10" />
                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                d="M5 5h10c.55191502 0 1 .44808498 1 1s-.44808498 1-1 1H5c-.55191502 0-1-.44808498-1-1s.44808498-1 1-1z" />
                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                d="M5 9h10c.55191502 0 1 .44808498 1 1s-.44808498 1-1 1H5c-.55191502 0-1-.44808498-1-1s.44808498-1 1-1z" />
                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                d="M5 13h10c.55191502 0 1 .44808498 1 1s-.44808498 1-1 1H5c-.55191502 0-1-.44808498-1-1s.44808498-1 1-1z" />
                        </g>
                    </svg>
                    <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                    aria-labelledby="button-basic">
                    <li role="menuitem">
                        <a class="dropdown-item" [routerLink]="['/profile']" *ngIf="user.user_role == '2'">Profile</a>
                    </li>
                    <li role="menuitem">
                        <a class="dropdown-item" [routerLink]="['/profile/setting']">Settings</a>
                    </li>
                    <li role="menuitem">
                        <a class="dropdown-item" href="javascript:void(0)"
                            (click)="settingShow = !settingShow">Logout</a>
                    </li>
                </ul>
                <div class="setting-div mt-2 logout-overlay" [ngClass]="{ '': settingShow, show: !settingShow}">
                    <div class="setting-inner">
                        <h3>Logout</h3>
                        <div class="logout-div">
                            <p>Are you sure you want to logout?</p>
                            <button class="btn comman-button" (click)="logout()">Yes</button>
                            <button class="btn cancel-button" (click)="settingShow = !settingShow">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</header>
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export function httpServiceCreator(http: HttpClient) {
  return new BaseService(http);
}

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  constructor(private http: HttpClient) { }

  /**
   * GET request
   * @param {string} url api url
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public get(url: string, options?: IRequestOptions): Observable<any> {
    return this.http.get(url, options);
  }

  /**
   * HEAD request
   * @param {string} url api url
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public head(url: string, options?: IRequestOptions): Observable<any> {
    return this.http.head(url, options);
  }

  /**
   * POST request
   * @param {string} url api url
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  post(url: string, params: Object, options?: IRequestOptions): Observable<any> {
    return this.http.post(url, params, options);
  }

  /**
   * PUT request
   * @param {string} url api url
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public put<T>(url: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(url, params, options);
  }

  /**
   * DELETE request
   * @param {string} url api url
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public delete<T>(url: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(url, options);
  }

}



<section class="profile-head">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-md-4 pl-0 student pr-0 fullPage">
                <div class="profile-sidebar" [ngClass]="{ '': settingShow, show: !settingShow}">
                    <div class="sidebarForm">
                        <h3>
                            Search (maximum of 1 entry per box)
                        </h3>
                        <form [formGroup]="searchForm" novalidate>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                                <div class="error-message"
                                    *ngIf="searchForm.get('name').hasError('whitespace') && (searchForm.get('name').dirty || searchForm.get('name').touched)">
                                    Please enter valid data
                                </div>
                                <div class="error-message"
                                    *ngIf="searchForm.get('name').hasError('maxlength') && (searchForm.get('name').dirty || searchForm.get('name').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="School" formControlName="school">
                                <div class="error-message"
                                    *ngIf="searchForm.get('school').hasError('whitespace') && (searchForm.get('school').dirty || searchForm.get('school').touched)">
                                    Please enter valid data
                                </div>
                                <div class="error-message"
                                    *ngIf="searchForm.get('school').hasError('maxlength') && (searchForm.get('school').dirty || searchForm.get('school').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Skill or Interest"
                                    formControlName="skill_interest">
                                <div class="error-message"
                                    *ngIf="searchForm.get('skill_interest').hasError('whitespace') && (searchForm.get('skill_interest').dirty || searchForm.get('skill_interest').touched)">
                                    Please enter valid data
                                </div>
                                <div class="error-message"
                                    *ngIf="searchForm.get('skill_interest').hasError('maxlength') && (searchForm.get('skill_interest').dirty || searchForm.get('skill_interest').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control"
                                    placeholder="Business or Non-Profit Organization" formControlName="business">
                                <div class="error-message"
                                    *ngIf="searchForm.get('business').hasError('whitespace') && (searchForm.get('business').dirty || searchForm.get('business').touched)">
                                    Please enter valid data
                                </div>
                                <div class="error-message"
                                    *ngIf="searchForm.get('business').hasError('maxlength') && (searchForm.get('business').dirty || searchForm.get('business').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="School Club or Team"
                                    formControlName="club_team">
                                <div class="error-message"
                                    *ngIf="searchForm.get('club_team').hasError('whitespace') && (searchForm.get('club_team').dirty || searchForm.get('club_team').touched)">
                                    Please enter valid data
                                </div>
                                <div class="error-message"
                                    *ngIf="searchForm.get('club_team').hasError('maxlength') && (searchForm.get('club_team').dirty || searchForm.get('club_team').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Books" formControlName="books">
                                <div class="error-message"
                                    *ngIf="searchForm.get('books').hasError('whitespace') && (searchForm.get('books').dirty || searchForm.get('books').touched)">
                                    Please enter valid data
                                </div>
                                <div class="error-message"
                                    *ngIf="searchForm.get('books').hasError('maxlength') && (searchForm.get('books').dirty || searchForm.get('books').touched)">
                                    Max 50 characters allowed.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <button class=" d-md-none d-inline-block common-btn mr-2"
                                        (click)="settingShow = !settingShow">Close</button>
                                    <button type="submit" (click)="search(searchForm.valid)"
                                        class="common-btn mt-3">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 bg-white">
                <div class="search-div mt-3" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50"
                    (scrolled)="onScrollDown()" [scrollWindow]="true">
                    <div *ngIf="students.length > 0" class="">
                        <p class="title">{{totalStudents}} Results</p>
                        <span>Showing {{students.length}}</span>
                        <button class="filterBtn d-md-none d-block float-right common-btn"
                            (click)="settingShow = !settingShow">Filters</button>
                        <div class="d-flex justify-content-between flex-wrap">
                            <div *ngFor="let student of students" class="search-inner d-md-flex slide">
                                <div class="imgDiv cursor-pointer" [routerLink]="['/profile', student.id]">
                                    <img
                                        src="{{student.personal_info.display_picture? mediaURL + student.personal_info.display_picture : 'assets/images/p-img.png'}}">
                                </div>
                                <div class="search-side ml-lg-3 mr-lg-2">
                                    <h2 [routerLink]="['/profile', student.id]">{{student.personal_info.name}} </h2>
                                    <h3><span *ngFor="let school of student.user_schools; let i = index">
                                       {{school}}<span *ngIf="i+1 < student.user_schools.length">, </span>
                                    </span>
                                    </h3>
                                    <p>{{student.profile_description}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="students.length == 0" class="next-listing text-center mt-4 mb-4 fullPage">
                        <button class=" d-md-none d-block float-right common-btn"
                            (click)="settingShow = !settingShow">Filters</button>
                        <h5>No Result(s) Found</h5>
                    </div>
                    <div *ngIf="totalStudents > 10 && totalStudents > students.length"
                        class="next-listing text-center mt-4 mb-4">
                        <h5>Scroll to view next 10</h5>
                        <img  alt="image" src="assets/images/arrow-down.png" alt="" id="nextBtn">
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 p-0" [ngClass]="{'volunteer': pagesDetails.page_type == 2, 'business': pagesDetails.page_type == 1, 'school': [3,4].indexOf(pagesDetails.page_type) > -1 }">
            </div>
            <div class="col-md-10 p-0">
                <app-page-header [page]="pagesDetails"></app-page-header>
                <app-page-sidebar [page]="pagesDetails"></app-page-sidebar>
                <div class="row m-0">
                    <div class="col-md-12 p-0">
                        <div class="content-section fullPage">
                            <div class="profile-div">
                                <div class="profile-inner full-inner member-listing">
                                    <h3>Pending</h3>
                                    <h5 class="title">{{pendingExperiences.length}} Students</h5>
                                    <p *ngIf="showSubmitMsg" class="alert"
                                        [ngClass]="{'alert-danger': !isSuccess, 'alert-success': isSuccess }">
                                        {{submitMsg}}</p>
                                    <div class="box-div d-flex mt-3 flex-wrap">
                                        <div *ngFor="let experience of pendingExperiences"
                                            class="student-box color1-border">
                                            <div class="student">
                                                <img *ngIf="experience.display_pic"
                                                    src="{{url}}{{experience.display_pic}}">
                                                <img *ngIf="!experience.display_pic" src="assets/images/p-img.png">
                                            </div>
                                            <div class="detailBox p-md-3 p-2">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <h4>Name</h4>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{experience.student_name}}</p>
                                                    </div>
                                                    <div class="col-4">
                                                        <h4>Position</h4>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{experience.position}}</p>
                                                    </div>
                                                    <div class="col-4">
                                                        <h4 *ngIf="experience.start_date != experience.end_date">Start</h4>
                                                        <h4 *ngIf="experience.start_date == experience.end_date">Date</h4>
                                                    </div>
                                                    <div class="col-8">
                                                        <p *ngIf="!experience.approver_name">{{experience.start_date}}
                                                        </p>
                                                        <p *ngIf="experience.approver_name"><span>Approved</span></p>
                                                    </div>
                                                    <div class="col-4" *ngIf="experience.start_date != experience.end_date">
                                                        <h4>End</h4>
                                                    </div>
                                                    <div class="col-8" *ngIf="experience.start_date != experience.end_date">
                                                        <p>{{experience.end_date}}<span
                                                                *ngIf="!experience.end_date">Current</span></p>
                                                    </div>
                                                    <div class="col-4" *ngIf="experience.total_hours != null">
                                                        <h4>Hours</h4>
                                                    </div>
                                                    <div class="col-8" *ngIf="experience.total_hours != null">
                                                        <p>{{ experience.total_hours }}</p>
                                                    </div>
                                                    <div class="col-md-12 text-center">
                                                        <button class="common-btn mt-3"
                                                            (click)="expModal(experienceApproveModal,declineTemplate, experience)">Decline</button>
                                                        <button class="common-btn mr-2 mt-3"
                                                            (click)="approveReq(experienceApproveModal, experience, true)">Approve</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner member-listing">
                                    <h3>Approved</h3>
                                    <p class="title">{{approvedExperiences.length}} Total</p>
                                    <div class="box-div d-flex mt-3 flex-wrap">
                                        <div *ngFor="let experience of approvedExperiences"
                                            class="student-box color1-border">
                                            <div class="student">
                                                <img
                                                    src="{{experience.display_pic? mediaURL + experience.display_pic : 'assets/images/p-img.png'}}">
                                            </div>
                                            <div class="detailBox p-md-3 p-2">
                                                <div class="row">
                                                    <div class="col-4">
                                                        <h4>Name</h4>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{experience.student_name}}</p>
                                                    </div>
                                                    <div class="col-4">
                                                        <h4>Position</h4>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{experience.position}}</p>
                                                    </div>
                                                    <div class="col-4">
                                                        <h4 *ngIf="experience.start_date != experience.end_date">Start</h4>
                                                        <h4 *ngIf="experience.start_date == experience.end_date">Date</h4>
                                                    </div>
                                                    <div class="col-8">
                                                        <p>{{experience.start_date}}</p>
                                                    </div>
                                                    <div class="col-4" *ngIf="experience.start_date != experience.end_date">
                                                        <h4>End</h4>
                                                    </div>
                                                    <div class="col-8" *ngIf="experience.start_date != experience.end_date">
                                                        <p>{{experience.end_date}}<span
                                                                *ngIf="!experience.end_date">Current</span></p>
                                                    </div>
                                                    <div class="col-4" *ngIf="experience.total_hours != null">
                                                        <h4>Hours</h4>
                                                    </div>
                                                    <div class="col-8" *ngIf="experience.total_hours != null">
                                                        <p>{{ experience.total_hours }}</p>
                                                    </div>
                                                    <div class="col-md-12 text-center">
                                                        <p class="verifiedText"><img
                                                                src="assets/images/verify-resume.svg">Verified by
                                                            <span>{{experience.approver_name}}</span> on
                                                            <span>{{experience.verified_date | date: 'yyyy-MM-dd'}}
                                                            </span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #declineTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Confirm Decline</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="declineForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Reason for Rejection</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="rejection_reason"> </textarea>
                        <div class="error-message"
                            *ngIf="declineForm.get('rejection_reason').hasError('maxlength') && (declineForm.get('rejection_reason').dirty || declineForm.get('rejection_reason').touched)">
                            Max 250 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="declineReq(declineForm.valid)">
            Confirm
        </button>
    </div>
</ng-template>

<ng-template #experienceApproveModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Confirm Decline</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body modalCustom">
        <div class="modal-box">
            <form [formGroup]="declineForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12" *ngIf="pagesDetails.page_type == 1">
                        <label>You need to provide your Business Number before you can take action on the
                            Verification.</label>
                    </div>
                    <div class="col-md-12" *ngIf="pagesDetails.page_type == 2">
                        <label>You need to provide your NPO Number before you can take action on the
                            Verification.</label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
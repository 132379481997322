<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img  alt="image" src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section form-section help-section">
                    <div class="side-links">
                        <ul class="">
                            <li class="bg-bsns">
                                <a [routerLink]="['/dashboard']" routerLinkActive="student student-border">
                                    <img  alt="image" src="assets/images/calender-icon.svg" class="d-none d-md-inline-block">
                                    <span class="d-md-none d-block">Calendar</span>
                                </a>
                            </li>
                            <li *ngIf="isSenior" class=" bg-bsns create-link ">
                                <a [routerLink]="['/create-page']" routerLinkActive="student student-border">
                                    <img  alt="image" src="assets/images/add-icon.svg" class="d-none d-md-inline-block">
                                    <span class="d-md-none d-block">Add Page</span>
                                </a>
                            </li>
                            <li class="active">
                                <a [routerLink]="['/help-page']" routerLinkActive="student student-border">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <rect width="24" height="24" fill="#335175" rx="12"
                                                transform="matrix(-1 0 0 1 24 0)" />
                                            <g fill="#FFF" fill-rule="nonzero">
                                                <path
                                                    d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                                    transform="translate(7.2 6)" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="content-section p-4">
                        <div class="profile-div">
                            <h2 class="top-title">Need Help? </h2>
                            <div class="profile-inner full-inner mt-md-4">
                                <app-contact-us-form [displayColClass]="false"></app-contact-us-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="info-overlay" *ngIf="infoSlide">
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <img  alt="image" src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none">
        <img  alt="image" src="assets/images/info-mobile.svg" class="d-md-none d-block">
    </div>
</div>
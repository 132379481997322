<div class="mobileMsg">
    <div class="return-div d-md-none d-block">
        <a href="javascript:void();">Return</a>
    </div>
    <div class="top-div d-flex align-items-center justify-content-between" [ngClass]="{ '': msgSetting, show: !msgSetting}">
        <div class="info-div d-flex align-items-center">
            <img  alt="image" src="{{conversation?.user_info?.display_pic? mediaURL + conversation?.user_info?.display_pic : 'assets/images/p-img.png'}}" alt="Profile" class="profile">
            <h2>{{conversation?.user_info?.name}}</h2>
        </div>
        <img  alt="image" src="assets/images/more-icon.svg" alt="" class="float-right cursor-pointer" (click)="msgSetting = !msgSetting">
        <div class="msg-overlay">
            <div class="msg-setting">
                <h2 class="d-md-none d-block">Settings <a href="javascript:void();" (click)="msgSetting = !msgSetting" class="float-right"><img  alt="image" src="assets/images/cross.png" alt=""></a></h2>
                <ul class="pl-0 mb-0">
                    <li>
                        <a (click)="deleteModal(deleteConversation)">Delete Conversation</a>
                    </li>
                    <li *ngIf="checkSetting">
                        <a (click)="toSelectMessages()">Delete Messages</a>
                    </li>
                    <li *ngIf="!checkSetting">
                        <a (click)="toSelectMessages(true)">Cancel</a>
                    </li>
                    <li *ngIf="!checkSetting">
                        <a (click)="deleteModal(deleteMessages)">Delete</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="chat-section pl-3 pr-3" [ngClass]="{ '': checkSetting, showCheck: !checkSetting}">
        <div *ngFor="let message of messages" class="msg-div" [ngClass]="{'sender': !message.is_sender, 'receiver': message.is_sender}">
            <div class="msg-content">
                <p>{{message.text}}</p>
                <small>{{message.created_date | date: 'yyyy/MM/dd h:mm:ss a'}}</small>
            </div>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck" name="privacy" (change)="selectMessages($event, message)">
                <label class="custom-control-label" for="customCheck"></label>
            </div>
        </div>
    </div>
    <div class="chat-div">
        <form [formGroup]="messageForm" novalidate>
            <div class="form-group mb-md-3 mb-0 position-relative">
                <input type="text" class="form-control" placeholder="Type Here" formControlName="text">
                <button class="sendBtn" (click)="sendText(messageForm.valid)"></button>
                <p *ngIf="showMsg" class="alert alert-error">{{submitMsg}}</p>
            </div>
        </form>
    </div>
</div>

<ng-template #deleteConversation>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Conversation</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>Are you sure you want to delete this conversation ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delConversation()">
            Yes
        </button>
    </div>
</ng-template>

<ng-template #deleteMessages>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Delete Message(s)</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>Are you sure you want to delete selected message(s) ?</p>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            No
        </button>
        <button class="btn btn-footer" (click)="delMessages()">
            Yes
        </button>
    </div>
</ng-template>

<section class="search-overlay">
    <div class="searchdeatil d-flex align-items-center h-100">
        <div class="row">
            <div class="col-md-12 mx-auto">
                <div class="search-inner text-center">
                    <h1>What Would you Like to Search Today?</h1>
                    <div class="search-flex d-flex mt-4 flex-wrap">
                        <div class="searchDiv text-center">
                            <a [routerLink]="['/search-experiences']">
                                <img alt="image" src="assets/images/search.png" alt="icon">
                                <h2>Experiences</h2>
                            </a>
                            <img alt="image" src="assets/images/helpIcon.svg" alt="icon" class="infoicon"
                                (click)="infoDiv();">
                            <div class="info-overlay1 show" *ngIf="info1">
                                <div class="info-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                        viewBox="0 0 63.64 63.64" class="cursor-pointer" (click)="close();">
                                        <g transform="rotate(45.000016 31.81980515 31.81980515)">
                                            <ellipse cx="31.82" cy="31.82" fill="rgb(176,54,54)" rx="22.5" ry="22.5" />
                                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                                d="M30.81980515 18.31980515h2c1.10383005 0 2 .89616995 2 2v23c0 1.10383005-.89616995 2-2 2h-2c-1.10383005 0-2-.89616995-2-2v-23c0-1.10383005.89616995-2 2-2z" />
                                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                                d="M45.31980572 30.81981274l-.00000113 2c-6.2e-7 1.10383004-.89617107 1.9999995-2.00000112 1.99999887l-23-.00001292c-1.10383005-6.2e-7-1.9999995-.89617107-1.99999888-2.00000112l.00000113-2c6.2e-7-1.10383005.89617107-1.9999995 2.00000112-1.99999888l23 .00001292c1.10383005 6.2e-7 1.9999995.89617108 1.99999888 2.00000113z" />
                                        </g>
                                    </svg>
                                    <p>The Experiences Search lets you find Jobs and Volunteer opportunities near you.
                                        Search by keywords and results will generate based on your Location or any
                                        Location of your choosing</p>
                                </div>
                            </div>
                        </div>
                        <div class="searchDiv text-center"
                            *ngIf="verifiers.length >= 5 || user.approval_status || (user.age_group == 1 && user.user_role == '2')">
                            <a [routerLink]="['/student-search']">
                                <img alt="image" src="assets/images/search-1.png" alt="icon">
                                <h2>Students</h2>
                            </a>
                            <img alt="image" src="assets/images/helpIcon.svg" alt="icon" class="infoicon"
                                (click)="infoDiv1();">
                            <div class="info-overlay1 show" *ngIf="info2">
                                <div class="info-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                        viewBox="0 0 63.64 63.64" class="cursor-pointer" (click)="close();">
                                        <g transform="rotate(45.000016 31.81980515 31.81980515)">
                                            <ellipse cx="31.82" cy="31.82" fill="rgb(176,54,54)" rx="22.5" ry="22.5" />
                                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                                d="M30.81980515 18.31980515h2c1.10383005 0 2 .89616995 2 2v23c0 1.10383005-.89616995 2-2 2h-2c-1.10383005 0-2-.89616995-2-2v-23c0-1.10383005.89616995-2 2-2z" />
                                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                                d="M45.31980572 30.81981274l-.00000113 2c-6.2e-7 1.10383004-.89617107 1.9999995-2.00000112 1.99999887l-23-.00001292c-1.10383005-6.2e-7-1.9999995-.89617107-1.99999888-2.00000112l.00000113-2c6.2e-7-1.10383005.89617107-1.9999995 2.00000112-1.99999888l23 .00001292c1.10383005 6.2e-7 1.9999995.89617108 1.99999888 2.00000113z" />
                                        </g>
                                    </svg>

                                    <p>The Students Search lets you find other Students on the Platform. Search
                                        different fields based on who you would like to find – such as an interest or
                                        place of work</p>
                                    <p><strong>NOTE:</strong> Students above 20 years old will not be able to search
                                        Students between 14-19 years old</p>
                                </div>
                            </div>
                        </div>
                        <div class="searchDiv text-center">
                            <a [routerLink]="['/search-profiles']">
                                <img alt="image" src="assets/images/search-2.png" alt="icon">
                                <h2>Pages</h2>
                            </a>
                            <img alt="image" src="assets/images/helpIcon.svg" alt="icon" class="infoicon"
                                (click)="infoDiv2();">
                            <div class="info-overlay1 show" *ngIf="info3">
                                <div class="info-div">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                        viewBox="0 0 63.64 63.64" class="cursor-pointer" (click)="close();">
                                        <g transform="rotate(45.000016 31.81980515 31.81980515)">
                                            <ellipse cx="31.82" cy="31.82" fill="rgb(176,54,54)" rx="22.5" ry="22.5" />
                                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                                d="M30.81980515 18.31980515h2c1.10383005 0 2 .89616995 2 2v23c0 1.10383005-.89616995 2-2 2h-2c-1.10383005 0-2-.89616995-2-2v-23c0-1.10383005.89616995-2 2-2z" />
                                            <path fill="rgb(255,255,255)" fill-rule="evenodd"
                                                d="M45.31980572 30.81981274l-.00000113 2c-6.2e-7 1.10383004-.89617107 1.9999995-2.00000112 1.99999887l-23-.00001292c-1.10383005-6.2e-7-1.9999995-.89617107-1.99999888-2.00000112l.00000113-2c6.2e-7-1.10383005.89617107-1.9999995 2.00000112-1.99999888l23 .00001292c1.10383005 6.2e-7 1.9999995.89617108 1.99999888 2.00000113z" />
                                        </g>
                                    </svg>
                                    <p>The Pages Search lets you find Businesses, Non-Profits and Schools near you.
                                        Search by keywords and results will generate based on your Location or any
                                        Location of your choosing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-return mt-5" [routerLink]="['/']">Return</button>
                </div>
            </div>
        </div>
    </div>
</section>
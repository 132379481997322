import { Component, OnInit, TemplateRef, Input   } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { ITeam } from '../../shared/team'
import { CarouselConfig } from 'ngx-bootstrap';

@Component({
  selector: 'business-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss'], 
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class OurTeamComponent implements OnInit {

  @Input() editable: boolean = false;
  @Input() page_id: string = null;
  @Input() pageVerified: string;
  disableSubmit: boolean = false;
  ourTeam: ITeam[] = [];
  teamObj: ITeam = null;
  modalRef: BsModalRef;
  ourTeamForm: FormGroup;
  maxNum: number = new Date().getFullYear();
  addNewTeam: boolean = false;
  editTeamID: string = null;
  submitted: boolean = false;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService, ) {
    this.ourTeamForm = this.formBuilder.group({
      first_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', Validators.maxLength(1125)),
      position: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      start_year: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)]),
      profile_image: [''],
      image_name: ['']
    });
   }

  teamFormInit(){
    this.ourTeamForm.reset();
    this.submitted = false;
    this.disableSubmit = false;
  }

  ngOnInit(): void {
    this.getTeamList();
  }

  addUpdateTeamClicked(is_update: boolean = false, isDelete:boolean = false, delImage:boolean = false){
    if (!this.ourTeamForm.valid){
      this.submitted = true;
      return
    }
    this.disableSubmit = true;
    this.submitted = false;
    let teamObjN: ITeam = {
        'first_name': this.ourTeamForm.controls['first_name'].value,
        'last_name': this.ourTeamForm.controls['last_name'].value,
        'position': this.ourTeamForm.controls['position'].value,
        'start_year': this.ourTeamForm.controls['start_year'].value,
        'description': this.ourTeamForm.controls['description'].value,
        'is_deleted': isDelete,
        'profile_image': this.ourTeamForm.controls['profile_image'].value,
        'image_name': this.ourTeamForm.controls['image_name'].value,
        'page': this.page_id
    }
    if (is_update){
      if(delImage){
        teamObjN = this.teamObj;
        teamObjN.profile_image = null;
        teamObjN.image_name = null;
      } else {
        if (!teamObjN.image_name && this.teamObj.image_name){
          teamObjN.image_name = this.teamObj.image_name;
        }
      }
      this.profileService.updateTeam(teamObjN, this.editTeamID)
        .subscribe(response => {
          if(delImage){
            this.handleTeamResponseDelete(response)
          } else {
            this.handleTeamResponse(response);
          }
        }, error => {
          this.handleTeamSubmitError(error.error);
        });
    } else{
      this.profileService.addTeam(teamObjN)
        .subscribe(response => {
          this.handleTeamResponse(response);
        }, error => {
          this.handleTeamSubmitError(error.error);
        });
    }
  }

  protected handleTeamResponseDelete(response: any) {
    this.teamObj = response.response;
    this.teamFormUpdate(this.teamObj);
    this.disableSubmit = false;
  }

  protected handleTeamResponse(response: any) {
    if (response.status === 111) {
      this.handleTeamSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.getTeamList();
        this.editTeamID = null;
        this.teamObj = null;
    }
  }

  protected handleTeamSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.ourTeamForm.contains(field)) {
        this.ourTeamForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  teamFormUpdate(teamObj:ITeam){
    this.ourTeamForm.controls['first_name'].setValue(teamObj.first_name);
    this.ourTeamForm.controls['last_name'].setValue(teamObj.last_name);
    this.ourTeamForm.controls['start_year'].setValue(teamObj.start_year);
    this.ourTeamForm.controls['description'].setValue(teamObj.description);
    this.ourTeamForm.controls['position'].setValue(teamObj.position);
    this.editTeamID = teamObj.id.toString();
    this.addNewTeam = false;
    this.disableSubmit = false;
  }

  updateTeamModal(teamModal: TemplateRef<any>, teamModalStatic: TemplateRef<any>, team_member: any) {
    let team_id = team_member.id;
    let ShowModal = null;
    this.disableSubmit = false;
    if (this.editable){
       ShowModal = teamModal;
       this.profileService.RetreiveTeam(team_id)
      .subscribe(response => {
        if(response.response.id && response.status == 200 ){
            this.teamObj = response.response;
            this.teamFormUpdate(this.teamObj);
            this.modalRef = this.modalService.show(
              ShowModal, Object.assign({}, { 
                class: 'modal-dialog-centered custom-modal'}));
          } else {
            this.getTeamList();
          }
        });
    } else {
      this.teamObj = team_member;
      ShowModal = teamModalStatic;
      this.modalRef = this.modalService.show(
        ShowModal, Object.assign({}, { 
          class: 'modal-dialog-centered custom-modal'}));
    }
  }

  openTeamModal(teamModal: TemplateRef<any>) {
    this.addNewTeam = true;
    this.editTeamID = null;
    this.teamObj = null;
    this.teamFormInit();
    this.modalRef = this.modalService.show(teamModal,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  onFileSelect(event) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop().toLowerCase();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg","jpeg","png"];
      if(!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png and jpeg files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if(fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Image size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if(!this.fileUploadError && !this.fileUploadSizeError){
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
              /.+;base64,(.+)/
          )[1];
          this.ourTeamForm.get('profile_image').setValue(base64String);
          this.ourTeamForm.get('image_name').setValue(file_name);
        };
      }
    }
  }

  getTeamList(){
    this.profileService.listTeam(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.ourTeam = response.response;
        } else {
          this.ourTeam = [];
        }
      });
  }

}

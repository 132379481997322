<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon"> 
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student form-section">
                    
                    <div class="side-links">
                        <ul class="">
                            <li class=" ">
                                <a [routerLink]="['/dashboard']" class=" " routerLinkActive="student student-border">
                                    <img  alt="image" src="assets/images/calender-icon.svg" class="d-none d-md-inline-block">
                                    <span class="d-md-none d-block">Calendar</span>
                                </a>
                            </li>
                            <li class=" create-link ">
                                <a [routerLink]="['/create-page']" class="student student-border " routerLinkActive="student student-border">
                                    <img  alt="image" src="assets/images/add-icon.svg" class="d-none d-md-inline-block"> 
                                </a>
                            </li>
                            <li class=" d-none d-md-inline-block">
                                <a [routerLink]="['/contact-us']" routerLinkActive="student student-border">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <rect width="24" height="24" fill="#335175" rx="12"
                                                transform="matrix(-1 0 0 1 24 0)" />
                                            <g fill="#FFF" fill-rule="nonzero">
                                                <path
                                                    d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                                    transform="translate(7.2 6)" />
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center mx-auto">
                            <h1>User Profile</h1>
                            <p>Personal Details</p>
                            <form [formGroup]="profileForm" novalidate>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control"  formControlName="first_name" placeholder="First Name">
                                        <div class="error-message" *ngIf="profileForm.get('first_name').hasError('required') && (profileForm.get('first_name').dirty || searchSubmitted)">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" formControlName="last_name" placeholder="Last Name">
                                        <div class="error-message" *ngIf="profileForm.get('last_name').hasError('required') && (profileForm.get('last_name').dirty || searchSubmitted)">
                                            This field is required.
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-10">
                                        <input type="text" class="form-control" bsDatepicker
                                        [minDate]="minDate" [maxDate]="maxDate"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="date_of_birth" style="background-color: white;" readonly>
                                        <div class="error-message"
                                            *ngIf="profileForm.get('date_of_birth').hasError('required') && (profileForm.get('date_of_birth').dirty || searchSubmitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message" *ngIf="profileForm.get('date_of_birth').hasError('aftersubmit')">
                                            {{profileForm.controls.date_of_birth.errors.aftersubmit}}
                                        </div>
                                    </div>
                                    <div class="col-2"> 
                                        <img  alt="image" src="assets/images/help-icon.png" (click)="infoDiv = !infoDiv" class="cursor-pointer">
                                        <div class="info-overlay1" [ngClass]="{ '': infoDiv, show: !infoDiv}">
                                            <div class="info-div">
                                                <p>**You may change your date and month of the year if you feel like updating it.</p>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <input type="email" class="form-control" formControlName="email" placeholder="Email" readonly>
                                        <div class="error-message" *ngIf="profileForm.get('email').hasError('required') && (profileForm.get('email').dirty || searchSubmitted)">
                                            This field is required.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <input type="submit" class="btn btn-submit" value="Confirm" (click)="save(profileForm.valid)">
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> 
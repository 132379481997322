<div class="side-links">
    <ul class="d-none d-md-block">
        <li class="active">
            <a [routerLink]="['/student']">
                Student
            </a>
        </li>
        <li class="bg-bsns">
            <a [routerLink]="['/business']">
                Business
            </a>
        </li>
        <li class="bg-vol">
            <a [routerLink]="['/volunteer']">
                Volunteer
            </a>
        </li>
        <li class="bg-shl">
            <a [routerLink]="['/school']">
                School
            </a>
        </li>
        <li class="bg-hlp">
            <a [routerLink]="['/contact-us']">
                Need Help
            </a>
        </li>
    </ul>
    <ul class="d-flex d-md-none">
        <li class="active">
            <a [routerLink]="['/']">
                Sign up
            </a>
        </li>
        <li class="bg-login">
            <a [routerLink]="['/login']">
                Log In
            </a>
        </li>
    </ul>
</div>
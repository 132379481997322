<section class="profile-head business-section">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 p-0" [ngClass]="isNPO ? 'volunteer': 'business'">
                <div class="green-sidebarSec">
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <app-page-header [page]="pagesDetails"></app-page-header>
                <app-page-sidebar [page]="pagesDetails"></app-page-sidebar>
                <div class="row m-0" *ngIf="pageVerified != '3'">
                    <div class="col-md-12 p-0">
                        <div class="edit-div d-flex  flex-end align-items-center">
                            <p>Edit Mode</p>
                            <label class="switch mb-0 ml-3">
                                <input class="switch-input" type="checkbox" (change)="disableEdit()" />
                                <span class="switch-label" data-on="On" data-off="Off"></span>
                                <span class="switch-handle"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <tabset class="topLinks">
                    <tab>
                        <ng-template tabHeading>
                            <h3>Details</h3>
                        </ng-template>
                        <div class="content-section business-content">
                            <div class="profile-div">
                                <div class="profile-inner bioSection2">
                                    <h3>About Us
                                        <a *ngIf="(profile | json) == '{}' && pageVerified != '3'"
                                            href="javascript:void()" class="float-right"
                                            (click)="openDescModal(descriptionTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable && (profile | json) != '{}'"
                                            class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="box-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                        <div class="inner-div cursor-pointer" *ngIf="(profile | json) != '{}'"
                                            (click)="openDescModal(editDescriptionTemplate)">
                                            <ul class="pl-0 d-flex">
                                                <li *ngIf="profile.profile_keyword_1">
                                                    <p>{{profile.profile_keyword_1}}</p>
                                                </li>
                                                <li *ngIf="profile.profile_keyword_2">
                                                    <p>{{profile.profile_keyword_2}}</p>
                                                </li>
                                                <li *ngIf="profile.profile_keyword_3">
                                                    <p>{{profile.profile_keyword_3}}</p>
                                                </li>
                                            </ul>
                                            <p class="px-1" style="white-space: pre-wrap;">{{profile.description}}</p>
                                        </div>
                                        <div *ngIf="(profile | json) == '{}'"
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                            <p>Explain what you do</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner light-shade">
                                    <business-team [editable]="editable" [page_id]="page_id"
                                        [pageVerified]="pageVerified"></business-team>
                                </div>
                                <div class="profile-inner">
                                    <job-posting [editable]="editable" [page_id]="page_id"
                                        [pageVerified]="pageVerified"></job-posting>
                                </div>
                                <div *ngIf="isNPO" class="profile-inner">
                                    <volunteer-posting [editable]="editable" [page_id]="page_id"
                                        [pageVerified]="pageVerified"></volunteer-posting>
                                </div>
                                <div class="profile-inner">
                                    <h3>cause and social responsibility
                                        <a *ngIf="!editable && pageVerified != '3'" href="javascript:void()"
                                            class="float-right" (click)="csrOpen(csrTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="heightDiv box-div business-div m-0 scrollDiv" slimScroll [options]="opts"
                                        [scrollEvents]="scrollEvents">
                                        <div *ngIf="csrs?.length > 0" class="listing-box csrBox">
                                            <div *ngFor="let csr of csrs" class="box-div no-height cursor-pointer"
                                                (click)="csrDetailOpen(csrModal,editCSRTemplate,csr)">
                                                <h4 class="">{{csr.title}}</h4>
                                                <p>{{csr.description}}</p>
                                            </div>
                                        </div>
                                        <div *ngIf="csrs?.length == 0" class="box-div">
                                            <div
                                                class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                <p>You can add Your Cause and CSR</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner">
                                    <h3>Location
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                        <button (click)="showHideLocation()" *ngIf="showLocationToOthers"
                                            class="float-right btnShow">Hide</button>
                                        <button (click)="showHideLocation()" *ngIf="!showLocationToOthers"
                                            class="float-right btnShow">Show</button>
                                    </h3>
                                    <div class="box-div border-radius-0 no-height">
                                        <div class="d-flex flex-wrap">
                                            <div class="mapContainer">
                                                <h3 class="addressField" title="{{pagesDetails?.business?.address}}">{{pagesDetails?.business?.address}}</h3>
                                                <div id="mapContainer" class=" mx-0"></div>
                                            </div>
                                            <div class="hoursDiv text-md-right">
                                                <div class="innerHour mb-2" *ngFor="let bwh of businessWorkingHours"
                                                    (click)="updateopenHour(updatehourModal, bwh)">
                                                    <h4>
                                                        {{bwh.start_weekday | dayOfWeekAsStringTransform}}
                                                        {{bwh.end_weekday  == bwh.start_weekday ? "" : "-"}}
                                                        {{bwh.end_weekday  == bwh.start_weekday ? "" : bwh.end_weekday | dayOfWeekAsStringTransform}}
                                                    </h4>
                                                    <p *ngFor="let time of bwh.timings">
                                                        {{time.start_time | convertFrom24To12Format}} –
                                                        {{time.end_time | convertFrom24To12Format}}</p>
                                                </div>
                                                <a href="javascript:void()" *ngIf="!hideBWH" class="float-right"
                                                    (click)="openHour(hourModal)">
                                                    <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <h3 (click)="updBulletin()">Library</h3>
                        </ng-template>
                        <div class="content-section business-content fullPage">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>Bulletin Board
                                        <a *ngIf="!editable && pageVerified != '3'" href="javascript:void()"
                                            class="float-right" (click)="bulletinModal(buletinModal)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <ngx-slick-carousel *ngIf="isLibrary"
                                        class="box-div library-div carousel" #slickModal="slick-carousel"
                                        [config]="slideConfig">
                                        <div *ngFor="let bulletin of bulletins" class="img-cont slide" ngxSlickItem
                                            (click)="updatebulletinModal(bulletinDetail, editbulletinModal, bulletin)">
                                            <div *ngIf="bulletin.document" class="img-div">
                                                <img *ngIf="isImage(bulletin.document_name)" src="{{bulletin.document}}">
                                                <img *ngIf="!isImage(bulletin.document_name)" src="assets/images/pdf-icon.svg">
                                                <p>{{bulletin.title}}</p>
                                            </div>
                                            <div *ngIf="!bulletin.document" class="img-div">
                                                <img src="assets/images/default.svg">
                                                <p>{{bulletin.title}}</p>
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                    <div *ngIf="!isLibrary"
                                        class="library-div empty-div box-div text-center h-100 d-flex align-items-center justify-content-center">
                                        <p>Add documents and links that show off your accomplishments</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</section>
<ng-template #csrModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Cause and social responsiblity</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Title
                                </h3>
                            </div>
                            <div class="col-md-8 col-6">
                                <h4>{{editCSR.title}}</h4>
                            </div>
                            <div class="col-12">
                                <h3>
                                    Details
                                </h3>
                            </div>
                            <div class="col-md-12">
                                <p>{{editCSR.description}}</p>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #hourModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Business Working Hours</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="clearBusinessForm()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="modal-box">
                    <form [formGroup]="businessWH" novalidate>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label>Start Day *</label>
                                <select class="form-control custom-select" formControlName="start_weekday">
                                    <option value=1>Monday</option>
                                    <option value=2>Tuesday</option>
                                    <option value=3>Wednesday</option>
                                    <option value=4>Thursday</option>
                                    <option value=5>Friday</option>
                                    <option value=6>Saturday</option>
                                    <option value=7>Sunday</option>
                                </select>
                                <div class="error-message"
                                    *ngIf="businessWH.get('start_weekday').hasError('required') && (businessWH.get('start_weekday').dirty || businessWH.get('start_weekday').touched || businessWHsubmitted)">
                                    This field is required.
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>End Day *</label>
                                <select class="form-control custom-select" formControlName="end_weekday">
                                    <option value=1>Monday</option>
                                    <option value=2>Tuesday</option>
                                    <option value=3>Wednesday</option>
                                    <option value=4>Thursday</option>
                                    <option value=5>Friday</option>
                                    <option value=6>Saturday</option>
                                    <option value=7>Sunday</option>>
                                </select>
                            </div>
                        </div>
                        <div formArrayName="timings"
                            *ngFor="let item of businessWH.get('timings')['controls']; let i = index;"
                            class="form-group ">
                            <div [formGroupName]="i" class="row">
                                <div class="col-md-6">
                                    <label for="">Time Open *</label>
                                    <ngb-timepicker formControlName="start_time"></ngb-timepicker>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Time Close *</label>
                                    <ngb-timepicker formControlName="end_time"></ngb-timepicker>
                                </div>
                            </div>
                            <div class="error-message">
                                {{ item.invalid && businessWHsubmitted ? "This field is required" : "" }}
                            </div>
                        </div>
                        <div class="form-group" *ngIf="!hideSlot">
                            <a href="javascript:void();" (click)="addtimings()"><img
                                    src="assets/images/plus-icon.png"></a>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="clearBusinessForm()">
            Cancel
        </button>
        <button class="btn btn-footer" (click)="addBusinessWorkingHours(businessWH.valid)">
            Done
        </button>
    </div>
</ng-template>

<ng-template #updatehourModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Business Working Hours</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="clearBusinessForm()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <div class="modal-box">
                    <form [formGroup]="businessWH" novalidate>
                        <div class="form-group row">
                            <div class="col-md-6">
                                <label>Start Day *</label>
                                <select class="form-control custom-select" formControlName="start_weekday">
                                    <option value=1>Monday</option>
                                    <option value=2>Tuesday</option>
                                    <option value=3>Wednesday</option>
                                    <option value=4>Thursday</option>
                                    <option value=5>Friday</option>
                                    <option value=6>Saturday</option>
                                    <option value=7>Sunday</option>
                                </select>
                                <div class="error-message"
                                    *ngIf="businessWH.get('start_weekday').hasError('required') && (businessWH.get('start_weekday').dirty || businessWH.get('start_weekday').touched || businessWHsubmitted)">
                                    This field is required.
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label>End Day *</label>
                                <select class="form-control custom-select" formControlName="end_weekday">
                                    <option value=1>Monday</option>
                                    <option value=2>Tuesday</option>
                                    <option value=3>Wednesday</option>
                                    <option value=4>Thursday</option>
                                    <option value=5>Friday</option>
                                    <option value=6>Saturday</option>
                                    <option value=7>Sunday</option>>
                                </select>
                            </div>
                        </div>
                        <div formArrayName="timings"
                            *ngFor="let item of businessWH.get('timings')['controls']; let i = index;"
                            class="form-group ">
                            <div [formGroupName]="i" class="row">
                                <div class="col-md-6">
                                    <label for="">Time Open *</label>
                                    <ngb-timepicker formControlName="start_time"></ngb-timepicker>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Time Close *</label>
                                    <ngb-timepicker formControlName="end_time"></ngb-timepicker>
                                </div>
                            </div>
                            <div class="error-message">
                                {{ item.invalid && businessWHsubmitted ? "This field is required" : "" }}
                            </div>
                        </div>

                        <div class="form-group" *ngIf="!hideSlot && editable">
                            <a href="javascript:void();" (click)="addtimings()"><img
                                    src="assets/images/plus-icon.png"></a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="deleteBusinessForm(businessWH,currentBWH)">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateBusinessForm(businessWH.valid, currentBWH)">
            Update
        </button>
    </div>
</ng-template>

<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin Board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-modal d-flex align-items-center">
                        <h2>Title</h2>
                        <p>{{editBulletin.title}}</p>
                    </div>
                    <div class="divDetail">
                        <h2>Attachments & Links</h2>
                        <div class="d-flex mt-3">
                            <figure *ngIf="editBulletin.document">
                                <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank" class="link-style">{{editBulletin.document_name}}</a>
                                <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank">
                                    <img  alt="image" src="{{editBulletin.document}}">
                                </a>
                            </figure>
                            <div class="uploadDetail ml-3">
                                <a href="{{editBulletin.link}}" target="_blank">{{editBulletin.link}}</a>
                            </div>
                        </div>
                        <div class="content-detail">
                            <h2>Details</h2>
                            <p>{{editBulletin.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #buletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('required') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addBulletin(bulletinForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #editbulletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" [readonly]="!editable" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div *ngIf="editable || editBulletin.document" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add file</label>
                        <label *ngIf="!editable">File</label>
                    </div>
                    <div *ngIf="editable && !editBulletin.document" class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editBulletin.document" class="col-md-10">

                        <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank"
                            class="link-style">{{editBulletin.document_name}}</a>
                        <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank">
                            <img  alt="image" src="{{editBulletin.document}}" class="uploaded-img">
                        </a>
                        <a href="javascript:void()" *ngIf="editable" (click)="removeBulletinDoc(editBulletin.id)">
                            <img  alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>
                <div *ngIf="editable || editBulletin.link" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add Link</label>
                        <label *ngIf="!editable">Link</label>
                    </div>
                    <div *ngIf="editable" class="col-md-10">
                        <input type="text" class="form-control modal-control" [readonly]="!editable" placeholder="Link"
                            formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                    <div *ngIf="!editable" class="col-md-10">
                        <a href={{editBulletin.link}} target="_blank" class="link-style">{{editBulletin.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('required') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="delBulletin()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateBulletin(bulletinForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #descriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">About Us</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top 3 Keywords</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control  " placeholder="Keyword 1"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " placeholder="Keyword 2"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">

                                <input type="text" class="form-control  " placeholder="Keyword 3"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" placeholder="Description"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 1750 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">About Us</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top 3 Keywords</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control  " placeholder="First Skill"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control  " placeholder="Second Skill"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control  " placeholder="Third Skill"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [readonly]="!editable" formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 1750 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="updateDescription(descriptionForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #csrTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Cause and social responsiblity</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box" *ngIf="csrs?.length < 5">
            <form [formGroup]="csrForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control  " placeholder="Title" formControlName="title">
                        <div class="error-message"
                            *ngIf="csrForm.get('title').hasError('required') && (csrForm.get('title').dirty || csrForm.get('title').touched || csrFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="csrForm.get('title').hasError('maxlength') && (csrForm.get('title').dirty || csrForm.get('title').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="csrForm.get('description').hasError('required') && (csrForm.get('description').dirty || csrForm.get('description').touched || csrFrmsubmitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="csrForm.get('description').hasError('maxlength') && (csrForm.get('description').dirty || csrForm.get('description').touched)">
                                    Max 1125 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <p *ngIf="csrs?.length == 5">You cannot add more than 5 Cause and Social Responsibility.</p>
    </div>
    <div class="modal-footer" *ngIf="csrs?.length < 5">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addCSR(csrForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editCSRTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Cause and social responsiblity</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="csrForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" [readonly]="!editable" class="form-control  " placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="csrForm.get('title').hasError('required') && (csrForm.get('title').dirty || csrForm.get('title').touched || csrFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="csrForm.get('title').hasError('maxlength') && (csrForm.get('title').dirty || csrForm.get('title').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea [readonly]="!editable" class="form-control"
                                    formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="csrForm.get('description').hasError('required') && (csrForm.get('description').dirty || csrForm.get('description').touched || csrFrmsubmitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="csrForm.get('description').hasError('maxlength') && (csrForm.get('description').dirty || csrForm.get('description').touched)">
                                    Max 1125 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="deleteCSR()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateCSR(csrForm.valid)">
            Update
        </button>
    </div>
</ng-template>
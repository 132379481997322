import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { ProfileService } from '../../services/profile.service';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { unqiueProfile } from '../../shared/uniqueProfile';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';

const mediaURL = environment.mediaURL;

@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.scss']
})
export class ProfileSettingComponent implements OnInit {
  user: any = {};
  age_user :any;
  changeReqData: any = {};
  toDelVerifier: any = {};
  verifiers = [];
  passwordChangeForm: FormGroup;
  passwordForm: FormGroup;
  otpForm: FormGroup;
  newEmailForm: FormGroup;
  verifierForm: FormGroup;
  GetNameDisplay: string = "1";
  GetImageDisplay: string = "true";
  GetLocationDisplay: string = "true";
  privaceSettingForm: FormGroup;
  BlockUserList: any;
  BlockUserListLength: any;
  account_verification_status: string;
  public submitted: boolean = false;
  public partialSubmitted: boolean = false;
  public infoDiv = true;
  public infoDiv1 = true;
  public showMsg: boolean = false;
  public isSuccess: boolean = false;
  public isVerifSuccess: boolean = false;
  public showPassForm: boolean = true;
  public showEmailForm: boolean = false;
  public showOTPForm: boolean = false;
  public showDelMsg: boolean = false;
  public isDisabled :boolean = false;
  public isMinor: boolean = false;
  public isSenior: boolean = false;
  pageslist_owners: any = []
  pageslist_admins: any = []
  delSubmitMsg: string;
  approvedVerifier = 0;
  referralCount = 0;
  profileForm: FormGroup;
  profile: unqiueProfile = {
    user_id : this.user.id,
    date_of_birth : undefined
  };
  public searchSubmitted: boolean = false;
  url = mediaURL;
  minDate = new Date();
  maxDate = new Date();


  modalRef: BsModalRef;
  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private profileService: ProfileService,
    private loginService: LoginService, private authService: AuthenticationService, private router: Router) {
    this.privaceSettingForm = this.formBuilder.group({
      name_display: new FormControl(this.GetNameDisplay),
      image_display: new FormControl(this.GetImageDisplay),
      location_display: new FormControl(this.GetLocationDisplay)
    });
    this.passwordChangeForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      new_password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      confirm_new_password: new FormControl('', Validators.required)
    }, {
      validators: [this.checkPassword.bind(this)]
    });
    this.newEmailForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      confirm_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
    }, {
      validators: [this.checkEmail.bind(this)]
    });
    this.passwordForm = this.formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()])
    });
    this.otpForm = this.formBuilder.group({
      otp: new FormControl('', [Validators.required, Validators.maxLength(8)])
    });
    this.authService.AccountVerificationStatus()
    .subscribe(response => {
      this.account_verification_status = response.response.account_verified
    });
    this.authService.BlockUserGet().subscribe(response =>{
      this.BlockUserList = response.response.blocked_users
      this.BlockUserListLength = this.BlockUserList.length
    })
    this.verifierForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      first_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      relation: new FormControl('', [Validators.required, Validators.maxLength(50)])
    });
    this.profileForm = this.formBuilder.group({
      first_name: new FormControl({value: '', disabled: true}, [Validators.required]),
      last_name: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(50)]),
      date_of_birth: new FormControl('', Validators.required),
      email: new FormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(100)]),
    });
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.getVerifiers();
    this.getReferralCount();
    if (this.user.user_role === 2){
      this.isDisabled =true;
    }
    if (this.user.age_group == 1) {
      this.isMinor = true;
    } else {
      this.isMinor = false;
    }
    if (this.user.age_group == 3) {
      this.isSenior = true;
    } else {
      this.isSenior = false;
    }
    if (this.user.date_of_birth) {
      this.profile.date_of_birth = this.user.date_of_birth;
      let dob = this.user.date_of_birth.split("-");
      let yob = Number(dob[0]);
      this.minDate = new Date(yob, 0, 1);
      this.maxDate = new Date(yob, 11, 31);
    }
    this.profilePrivacy();
    this.authService.listPages('owner')
    .subscribe(response => {
      this.pageslist_owners = response.response
    });
    this.authService.listPages('admin')
    .subscribe(response => {
      this.pageslist_admins=response.response
    });
  }

  getVerifiers(){
    this.profileService.listVerifiers()
    .subscribe(response => {
      if(response.response.length > 0){
        this.verifiers = response.response;
        this.approvedVerifier = this.verifiers.filter(obj => obj.status === 2).length;
      } else {
        this.verifiers = [];
      }
    })
  }

  getReferralCount(){
    this.profileService.getReferralCount()
    .subscribe(response => {
      if (response.response.referrals) {
        this.referralCount = response.response.referrals;
      } else {
        this.referralCount = 0;
      }
    })
  }

  UnblockUser(id:any){
    var data ={'blocked_user':id, 'is_blocked':false}
    this.authService.BlockUserPost(data)
    .subscribe(response => {
      window.location.reload()
    });
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('new_password');
    const { value: confirmPassword } = formGroup.get('confirm_new_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  checkEmail(formGroup: FormGroup) {
    const { value: email } = formGroup.get('email');
    const { value: confirmEmail } = formGroup.get('confirm_email');
    return email === confirmEmail ? null : { emailNotMatch: true };
  }

  emailModal(changeEmail: TemplateRef<any>) {
    this.submitted = false;
    this.partialSubmitted = false;
    this.passwordForm.reset();
    this.newEmailForm.reset();
    this.otpForm.reset();
    this.showPassForm = true;
    this.showEmailForm = false;
    this.showOTPForm = false;
    this.isSuccess = false;
    this.modalRef = this.modalService.show(
      changeEmail,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  passwordModal(changePassword: TemplateRef<any>) {
    this.submitted = false;
    this.passwordChangeForm.reset();
    this.verifierForm.reset();
    this.modalRef = this.modalService.show(changePassword,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  deleteModal(deleteVerifier: TemplateRef<any>, verifier: any) {
    this.toDelVerifier = verifier;
    this.modalRef = this.modalService.show(deleteVerifier,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  UniqueProfileModal(uniqueProfile: TemplateRef<any>) {
    this.submitted = false;
    this.profileForm.controls['first_name'].setValue(this.user.first_name, {onlySelf: true});
    this.profileForm.controls['last_name'].setValue(this.user.last_name, {onlySelf: true});
    this.profileForm.controls['date_of_birth'].setValue(this.user.date_of_birth, {onlySelf: true});
    this.profileForm.controls['email'].setValue(this.user.email, {onlySelf: true});
    this.modalRef = this.modalService.show(uniqueProfile,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  updatePassword(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {'password': this.passwordChangeForm.get('password').value,
                  'new_password': this.passwordChangeForm.get('new_password').value,
                  'confirm_new_password': this.passwordChangeForm.get('confirm_new_password').value}
      this.profileService.changePassword(model)
      .subscribe(response => {
        this.handlePasswordResponse(response);
      }, error => {
          this.handlePasswordSubmitError(error.error);
      })
    }
  }

  OpenPage(pageid: string, pagetype: string){
    if (pagetype === 'business'){
      this.router.navigate(['page/'+pageid +'/profile-business']);
    } else {
      this.router.navigate(['page/'+pageid +'/profile-school']);
    }
  }

  OpenPageSettings(pageid: string){
    this.router.navigate(['page/'+pageid +'/setting']);
  }

  SavePrivacy(){
    this.authService.ProfilePrivacySettingPost(this.privaceSettingForm.value)
    .subscribe(response => {
      window.location.reload()
    },error => {
      this.handleSubmitError(error.error);
    });
  }
  protected handlePasswordResponse(response: any) {
    if (response.status === 111) {
      this.handlePasswordSubmitError(response.message);
    } else if (response.status === 200) {
        this.showMsg = true;
        this.modalRef.hide();
        this.passwordChangeForm.reset();
        this.submitted = false;
        this.fadeOutMsg();
    } else {
      this.modalRef.hide();
      this.passwordChangeForm.reset();
      this.submitted = false;
    }
  }

  protected handlePasswordSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.passwordChangeForm.contains(field)) {
        this.passwordChangeForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  fadeOutMsg() {
    setTimeout( () => {
      this.showMsg = false;
    }, 6000);
   }

   verifyPassword(isValid: boolean) {
    this.partialSubmitted = true;
    if (isValid){
      let password = this.passwordForm.get('password').value;
      this.profileService.verifyPassword(password)
      .subscribe(response => {
        if (response.status === 111) {
          this.passwordForm.get('password').setErrors({ aftersubmit: response.message });
        } else if (response.status === 200) {
          this.showPassForm = false;
          this.showEmailForm = true;
          this.showOTPForm = false;
          this.partialSubmitted = false;
          this.passwordForm.reset();
        } else {
          this.passwordForm.get('password').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.passwordForm.get('password').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
   }

   addNewEmail(isValid: boolean) {
    this.submitted = true;
    if (isValid){
      let email = this.newEmailForm.get('email').value;
      this.profileService.changeEmailReq(email)
      .subscribe(response => {
        if (response.status === 111) {
          this.newEmailForm.get('email').setErrors({ aftersubmit: response.message['new_email'] });
        } else if (response.status === 200) {
          this.changeReqData = response.response;
          this.showPassForm = false;
          this.showEmailForm = false;
          this.showOTPForm = true;
          this.submitted = false;
          this.newEmailForm.reset();
        } else {
          this.newEmailForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.newEmailForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
   }

   verifyOTP(isValid: boolean) {
    this.partialSubmitted = true;
    if (isValid){
      let otp = this.otpForm.get('otp').value;
      this.profileService.verifyOTP(otp, this.changeReqData.id)
      .subscribe(response => {
        this.handleOTPResponse(response);
      }, error => {
        this.otpForm.get('otp').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
   }

   handleOTPResponse(response: any){
    if (response.status === 111) {
      this.otpForm.get('otp').setErrors({ aftersubmit: response.message['otp'] });
    } else if (response.status === 200) {
      this.isSuccess = true;
      this.showPassForm = false;
      this.showEmailForm = false;
      this.showOTPForm = false;
      this.partialSubmitted = false;
      this.otpForm.reset();
      this.logOutUser();
    } else {
      this.otpForm.get('otp').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
    }
   }

   logOutUser() {
      this.loginService.userLogout()
      .subscribe(
          data => {
              this.modalRef.hide();
              localStorage.removeItem('user');
              window.location.href='/';
          },
          error => {}
      )
   }

   createVerifier(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {'email': this.verifierForm.get('email').value.toLowerCase(),
                  'first_name': this.verifierForm.get('first_name').value,
                  'last_name': this.verifierForm.get('last_name').value,
                  'relation': this.verifierForm.get('relation').value}
      this.profileService.createVerifier(model)
      .subscribe(response => {
        if (response.status === 111) {
          let data = response.message;
          const fields = Object.keys(data || {});
          fields.forEach(field => {
            if (this.verifierForm.contains(field)) {
              this.verifierForm.get(field).setErrors({ aftersubmit: data[field][0] });
            }
          });
        } else if (response.status === 200) {
          this.verifiers.push(response.response);
          this.modalRef.hide();
          this.submitted = false;
          this.verifierForm.reset();
        } else {
          this.newEmailForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
        }
      }, error => {
        this.newEmailForm.get('email').setErrors({ aftersubmit: 'Something went wrong. Please try again later.' });
      })
    }
   }

  delVerifier() {
    this.modalRef.hide();
    if (this.toDelVerifier.id) {
      this.profileService.deleteVerifier(this.toDelVerifier.id, this.toDelVerifier)
      .subscribe(response => {
        this.handledelResponse(response, this.toDelVerifier);
      }, error => {
        this.isVerifSuccess = false;
        this.delSubmitMsg = "Something went wrong. Please try again later.";
        this.showDelMsg = true;
        this.fadeOutDelMsg();
      })
    }
  }

  handledelResponse(response: any, verifierObj: any){
    if (response.status === 111) {
      this.isVerifSuccess = false;
      this.delSubmitMsg = response.message;
      this.showDelMsg = true;
    } else if (response.status === 200) {
      this.isVerifSuccess = true;
      this.delSubmitMsg = "Verfier has been deleted successfully.";
      this.showDelMsg = true;
      let index = this.verifiers.indexOf(verifierObj);
      this.verifiers.splice(index, 1);
      this.approvedVerifier = this.verifiers.filter(obj => obj.status === 2).length;
    } else {
      this.delSubmitMsg = response.message;
      this.showDelMsg = true;
      this.isVerifSuccess = false;
    }
    this.fadeOutDelMsg();
   }

  fadeOutDelMsg() {
    setTimeout( () => {
      this.showDelMsg = false;
      this.isVerifSuccess = false;
      this.delSubmitMsg = "";
    }, 6000);
   }

  canDelete(verifierObj: any) {
    if (verifierObj.status == 1 || this.approvedVerifier > 5) {
      return true;
    }
    return false;
  }

  resendEmail(verifierObj: any) {
    if (verifierObj.id) {
      this.profileService.resendVerification(verifierObj.id)
      .subscribe(response => {
        if (response.status === 111) {
          this.delSubmitMsg = response.message;
          this.showDelMsg = true;
          this.isVerifSuccess = false;
        } else if (response.status === 200) {
          this.delSubmitMsg = "Email sent successfully.";
          this.showDelMsg = true;
          this.isVerifSuccess = true;
        } else {
          this.delSubmitMsg = response.message;
          this.showDelMsg = true;
          this.isVerifSuccess = false;
        }
        this.fadeOutDelMsg();
      }, error => {
        this.delSubmitMsg = "Something went wrong. Please try again later.";
        this.showDelMsg = true;
        this.isVerifSuccess = false;
        this.fadeOutDelMsg();
      })
    }
  }

  getDateString(_date: Date) {
    if(_date == null){
      return null;
    }
    return _date.getFullYear().toString() + '-' 
      + (_date.getMonth() + 1).toString().padStart(2, "0") 
      + '-' + _date.getDate().toString().padStart(2, "0");
  }

   save(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let dob = this.getDateString(new Date(this.profileForm.get('date_of_birth').value));
      this.profile.user_id = this.user.id;
      this.profile.date_of_birth = dob;
      this.authService.setupUniqueProfile(this.profile)
      .subscribe(response => {
        this.handleResponse(response);
      },
        error => {
          this.handleSubmitError(error.error);
        });
    }
    else{
        this.searchSubmitted = true; 
    }
  }

  profilePrivacy(){
  this.authService.ProfilePrivacySettingGet()
  .subscribe(response => {
    if (response.response.length > 0) {
      this.GetNameDisplay = (response.response[0].name_display).toString();
      this.GetImageDisplay = (response.response[0].image_display).toString();
      if (response.response[0].location_display.toString() == 'true' && this.isMinor == false ){
        this.GetLocationDisplay = (response.response[0].location_display).toString();
      }
      this.initialPrivacyData();
    } else{
      this.GetNameDisplay = '1'
      this.GetImageDisplay = 'true'
      this.GetLocationDisplay = 'true'
    }
  },error => {
      this.handleSubmitError(error.error);
    });
  }
  initialPrivacyData(){
    this.privaceSettingForm.controls['name_display'].setValue(this.GetNameDisplay);
    this.privaceSettingForm.controls['image_display'].setValue(this.GetImageDisplay);
    this.privaceSettingForm.controls['location_display'].setValue(this.GetLocationDisplay);
  }
  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.searchSubmitted = false;
        this.profileForm.reset();
        this.submitted = false;
        this.user.user_role = response.response.user_role;
        this.user.date_of_birth = response.response.date_of_birth;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.href='/dashboard';
    } else {
      this.modalRef.hide();
      this.profileForm.reset();
      this.submitted = false;
      this.searchSubmitted = false;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.searchSubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.profileForm.contains(field)) {
        this.profileForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }
}

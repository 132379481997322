import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { Router } from '@angular/router';
import { PasswordService } from '../services/password.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  passwordForm: FormGroup
  public submitted: boolean = false;
  public showError: boolean = false;
  public showMessage: boolean = false;
  public hideform: boolean = false;

  constructor(public formBuilder: FormBuilder, private customValidator: CustomvalidationService, private router: Router, private passwordService: PasswordService) {

    this.passwordForm = formBuilder.group({
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      confirm_password: new FormControl('', Validators.required)
    }, {
      validators: this.checkPassword.bind(this)
    })

  }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      this.router.navigate(['dashboard']);
    }
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirm_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  submit(isValid: boolean){
    this.submitted = true;
    if (isValid) {
      let url = this.router.url;
      const params = url.split('?')[1];
      this.passwordService.resetPassword(params, this.passwordForm.get('confirm_password').value, this.passwordForm.get('password').value)
      .subscribe(response => {
        this.handleResponse(response);
      },
      error => {
        this.showError = true;
        this.hideform = true;
      })
    }
  }

  protected handleResponse(response: any) {
    this.hideform = true;
    this.submitted = false;
    if (response.status === 111) {
      this.showError = true;
      this.showMessage = false;
    } else if (response.status === 200) {
        this.passwordForm.reset();
        this.showMessage = true;
    }
  }

}

import { Component, OnInit, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { UserProfile } from '../../shared/userProfile';
import { UserBook } from '../../shared/userBook';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CustomvalidationService } from '../../services/customvalidation.service';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { IEducationNew } from '../../shared/education';
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class StudentProfileComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  educationForm: FormGroup;
  schoolEdForm: FormGroup;
  collegeEdForm: FormGroup;
  schoolInvUpdForm: FormGroup;
  insText: string = "School";
  isCollegeSelected: boolean = false;
  editSchoolInv: any = {};
  plchldrState: string = "PR";
  plchldrPostal: string = "Postal";
  expUpdForm: FormGroup;
  businessImage: string = null;
  editExp: any = {};
  pageImage: string = null;
  schoolId: string = null;
  messageError: string;
  expForm: FormGroup;
  minDate = new Date("2013-1-1");
  mediaURL = environment.mediaURL;
  blockUserChecked: boolean = false;
  reportUserChecked: boolean = false;
  profileImage: string;
  verifiers = [];
  profileImage_name: string;
  usrWorkExperience = [];
  usrVolunteerWorkExperience = [];
  schoolInvolvements = [];
  currentUser: any;
  eduList: IEducationNew[] = [];
  modalRef: BsModalRef;
  UserDetail: any = {};
  UserID: any = {};
  user: any = {};
  loggedInUser: any = {};
  public settingShow = true;
  blockReportForm: FormGroup;
  profile: any = {};
  editSkill: any = {};
  expModalTitle: any;
  isUserBlocked: any;
  editInterest: any = {};
  editBook: any = {};
  editBulletin: any = {};
  editActivity: any = {};
  skills = [];
  interests = [];
  books = [];
  bulletins = [];
  activities = [];
  organizations = [];
  descriptionForm: FormGroup;
  bookForm: FormGroup;
  uploadForm: FormGroup;
  bulletinForm: FormGroup;
  activityForm: FormGroup;
  searchForm: FormGroup;
  activityUpdForm: FormGroup;
  hideResults: boolean = false;
  formShow: boolean = false;
  previewShow: boolean = false;
  account_verification_status: string;
  days_left_to_verify: string;
  profileHeader: string = "";
  profileHeader_name: string = "";
  public partialSubmitted: boolean = false;
  public submitted: boolean = false;
  public editable: boolean = false;
  public interestFrmsubmitted: boolean = false;
  public bookFrmsubmitted: boolean = false;
  public disableSubmit: boolean = false;
  public hasData: boolean = false;
  public hasLibraryData: boolean = false;
  reportUser: boolean = false;
  activeSlideIndex = 0;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  today = new Date();
  orgId: string = null;
  isLibrary: boolean = false;

  userProfile: UserProfile = {
    description: '',
    profile_keyword_1: '',
    profile_keyword_2: '',
    profile_keyword_3: '',
    user: ''
  }

  userBook: UserBook = {
    description: '',
    title: '',
    user: '',
    id: '',
    author: '',
    rating: undefined
  }
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  constructor(private authService: AuthenticationService, private datePipe: DatePipe, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private profileService: ProfileService,
    private customValidator: CustomvalidationService) {
    this.expUpdForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', Validators.maxLength(20)),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      skill_1: new FormControl('', Validators.maxLength(30)),
      skill_2: new FormControl('', Validators.maxLength(30)),
      skill_3: new FormControl('', Validators.maxLength(30)),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    })
    this.activityUpdForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', Validators.maxLength(20)),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    });
    this.schoolInvUpdForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    });
    this.educationForm = this.formBuilder.group({
      is_college: new FormControl('', [Validators.required,]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(30)]),
    });
    this.schoolEdForm = this.formBuilder.group({
      interests: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    });
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
    this.collegeEdForm = this.formBuilder.group({
      minors: new FormControl(''),
      majors: new FormControl(''),
      start_date: new FormControl('', [Validators.required]),
      end_date: new FormControl('', [Validators.required]),
    });

    var url = document.URL.split('/')
    this.UserID = url[url.length - 1]
    this.profileService.OtherUserProfile(this.UserID)
      .subscribe(response => {
        if (response.message == "not-found"){
          this.router.navigate(['**']);
        } else if (response.message == "loggedin-user"){
          this.router.navigate(['profile/']);
        } else {
          this.currentUser = response.response;
          this.user = this.currentUser;
          this.profileHeader = this.mediaURL + this.user.header_image;
          this.profileHeader_name = this.user.header_image;
          this.profileImage = this.mediaURL + this.user.display_picture;
          this.profileImage_name = this.user.display_picture;
        }
      }, error => {
        this.router.navigate(['**']);
      })

    this.blockReportForm = this.formBuilder.group({
      block_user: new FormControl(),
      report_user: new FormControl(),
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl('', [Validators.maxLength(1000)])
    });
  }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.loggedInUser = currentUser;
    if (this.loggedInUser.user_role !== 2) {
      this.router.navigate(['**']);
    }
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    this.profileService.getDescription(this.UserID)
      .subscribe(response => {
        if (response.response['id'] !== undefined) {
          this.hasData = true;
          this.profile = response.response;
        }
      });
    this.CheckUserBlockStatus();
    this.getVerifiers();
    this.profileService.getSkills(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.skills = response.response;
        }
      })

    this.profileService.getInterests(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.interests = response.response;
        }
      })
    this.getActivities();
    this.profileService.getBooks(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasLibraryData = true;
          this.books = response.response;
        }
      })
    this.profileService.getBulletin(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasLibraryData = true;
          this.bulletins = response.response;
        }
      })

    this.authService.AccountVerificationStatus()
      .subscribe(response => {
        this.account_verification_status = response.response.account_verified
        this.days_left_to_verify = response.response.days_left
      });

    this.profileService.getOtherUserWorkExp("1", this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.usrWorkExperience = response.response;
        } else {
          this.usrWorkExperience = [];
        }
      });

    this.profileService.getOtherUserWorkExp("2", this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.usrVolunteerWorkExperience = response.response;
        } else {
          this.usrVolunteerWorkExperience = [];
        }
      });

    this.profileService.getOtherUserSchoolInvolvement(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.schoolInvolvements = response.response;
        } else {
          this.schoolInvolvements = [];
        }
      });

    this.profileService.otherStudentlistEducation(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.eduList = response.response;
        } else {
          this.eduList = [];
        }
      });


  }

  openSetting(settingModal: TemplateRef<any>) {
    this.reportUser = false;
    this.blockUserChecked = false;
    this.reportUserChecked = false;
    this.blockReportForm.reset();
    this.modalRef = this.modalService.show(settingModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  updBulletin() {
    if (this.bulletins.length > 0) {
      this.isLibrary = true;
      if (!this.slickModal){
        setTimeout(() => {
          if (!this.slickModal.initialized) {
            this.slickModal.initSlick();
          }
        }, 1000);
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    } else {
      this.isLibrary = false;
    }
  }

  disableEdit(e) {
    this.editable = !this.editable;
  }

  sendMessage(){
    this.profileService.createConversation({"user2": this.UserID})
    .subscribe(response => {
      if(response.status == 200) {
        this.router.navigate(['message-chat']);
      } else if (response.status < 200) {
        this.messageError = response.message;
      } else {
        this.messageError = "Something went wrong. Please try again later.";
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.submitted = false;
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
      this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1);
      this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2);
      this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3);
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  openMsgModal(msgModal: TemplateRef<any>) {
    this.sendMessage();
    this.modalRef = this.modalService.show(msgModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  openVerifiersModal(addWorkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addWorkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  resetEndDate() {
    this.activityUpdForm.controls['end_date'].setValue(null);
    this.searchForm.controls['end_date'].setValue(null);
  }

  resetStartDate() {
    this.activityUpdForm.controls['start_date'].setValue(null);
    this.searchForm.controls['start_date'].setValue(null);
  }

  showForm(isValid: boolean) {
    if (isValid) {
      this.formShow = !this.formShow;
    } else {
      this.partialSubmitted = true;
    }
  }

  showPreview(isValid: boolean) {
    if (isValid) {
      this.previewShow = !this.previewShow;
    } else {
      this.submitted = true;
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  reportReason() {
    this.reportUser = !this.reportUser;
    this.reportUserChecked = !this.reportUserChecked;
  }
  blockUser() {
    this.blockUserChecked = !this.blockUserChecked;
  }

  updateSkillModal(skillpopup: TemplateRef<any>, skill: any) {
    this.editSkill = skill;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateInterestModal(skillpopup: TemplateRef<any>, interest: any) {
    this.editInterest = interest;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  bookModal(template: TemplateRef<any>) {
    this.bookForm.reset();
    this.bookFrmsubmitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  getRating(e) {
    this.bookForm.controls['rating'].setValue(e);
  }

  updatebookModal(skillpopup: TemplateRef<any>, book: any) {
    this.editBook = book;
    this.bookForm.controls['description'].setValue(book.description);
    this.bookForm.controls['title'].setValue(book.title);
    this.bookForm.controls['author'].setValue(book.author);
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updatebulletinModal(bulletinDetail: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    this.modalRef = this.modalService.show(bulletinDetail,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal' })
    );
  }

  activityModal(activityTemplate: TemplateRef<any>) {
    this.previewShow = false;
    this.formShow = false;
    this.submitted = false;
    this.partialSubmitted = false;
    this.searchForm.reset();
    this.activityForm.reset();
    this.modalRef = this.modalService.show(activityTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateActivityModal(editActivityTemplate: TemplateRef<any>, activity: any) {
    this.editActivity = activity;
    this.activityUpdForm.controls['title'].setValue(activity.organization_title);
    this.activityUpdForm.controls['city'].setValue(activity.city);
    this.activityUpdForm.controls['position'].setValue(activity.position);
    this.activityUpdForm.controls['start_date'].setValue(activity.start_date);
    this.activityUpdForm.controls['end_date'].setValue(activity.end_date);
    this.activityUpdForm.controls['skill_1'].setValue(activity.skill_1);
    this.activityUpdForm.controls['skill_2'].setValue(activity.skill_2);
    this.activityUpdForm.controls['skill_3'].setValue(activity.skill_3);
    this.activityUpdForm.controls['main_role'].setValue(activity.main_role);
    this.activityUpdForm.controls['personal_details'].setValue(activity.personal_details);
    this.activityUpdForm.controls['zip_code'].setValue(activity.zip_code);
    this.modalRef = this.modalService.show(editActivityTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  getSkills() {
    this.profileService.getSkills(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.skills = response.response;
        } else {
          this.skills = [];
        }
      });
  }

  getInterests() {
    this.profileService.getInterests(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.interests = response.response;
        } else {
          this.interests = [];
        }
      });
  }

  getBooks() {
    this.profileService.getBooks(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.books = response.response;
        } else {
          this.books = [];
        }
      });
  }

  getBulletin() {
    this.profileService.getBulletin(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
        }
      });
  }

  getActivities() {
    this.profileService.getUserActivities(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.hasData = true;
          this.activities = response.response;
        } else {
          this.activities = [];
        }
      });
  }

  blockUnblockUser(isValid: boolean, id: any) {
    if (this.blockUserChecked) {
      if (this.isUserBlocked) {
        var block_user = false
      } else {
        var block_user = true
      }
      var data = { 'blocked_user': id, 'is_blocked': block_user }
      this.authService.BlockUserPost(data)
        .subscribe(response => {});
    }
    if (this.reportUserChecked) {
      var description;
      if (!this.blockReportForm.valid) {
        this.submitted = true;
        return
      }
      if (this.blockReportForm.value['report_description']) {
        description = this.blockReportForm.value['report_description']
      } else {
        description = '';
      }
      var report_data = {
        'reported_user': id, 'reason': this.blockReportForm.value['report_reason'],
        'description': description
      }
      this.authService.ReportUser(report_data).subscribe(respones => {});
    }
    this.modalRef.hide();
    window.location.reload();
  }

  CheckUserBlockStatus() {
    this.profileService.CheckUserBlockStatus(this.UserID)
      .subscribe(response => {
        this.isUserBlocked = response.response.is_user_blocked
      })
  }


  getVerifiers() {
    this.profileService.listOtherProfileVerifiers(this.UserID)
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      })
  }

  getEducationList(){
    this.profileService.listEducation()
    .subscribe(response => {
      if(response.response.length > 0){
          this.eduList = response.response;
        } else {
          this.eduList = [];
        }
      });
  }


  eduformUpdate(response) {
    this.disableSubmit = false;
    this.plchldrState = "PR";
    this.plchldrPostal = "Postal";
    this.insText = "School"
    this.isCollegeSelected = false;
    this.educationForm.controls['country'].setValue('Canada');
    this.educationForm.controls['is_college'].setValue("false");
    this.educationForm.controls['school_name'].setValue(response.school.school_name);
    this.educationForm.controls['state'].setValue(response.school.state);
    this.educationForm.controls['country'].setValue(response.school.country);
    let start_date;
    let end_date;
    if (this.editable) {
      start_date = new Date(response.start_date);
      end_date = new Date(response.end_date)
    } else {
      start_date = response.start_date;
      end_date = response.end_date
    }
    if (response.school.is_college) {
      this.educationForm.controls['is_college'].setValue("true");
      this.insText = "College/University";
      this.isCollegeSelected = true;
      this.collegeEdForm.controls['start_date'].setValue(start_date);
      this.collegeEdForm.controls['end_date'].setValue(end_date);
      this.collegeEdForm.controls['majors'].setValue(response.majors.toString());
      this.collegeEdForm.controls['minors'].setValue(response.minors.toString());
    } else {
      this.educationForm.controls['is_college'].setValue("false");
      this.insText = "School";
      this.isCollegeSelected = false;
      this.schoolEdForm.controls['start_date'].setValue(start_date);
      this.schoolEdForm.controls['end_date'].setValue(end_date);
      if (response.interests) {
        this.schoolEdForm.controls['interests'].setValue(response.interests.toString());
      } else {
        this.schoolEdForm.controls['interests'].setValue(response.interests);
      }
    }

    if (response.school.country == 'Canada') {
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
    } else {
      this.plchldrState = "State";
      this.plchldrPostal = "Zip Code";
    }

  }

  updateEducationPopup(staticEdPopup: TemplateRef<any>, ed_id: string) {
    let showModal;
    showModal = staticEdPopup;
    this.eduformUpdate(ed_id);
    this.modalRef = this.modalService.show(staticEdPopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  updateExpModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.orgId = null;
    this.businessImage = null;
    this.editExp = exp;
    if (exp.experience_type == 1) {
      this.expModalTitle = 'Work'
    }
    else {
      this.expModalTitle = 'Volunteer'
    }
    this.expUpdForm.controls['title'].setValue(exp.organization_title);
    this.expUpdForm.controls['city'].setValue(exp.city);
    this.expUpdForm.controls['position'].setValue(exp.position);
    this.expUpdForm.controls['start_date'].setValue(exp.start_date);
    this.expUpdForm.controls['end_date'].setValue(exp.end_date);
    this.expUpdForm.controls['skill_1'].setValue(exp.skill_1);
    this.expUpdForm.controls['skill_2'].setValue(exp.skill_2);
    this.expUpdForm.controls['skill_3'].setValue(exp.skill_3);
    this.expUpdForm.controls['main_role'].setValue(exp.main_role);
    this.expUpdForm.controls['personal_details'].setValue(exp.personal_details);
    this.expUpdForm.controls['zip_code'].setValue(exp.zip_code);
    this.modalRef = this.modalService.show(editExpTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateSchoolInvolvementModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.schoolId = null;
    this.pageImage = null;
    this.editSchoolInv = exp;
    this.schoolInvUpdForm.controls['title'].setValue(exp.title);
    this.schoolInvUpdForm.controls['city'].setValue(exp.city);
    this.schoolInvUpdForm.controls['position'].setValue(exp.position);
    this.schoolInvUpdForm.controls['start_date'].setValue(exp.start_date);
    this.schoolInvUpdForm.controls['end_date'].setValue(exp.end_date);
    this.schoolInvUpdForm.controls['skill_1'].setValue(exp.skill_1);
    this.schoolInvUpdForm.controls['skill_2'].setValue(exp.skill_2);
    this.schoolInvUpdForm.controls['skill_3'].setValue(exp.skill_3);
    this.schoolInvUpdForm.controls['main_role'].setValue(exp.main_role);
    this.schoolInvUpdForm.controls['personal_details'].setValue(exp.personal_details);
    this.modalRef = this.modalService.show(editExpTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  redirectPage(experience: any){
    if (experience.page && !experience.related_data.is_deleted && experience.verified_date && experience.approver_name) {
      let url = '/page/' + experience.page;
      if (experience.related_data.is_related) {
        url = '/page/' + experience.page + '/profile-school';
      }
      this.router.navigate([url]);
    }
  }

  redirectToPage(experience: any){
    if (experience.page && !experience.related_data.is_deleted && experience.verified_date && experience.approver_name) {
      let url = '/page/' + experience.page;
      if (experience.related_data.is_related) {
        url = '/page/' + experience.page + '/profile-business';
      }
      this.router.navigate([url]);
    }
  }
  openStudent(studentModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(studentModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openVolunteer(volunteerModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(volunteerModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openSchool(schoolModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolModal, Object.assign({}, { class: 'profile-modal' }));
  }

}

import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../services/profile.service';


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  faqList: any[] = [];

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.getRestrictedWebsites();
  }

  getRestrictedWebsites(){
    this.profileService.listFAQs()
    .subscribe(response => {
      if(response.response.length > 0){
          this.faqList = response.response;
        } else {
          this.faqList = [];
        }
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { SearchService } from '../../services/search.service';
import { AuthenticationService } from '../../services/authentication.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../services/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.scss']
})
export class StudentSearchComponent implements OnInit {
  mediaURL = environment.mediaURL;
  user: any = {};
  verifiers = [];
  searchForm: FormGroup; 
  public settingShow = true;
  studentNextPage: any;
  totalStudents: any;
  students: any[] = [];
  loading: boolean = false;

  constructor(private searchService: SearchService, public formBuilder: FormBuilder, private authService: AuthenticationService,
    private profileService: ProfileService, private router: Router) {
      if (localStorage.getItem('user')) {
        const currentUser = JSON.parse(localStorage.getItem('user'));
        this.user = currentUser;
      } else {
        this.router.navigate(['/']);
      }
      this.getVerifiers();
      this.searchForm = this.formBuilder.group({
        name: new FormControl('', [Validators.maxLength(50)]),
        school: new FormControl('', [Validators.maxLength(50)]),
        skill_interest: new FormControl('', [Validators.maxLength(50)]),
        business: new FormControl('', [Validators.maxLength(50)]),
        club_team: new FormControl('', [Validators.maxLength(50)]),
        books: new FormControl('', [Validators.maxLength(50)])
      });
  }

  ngOnInit(): void {
    this.studentSearch();
  }

  getVerifiers(){
    this.profileService.listVerifiers()
    .subscribe(response => {
      if (response.response.length > 0) {
        let verifiers = response.response;
        this.verifiers = verifiers.filter(obj => obj.status === 2);
      }
      if (this.user.age_group !== 1 && this.user.user_role !== 2 && (this.verifiers.length < 5 || !this.user.approval_status)) {
        this.router.navigate(['/']);
      }
    })
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  search(isValid: boolean) {
    if (isValid) {
      this.studentSearch();
    }
    this.settingShow = false;
  }

  setParams() {
    let name = this.searchForm.get('name').value;
    let school = this.searchForm.get('school').value;
    let skill = this.searchForm.get('skill_interest').value;
    let business = this.searchForm.get('business').value;
    let club_team = this.searchForm.get('club_team').value;
    let book = this.searchForm.get('books').value;
    let params = "?page=1";
    if (name !== null && name !== "") {
      params += "&name=" + name;
    }
    if (school !== null && school !== "") {
      params += "&school=" + school;
    }
    if (skill !== null && skill !== "") {
      params += "&skill=" + skill;
    }
    if (business !== null && business !== "") {
      params += "&business=" + business;
    }
    if (club_team !== null && club_team !== "") {
      params += "&club_team=" + club_team;
    }
    if (book !== null && book !== "") {
      params += "&book=" + book;
    }
    return params;
  }

  studentSearch() {
    let params = this.setParams();
    this.searchService.studentSearch(params)
    .subscribe(data => {
      this.students = data.response.results;
      this.studentNextPage = data.response.next;
      this.totalStudents = data.response.total_count;
    })
  }

  onScrollDown() {
    if (this.studentNextPage != null && !this.loading) {
      this.loading = true;
      let params = this.setScrollParams();
      this.searchService.studentSearch(params)
        .subscribe(data => {
          this.students = this.students.concat(data.response.results);
          this.studentNextPage = data.response.next;
          this.totalStudents = data.response.total_count;
          this.loading = false;
        })
    }
  }

  setScrollParams() {
    let name = this.searchForm.get('name').value;
    let school = this.searchForm.get('school').value;
    let skill = this.searchForm.get('skill_interest').value;
    let business = this.searchForm.get('business').value;
    let club_team = this.searchForm.get('club_team').value;
    let book = this.searchForm.get('books').value;
    let params = "?page=" + this.studentNextPage;
    if (name !== null && name !== "") {
      params += "&name=" + name;
    }
    if (school !== null && school !== "") {
      params += "&school=" + school;
    }
    if (skill !== null && skill !== "") {
      params += "&skill=" + skill;
    }
    if (business !== null && business !== "") {
      params += "&business=" + business;
    }
    if (club_team !== null && club_team !== "") {
      params += "&club_team=" + club_team;
    }
    if (book !== null && book !== "") {
      params += "&book=" + book;
    }
    return params;
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseProfileEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;

  constructor(private http: BaseService) { }

  businessProfileSearch(org_type: string, params: string) {
    return this.http.get(this.baseProfileUrl + 'search-profiles/' + org_type + '/' + params);
  }

  searchExperienceEmployment(params: string){
    return this.http.get(this.baseProfileUrl + 'search-experience-employment/' + params)
  }

  searchExperienceVolunteer(params: string){
    return this.http.get(this.baseProfileUrl + 'search-experience-volunteer/' + params)
  }
  
  schoolProfileSearch(params: string) {
    return this.http.get(this.baseProfileUrl + 'search-school-profiles/' + params);
  }

  studentSearch(params: string) {
    return this.http.get(this.baseProfileUrl + 'search-students/' + params);
  }

  getJobList(page_id:any) {
    return this.http.get(this.baseProfileUrl + 'get-pages-jobs/' + 1 + '/page/' + page_id)
  }

  getVolunteerJobList(page_id:any) {
    return this.http.get(this.baseProfileUrl + 'get-pages-jobs/' + 2 + '/page/' + page_id)
  }

  getJobDetails(job_id:any) {
    return this.http.get(this.baseProfileUrl + 'get-job-details/' + 1 + '/job/' + job_id)
  }

  getVolunteerJobDetails(job_id:any) {
    return this.http.get(this.baseProfileUrl + 'get-job-details/' + 2 + '/job/' + job_id)
  }
}

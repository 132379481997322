import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpHeaders } from '@angular/common/http';

import { ReportUserEndPoint, StudentSignupEndPoint, BusinessSignupEndPoint, SchoolSignupEndPoint, ChangeParentEmailEndPoint, ResendVerificationEndPoint, AccountVerificationEndPoint, SchoolSearchEndPoint, SchoolUserEndPoint, BusinessPageEndPoint, SchoolPageEndPoint,
  listPagesEndPoint , uniqueProfileUrlEndPoint, PageDetailEndPoint, NotificationlEndPoint, PageImageEndPoint, AccountVerificationStatusEndPoint, ProfilePrivacySettingEndPoint, BlockUserEndPoint, staticContentEndPoint,
  BaseProfileEndPoint } from '../app.config';
import { User } from '../shared/user';
import { BusinessUser } from '../shared/businessUser';
import { schoolPage } from '../shared/schoolPage';
import { schoolUserPage } from '../shared/schoolUserPage';
import { BusinessPage } from '../shared/businessPage';
import { School } from '../shared/school';
import { SchoolUser } from '../shared/schoolUser';
import { unqiueProfile } from '../shared/uniqueProfile';
import { BaseAccountEndPoint } from '../app.config';
import { ParsedVariable } from '@angular/compiler';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private userSignupUrl: string = StudentSignupEndPoint.apiEndpoint;
  private businessSignupUrl: string = BusinessSignupEndPoint.apiEndpoint;
  private schoolSignupUrl: string = SchoolSignupEndPoint.apiEndpoint;
  private accountVerificationUrl: string = AccountVerificationEndPoint.apiEndpoint;
  private schoolSearchEndPoint: string = SchoolSearchEndPoint.apiEndpoint;
  private schoolUserEndPoint: string = SchoolUserEndPoint.apiEndpoint;
  private resendVerifyEndPoint: string = ResendVerificationEndPoint.apiEndpoint;
  private changeParentEmailEndPoint: string = ChangeParentEmailEndPoint.apiEndpoint;
  private baseAccountsUrl: string = BaseAccountEndPoint.apiEndpoint;
  private businessPageUrl: string = BusinessPageEndPoint.apiEndpoint;
  private schoolPageUrl: string = SchoolPageEndPoint.apiEndpoint;
  private listPagesUrl: string = listPagesEndPoint.apiEndpoint;
  private uniqueProfileUrl: string = uniqueProfileUrlEndPoint.apiEndpoint;
  private PageDetailUrl: string = PageDetailEndPoint.apiEndpoint;
  private NotificationUrl: string = NotificationlEndPoint.apiEndpoint;
  private PageImageUrl: string = PageImageEndPoint.apiEndpoint;
  private AccountVerificationStatusUrl: string = AccountVerificationStatusEndPoint.apiEndpoint;
  private ProfilePrivacySettingUrl: string = ProfilePrivacySettingEndPoint.apiEndpoint
  private BlockUserUrl: string = BlockUserEndPoint.apiEndpoint 
  private ReportUserUrl: string = ReportUserEndPoint.apiEndpoint
  private staticContentUrl: string = staticContentEndPoint.apiEndpoint

  private ProfileUrl: string = BaseProfileEndPoint.apiEndpoint
  
  constructor(private http: BaseService) { }

  userSignup(user: User) {
    return this.http.post(this.userSignupUrl, user);
  }

  invitedUserSignup(user: User, params: any) {
    return this.http.post(this.userSignupUrl + params, user);
  }

  businessUserSignup(businessUser: BusinessUser) {
    return this.http.post(this.businessSignupUrl, businessUser);
  }

  schoolSignup(school: School) {
    return this.http.post(this.schoolSignupUrl, school);
  }

  verifyAccount(params: any) {
    return this.http.get(this.accountVerificationUrl + params);
  }

  searchSchool(params: any) {
    return this.http.get(this.schoolSearchEndPoint + params);
  }

  schoolUserSignup(schoolUser: SchoolUser) {
    return this.http.post(this.schoolUserEndPoint, schoolUser);
  }

  resendVerify(data: any) {
    return this.http.post(this.resendVerifyEndPoint, data);
  }

  changeParentEmail(data: any) {
    return this.http.put(this.changeParentEmailEndPoint, data);
  }

  verifyInviteCode(params: any) {
    return this.http.get(this.baseAccountsUrl + 'verify-code/' + params + '/');
  }

  businessPage(businessPage: BusinessPage) {
    return this.http.post(this.businessPageUrl, businessPage);
  }

  createSchoolPage(school: schoolPage) {
    return this.http.post(this.schoolPageUrl, school);
  }

  createSchoolUserPage(schoolUser: schoolUserPage) {
    return this.http.post(this.businessPageUrl, schoolUser);
  }

  listPages(params: any) {
      return this.http.get(this.listPagesUrl + params );
  }

  setupUniqueProfile(profile: unqiueProfile) {
    return this.http.put(this.uniqueProfileUrl, profile);
  }

  PageDetails(id: any) {
    return this.http.get(this.PageDetailUrl + id + '/');
  }
  
  UpdatePageBusiness(data: any, id: any) {
    return this.http.put(this.PageDetailUrl + id + '/', data);
  }

  verifyProfile(params: any) {
    return this.http.get(this.accountVerificationUrl + params);
  }

  verifyPage(params: any) {
    return this.http.get(this.accountVerificationUrl + params);
  }
  
  notifications() {
    return this.http.get(this.NotificationUrl);
  }

  PageImg(data: any, id: any) {
    return this.http.put(this.PageImageUrl + id + '/', data);
  }

  AccountVerificationStatus() {
    return this.http.get(this.AccountVerificationStatusUrl);
  }

  ProfilePrivacySettingGet() {
    return this.http.get(this.ProfilePrivacySettingUrl);
  }

  ProfilePrivacySettingPost(privacydata:any) {
    let headers = new HttpHeaders();
    headers = headers.set('content-type', 'application/json');
    return this.http.post(this.ProfilePrivacySettingUrl, privacydata, {headers : headers});
  }
  
  BlockUserGet(){
    return this.http.get(this.BlockUserUrl);
  }

  BlockUserPost(blocked_user:any){
    return this.http.post(this.BlockUserUrl, blocked_user);
  }

  ReportUser(reported_user){
    return this.http.post(this.ReportUserUrl, reported_user);
  }

  staticContent(data){
    let headers = new HttpHeaders();
    const requestOptions: Object = {
      /* other options here */
      responseType: 'text'
    }
    return this.http.get(this.staticContentUrl + '?page=' + data, requestOptions);
  }

  getTimeline(year?: any){
    return this.http.get(this.ProfileUrl + 'timeline/' +  '?year=' + year)
  }

  validateEmail(obj: any){
    return this.http.post(this.ProfileUrl + 'validate-email/', obj);
  }

}

import { Component, OnInit, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as atlas from 'azure-maps-control';
import { AuthenticationType } from 'azure-maps-control';
import { ProfileService } from '../../services/profile.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll'
import { PageService } from '../../services/page.service';
import { EventService } from '../../services/event.service';
import { IEvent } from 'src/app/shared/event';
import { CarouselConfig } from 'ngx-bootstrap';
import { AuthenticationService } from '../../services/authentication.service';
import { environment } from '../../../environments/environment';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import { Subject } from 'rxjs';
import { SlickCarouselComponent } from "ngx-slick-carousel";

const colors: any = {
  calColor: {
    primary: '#339999 ',
    secondary: '#339999',
  }
};

@Component({
  selector: 'app-business-profile',
  templateUrl: './business-profile.component.html',
  styleUrls: ['./business-profile.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class BusinessProfileComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  viewDate: Date = new Date();
  mediaURL = environment.mediaURL;
  map: any;
  key: string = environment.subscriptionKeyAzure;
  modalRef: BsModalRef;
  user: any = {};
  events: CalendarEvent[] = [];
  responseEvent: IEvent[] = [];
  exec_members = [];
  refresh: Subject<any> = new Subject();
  teachers = [];
  editTeacher: any = {};
  editBulletin: any = {};
  editCSR: any = {};
  profile: any = {};
  bulletins = [];
  schoolInvolvementMembers = [];
  csrs = [];
  ourTeam = [];
  jobPostings = [];
  volunteerPostings = [];
  uploadForm: FormGroup;
  bulletinForm: FormGroup;
  descriptionForm: FormGroup;
  csrForm: FormGroup;
  showLocation: boolean = true;
  isNPO: boolean = false;
  isClubTeam: boolean = false;
  hasData: boolean = false;
  page_id: any;
  pagesDetails: any = {};
  jobs: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  page_Image_name : string = "";
  page_Image: string = "";
  pageHeaderImg: string = "";
  pageHeaderImgName: string = "";
  activeDayIsOpen: boolean = false; 
  businessWorkingHours: any;
  isLibrary: boolean = false;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;
  
  constructor(private eventService: EventService, private router: Router, private modalService: BsModalService, public formBuilder: FormBuilder,private route: ActivatedRoute, private profileService: ProfileService, private authService: AuthenticationService,
    private pageService: PageService) {}

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.pageService.getPageDetails(this.page_id)
        .subscribe(response => {
          if (response.status === 200) {
            this.pagesDetails = response.response;
            this.showLocation = response.response.display_location;
            this.page_Image = this.mediaURL + this.pagesDetails.display_pic;
            this.page_Image_name = this.pagesDetails.display_pic;
            this.pageHeaderImg =  this.mediaURL + this.pagesDetails.header_img;
            this.pageHeaderImgName = this.pagesDetails.header_img;
            this.businessWorkingHours = this.pagesDetails.business_working_hours;
            if (this.pagesDetails.page_type == 2){
              this.isNPO = true;
              this.getVolunteerList();
              this.searchMap();
            }
            else if (this.pagesDetails.page_type == 3 || this.pagesDetails.page_type == 4 ) {
              this.isClubTeam = true;
              this.getExecTeam();
              this.getTeachers();
              this.getEvents();
            } else {
              this.searchMap();
            }
          } else {
            this.router.navigate(['/dashboard']);
          }
      });
    this.getBulletin();
    this.getCSR();
    this.getTeamList();
    this.getJobList();
    this.pageService.getPageDescription(this.page_id)
    .subscribe(response => {
      if(response.response['id'] !== undefined){
        this.hasData = true;
        this.profile = response.response;
      }
    })

    this.profileService.UserSchoolInvolvementMembers(this.page_id).subscribe(response => {
      if (response.response.length > 0){
        this.hasData = true;
        this.schoolInvolvementMembers = response.response;
      } else {
        this.schoolInvolvementMembers = [];
      }
    })
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive":[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  updBulletin() {
    if (this.bulletins.length > 0) {
      this.isLibrary = true;
      if (!this.slickModal){
        setTimeout(() => {
          if (!this.slickModal.initialized) {
            this.slickModal.initSlick();
          }
        }, 1000);
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    } else {
      this.isLibrary = false;
    }
  }

  getBulletin(){
    this.pageService.getPageBulletin(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
        }
      });
  }

  getCSR(){
    this.profileService.getCSR(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.csrs = response.response;
        } else {
          this.csrs = [];
        }
      });
  }

  getTeamList(){
    this.profileService.listTeam(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.ourTeam = response.response;
        } else {
          this.ourTeam = [];
        }
      });
  }

  getJobList(){
    this.profileService.listJobs(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.jobPostings = response.response;
        } else {
          this.jobPostings = [];
        }
      });
  }

  getVolunteerList() {
    this.profileService.listVolunteerJobs(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.volunteerPostings = response.response;
        } else {
          this.volunteerPostings = [];
        }
      });
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg","jpeg","png","JPG","JPEG","PNG"];
    if(allowedExtensions.includes(fileExtension)) {
        return true
    } else {
      return false
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal' })
    );
  }

  openJobModal(template: TemplateRef<any>, jobs: any) {
    this.jobs = jobs;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal' })
    );
  }

  updateAboutModal(aboutModal: TemplateRef<any>, teacher: any) {
    this.editTeacher = teacher;
    this.modalRef = this.modalService.show(aboutModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink;
    }
  }

  getExecTeam(){
    this.profileService.getSchoolExecTeam(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.exec_members = response.response;
        } else {
          this.exec_members = [];
        }
      });
  }

  getTeachers(){
    this.profileService.getSchoolTeachers(this.page_id)
    .subscribe(response => {
      if(response.response.length > 0){
          this.hasData = true;
          this.teachers = response.response;
        } else {
          this.teachers = [];
        }
      });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }


  getEvents(){
    let events: CalendarEvent[] = [];
    let month = (this.viewDate.getMonth() + 1).toString();
    let year = this.viewDate.getFullYear().toString();
    this.eventService.GetOtherUserEventList(this.page_id, month, year)
    .subscribe(response => {
      if (response.response.length > 0) {
        this.hasData = true;
      }
      this.responseEvent = response.response;
      let today = new Date();
      this.responseEvent.forEach(day_event => {
        let start_date = day_event.start_date.split("-");
        let start_date_day = Number(start_date[2]);
        let start_date_month = Number(start_date[1]) - 1;
        let start_date_year = Number(start_date[0]);
        let end_date = day_event.start_date.split("-");
        let end_date_day = Number(end_date[2]);
        let end_date_month = Number(end_date[1]) - 1;
        let end_date_year = Number(end_date[0]);
        let start = new Date(start_date_year, start_date_month, start_date_day);
        let end = new Date(end_date_year, end_date_month, end_date_day);
        if (today.toDateString() == start.toDateString() || today.toDateString() == end.toDateString()){
          this.activeDayIsOpen = true;
        }
        events.push({
          'start': start, 
          'end': end,
          'title': day_event.title,
          'color': colors.calColor,
          'meta': {
            'id': day_event.id
          }
        })
      })
      this.events = events;
      this.refresh.next();
    })
  }

  searchMap() {
    setTimeout(() => {
      this.map = new atlas.Map('mapContainer',
        {
          center: [-79.347015, 43.651070],
          zoom: 11,
          language: 'en-US',
          showLogo: false,
          showFeedbackLink: false,
          authOptions: {
            authType: AuthenticationType.subscriptionKey,
            subscriptionKey: this.key
          }
        });
      var marker = new atlas.HtmlMarker({
        color: '#D25557',
        text: '',
        position: [this.pagesDetails.business.longitude, this.pagesDetails.business.latitude],
        popup: new atlas.Popup({
          content: '<div style="padding:10px">' + this.pagesDetails.page_info.title + '</div>',
          pixelOffset: [0, -30]
        })
      });
      this.map.markers.add(marker);
      this.map.events.add('click', marker, () => {
        marker.togglePopup();
      });
      this.map.setCamera({
        center: [this.pagesDetails.business.longitude, this.pagesDetails.business.latitude],
        zoom: 11
      });
    }, 1000);
  }

  openStudent(studentModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(studentModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openVolunteer(volunteerModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(volunteerModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openSchool(schoolModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolModal, Object.assign({}, { class: 'profile-modal' }));
  }
}

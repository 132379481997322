import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-need-help',
  templateUrl: './need-help.component.html',
  styleUrls: ['./need-help.component.scss']
})
export class NeedHelpComponent implements OnInit {

  user: any = {};

  constructor(private modalService: BsModalService) { }
  modalRef: BsModalRef;
  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
  }
  openStudent(studentModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(studentModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openVolunteer(volunteerModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(volunteerModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openSchool(schoolModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolModal, Object.assign({}, { class: 'profile-modal' }));
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayOfWeekAsStringTransform' })
export class dayOfWeekAsString implements PipeTransform {
  transform(day: any): any {
    
var dayOfWeekAsString = function(a) {
    return ["Monday","Tuesday","Wednesday","Thursday","Friday","Saturday","Sunday"][a-1] || '';
};
    return dayOfWeekAsString(day)
  }
}
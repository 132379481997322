import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ProfileService } from '../services/profile.service';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IContactUs } from '../shared/contactUs'
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CustomvalidationService } from '../services/customvalidation.service';
import { AuthenticationService } from '../services/authentication.service';
const mediaURL = environment.mediaURL;

@Component({
  selector: 'app-otheruser-need-help',
  templateUrl: './otheruser-need-help.component.html',
  styleUrls: ['./otheruser-need-help.component.scss']
})
export class OtheruserNeedHelpComponent implements OnInit {
  profileImage: string;
  profileImage_name: string;
  profileHeader: string = "";
  profileHeader_name: string = "";
  UserID: any = {};
  user: any = {};
  isUserBlocked: any;
  loggedInUser: any = {};
  currentUser: any;
  reportUser: boolean = false;
  infoSlide: boolean = false;
  blockUserChecked: boolean = false;
  reportUserChecked: boolean = false;
  contactUsForm: FormGroup;
  submitted: boolean = false;
  displayColClass: boolean = true
  displayColClass4: boolean;
  modalRef: BsModalRef;
  disableSubmit: boolean = false;
  hideNameEmail: boolean = false;
  blockReportForm: FormGroup;
  messageError: string;

  constructor(private authService: AuthenticationService, private modalService: BsModalService,  public formBuilder: FormBuilder,private customValidator: CustomvalidationService,
    private profileService: ProfileService, private router: Router) {
    this.contactUsForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      message: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      subject: new FormControl('', [Validators.required, Validators.maxLength(100),]),
    });

    this.blockReportForm = this.formBuilder.group({
      block_user: new FormControl(),
      report_user: new FormControl(),
      report_reason: new FormControl('', [Validators.required]),
      report_description: new FormControl(),
    });
   }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.loggedInUser = currentUser;
    if (this.loggedInUser.user_role !== 2) {
      this.router.navigate(['**']);
    }
    this.displayColClass4 = this.displayColClass;
    var url = document.URL.split('/');
    this.UserID = url[url.length-1];
    this.contactFormInit();
    this.CheckUserBlockStatus();
    this.profileService.OtherUserProfile(this.UserID)
    .subscribe(response => {
      this.currentUser = response.response;
      this.user = this.currentUser;
      this.profileHeader = mediaURL + this.user.header_image
      this.profileHeader_name = this.user.header_image
      this.profileImage = mediaURL + this.user.display_picture;
      this.profileImage_name = this.user.display_picture;
    })
  }
  openStudent(studentModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(studentModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openBusiness(businessModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(businessModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openVolunteer(volunteerModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(volunteerModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openSchool(schoolModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(schoolModal, Object.assign({}, { class: 'profile-modal' }));
  }
  openSetting(settingModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(settingModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }

  submitContactUs(successModal: TemplateRef<any>){
    if (!this.contactUsForm.valid){
      this.submitted = true;
      return
    }
    this.disableSubmit = true;
    this.submitted = false;
    let contactObj: IContactUs = {
        'name': this.contactUsForm.controls['name'].value,
        'email': this.contactUsForm.controls['email'].value,
        'subject': this.contactUsForm.controls['subject'].value,
        'message': this.contactUsForm.controls['message'].value,
    }
    this.profileService.contactUsPost(contactObj)
      .subscribe(response => {
        this.handleContactSubmit(response, successModal);
      }, error => {
        this.handleContactError(error.error);
      });
  }

  contactFormInit() {
    this.contactUsForm.reset();
    this.submitted = false;
    if (localStorage.user){
      let user_ob = JSON.parse(localStorage.user);
      let email = user_ob.email;
      let full_name = user_ob.first_name + " " + user_ob.last_name;
      this.contactUsForm.controls['email'].setValue(email);
      this.contactUsForm.controls['name'].setValue(full_name);
      this.hideNameEmail = true;
      this.displayColClass4 = false;
    } else {
      this.hideNameEmail = false;
      this.displayColClass4 = true;
    }
  }

  protected handleContactSubmit(response: any, successModal: TemplateRef<any>) {
    if (response.status === 111) {
      this.handleContactError(response.message);
    } else if (response.status === 200) {
        this.disableSubmit = false;
        this.contactFormInit();
        this.modalRef = this.modalService.show(
          successModal, Object.assign({}, { 
            class: 'modal-dialog-centered custom-modal'}));
        
    }
  }

  openMsgModal(msgModal: TemplateRef<any>) {
    this.sendMessage();
    this.modalRef = this.modalService.show(msgModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  sendMessage(){
    this.profileService.createConversation({"user2": this.UserID})
    .subscribe(response => {
      if(response.status == 200) {
        this.router.navigate(['message-chat']);
      } else if (response.status < 200) {
        this.messageError = response.message;
      } else {
        this.messageError = "Something went wrong. Please try again later.";
      }
    });
  }


  protected handleContactError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.contactUsForm.contains(field)) {
        this.contactUsForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  reportReason() {
    this.reportUser = true;
    if (this.reportUserChecked == false) {
      this.reportUserChecked = true;
    }
    else if (this.reportUserChecked) {
      this.reportUserChecked = false;
    }
  }
  blockUser() {
    this.reportUser = false;
    if (this.blockUserChecked == false) {
      this.blockUserChecked = true;
    }
    else if (this.blockUserChecked) {
      this.blockUserChecked = false;
    }
  }

  blockUnblockUser(id: any) {
    if (this.blockUserChecked) {
      if (this.isUserBlocked) {
        var block_user = false
      }
      else {
        var block_user = true
      }
      var data = { 'blocked_user': id, 'is_blocked': block_user }
      this.authService.BlockUserPost(data)
        .subscribe(response => {

        });
    }

    if (this.reportUserChecked) {
      var description;
      if (!this.blockReportForm.valid) {
        this.submitted = true;
        return
      }
      if (this.blockReportForm.value['report_description']) {
        description = this.blockReportForm.value['report_description']
      }
      else {
        description = ''
      }
      var report_data = {
        'reported_user': id, 'reason': this.blockReportForm.value['report_reason'],
        'description': description
      }
      this.authService.ReportUser(report_data).subscribe(respones => {

      })

    }
    window.location.reload()
  }

  CheckUserBlockStatus() {
    this.profileService.CheckUserBlockStatus(this.UserID)
      .subscribe(response => {
        this.isUserBlocked = response.response.is_user_blocked
      })
  }
}

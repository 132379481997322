import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';
import { environment } from '../../environments/environment';

const mediaURL = environment.mediaURL;


@Component({
  selector: 'app-business-listing',
  templateUrl: './business-listing.component.html',
  styleUrls: ['./business-listing.component.scss']
})
export class BusinessListingComponent implements OnInit {
  settingShow: boolean = true;
  pageslist: any = []
  params: string = "";
  loggedIn: boolean = false;
  user: any = {};
  day: string = "";
  url: string = ""
  headerImage: string = ""
  headerImage_name: string = ""
  displayImage: string = ""
  displayImage_name: string = ""

  constructor(private authService: AuthenticationService, private router: Router) { }

  ngOnInit(): void {
    this.url = mediaURL
    this.day = new Date().toLocaleString('en-us', { weekday: 'long' })
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      this.headerImage = mediaURL + this.user.header_image
      this.headerImage_name = this.user.header_image
      this.displayImage = mediaURL + this.user.display_picture
      this.displayImage_name = this.user.display_picture
    }
    this.authService.listPages(this.params)
      .subscribe(response => {
        this.pageslist = response.response
      });

  }

  OpenPage(pageid: string, pagetype: string) {
    if (pagetype === 'business') {
      this.router.navigate(['page/' + pageid + '/profile-business']);
    } else {
      this.router.navigate(['page/' + pageid + '/profile-school']);
    }

  }

  OpenPageSettings(pageid: string) {
    this.router.navigate(['page/' + pageid + '/setting']);
  }
  redirectToProfile() {
    if (this.user.user_role == "2") {
      this.router.navigate(['/profile']);
    }
  }
}

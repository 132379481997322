import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-community-guidelines',
  templateUrl: './community-guidelines.component.html',
  styleUrls: ['./community-guidelines.component.scss']
})
export class CommunityGuidelinesComponent implements OnInit {
  communityGuidelines: any = "guidelines";
  communityGuidelinesContent: any
  constructor(private authService: AuthenticationService) { }

  ngOnInit(): void {
    this.authService.staticContent(this.communityGuidelines)
    .subscribe(response => {
      this.communityGuidelinesContent = response
    });
  }

}

import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { BaseAccountEndPoint, BaseProfileEndPoint } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  private baseAccountsUrl: string = BaseAccountEndPoint.apiEndpoint;
  private baseProfileUrl: string = BaseProfileEndPoint.apiEndpoint;

  constructor(private http: BaseService) { }

  addAdmin(obj: any) {
    return this.http.post(this.baseAccountsUrl + 'send-invite/', obj);
  }

  getPageAdminInvites(id: string) {
    return this.http.get(this.baseAccountsUrl + 'get-page-invites/' + id + '/');
  }

  getUserInvites() {
    return this.http.get(this.baseAccountsUrl + 'get-user-invites/');
  }

  deletePageAdmin(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'delete-page-admin/' + id + '/', obj);
  }

  cancelPageAdmin(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'cancel-invite/' + id + '/', obj);
  }

  transferPageOwnership(id: string, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'change-page-owner/' + id + '/', obj);
  }

  inviteResponse(id: string, params: any, obj: any) {
    return this.http.put(this.baseAccountsUrl + 'invite-response/' + id + '/?approve=' + params, obj);
  }

  addPageBulletin(userBulletin: any) {
    return this.http.post(this.baseProfileUrl + 'add-page-bulletin/', userBulletin);
  }

  getPageBulletin(id: string) {
    return this.http.get(this.baseProfileUrl + 'page-bulletin/' + id + '/');
  }

  updatePageBulletin(userBulletin: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'update-page-bulletin/' + id + '/', userBulletin);
  }

  deletePageBulletin(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-page-bulletin/' + id + '/');
  }

  addPageDescription(desc: any) {
    return this.http.post(this.baseProfileUrl + 'add-page-description/', desc);
  }

  getPageDescription(id: string) {
    return this.http.get(this.baseProfileUrl + 'page-description/' + id + '/');
  }

  updatePageDescription(desc: any, id: string) {
    return this.http.put(this.baseProfileUrl + 'update-page-description/' + id + '/', desc);
  }

  deletePageDescription(id: string) {
    return this.http.delete(this.baseProfileUrl + 'update-page-description/' + id + '/');
  }

  getPageExperiences(page_id: string, id: string) {
    return this.http.get(this.baseProfileUrl + 'page-experiences/' + page_id + '/' + id + '/');
  }

  getSchoolPageExperiences(page_id: string) {
    return this.http.get(this.baseProfileUrl + 'clubteam-page-experiences/' + page_id + '/');
  }

  verifyPageExperiences(obj: any, params: any) {
    return this.http.put(this.baseProfileUrl + 'experience-verification/' + params, obj);
  }

  verifyClubTeam(obj: any, params: any) {
    return this.http.put(this.baseProfileUrl + 'clubteam-verification/' + params, obj);
  }

  getPageDetails(page_id: string) {
    return this.http.get(this.baseAccountsUrl + 'page-details/' + page_id + '/');
  }

  getSchoolDetails(school_id: string) {
    return this.http.get(this.baseAccountsUrl + 'school-details/' + school_id + '/');
  }

  addBusinessWorkinghours(data: any) {
    return this.http.post(this.baseAccountsUrl + 'businesswh/', data);
  }
  
  deleteBusinessForm(id: any,data: any) {
    return this.http.put(this.baseAccountsUrl + 'delete-bwh/'+ id + '/',data);
  }

  updateBusinessForm(id: any,data: any) {
    return this.http.put(this.baseAccountsUrl + 'businesswh/'+ id + '/',data);
  }

  updatePageLocationDisplay(id:any, params:any){
    return this.http.put(this.baseAccountsUrl + 'update-display-location/' + id + '/', params)
  }

  updateSchoolImage(data: any, id: any) {
    return this.http.put(this.baseAccountsUrl + 'school-profile/' + id + '/', data);
  }

  deleteSchoolImage(data: any, id: any) {
    return this.http.put(this.baseAccountsUrl + 'school-image/' + id + '/', data);
  }
}

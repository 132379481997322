import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';

import { EventService } from "../services/event.service"

@Component({
  selector: 'app-azure-maps',
  templateUrl: './azure-maps.component.html',
  styleUrls: ['./azure-maps.component.scss']
})
export class AzureMapsComponent implements OnInit {

  @Input() countrySet: string = 'CA,US';
  @Input() updateAddress: string = '';
  @Input() placeHolder: string = 'Full Address';
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() setHideResult: EventEmitter<boolean> = new EventEmitter();

  results?: any[] = [];
  queryWait: boolean;

  constructor(private eventService: EventService) { }

  ngOnInit(): void {
  }

  placeSearched(e) {
    if(e.target.value==null || e.target.value.length<=3){
      return;
    }
    this.eventService.getSearchAzure(e.target.value, this.countrySet)
    .subscribe(response => {
      this.results = response.results;
      this.setAddress.emit(this.results);
      this.setHideResult.emit(false);
      });
  }

}

<h3>Our Team
    <a *ngIf="!editable && pageVerified != '3'" href="javascript:void()" class="float-right" (click)="openTeamModal(teamTemplate)">
        <img  alt="image" src="assets/images/plus-icon.png" alt="">
    </a>
    <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
        <img  alt="image" src="assets/images/icEdit.svg">
    </a>
</h3>
<carousel *ngIf="ourTeam?.length > 0">
    <slide *ngFor="let team_member of ourTeam">
        <div class="box-div inner-exp">
            <div class="experience-div media cursor-pointer" (click)="updateTeamModal(teamTemplate, teamTemplateStatic, team_member)">
                <img height="130px" width="130px" *ngIf="team_member.profile_image" src="{{team_member.profile_image}}">
                <div *ngIf="!team_member.profile_image">
                    <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <title>Artboard</title>
                        <desc>Created with Sketch.</desc>
                        <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <rect id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130" rx="12"></rect>
                            <g id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF" fill-rule="nonzero">
                                <path d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                    id="Shape"></path>
                            </g>
                        </g>
                    </svg>
                </div>
                <div class="media-body ml-2">
                    <h4 class="mt-md-1">{{team_member.first_name}} {{team_member.last_name}}</h4>
                    <p>{{team_member.position}}</p>
                    <h5>Start
                        <span>{{team_member.start_year}}</span>
                    </h5>
                </div>
            </div>
        </div>
    </slide>
</carousel>
<div *ngIf="ourTeam?.length == 0" class="box-div p-0 my-2">
    <div class="empty-div text-center h-100 d-flex align-items-center justify-content-center py-0 mt-4">
        <p>Add a Bio for Managers, Admin.</p>
    </div>
</div>
<ng-template #teamTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left" *ngIf="!editable">Add Team</h4>
        <h4 class="modal-title pull-left" *ngIf="editable">Edit Team</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="ourTeamForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>First Name
                            <input type="text" class="form-control" formControlName="first_name" placeholder="First Name">
                            <div class="error-message" *ngIf="ourTeamForm.get('first_name').hasError('required') && (ourTeamForm.get('first_name').dirty || ourTeamForm.get('first_name').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message" *ngIf="ourTeamForm.get('first_name').hasError('maxlength') && (ourTeamForm.get('first_name').dirty || ourTeamForm.get('first_name').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>Last Name
                            <input type="text" class="form-control" formControlName="last_name" placeholder="Last Name">
                            <div class="error-message" *ngIf="ourTeamForm.get('last_name').hasError('required') && (ourTeamForm.get('last_name').dirty || ourTeamForm.get('last_name').touched || submitted)">
                                This field is required.
                            </div>
                            <div class="error-message" *ngIf="ourTeamForm.get('last_name').hasError('maxlength') && (ourTeamForm.get('last_name').dirty || ourTeamForm.get('last_name').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title Of Position</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" placeholder="Title Of Position" formControlName="position">
                        <div class="error-message" *ngIf="ourTeamForm.get('position').hasError('required') && (ourTeamForm.get('position').dirty || ourTeamForm.get('position').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="ourTeamForm.get('position').hasError('maxlength') && (ourTeamForm.get('position').dirty || ourTeamForm.get('position').touched)">
                            Max 75 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Start Date</label>
                    </div>
                    <div class="col-md-4">
                        <input type="number" class="form-control" placeholder="Start Date Year" formControlName="start_year">
                        <div class="error-message" *ngIf="ourTeamForm.get('start_year').hasError('required') && (ourTeamForm.get('start_year').dirty || ourTeamForm.get('start_year').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="(ourTeamForm.get('start_year').hasError('min') || ourTeamForm.get('start_year').hasError('max')) && (ourTeamForm.get('start_year').dirty || ourTeamForm.get('start_year').touched)">
                            Please enter valid Year.
                        </div>
                    </div>
                </div>
                <ng-template [ngIf]="!editable || (teamObj && !teamObj.profile_image)" [ngIfElse]="editBlock">
                    <div class="form-group row">
                        <div class="col-md-3">
                            <label>Upload Image</label>

                        </div>
                        <div class="col-md-9">
                            <input type="file" class="file-uploader" placeholder="Type Here" (change)="onFileSelect($event)" accept="image/x-png,image/jpg,image/jpeg">
                            <div *ngIf="fileUploadError" class="error-message">
                                {{fileUploadMessage}}
                            </div>
                            <div *ngIf="fileUploadSizeError" class="error-message">
                                {{fileUploadSizeMessage}}
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template #editBlock>
                    <div class="form-group-row">
                        <div class="col-md-2">
                            <label>Image</label>
                        </div>
                        <div class="col-md-10">
                            <a *ngIf="teamObj" href="{{teamObj.profile_image}}" target="_blank">
                                <img  alt="image" src="{{teamObj.profile_image}}" class="uploaded-img">
                            </a>
                            <a href="javascript:void()" *ngIf="editable" (click)="addUpdateTeamClicked(true, false, true)">
                                <img  alt="image" src="assets/images/delete_icon.svg">
                            </a>
                        </div>
                    </div>
                </ng-template>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea type="text" class="form-control" placeholder="Description" formControlName="description"></textarea>
                        <div class="error-message" *ngIf="ourTeamForm.get('description').hasError('required') && (ourTeamForm.get('description').dirty || ourTeamForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message" *ngIf="ourTeamForm.get('description').hasError('maxlength') && (ourTeamForm.get('description').dirty || ourTeamForm.get('description').touched)">
                            Max 1125 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="addNewTeam" (click)="addUpdateTeamClicked()" [disabled]="disableSubmit" class="btn btn-footer">
            Save
        </button>
        <button *ngIf="!addNewTeam" (click)="addUpdateTeamClicked(true)" [disabled]="disableSubmit" class="btn btn-footer">
            Update
        </button>
        <button *ngIf="!addNewTeam" (click)="addUpdateTeamClicked(true, true)" [disabled]="disableSubmit" class="btn btn-footer">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #teamTemplateStatic>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Our Team</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body about-div">
        <div class="media">
            <img *ngIf="teamObj.profile_image" src="{{teamObj.profile_image}}">
            <div *ngIf="!teamObj.profile_image">
                <svg width="130px" height="130px" viewBox="0 0 130 130" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Artboard</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect id="Rectangle" fill="#C0CBD7" x="0" y="0" width="130" height="130" rx="12"></rect>
                        <g id="image1" transform="translate(21.000000, 21.000000)" fill="#FFFFFF" fill-rule="nonzero">
                            <path d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                id="Shape"></path>
                        </g>
                    </g>
                </svg>
            </div>
            <div class="media-body ml-md-3">
                <h2>Name
                    <span>{{teamObj.first_name}} {{teamObj.last_name}}</span>
                </h2>
                <h2>Position
                    <span>{{teamObj.position}}</span>
                </h2>
                <h2>Start
                    <span>{{teamObj.start_year}}</span>
                </h2>
            </div>
        </div>
        <div class="about-detail mt-3">
            <h2>About</h2>
            <p>{{teamObj.description}}</p>
        </div>
    </div>
</ng-template>
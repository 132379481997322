import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-profile-verification',
  templateUrl: './profile-verification.component.html',
  styleUrls: ['./profile-verification.component.scss']
})
export class ProfileVerificationComponent implements OnInit {
  public isValid: boolean = false;
  public isResponse: boolean = false;
  public pageVerif: boolean = false;

  constructor(private router: Router, private authService: AuthenticationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let url = this.router.url;
    if (this.route.snapshot.queryParams['id'] && this.route.snapshot.queryParams['approve']) {
      this.authService.verifyProfile(url)
      .subscribe(response => {
        this.isResponse = true;
        if (response.status === 111) {
            this.isValid = false;
        } else if (response.status === 200) {
          if (response.response['validity'] == "valid_token") {
              this.isValid = true;
          } else {
             this.isValid = false;
          }
        } else {
          this.isValid = false;
        }
      }, error => {
          this.isResponse = true;
          this.isValid = false;
      });
    } else if (this.route.snapshot.queryParams['key'] && this.route.snapshot.queryParams['approve']) {
      this.pageVerif = true;
      url = url.replace("profile", "page");
      this.authService.verifyPage(url)
      .subscribe(response => {
        this.isResponse = true;
        if (response.status === 111) {
            this.isValid = false;
        } else if (response.status === 200) {
          if (response.response['validity'] == "valid_token") {
              this.isValid = true;
          } else {
             this.isValid = false;
          }
        } else {
          this.isValid = false;
        }
      }, error => {
          this.isResponse = true;
          this.isValid = false;
      });
    } else {
      this.isResponse = true;
      this.isValid = false;
    }
  }

}

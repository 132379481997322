<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 search-sidebar">
                <div class="green-sidebarSec">

                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <div class="return-div "><a href="javascript:void();" class="d-md-none d-block">Return</a>
                </div>
                <div class="col-md-12">
                    <h1>Experiences</h1>
                    <div class="filterDiv">
                        <form [formGroup]="searchForm" novalidate>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control search-field mb-md-0 mb-3"
                                            placeholder="Search By Keyword" formControlName="search_key">
                                        <div class="error-message"
                                            *ngIf="searchForm.get('search_key').hasError('whitespace') && (!searchForm.get('search_key').hasError('required')) && (searchForm.get('search_key').dirty || searchForm.get('search_key').touched)">
                                            Please enter valid data
                                        </div>
                                        <div class="error-message"
                                            *ngIf="searchForm.get('search_key').hasError('maxlength') && (searchForm.get('search_key').dirty || searchForm.get('search_key').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                        <div class="error-message" *ngIf="keywordsErr">
                                            Max 3 search keywords allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="d-flex align-items-center justify-content-between" (focusout)="focusOutFunction()">
                                        <label>
                                            Search By:
                                        </label>
                                        <app-azure-maps (change)="changeAddress($event)" [updateAddress]="updateAddress"
                                            (setAddress)="getAddress($event)" [placeHolder]="placeHolder"
                                            (setHideResult)="setResultHide($event)">
                                        </app-azure-maps>
                                    </div>
                                    <div class="error-message" *ngIf="!showLocation && !updateAddress && submitted">
                                        Please select location or enable location from your privacy settings
                                    </div>
                                    <div *ngIf="!hidePlaceResults" class="col-md-12">
                                        <div class="option-div">
                                            <div class="listing" *ngFor="let place of searchPlaces"
                                                (click)="selectPlace(place)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                                    viewBox="0 0 15 20">
                                                    <path fill="none" stroke="#505759" stroke-width="1.4"
                                                        d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                                </svg>
                                                <div class="detail">
                                                    <h6>{{place.address.freeformAddress}}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4"></div>
                            </div>
                            <div class="row">
                                <div class="col-md-12 form-group">
                                    <button type="submit" (click)="search(searchForm.valid)"
                                        class="common-btn mt-3">Submit</button>
                                </div>
                            </div>
                        </form>
                        <div *ngIf="searchKeys.length > 0" class="d-flex align-items-center flex-wrap">
                            <ul class="d-flex pl-0 mb-0  mt-md-3 tagListing">
                                <li *ngFor="let searchKey of searchKeys">
                                    <a href="javascript:void();">{{searchKey}} </a>
                                    <span class="float-right cursor-pointer" (click)="delKey(searchKey)">x</span>
                                </li>
                            </ul>
                            <button class="common-btn ghost  mt-3" (click)="delKey('')">Clear Filter</button>
                        </div>
                    </div>
                </div>
                <div class="row m-0">
                    <div class="col-md-12 search-sidebar h-auto mh-auto">
                    </div>
                    <div class="d-md-flex searchScroll w-100" infiniteScroll [infiniteScrollDistance]="1"
                        [infiniteScrollThrottle]="50" (scrolled)="onScrollDown()" [scrollWindow]="true">
                        <div class="col-md-7">
                            <tabset class="side-links tabsetSide">
                                <tab (selectTab)="onTabChange('job')">
                                    <ng-template tabHeading>
                                        JOBS
                                    </ng-template>
                                    <div *ngIf="jobs.length > 0" class="search-div mt-3">
                                        <p class="title">{{totalJobs}} Results</p>
                                        <span>Showing {{totalJobs}}</span>
                                        <div *ngFor="let job of jobs">
                                            <div class="search-inner d-md-flex " *ngFor="let obj_detail of job.employment_info.jobs_details">
                                                <div [routerLink]="['/page/' + job.employment_info.page]"
                                                    class="imgDiv cursor-pointer">
                                                    <img
                                                        src="{{job.employment_info.display_picture? mediaURL + job.employment_info.display_picture : 'assets/images/default.svg'}}">
                                                </div>
                                                <div class="search-side ml-lg-3 mr-lg-2">
                                                    <h2 (click)="jobTitleModal(jobTemplate, obj_detail.id)">
                                                        {{obj_detail.title}}</h2>
                                                    <h3>{{job.employment_info.business_title}}</h3>
                                                    <div class="searchDetail">
                                                        <h3>Experiences</h3>
                                                        <ul class="pl-0 mb-0 d-flex">
                                                            <li (click)="jobInfoModal(jobinfo, job.employment_info)"
                                                                class="onHover">
                                                                <h4
                                                                    [class]="{'cursor-pointer': job.employment_info.jobs_count > 0}">
                                                                    <span>{{job.employment_info.jobs_count}}</span>Available
                                                                </h4>
                                                            </li>
                                                            <li>
                                                                <h4><span>{{job.employment_info.verified_experiences}}
                                                                    </span>Verified</h4>
                                                            </li>
                                                            <li>
                                                                <h4>{{job.employment_info.distance}} Km Away</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="jobs.length == 0" class="next-listing text-center mt-4 mb-4">
                                        <h5>No Result(s) Found</h5>
                                    </div>
                                    <div *ngIf="totalJobs > 3 && totalJobs > jobs.length"
                                        class="next-listing text-center mt-4 mb-4">
                                        <h5>Scroll to view next 3</h5>
                                        <img alt="image" src="assets/images/arrow-down.png" alt="" id="nextBtn">
                                    </div>
                                </tab>
                                <tab (selectTab)="onTabChange('volunteer')">
                                    <ng-template tabHeading>
                                        VOLUNTEER
                                    </ng-template>
                                    <div class="search-div mt-3" *ngIf="volunteerJobs.length > 0">
                                        <p class="title">{{totalVolunteer}} Results</p>
                                        <span>Showing {{volunteerJobs.length}}</span>
                                        <div *ngFor="let job of volunteerJobs">
                                            <div class="search-inner d-md-flex  " *ngFor="let obj_detail of job.employment_info.jobs_details">
                                                <div [routerLink]="['/page/' + job.employment_info.page]"
                                                    class="imgDiv cursor-pointer">
                                                    <img
                                                        src="{{job.employment_info.display_picture? mediaURL + job.employment_info.display_picture : 'assets/images/default.svg'}}">
                                                </div>
                                                <div class="search-side ml-lg-3 mr-lg-2">
                                                    <h2
                                                        (click)="volunteerJobTitleModal(jobTemplate, obj_detail.id)">
                                                        {{obj_detail.title}}</h2>
                                                    <h3>{{job.employment_info.business_title}}</h3>
                                                    <div class="searchDetail">
                                                        <h3>Experiences</h3>
                                                        <ul class="pl-0 mb-0 d-flex justify-content-between">
                                                            <li (click)="volunteerJobInfoModal(jobinfo, job.employment_info.page)"
                                                                class="onHover">
                                                                <h4 class="cursor-pointer">
                                                                    <span>{{job.employment_info.jobs_count}}</span>Available
                                                                </h4>
                                                            </li>
                                                            <li>
                                                                <h4><span>{{job.employment_info.verified_experiences}}
                                                                    </span>Verified</h4>
                                                            </li>
                                                            <li>
                                                                <h4>{{job.employment_info.distance}} km Away</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="volunteerJobs.length == 0" class="next-listing text-center mt-4 mb-4">
                                        <h5>No Result(s) Found</h5>
                                    </div>
                                    <div *ngIf="totalVolunteer > 3 && totalVolunteer > volunteerJobs.length"
                                        class="next-listing text-center mt-4 mb-4">
                                        <h5>Scroll to view next 3</h5>
                                        <img alt="image" src="assets/images/arrow-down.png" alt="" id="nextBtn">
                                    </div>
                                </tab>
                            </tabset>
                        </div>
                        <div class="col-md-5">
                            <div class="mapContainerDiv">
                                <div id="mapContainer" class="mx-0"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #jobinfo>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Job Info</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="closeModal(jobinfo)">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="content-section pl-md-3 pr-md-3 pt-2 pb-2">
                <div class="profile-div">
                    <div class="profile-inner mb-3 mt-2 full-inner" *ngFor="let job of jobList">
                        <div class="box-div inner-exp">
                            <div class="experience-div media">
                                <div class="media-body ml-2">
                                    <h4>{{job.title}}</h4>
                                    <p>
                                        {{job.page__business__organization_title}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #jobTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Job Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="closeModal(jobTemplate)">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div" *ngFor="let job_post of jobDetails">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Title</h3>
                            </div>
                            <div class="col-6">
                                <h4>{{job_post.title}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Date Posted</h3>
                            </div>
                            <div class="col-6">
                                <h4>{{job_post.created_date|date}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>How to Apply</h3>
                            </div>
                            <div class="col-12">
                                <h4 class="ml-0">{{job_post.how_to_apply}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>Description</h3>
                            </div>
                            <div class="col-12">
                                <h4 [innerHtml]="job_post.description" class="ml-0"></h4>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>
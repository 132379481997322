import { Component, OnInit, TemplateRef, Input, EventEmitter} from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../../services/profile.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-volunteer-experience',
  templateUrl: './volunteer-experience.component.html',
  styleUrls: ['./volunteer-experience.component.scss']
})
export class VolunteerExperienceComponent implements OnInit {
  mediaURL = environment.mediaURL;
  modalRef: BsModalRef;
  expForm: FormGroup;
  searchForm: FormGroup;
  expUpdForm: FormGroup;
  usrWorkExperience = [];
  organizations = [];
  editExp: any = {};
  hideResults: boolean = false;
  previewShow: boolean = false;
  formShow: boolean = false; 
  isOneDayEvnt: boolean = false;
  public partialSubmitted: boolean = false;
  public submitted: boolean = false;
  today = new Date();
  minDate = new Date("2013-1-1");
  orgId: string = null;
  evntPlaceholder: string = "Start Date";
  @Input() editable: boolean = false;
  @Input() user: any = {};
  @Input() account_verification_status: string;
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  pageImage: string = null;
  public exp_end_one_day: boolean = false;

  constructor(private datePipe: DatePipe, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private profileService: ProfileService) {

    this.searchForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      total_hours: new FormControl('', Validators.required)
    }, { validators: this.dateValidator });
    this.expForm = this.formBuilder.group({
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(750)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
    });
    this.expUpdForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', Validators.maxLength(20)),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      total_hours: new FormControl('', Validators.required),
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(750)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
    }, { validators: this.dateValidator });

  }

  ngOnInit(): void {
    this.getUserWorkExperience();
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
  }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0,0,0,0);
      end_date = new Date(end_date).setHours(0,0,0,0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    }
    return null;
  }

  oneDayEvnt(e) {
    const key = e.target.value;
    this.searchForm.controls['end_date'].setValue(null);
    if (key == "1") {
      this.isOneDayEvnt = true;
      this.evntPlaceholder = "Date";
    } else {
      this.isOneDayEvnt = false;
      this.evntPlaceholder = "Start Date";
    }
  }

  canEdit(){
    if (this.editable) {
      if (this.editExp.page) {
        return true;
      } else if (this.editExp.page == null) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  canEditEndDate(){
    if (this.editable) {
      if (this.editExp.page && this.editExp.verification_status !== 1 && !this.editExp.end_date) {
        return true;
      } else if (this.editExp.page == null && this.editExp.verification_status == 1) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  resetEndDate(){
    this.expUpdForm.controls['end_date'].setValue(null);
    this.searchForm.controls['end_date'].setValue(null);
  }

  resetStartDate(){
    this.expUpdForm.controls['start_date'].setValue(null);
    this.searchForm.controls['start_date'].setValue(null);
  }

  getUserWorkExperience(){
    this.profileService.getUserWorkExp("2")
    .subscribe(response => {
      if(response.response.length > 0){
          this.usrWorkExperience = response.response;
        } else {
          this.usrWorkExperience = [];
        }
      });
  }

  expModal(expTemplate: TemplateRef<any>) {
    this.orgId = null;
    this.pageImage = null;
    this.isOneDayEvnt = false;
    this.previewShow = false;
    this.formShow = false;
    this.submitted = false;
    this.partialSubmitted = false;
    this.searchForm.reset();
    this.expForm.reset();
    this.hideResults = true;
    this.organizations = [];
    this.modalRef = this.modalService.show(expTemplate,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  updateExpModal(editExpTemplate: TemplateRef<any>, exp: any) {
    this.orgId = null;
    this.pageImage = null;
    this.editExp = exp;
    this.expUpdForm.controls['title'].setValue(exp.organization_title);
    this.expUpdForm.controls['city'].setValue(exp.city);
    this.expUpdForm.controls['position'].setValue(exp.position);
    this.expUpdForm.controls['start_date'].setValue(exp.start_date);
    this.expUpdForm.controls['end_date'].setValue(exp.end_date);
    this.expUpdForm.controls['skill_1'].setValue(exp.skill_1);
    this.expUpdForm.controls['skill_2'].setValue(exp.skill_2);
    this.expUpdForm.controls['skill_3'].setValue(exp.skill_3);
    this.expUpdForm.controls['main_role'].setValue(exp.main_role);
    this.expUpdForm.controls['personal_details'].setValue(exp.personal_details);
    this.expUpdForm.controls['zip_code'].setValue(exp.zip_code);
    this.expUpdForm.controls['total_hours'].setValue(exp.total_hours);
    this.hideResults = true;
    this.organizations = [];
    this.modalRef = this.modalService.show(editExpTemplate,
    Object.assign({}, { class: 'modal-dialog-centered custom-modal'})
    );
  }

  showForm(isValid: boolean) {
    if (isValid){
      this.formShow = !this.formShow;
    } else {
      this.partialSubmitted = true;
    }
  }

  showPreview(isValid: boolean){
    let exp_start_date: any = this.searchForm.controls['start_date'].value;
    let exp_end_date: any = this.searchForm.controls['end_date'].value;
    if (exp_end_date){
      if (exp_start_date.getTime() === exp_end_date.getTime()){
        this.exp_end_one_day = true;
      }
      else {
        this.exp_end_one_day = false;
      }
    }
    if (isValid){
      this.previewShow = !this.previewShow;
    } else {
      this.submitted = true;
    }
  }

  searchOrg(e, update:boolean=false){
    if (this.user.age_group == 1) {
      return;
    }
    let key = e.target.value;
    let zip_code = this.searchForm.get('zip_code').value;
    if (update) {
      zip_code = this.expUpdForm.get('zip_code').value;
    }
    this.orgId = null;
    this.pageImage = null;
    if (key !== null && key.length > 0 && zip_code !== null && zip_code.length > 0) {
      this.organizations = [];
      let params = key.toLowerCase() + '&zip_code=' + zip_code.toLowerCase();
      this.profileService.searchOrgPage("1", params)
      .subscribe(response => {
        if (response.status == 200){
          this.hideResults = false;
          this.organizations = response.response;
        }
      });
    } else {
      this.hideResults = true;
      this.organizations = [];
    }
  }

  selectOrg(org: any, update:boolean=false) {
    this.hideResults = true;
    if (update) {
      this.expUpdForm.controls['title'].setValue(org.page_title);
      this.expUpdForm.controls['city'].setValue(org.page_city);
    } else {
      this.searchForm.controls['title'].setValue(org.page_title);
      this.searchForm.controls['city'].setValue(org.page_city);
    }
    this.orgId = org.id;
    this.pageImage = org.display_pic;
    this.organizations = [];
  }

  addExperience() {
    let start_date = this.datePipe.transform(this.searchForm.get('start_date').value,"yyyy-MM-dd");
    let end_Date =  this.datePipe.transform(this.searchForm.get('end_date').value,"yyyy-MM-dd");
    if (this.isOneDayEvnt) {
      end_Date =  this.datePipe.transform(this.searchForm.get('start_date').value,"yyyy-MM-dd");
    }
    let model = {'page': this.orgId,
                'organization_title': this.searchForm.get('title').value,
                'user': this.user.id,
                'city': this.searchForm.get('city').value,
                'position': this.searchForm.get('position').value,
                'zip_code': this.searchForm.get('zip_code').value,
                'start_date': start_date,
                'end_date': end_Date,
                'total_hours': this.searchForm.get('total_hours').value,
                'experience_type': 2,
                'skill_1': this.expForm.get('skill_1').value,
                'skill_2': this.expForm.get('skill_2').value,
                'skill_3': this.expForm.get('skill_3').value,
                'main_role': this.expForm.get('main_role').value,
                'personal_details': this.expForm.get('personal_details').value}
    this.profileService.addUserWorkExp(model)
    .subscribe(response => {
      this.handleExpResponse(response);
    }, error => {
        this.handleExpSubmitError(error.error);
    })
  }

  updateExperience(isValid: boolean){
    this.submitted = true;
    let businessId = this.editExp.page;
    if (this.orgId !== null) {
      businessId = this.orgId;
    }
    if (isValid) {
      let start_date = this.datePipe.transform(this.expUpdForm.get('start_date').value, "yyyy-MM-dd");
      let end_Date =  this.datePipe.transform(this.expUpdForm.get('end_date').value, "yyyy-MM-dd");
      let model = {'page': businessId,
                  'organization_title': this.expUpdForm.get('title').value,
                  'user': this.user.id,
                  'city': this.expUpdForm.get('city').value,
                  'position': this.expUpdForm.get('position').value,
                  'zip_code': this.expUpdForm.get('zip_code').value,
                  'start_date': start_date,
                  'end_date': end_Date,
                  'total_hours': this.expUpdForm.get('total_hours').value,
                  'experience_type': 2,
                  'skill_1': this.expUpdForm.get('skill_1').value,
                  'skill_2': this.expUpdForm.get('skill_2').value,
                  'skill_3': this.expUpdForm.get('skill_3').value,
                  'main_role': this.expUpdForm.get('main_role').value,
                  'personal_details': this.expUpdForm.get('personal_details').value}
      this.profileService.updateUserWorkExp(model, this.editExp.id)
      .subscribe(response => {
        this.handleExpUpdResponse(response);
      }, error => {
          this.handleExpSubmitError(error.error);
      })
    }
  }

  delExperience(){
    this.profileService.deleteUserWorkExp(this.editExp.id)
    .subscribe(response => {
      this.getUserWorkExperience();
      this.modalRef.hide();
      this.editExp = {};
      this.expUpdForm.reset();
    })
  }

  protected handleExpResponse(response: any) {
    if (response.status === 111) {
      this.handleExpSubmitError(response.message);
    } else if (response.status === 200) {
        this.modalRef.hide();
        this.usrWorkExperience.push(response.response);
        this.usrWorkExperience.sort(this.custom_sort);
        this.searchForm.reset();
        this.expForm.reset();
        this.partialSubmitted = false;
        this.submitted = false;
    }
  }

  protected handleExpUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleExpSubmitError(response.message);
    } else if (response.status === 200) {
        this.getUserWorkExperience();
        this.modalRef.hide();
        this.expUpdForm.reset();
        this.submitted = false;
    }
  }

  protected handleExpSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.expUpdForm.contains(field)) {
        this.expUpdForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  focusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.organizations = [];
    }, 1000);
  }

  redirectPage(experience: any){
    if (experience.page && !experience.related_data.is_deleted && experience.verified_date && experience.approver_name) {
      let url = '/page/' + experience.page;
      if (experience.related_data.is_related) {
        url = '/page/' + experience.page + '/profile-business';
      }
      this.router.navigate([url]);
    }
  }

  custom_sort(a: any, b: any) {
    return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
  }

}

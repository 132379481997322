<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 student p-0 p-0">
                <div class="green-sidebarSec">
                    <div class="setting-div mt-2" [ngClass]="{ '': settingShow, show: !settingShow}">
                        <div class="setting-inner">
                            <h3>Settings</h3>
                            <img  alt="image" src="assets/images/cross.png" alt="" class="close-btn"
                                (click)="settingShow = !settingShow">
                            <form action="">
                                <div class="form-group mb-2 mt-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck1">Block
                                            User</label>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck">Report
                                            User</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <app-profile-header [account_verification_status]="account_verification_status"
                    [days_left_to_verify]="days_left_to_verify"></app-profile-header>
                <app-sidebar-inner></app-sidebar-inner>
                <div class="row m-0" *ngIf="account_verification_status != '3'">
                    <div class="col-md-12 p-0">
                        <div class="edit-div d-flex  flex-end align-items-center">
                            <p>Edit Mode</p>
                            <label class="switch mb-0 ml-3">
                                <input class="switch-input" type="checkbox" (change)="disableEdit($event)" />
                                <span class="switch-label" data-on="On" data-off="Off"></span>
                                <span class="switch-handle"></span>
                            </label>
                        </div>
                    </div>
                </div>
                <tabset class="topLinks">
                    <tab>
                        <ng-template tabHeading>
                            <h3>Resume</h3>
                        </ng-template>

                        <div class="content-section">
                            <div class="profile-div">
                                <div class="profile-inner bioSection">
                                    <h3>Biography
                                        <a *ngIf="(profile | json) == '{}'" href="javascript:void()" class="float-right"
                                            (click)="openModal(descriptionTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable && (profile | json) != '{}'"
                                            class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="box-div" slimScroll [options]="opts">
                                        <div class="inner-div cursor-pointer" *ngIf="(profile | json) != '{}'"
                                            (click)="openModal(editDescriptionTemplate)">
                                            <ul class="pl-0 d-flex">
                                                <li *ngIf="profile.profile_keyword_1">
                                                    <p>{{profile.profile_keyword_1}}</p>
                                                </li>
                                                <li *ngIf="profile.profile_keyword_2">
                                                    <p>{{profile.profile_keyword_2}}</p>
                                                </li>
                                                <li *ngIf="profile.profile_keyword_3">
                                                    <p>{{profile.profile_keyword_3}}</p>
                                                </li>
                                            </ul>
                                            <p class="px-1" style="white-space: pre-wrap;">{{profile.description}}</p>
                                        </div>
                                        <div *ngIf="(profile | json) == '{}'"
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                            <p>Explain who you are</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner slider-div ">
                                    <app-education [editable]="editable"
                                        [account_verification_status]="account_verification_status"></app-education>
                                </div>
                                <div class="profile-inner full-inner">
                                    <app-work-experience [editable]="editable" [user]="user"
                                        [account_verification_status]="account_verification_status">
                                    </app-work-experience>
                                </div>
                                <div class="profile-inner full-inner colorOdd">
                                    <app-volunteer-experience [editable]="editable" [user]="user"
                                        [account_verification_status]="account_verification_status">
                                    </app-volunteer-experience>
                                </div>
                                <div class="profile-inner full-inner colorOdd">
                                    <h3>Extracurricular Experience
                                        <a href="javascript:void()" class="float-right"
                                            *ngIf="!editable && account_verification_status != '3'"
                                            (click)="activityModal(activityTemplate)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="scroll-activity" slimScroll [options]="opts"
                                        [scrollEvents]="scrollEvents">
                                        <div class="box-div inner-exp cursor-pointer" *ngFor="let activity of activities"
                                            (click)="updateActivityModal(editActivityTemplate, activity)">
                                            <div class="experience-div media position-relative">
                                                <img src="assets/images/default.svg">
                                                <div class="media-body ml-2">
                                                    <h4>{{activity.position}}</h4>
                                                    <p>{{activity.organization_title}}</p>
                                                    <h5 *ngIf="activity.start_date != activity.end_date">Start
                                                        <span>{{activity.start_date}}</span> End
                                                        <span *ngIf="!activity.end_date">-</span>
                                                        <span>{{activity.end_date}}</span>
                                                    </h5>
                                                    <h5 *ngIf="activity.start_date == activity.end_date">Date
                                                        <span>{{activity.start_date}}</span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div class="side-col">
                                                <ul class="d-flex pl-0">
                                                    <li>
                                                        <p>{{activity.skill_1}}</p>
                                                    </li>
                                                    <li>
                                                        <p>{{activity.skill_2}}</p>
                                                    </li>
                                                    <li>
                                                        <p>{{activity.skill_3}}</p>
                                                    </li>
                                                </ul>
                                                <p>{{activity.main_role}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="activities?.length == 0"
                                        class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                        <p>Add an Extracurricular Experience to your resume – this cannot yet be verified ... Stay tuned!</p>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner colorOdd">
                                    <app-school-involvement [editable]="editable" [user]="user"
                                        [account_verification_status]="account_verification_status">
                                    </app-school-involvement>
                                </div>
                                <div class="profile-inner" *ngIf="user.user_role == '2'">
                                    <h3>Skills
                                        <a href="javascript:void()" class="float-right"
                                            *ngIf="!editable && account_verification_status != '3'"
                                            (click)="skillModal(skillpopup)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="box-div scroll-section" slimScroll [options]="opts"
                                        [scrollEvents]="scrollEvents">
                                        <ul *ngIf="skills?.length > 0" class="pl-0 d-flex skills-listing mb-0">
                                            <li *ngFor="let skill of skills" class="cursor-pointer">
                                                <p (click)="updateSkillModal(editSkillpopup, skill)">{{skill.title}}
                                                </p>
                                            </li>
                                        </ul>
                                        <div *ngIf="skills?.length == 0"
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                            <p>You can add Links or documents</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner" *ngIf="user.user_role == '2'">
                                    <h3>Interests
                                        <a href="javascript:void()" class="float-right"
                                            *ngIf="!editable && account_verification_status != '3'"
                                            (click)="interestModal(interestpopup)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <div class="box-div scroll-section" slimScroll [options]="opts"
                                        [scrollEvents]="scrollEvents">
                                        <ul *ngIf="interests?.length > 0" class="pl-0 d-flex skills-listing mb-0">
                                            <li *ngFor="let interest of interests" class="cursor-pointer">
                                                <p (click)="updateInterestModal(editInterestpopup, interest)">
                                                    {{interest.title}}</p>
                                            </li>
                                        </ul>
                                        <div *ngIf="interests?.length == 0"
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                            <p>You can add links or documents</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner">
                                    <h3>Timeline</h3>
                                    <div class="d-flex justify-content-between align-items-center slideDiv px-2">
                                        <p class="mb-0 d-flex align-items-center">
                                            <button type="button" class="btnSlide prevBtn bg-transparent border-0"
                                                id="prevbut" (click)="prevYear()" *ngIf="!prevYearCheck">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    viewBox="0 0 30 30">
                                                    <g fill="none">
                                                        <path fill="#285278"
                                                            d="M0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-3.978-1.58-7.794-4.393-10.607C22.794 1.58 18.978 0 15 0 11.022 0 7.206 1.58 4.393 4.393 1.58 7.206 0 11.022 0 15z"
                                                            transform="matrix(0 1 1 0 0 0)" />
                                                        <path fill="#FFF"
                                                            d="M16.314 10.766l5.666 5.867c.449.465.624 1.143.46 1.777-.165.635-.645 1.13-1.258 1.3-.613.17-1.267-.012-1.715-.477L15 14.605l-4.467 4.628c-.693.719-1.818.72-2.512.002s-.695-1.883-.001-2.602l5.666-5.867c.347-.36.823-.555 1.314-.538h.057c.471 0 .923.193 1.257.538z"
                                                            transform="matrix(0 1 1 0 0 0)" />
                                                    </g>
                                                </svg>
                                            </button><span *ngIf="!prevYearCheck">{{current_year-1}}</span>
                                        </p>
                                        <h4>
                                            {{current_year}}
                                        </h4>
                                        <p class="mb-0 d-flex align-items-center"><span *ngIf="nextYearCheck">Now</span>
                                            <span *ngIf="!nextYearCheck">{{current_year + 1}}</span>
                                            <button type="button" class="btnSlide nextBtn bg-transparent border-0"
                                                id="nextbut" (click)="nextYear()" *ngIf="!nextYearCheck"><svg
                                                    xmlns="http://www.w3.org/2000/svg" width="30" height="30"
                                                    viewBox="0 0 30 30">
                                                    <g fill="none">
                                                        <path fill="#285278"
                                                            d="M0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-3.978-1.58-7.794-4.393-10.607C22.794 1.58 18.978 0 15 0 11.022 0 7.206 1.58 4.393 4.393 1.58 7.206 0 11.022 0 15z"
                                                            transform="rotate(90 15 15)" />
                                                        <path fill="#FFF"
                                                            d="M16.314 10.766l5.666 5.867c.449.465.624 1.143.46 1.777-.165.635-.645 1.13-1.258 1.3-.613.17-1.267-.012-1.715-.477L15 14.605l-4.467 4.628c-.693.719-1.818.72-2.512.002s-.695-1.883-.001-2.602l5.666-5.867c.347-.36.823-.555 1.314-.538h.057c.471 0 .923.193 1.257.538z"
                                                            transform="rotate(90 15 15)" />
                                                    </g>
                                                </svg>
                                            </button></p>
                                    </div>
                                    <div class="box-div m-0">
                                        <div class="timeLineDiv d-flex align-items-center" id="testinggg">

                                            <span *ngFor="let item of month_instances | keyvalue:descOrder"
                                                class="d-flex align-items-center justify-content-center dotSpan"
                                                [ngClass]="getClass(item.value, item.key)"
                                                (click)="getTimelineDetails(item.key) ">{{item.value.length > 1 ? item.value.length : ''}}</span>
                                        </div>
                                        <div class="eventListing" [ngClass]="{ '': eventListing, show: !eventListing}">
                                            <h4>{{timelineMonth}}</h4>
                                            <ul class=" pl-0 mb-0 d-flex flex-wrap">
                                                <li *ngFor="let details of timelineDetails">
                                                    <div class="eventDiv" [ngClass]="getClassCards(details)">
                                                        <h4>
                                                            {{details.position}}
                                                        </h4>
                                                        <p>
                                                            <span *ngIf="!details.one_day_event">{{details.start_date ? 'Starting' : 'Ending'}} work at </span>
                                                            <span *ngIf="details.one_day_event">Worked at </span>
                                                            <span>{{details.organization_title}}</span> on<span
                                                                class="d-block">
                                                                {{details.start_date ? details.start_date : details.end_date}}</span>
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <h3 (click)="updBulletin()">Library</h3>
                        </ng-template>
                        <div class="content-section business-content fullPage">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>Bulletin Board
                                        <a href="javascript:void()" class="float-right" 
                                        *ngIf="!editable && account_verification_status != '3'" (click)="bulletinModal(buletinModal)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                    </h3>
                                    <ngx-slick-carousel *ngIf="isLibrary"
                                        class="box-div library-div carousel" #slickModal="slick-carousel"
                                        [config]="slideConfig">
                                        <div *ngFor="let bulletin of bulletins" class="img-cont slide" ngxSlickItem
                                            (click)="updatebulletinModal(bulletinDetail, editbulletinModal, bulletin)">
                                            <div *ngIf="bulletin.document" class="img-div">
                                                <img *ngIf="isImage(bulletin.document_name)"
                                                    src="{{bulletin.document}}">
                                                <img *ngIf="!isImage(bulletin.document_name)"
                                                    src="assets/images/pdf-icon.svg">
                                                <p>{{bulletin.title}}</p>
                                            </div>
                                            <div *ngIf="!bulletin.document" class="img-div">
                                                <img src="assets/images/default.svg">
                                                <p>{{bulletin.title}}</p>
                                            </div>
                                        </div>
                                    </ngx-slick-carousel>
                                    <div *ngIf="!isLibrary"
                                        class="library-div empty-div box-div text-center h-100 d-flex align-items-center justify-content-center">
                                        <p>Add documents and links that show off your accomplishments</p>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner custom-slider" *ngIf="user.user_role == '2'">
                                    <h3>Books
                                        <a href="javascript:void()" class="float-right"
                                            *ngIf="!editable && account_verification_status != '3'"
                                            (click)="bookModal(addBooks)">
                                            <img  alt="image" src="assets/images/plus-icon.png" alt="">
                                        </a>
                                        <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
                                            <img  alt="image" src="assets/images/icEdit.svg">
                                        </a>
                                    </h3>
                                    <carousel *ngIf="books?.length > 0" [(activeSlide)]="activeSlideIndex">
                                        <slide *ngFor="let book of books">
                                            <div class="box-div">
                                                <div class="books-listing mw-100"
                                                    (click)="updatebookModal(updateBooks, book)">
                                                    <h3>{{book.title}}</h3>
                                                    <h4>{{book.author}}</h4>
                                                    <ul *ngIf="book.rating"
                                                        class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                                        <ngb-rating [(rate)]="book.rating" [readonly]="true" [max]="5">
                                                            <ng-template let-fill="fill" let-index="index">
                                                                <span class="star" [class.filled]="fill === 100"
                                                                    [class.bad]="index < 3"></span>
                                                            </ng-template>
                                                        </ngb-rating>
                                                    </ul>
                                                    <div class="inner-listing">
                                                        {{book.description}}
                                                    </div>
                                                </div>
                                            </div>
                                        </slide>
                                    </carousel>
                                    <div class="box-div p-0 m-0">
                                        <div *ngIf="books?.length == 0"
                                            class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                            <p>any books you have read go here</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</section>
<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin Board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-modal d-flex align-items-center">
                        <h2>Title</h2>
                        <p>{{editBulletin.title}}</p>
                    </div>
                    <div class="divDetail">
                        <h2>Attachments & Links</h2>
                        <div class="d-flex mt-3">
                            <figure *ngIf="editBulletin.document">
                                <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank" class="link-style">{{editBulletin.document_name}}</a>
                                <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank">
                                    <img  alt="image" src="{{editBulletin.document}}">
                                </a>
                            </figure>
                            <div class="uploadDetail ml-3">
                                <a href="{{editBulletin.link}}" target="_blank">{{editBulletin.link}}</a>
                            </div>
                        </div>
                        <div class="content-detail">
                            <h2>Details</h2>
                            <p>{{editBulletin.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #descriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>My Top 3 Descriptive Words</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control  " placeholder="Keyword 1"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " placeholder="Keyword 2"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">

                                <input type="text" class="form-control  " placeholder="Keyword 3"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" placeholder="Description"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 4000 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="saveDescription(descriptionForm.valid)">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>My Top 3 Descriptive Words</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control " placeholder="Keyword 1"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control " placeholder="Keyword 2"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control " placeholder="Keyword 3"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [readonly]="!editable" formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 4000 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="updateDescription(descriptionForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #activityTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!formShow" [formGroup]="searchForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>
                            City
                            <input type="text" class="form-control" placeholder="City" formControlName="city">
                            <div class="error-message"
                                *ngIf="searchForm.get('city').hasError('required') && (searchForm.get('city').dirty || searchForm.get('city').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('city').hasError('maxlength') && (searchForm.get('city').dirty || searchForm.get('city').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>
                            Postal Code
                            <input type="text" class="form-control" placeholder="Postal Code"
                                formControlName="zip_code">
                            <div class="error-message"
                                *ngIf="searchForm.get('zip_code').hasError('required') && (searchForm.get('zip_code').dirty || searchForm.get('zip_code').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('zip_code').hasError('maxlength') && (searchForm.get('zip_code').dirty || searchForm.get('zip_code').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Enter Title of Organization"
                            formControlName="title" (keydown)="searchOrg($event)" (focusout)="focusOutFunction()">
                        <div class="error-message"
                            *ngIf="searchForm.get('title').hasError('required') && (searchForm.get('title').dirty || searchForm.get('title').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="searchForm.get('title').hasError('maxlength') && (searchForm.get('title').dirty || searchForm.get('title').touched)">
                            Max 60 characters allowed.
                        </div>
                        <div *ngIf="!hideResults" class="col-md-9">
                            <div class="option-div position-absolute" slimScroll [options]="opts"
                                [scrollEvents]="scrollEvents">
                                <div *ngFor="let organization of organizations">
                                    <div (click)="selectOrg(organization)" class="media p-2">
                                        <img
                                            src="{{organization.display_pic? mediaURL + organization.display_pic : 'assets/images/default.svg'}}">
                                        <div class="media-body ml-2">
                                            <p class="pl-2">{{organization.page_title}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Position</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control" placeholder="Type Here" formControlName="position">
                        <div class="error-message"
                            *ngIf="searchForm.get('position').hasError('required') && (searchForm.get('position').dirty || searchForm.get('position').touched || partialSubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="searchForm.get('position').hasError('maxlength') && (searchForm.get('position').dirty || searchForm.get('position').touched)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Dates</label>
                    </div>
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Start
                                    <input type="text" class="form-control modal-control modal-field"
                                        placeholder="Start Date" bsDatepicker [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <a href="javascript:void(0);" (click)="resetStartDate()"><img
                                            src="assets/images/plus-icon.png" class="img-cross"></a>
                                    <div class="error-message"
                                        *ngIf="searchForm.get('start_date').hasError('required') && (searchForm.get('start_date').dirty || searchForm.get('start_date').touched || partialSubmitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6">
                                <label for="">End
                                    <input type="text" class="form-control modal-control modal-field"
                                        placeholder="End Date" bsDatepicker [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <a href="javascript:void(0);" (click)="resetEndDate()"><img
                                            src="assets/images/plus-icon.png" class="img-cross"></a>
                                    <div class="error-message"
                                        *ngIf="searchForm.errors?.invalidEndDate && (searchForm.touched || searchForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="formShow && !previewShow" [formGroup]="activityForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top Skills Learned</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_1">
                                <div class="error-message"
                                    *ngIf="activityForm.get('skill_1').hasError('required') && (activityForm.get('skill_1').dirty || activityForm.get('skill_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityForm.get('skill_1').hasError('maxlength') && (activityForm.get('skill_1').dirty || activityForm.get('skill_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_2">
                                <div class="error-message"
                                    *ngIf="activityForm.get('skill_2').hasError('required') && (activityForm.get('skill_2').dirty || activityForm.get('skill_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityForm.get('skill_2').hasError('maxlength') && (activityForm.get('skill_2').dirty || activityForm.get('skill_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_3">
                                <div class="error-message"
                                    *ngIf="activityForm.get('skill_3').hasError('required') && (activityForm.get('skill_3').dirty || activityForm.get('skill_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityForm.get('skill_3').hasError('maxlength') && (activityForm.get('skill_3').dirty || activityForm.get('skill_3').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Main Role</label>
                    </div>
                    <div class="col-md-10">
                        <textarea class="form-control" formControlName="main_role"> </textarea>
                        <div class="error-message"
                            *ngIf="activityForm.get('main_role').hasError('required') && (activityForm.get('main_role').dirty || activityForm.get('main_role').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="activityForm.get('main_role').hasError('maxlength') && (activityForm.get('main_role').dirty || activityForm.get('main_role').touched)">
                            Max 750 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Personal Details</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="personal_details"> </textarea>
                        <div class="error-message"
                            *ngIf="activityForm.get('personal_details').hasError('required') && (activityForm.get('personal_details').dirty || activityForm.get('personal_details').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="activityForm.get('personal_details').hasError('maxlength') && (activityForm.get('personal_details').dirty || activityForm.get('personal_details').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="previewShow" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <img alt="image" src="{{businessImage? mediaURL + businessImage : 'assets/images/default.svg'}}">
                                <div class="media-body ml-2">
                                    <h4>
                                        <span class="mr-3 title">Business</span>{{this.searchForm.get('title').value}}
                                    </h4>
                                    <p>
                                        <span
                                            class="mr-3 title">Position</span>{{this.searchForm.get('position').value}}
                                    </p>
                                    <h5 *ngIf="!exp_end_one_day">
                                        <span class="mr-3 title">Dates</span>Start
                                        <span>{{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}</span>
                                        End
                                        <span *ngIf="!this.searchForm.get('end_date').value">-</span>
                                        <span>{{this.searchForm.get('end_date').value | date: 'yyyy-MM-dd'}}</span>
                                    </h5>
                                    <h5 *ngIf="exp_end_one_day">
                                        <span class="mr-3 title">Dates</span>Date
                                        <span>{{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="!formShow" class="btn btn-footer" (click)="showForm(searchForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="formShow && !previewShow" (click)="showForm(searchForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="formShow && !previewShow" (click)="showPreview(activityForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="showPreview(activityForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="addActivity()">
            Save
        </button>
    </div>
</ng-template>
<ng-template #editActivityTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="activityUpdForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="experience-div media w-100">
                    <div *ngIf="!editable">
                        <img  alt="image" src="assets/images/default.svg">
                    </div>
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Business</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Business Name"
                                            [readonly]="!editable" formControlName="title"
                                            (keydown)="searchOrg($event, true)" (focusout)="focusOutFunction()">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('title').hasError('required') && (activityUpdForm.get('title').dirty || activityUpdForm.get('title').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('title').hasError('maxlength') && (activityUpdForm.get('title').dirty || activityUpdForm.get('title').touched)">
                                            Max 60 characters allowed.
                                        </div>
                                        <div *ngIf="!hideResults && editable" class="col-md-9">
                                            <div class="option-div position-absolute" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                                <div *ngFor="let organization of organizations">
                                                    <div (click)="selectOrg(organization, true)" class="media p-2">
                                                        <img
                                                            src="{{organization.display_pic? mediaURL + organization.display_pic : 'assets/images/default.svg'}}">
                                                        <div class="media-body ml-2">
                                                            <p class="pl-2">{{organization.page_title}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Position"
                                            [readonly]="!editable" formControlName="position">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('position').hasError('required') && (activityUpdForm.get('position').dirty || activityUpdForm.get('position').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('position').hasError('maxlength') && (activityUpdForm.get('position').dirty || activityUpdForm.get('position').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label>
                                    City
                                    <input type="text" class="form-control" placeholder="City" [readonly]="!editable"
                                        formControlName="city">
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('city').hasError('required') && (activityUpdForm.get('city').dirty || activityUpdForm.get('city').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('city').hasError('maxlength') && (activityUpdForm.get('city').dirty || activityUpdForm.get('city').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </label>
                            </div>
                            <div class="col-6" *ngIf="editable">
                                <label>
                                    Postal Code
                                    <input type="text" class="form-control" placeholder="Postal Code"
                                        [readonly]="!editable" formControlName="zip_code">
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('zip_code').hasError('required') && (activityUpdForm.get('zip_code').dirty || activityUpdForm.get('zip_code').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('zip_code').hasError('maxlength') && (activityUpdForm.get('zip_code').dirty || activityUpdForm.get('zip_code').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>
                                    <span *ngIf="editActivity.start_date != editActivity.end_date" style="color: #335175;" class="mr-2">Start</span>
                                    <span *ngIf="editActivity.start_date == editActivity.end_date" style="color: #335175;" class="mr-2">Date</span>
                                    <input type="text" class="form-control" *ngIf="!editable" readonly
                                        value="{{editActivity.start_date}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="editable"
                                        [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('start_date').hasError('required') && (activityUpdForm.get('start_date').dirty || activityUpdForm.get('start_date').touched || submitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 col-6" *ngIf="editActivity.start_date != editActivity.end_date">
                                <label>End
                                    <input type="text" class="form-control" *ngIf="!editable" readonly
                                        value="{{editActivity.end_date}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="editable"
                                        [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.errors?.invalidEndDate && (activityUpdForm.touched || activityUpdForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Learned</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  " [readonly]="!editable"
                                            formControlName="skill_1">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_1').hasError('required') && (activityUpdForm.get('skill_1').dirty || activityUpdForm.get('skill_1').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_1').hasError('maxlength') && (activityUpdForm.get('skill_1').dirty || activityUpdForm.get('skill_1').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " [readonly]="!editable"
                                            formControlName="skill_2">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_2').hasError('required') && (activityUpdForm.get('skill_2').dirty || activityUpdForm.get('skill_2').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_2').hasError('maxlength') && (activityUpdForm.get('skill_2').dirty || activityUpdForm.get('skill_2').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " [readonly]="!editable"
                                            formControlName="skill_3">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_3').hasError('required') && (activityUpdForm.get('skill_3').dirty || activityUpdForm.get('skill_3').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_3').hasError('maxlength') && (activityUpdForm.get('skill_3').dirty || activityUpdForm.get('skill_3').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="main_role"> </textarea>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('main_role').hasError('required') && (activityUpdForm.get('main_role').dirty || activityUpdForm.get('main_role').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('main_role').hasError('maxlength') && (activityUpdForm.get('main_role').dirty || activityUpdForm.get('main_role').touched)">
                                    Max 750 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Summary of Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="personal_details"> </textarea>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('personal_details').hasError('required') && (activityUpdForm.get('personal_details').dirty || activityUpdForm.get('personal_details').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('personal_details').hasError('maxlength') && (activityUpdForm.get('personal_details').dirty || activityUpdForm.get('personal_details').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="delActivity()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateActivity(activityUpdForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #skillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Skills</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="skillForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="skillForm.get('title').hasError('required') && (skillForm.get('title').dirty || skillForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="skillForm.get('title').hasError('maxlength') && (skillForm.get('title').dirty || skillForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="skillForm.get('link').hasError('pattern') && (skillForm.get('link').dirty || skillForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message"
                            *ngIf="skillForm.get('link').hasError('maxlength') && (skillForm.get('link').dirty || skillForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="skillForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Explain your skill</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="skillForm.get('description').hasError('required') && (skillForm.get('description').dirty || skillForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="skillForm.get('description').hasError('maxlength') && (skillForm.get('description').dirty || skillForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addSkill(skillForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #editSkillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Skills</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="skillForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" [readonly]="!editable" class="form-control modal-control text-left" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="skillForm.get('title').hasError('required') && (skillForm.get('title').dirty || skillForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="skillForm.get('title').hasError('maxlength') && (skillForm.get('title').dirty || skillForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div *ngIf="editable || editSkill.document" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add file</label>
                        <label *ngIf="!editable">File</label>
                    </div>
                    <div *ngIf="editable && !editSkill.document" class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editSkill.document" class="col-md-10">

                        <a *ngIf="!isImage(editSkill.document_name)" href="{{editSkill.document}}" target="_blank"
                            class="link-style">{{editSkill.document_name}}</a>
                        <a *ngIf="isImage(editSkill.document_name)" href="{{editSkill.document}}" target="_blank">
                            <img  alt="image" src="{{editSkill.document}}" class="uploaded-img">
                        </a>
                        <a href="javascript:void()" *ngIf="editable" (click)="removeSkillDoc(editSkill.id)">
                            <img  alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>
                <div *ngIf="editable || editSkill.link" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add Link</label>
                        <label *ngIf="!editable">Link</label>
                    </div>
                    <div *ngIf="editable" class="col-md-10">
                        <input type="text" class="form-control modal-control" [readonly]="!editable" placeholder="Link"
                            formControlName="link">
                        <div class="error-message"
                            *ngIf="skillForm.get('link').hasError('pattern') && (skillForm.get('link').dirty || skillForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message"
                            *ngIf="skillForm.get('link').hasError('maxlength') && (skillForm.get('link').dirty || skillForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="skillForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                    <div *ngIf="!editable" class="col-md-10">
                        <a href={{editSkill.link}} target="_blank" class="link-style">{{editSkill.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label *ngIf="editable">Explain your skill</label>
                        <label *ngIf="!editable">Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="skillForm.get('description').hasError('required') && (skillForm.get('description').dirty || skillForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="skillForm.get('description').hasError('maxlength') && (skillForm.get('description').dirty || skillForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="delSkill()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateSkill(skillForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #interestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interests</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="interestForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="interestForm.get('title').hasError('required') && (interestForm.get('title').dirty || interestForm.get('title').touched || interestFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="interestForm.get('title').hasError('maxlength') && (interestForm.get('title').dirty || interestForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="interestForm.get('link').hasError('pattern') && (interestForm.get('link').dirty || interestForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message"
                            *ngIf="interestForm.get('link').hasError('maxlength') && (interestForm.get('link').dirty || interestForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="interestForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="interestForm.get('description').hasError('required') && (interestForm.get('description').dirty || interestForm.get('description').touched || interestFrmsubmitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="interestForm.get('description').hasError('maxlength') && (interestForm.get('description').dirty || interestForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addInterest(interestForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #editInterestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interests</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="interestForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" [readonly]="!editable" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="interestForm.get('title').hasError('required') && (interestForm.get('title').dirty || interestForm.get('title').touched || interestFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="interestForm.get('title').hasError('maxlength') && (interestForm.get('title').dirty || interestForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div *ngIf="editable || editInterest.document" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add file</label>
                        <label *ngIf="!editable">File</label>
                    </div>
                    <div *ngIf="editable && !editInterest.document" class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editInterest.document" class="col-md-10">
                        <a *ngIf="!isImage(editInterest.document_name)" href="{{editInterest.document}}"
                            target="_blank">{{editInterest.document_name}}</a>
                        <a *ngIf="isImage(editInterest.document_name)" href="{{editInterest.document}}" target="_blank">
                            <img  alt="image" src="{{editInterest.document}}" class="uploaded-img">
                        </a>
                        <a href="javascript:void()" *ngIf="editable" (click)="removeInterestDoc(editInterest.id)">
                            <img  alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>
                <div *ngIf="editable || editInterest.link" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add Link</label>
                        <label *ngIf="!editable">Link</label>
                    </div>
                    <div *ngIf="editable" class="col-md-10">
                        <input type="text" class="form-control modal-control" [readonly]="!editable" placeholder="Link"
                            formControlName="link">
                        <div class="error-message"
                            *ngIf="interestForm.get('link').hasError('pattern') && (interestForm.get('link').dirty || interestForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message"
                            *ngIf="interestForm.get('link').hasError('maxlength') && (interestForm.get('link').dirty || interestForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="interestForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                    <div *ngIf="!editable" class="col-md-10">
                        <a href={{editInterest.link}} target="_blank" class="link-style">{{editInterest.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="interestForm.get('description').hasError('required') && (interestForm.get('description').dirty || interestForm.get('description').touched || interestFrmsubmitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="interestForm.get('description').hasError('maxlength') && (interestForm.get('description').dirty || interestForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="delInterest()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateInterest(interestForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #addBooks>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Books</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bookForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control modal-control" placeholder="Title of Book"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bookForm.get('title').hasError('required') && (bookForm.get('title').dirty || bookForm.get('title').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('title').hasError('maxlength') && (bookForm.get('title').dirty || bookForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Name of Author</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" placeholder="Full Name of Author"
                            formControlName="author">
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('required') && (bookForm.get('author').dirty || bookForm.get('author').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('maxlength') && (bookForm.get('author').dirty || bookForm.get('author').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Rating</label>
                    </div>
                    <div class="col-md-9 custom-rating">
                        <ngb-rating [max]="5" (rateChange)="getRating($event)">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Reflection</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" placeholder="Type Here"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('required') && (bookForm.get('description').dirty || bookForm.get('description').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('maxlength') && (bookForm.get('description').dirty || bookForm.get('description').touched)">
                            Max 3000 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="addBook(bookForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #updateBooks>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Books</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bookForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" [readonly]="!editable" class="form-control modal-control"
                            placeholder="Title of Book" formControlName="title">
                        <div class="error-message"
                            *ngIf="bookForm.get('title').hasError('required') && (bookForm.get('title').dirty || bookForm.get('title').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('title').hasError('maxlength') && (bookForm.get('title').dirty || bookForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Name of Author</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" [readonly]="!editable" placeholder="Full Name of Author"
                            formControlName="author">
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('required') && (bookForm.get('author').dirty || bookForm.get('author').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('author').hasError('maxlength') && (bookForm.get('author').dirty || bookForm.get('author').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row custom-rating">
                    <div class="col-md-3">
                        <label>Rating</label>
                    </div>
                    <div *ngIf="editBook.rating" class="col-md-9">
                        <ngb-rating [max]="5" [(rate)]="editBook.rating" [readonly]="!editable"
                            (rateChange)="getRating($event)">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </div>
                    <div *ngIf="!editBook.rating" class="col-md-9">
                        <ngb-rating [max]="5" [readonly]="!editable" (rateChange)="getRating($event)">
                            <ng-template let-fill="fill" let-index="index">
                                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3"></span>
                            </ng-template>
                        </ngb-rating>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Reflection</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [readonly]="!editable" placeholder="Reflection"
                            formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('required') && (bookForm.get('description').dirty || bookForm.get('description').touched || bookFrmsubmitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bookForm.get('description').hasError('maxlength') && (bookForm.get('description').dirty || bookForm.get('description').touched)">
                            Max 3000 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="delBook()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateBook(bookForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #buletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add file</label>
                    </div>
                    <div class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Add Link</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" class="form-control modal-control" placeholder="Link" formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('required') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" [disabled]="disableSubmit" (click)="addBulletin(bulletinForm.valid)">
            Add
        </button>
    </div>
</ng-template>
<ng-template #editbulletinModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="bulletinForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        <input type="text" [readonly]="!editable" class="form-control modal-control" placeholder="Title"
                            formControlName="title">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('required') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('title').hasError('maxlength') && (bulletinForm.get('title').dirty || bulletinForm.get('title').touched)">
                            Max 30 characters allowed.
                        </div>
                    </div>
                </div>
                <div *ngIf="editable || editBulletin.document" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add file</label>
                        <label *ngIf="!editable">File</label>
                    </div>
                    <div *ngIf="editable && !editBulletin.document" class="col-md-10">
                        <input type="file" class="file-uploader" placeholder="Type Here"
                            (change)="onFileSelect($event)">
                        <div *ngIf="fileUploadError" class="error-message">
                            {{fileUploadMessage}}
                        </div>
                        <div *ngIf="fileUploadSizeError" class="error-message">
                            {{fileUploadSizeMessage}}
                        </div>
                    </div>
                    <div *ngIf="editBulletin.document" class="col-md-10">

                        <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank"
                            class="link-style">{{editBulletin.document_name}}</a>
                        <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}" target="_blank">
                            <img  alt="image" src="{{editBulletin.document}}" class="uploaded-img">
                        </a>
                        <a href="javascript:void()" *ngIf="editable" (click)="removeBulletinDoc(editBulletin.id)">
                            <img  alt="image" src="assets/images/delete_icon.svg">
                        </a>
                    </div>
                </div>
                <div *ngIf="editable || editBulletin.link" class="form-group row">
                    <div class="col-md-2">
                        <label *ngIf="editable">Add Link</label>
                        <label *ngIf="!editable">Link</label>
                    </div>
                    <div *ngIf="editable" class="col-md-10">
                        <input type="text" class="form-control modal-control" [readonly]="!editable" placeholder="Link"
                            formControlName="link">
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('maxlength') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Max 250 characters allowed.
                        </div>
                        <div class="error-message"
                            *ngIf="bulletinForm.get('link').hasError('pattern') && (bulletinForm.get('link').dirty || bulletinForm.get('link').touched)">
                            Please enter a valid URL.
                        </div>
                        <div class="error-message" *ngIf="bulletinForm.get('link').hasError('aftersubmit')">
                            Please enter a valid URL.
                        </div>
                    </div>
                    <div *ngIf="!editable" class="col-md-10">
                        <a href={{editBulletin.link}} target="_blank" class="link-style">{{editBulletin.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="description"> </textarea>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('required') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="bulletinForm.get('description').hasError('maxlength') && (bulletinForm.get('description').dirty || bulletinForm.get('description').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="editable">
        <button class="btn btn-footer" (click)="delBulletin()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateBulletin(bulletinForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<div class="overlay-edit" *ngIf="editable">
</div>
<div class="onBoard-overlay noticeOverlay" *ngIf="user.age_group_updated">
    <div class="onBoard-div">
        <div class="noticeDiv" *ngIf="stepOne">
            <h3>CONGRATS, CONNECTUS.TODAY GRAD!</h3>
            <p>
                Every year, on April 21, we take all of the students who will be turning 14 within the year and graduate
                them to a Full-Access User (a.k.a. users who have full access to all features within our Platform). We
                have noticed that you are turning 14 years old this year, so you, too, will be able to use the full
                site!

            </p>
            <p>
                Before using ConnectUs.Today as a Full-Access User, we must ask that you Read and Accept the Terms of
                Use and Privacy Policy again with a Parent/Guardian, so that you understand how your Terms and Privacy
                as a user is changing.
            </p>
            <div class="text-center">
                <button class="common-btn" (click)="showTwo();">Next</button>
            </div>
        </div>
        <div class="noticeDiv" *ngIf="stepTwo">
            <h3>TERMS OF USE / PRIVACY POLICY CLICKWRAP</h3>
            <p>
                Please Read and Accept the following Terms of Use and Privacy Policy with a Parent/Guardian, so that you
                understand how your Terms and Privacy as a user is changing. If you do not Accept, you will only have
                access to Account Settings where you may either Delete your Account or Read through the Terms and
                Privacy Policy to Accept.
            </p>
            <form [formGroup]="termsAcceptForm">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
                            (change)="onCheckboxChange($event)">
                        <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
                            Policy</label>
                    </div>
                    <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
                        This field is required.
                    </div>
                </div>
            </form>
            <div class="text-center">
                <button class="common-btn ghost mr-3" (click)="showThree();">Logout</button>
                <button class="common-btn" (click)="submitTermsAcceptForm()">Next</button>
            </div>
        </div>
        <div class="noticeDiv" *ngIf="stepThree">
            <h3>YOU ARE ABOUT TO LOG OUT!</h3>
            <h3 class="text-left">PLEASE NOTE</h3>
            <p>
                If you Log Out, you understand that you will only have Access to your Account Settings to either Review
                and Accept the Terms of Use and Privacy Policy, or to Delete your Account.</p>
            <h3 class="text-left">
                YOU WILL NOT HAVE ACCESS TO THE REST OF YOUR ACCOUNT UNTIL YOU ACCEPT THE TERMS OF USE AND PRIVACY
                POLICY.
            </h3>
            <p>
                Click Log Out to continue, or click the Back button to return to the previous screen.
            </p>
            <form [formGroup]="termsAcceptForm">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="accept" formControlName="terms"
                            (change)="onCheckboxChange($event)">
                        <label class="custom-control-label" for="accept">I Accept the Terms of Use and Privacy
                            Policy</label>
                    </div>
                    <div class="error-message" *ngIf="termsAcceptForm.get('terms').hasError('required')">
                        This field is required.
                    </div>
                </div>
            </form>
            <div class="text-center">
                <button class="common-btn ghost mr-3" (click)="goBack()">Go Back</button>
                <button class="common-btn" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>
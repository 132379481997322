<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <div class="centerDiv text-center w-100">
                        <img  alt="image" src="assets/images/connect-us-logo.svg" class="mx-auto">
                        <img  alt="image" src="assets/images/testing_phase.svg" class="testingIcon">
                        <button class="common-button" (click)="openInfo()">Fuel your learning
                            <img  alt="image" src="assets/images/help-icon.svg" alt="" class="ml-2 mt-md-1">
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student form-section">
                    <app-sidebar></app-sidebar>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center mx-auto">
                            <h1>Sign Up</h1>
                            <p>Student</p>
                            <div *ngIf="submitError" class="error-message col-md-12 text-center">{{submitErrorMsg}}</div>
                            <form [hidden]="!firstStep" [formGroup]="firstStepForm" novalidate>
                                <p class="topInfo">Please enter all accurate information below. You will not be allowed to change your Name or Date of Birth once your account is created</p>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <label for="first">Name <span>*</span></label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" placeholder="First" class="form-control"
                                            formControlName="firstname">
                                        <div class="error-message"
                                            *ngIf="firstStepForm.get('firstname').hasError('required') && (partialSubmitted || firstStepForm.get('firstname').dirty || firstStepForm.get('firstname').touched)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="firstStepForm.get('firstname').hasError('maxlength') && (firstStepForm.get('firstname').dirty || firstStepForm.get('firstname').touched)">
                                            Max 100 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="text" placeholder="Last" class="form-control"
                                            formControlName="lastname">
                                        <div class="error-message"
                                            *ngIf="firstStepForm.get('lastname').hasError('required') && (partialSubmitted || firstStepForm.get('lastname').dirty || firstStepForm.get('lastname').touched)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="firstStepForm.get('lastname').hasError('maxlength') && (firstStepForm.get('lastname').dirty || firstStepForm.get('lastname').touched)">
                                            Max 100 characters allowed.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <label for="">Birthday <span>*</span></label>
                                    </div>
                                    <div class="col-md-3 pr-0">
                                        <input type="number" placeholder="MM" class="form-control"
                                            formControlName="month_of_birth" (focusout)="checkDate()">
                                        <ng-container *ngFor="let error of error_messages.month_of_birth">
                                            <div class="error-message"
                                                *ngIf="firstStepForm.get('month_of_birth').hasError(error.type) && (partialSubmitted || firstStepForm.get('month_of_birth').dirty || firstStepForm.get('month_of_birth').touched)">
                                                {{ error.message }}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-3 pr-0">
                                        <input type="number" placeholder="DD" class="form-control"
                                            formControlName="date_of_birth" (focusout)="checkDate()">
                                        <ng-container *ngFor="let error of error_messages.date_of_birth">
                                            <div class="error-message"
                                                *ngIf="firstStepForm.get('date_of_birth').hasError(error.type) && (partialSubmitted || firstStepForm.get('date_of_birth').dirty || firstStepForm.get('date_of_birth').touched)">
                                                {{ error.message }}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="number" placeholder="YYYY" class="form-control"
                                            formControlName="year_of_birth" (focusout)="checkDate()">
                                        <ng-container *ngFor="let error of error_messages.year_of_birth">
                                            <div class="error-message"
                                                *ngIf="firstStepForm.get('year_of_birth').hasError(error.type) && (partialSubmitted || firstStepForm.get('year_of_birth').dirty || firstStepForm.get('year_of_birth').touched)">
                                                {{ error.message }}
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="error-message" *ngIf="!invalidDate">
                                        The date of birth is invalid.
                                    </div>
                                </div>
                                <div class="form-group gutters-5 row">
                                    <div class="col-md-12">
                                        <label for="">Email <span>*</span></label>
                                    </div>
                                    <input type="email" placeholder="Email" class="form-control"
                                        formControlName="email">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('required') && (partialSubmitted || firstStepForm.get('email').dirty || firstStepForm.get('email').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('maxlength') && (firstStepForm.get('email').dirty || firstStepForm.get('email').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('pattern') && (firstStepForm.get('email').dirty || firstStepForm.get('email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('email').hasError('aftersubmit')">
                                        {{firstStepForm.controls.email.errors.aftersubmit}}
                                    </div>
                                </div>
                                <div class="form-group gutters-5 row">
                                    <input type="email" placeholder="Repeat Email" class="form-control"
                                        formControlName="confirm_email">
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_email').hasError('required') && (partialSubmitted || firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_email').hasError('maxlength') && (firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        Max 100 characters allowed.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.get('confirm_email').hasError('pattern') && (firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        Please enter your email in a valid format.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="firstStepForm.hasError('emailNotMatch') && (firstStepForm.get('confirm_email').dirty || firstStepForm.get('confirm_email').touched)">
                                        Emails do not match.
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-submit"
                                        (click)="clickNext(firstStepForm.valid)">Next</button>
                                </div>
                            </form>
                            <form [hidden]="!secondStep" [formGroup]="secondStepForm" novalidate
                                (ngSubmit)="save(secondStepForm.valid)">
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12" *ngIf="isMinor">
                                        <input type="email" class="form-control mb-2" placeholder="Parent's Email"
                                            formControlName="parent_email">
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('parent_email').hasError('required') && (submitted || secondStepForm.get('parent_email').dirty || secondStepForm.get('parent_email').touched)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('parent_email').hasError('maxlength') && (secondStepForm.get('parent_email').dirty || secondStepForm.get('parent_email').touched)">
                                            Max 100 characters allowed.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('parent_email').hasError('pattern') && (secondStepForm.get('parent_email').dirty || secondStepForm.get('parent_email').touched)">
                                            Please enter your email in a valid format.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('parent_email').hasError('aftersubmit')">
                                            {{secondStepForm.controls.parent_email.errors.aftersubmit}}
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <label for="">Last Step! <span>*</span></label>
                                    </div>

                                    <div class="col-md-12">
                                        <input type="password" class="form-control mb-2" placeholder="Password"
                                            formControlName="password">
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('password').hasError('required') && (submitted || secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('password').hasError('maxlength') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                                            Max 15 characters allowed.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="!secondStepForm.get('password').hasError('maxlength') && secondStepForm.get('password').hasError('invalidPassword') && (secondStepForm.get('password').dirty || secondStepForm.get('password').touched)">
                                            Your password needs to be at least 8 characters, including a Capital Letter,
                                            Lowercase Letter and a Number.
                                        </div>
                                        <input type="password" class="form-control mb-2" placeholder="Repeat Password"
                                            formControlName="confirm_password">
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('confirm_password').hasError('required') && (submitted || secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="secondStepForm.get('confirm_password').hasError('minlength') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                                            Your password needs to be at least 8 characters, including a Capital Letter,
                                            Lowercase Letter and a Number.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="secondStepForm.hasError('passwordNotMatch') && (secondStepForm.get('confirm_password').dirty || secondStepForm.get('confirm_password').touched)">
                                            Passwords do not match.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"
                                            name="privacy" (change)="onCheckboxChange($event)">
                                        <label class="custom-control-label" for="customCheck">I have read and agree to
                                            the
                                            <a [routerLink]="['/terms-of-use']" target="_blank" rel="noopener noreferrer">Terms of use</a> and
                                            <a [routerLink]="['/privacy-policy']" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                                        </label>
                                    </div>
                                    <div class="error-message" *ngIf="!user.is_terms_accepted && submitted">
                                        Please agree to the Terms and Conditions and Privacy Policy.
                                    </div>
                                </div>
                                <div class="form-group row gutters-5">
                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-submit">Register</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="success-overlay" *ngIf="successOverlay">
    <div class="success-div">
        <div *ngIf="isMinor" class="inner-div text-center">
            <p>Verification Email Sent</p>
            <h1>Please Verify Your Email to Access Your Account. Make sure your Parent also verifies your Email Address.
            </h1>
            <h1>{{resendEmailMessage}}</h1>
            <button class="btn btn-common mr-3" (click)="disableOverlay()">Yes</button>
            <button class="btn btn-common" (click)="resendVerification()">Re-Send Email</button>
        </div>
        <div *ngIf="!isMinor" class="inner-div text-center">
            <p>Verification Email Sent</p>
            <h1>Please Verify Your Email to Access Your Account</h1>
            <h1>{{resendEmailMessage}}</h1>
            <button class="btn btn-common mr-3" (click)="disableOverlay()">Yes</button>
            <button class="btn btn-common" (click)="resendVerification()">Re-Send Email</button>
        </div>
    </div>
</div>
<div class="info-overlay" *ngIf="infoSlide">
    <div class="info-div">
        <button type="button" class="close-btn" aria-label="Close" (click)="hideInfo()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
        <img  alt="image" src="assets/images/FuelYourLearningNew.png" class="d-md-block d-none">
        <img  alt="image" src="assets/images/info-mobile.svg" class="d-md-none d-block">
    </div>
</div>
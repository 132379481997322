import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { School } from '../shared/school';
import { User } from '../shared/user';
import { SchoolUser } from '../shared/schoolUser';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomvalidationService } from '../services/customvalidation.service';
import { canadaStates, usStates } from '../app.data';

@Component({
  selector: 'app-school',
  templateUrl: './school.component.html',
  styleUrls: ['./school.component.scss']
})
export class SchoolComponent implements OnInit {
  resendEmailMessage: string= '';
  enteredEmail: string;
  nextShow: boolean = false;
  currentForm: boolean = true;
  firstStep: boolean = false;
  secondStep: boolean = false;
  firstStepForm: FormGroup;
  secondStepForm: FormGroup;
  searchForm: FormGroup;
  public submitted: boolean = false;
  public successOverlay: boolean = false;
  public searchSubmitted: boolean = false;
  public caStates: any[] = canadaStates;
  public usStates: any[] = usStates;
  public showCaStates: boolean = true;
  public showAddressError: boolean = false;
  public partialSubmitted: boolean = false;
  public hideResults: boolean = true;
  public schools: any[];
  public infoDiv  = true;
  public infoDiv1  = true;
  schoolId: string = null;
  plchldrState = "PR";
  plchldrPostal = "Postal";
  hidePlaceResults: boolean = true;
  searchPlaces: any[] = [];
  updateAddress: string = '';
  countrySet: string = 'CA';
  infoSlide: boolean = false;
  public invalidDate: boolean = false;
  maxNum: number = new Date().getFullYear();
  public dob: string;
  public age: number;
  public invalidDateMsg: string;
  submitErrorMsg: string = null;
  public submitError: boolean = false;

  user: User = {
    first_name: '',
    last_name: '',
    email: '',
    user_role : 3,
    password : '',
    is_terms_accepted : false,
    parent_email : undefined,
    date_of_birth: undefined,
    student_referral: undefined
  };

  school: School = {
    title: '',
    school_name: '',
    account_type: 1,
    school_category: 1,
    address: '',
    country: '',
    state: '',
    city: '',
    district: '',
    zip_code: '',
    user: this.user,
    professional_email: undefined,
    latitude: undefined,
    longitude: undefined
}

schoolUser: SchoolUser = {
  title: '',
  school: '',
  user: this.user,
  account_type: 1,
  professional_email: undefined
}

  error_messages = {
    'year_of_birth': [
      { type: 'required', message: 'This field is required.' },
      { type: 'min', message: 'Please enter valid Year.' },
      { type: 'max', message: 'Max ' + this.maxNum + ' is allowed.' }
    ]
  }

  constructor(private authService: AuthenticationService, private router: Router, public formBuilder: FormBuilder, private customValidator: CustomvalidationService) {

    if (localStorage.getItem('user')) {
      this.router.navigate(['/dashboard']);
    }
    this.searchForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      country: new FormControl('', Validators.required),
      account_type: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      professional_email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
    });
    this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
    this.firstStepForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      school_name: new FormControl('', [Validators.required, Validators.maxLength(125)]),
      address: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      country: new FormControl('', Validators.required),
      state: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      district: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.required, Validators.minLength(7), Validators.maxLength(7)])
    });
    this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
    
    this.secondStepForm = this.formBuilder.group({
      firstname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      lastname: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      confirm_email: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.customValidator.emailRegex)]),
      password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(15), this.customValidator.patternValidator()]),
      confirm_password: new FormControl('', Validators.required),
      student_referral: new FormControl('', [Validators.email, Validators.maxLength(100), Validators.pattern(this.customValidator.emailRegex)]),
      year_of_birth: new FormControl('', [Validators.required, Validators.min(1000), Validators.max(this.maxNum)])
    }, {
      validators: [this.checkEmail.bind(this), this.checkPassword.bind(this)]
    });

  } 
 
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, "autoPlay": true,};
  slideConfigMob = {"slidesToShow": 1, "slidesToScroll": 1, "dots": true, "adaptiveHeight": true, };

  ngOnInit(): void {
    this.showCaStates = true;
    this.hideResults = true;
  }
  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }

  checkDate() {
    if (this.secondStepForm.get('year_of_birth').value !== "" && this.secondStepForm.get('year_of_birth').value !== null) {
      this.dob = (this.secondStepForm.get('year_of_birth').value).toString() + '-1-1';
      let timeDiff = Math.abs(Date.now() - new Date(this.dob.replace(/-/g, "/")).getTime());
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
      if(new Date(this.dob.replace(/-/g, "/")) > new Date()){
        this.invalidDate = true;
        this.invalidDateMsg = "The year of birth is invalid.";
      } else if(this.age < 20) {
        this.invalidDate = true;
        this.invalidDateMsg = "Sorry, the minimum age to set up a Business is 20 years old.";
      } else {
        this.invalidDate = false;
        this.invalidDateMsg = null;
      }
    }
  }

  searchSchool(e){
    let key = e.target.value;
    this.schoolId = null;
    if (key !== null && key.length > 0) {
      this.schools = [];
      let state = this.searchForm.get('state').value;
      if (state == null) {
        state = "";
      }
      let country = this.searchForm.get('country').value;
      const params = key.toLowerCase() + '&country=' + country.toLowerCase() + '&state=' + state.toLowerCase();
      this.authService.searchSchool(params)
      .subscribe(response => {
        if (response.status == 200){
          if (response.response.length > 0) {
            this.hideResults = false;
            this.schools = response.response;
          } else {
            this.hideResults = true;
          }
        }
      });
    } else {
      this.hideResults = true;
      this.schools = [];
    }
  }

  focusOutFunction(){
    setTimeout(() => {
      this.hideResults = true;
      this.schools = [];
    }, 1000);
  }

  validateEmail(){
    let email = this.searchForm.get('professional_email').value;
    if (email && email.length > 0 && this.searchForm.controls['professional_email'].valid) {
      this.authService.validateEmail({"professional_email": email})
      .subscribe(response => {
        if (response.status === 111) {
          this.searchForm.controls['professional_email'].setErrors({'invalid_format': true});
        }
      })
    }
  }

  selectSchool(school) {
    this.hideResults = true;
    this.searchForm.controls['school_name'].setValue(school.school_name);
    this.firstStepForm.controls['school_name'].setValue(school.school_name);
    this.schoolId = school.id;
    this.schools = [];
  }

  showNext(isValid: boolean){
    if (isValid && this.schoolId == null) {
      this.nextShow = true;
      this.currentForm = false;
      this.firstStep = false;
      this.secondStep = false;
      this.firstStepForm.controls['title'].setValue(this.searchForm.get('title').value);
      this.firstStepForm.controls['country'].setValue(this.searchForm.get('country').value);
      this.firstStepForm.controls['state'].setValue(this.searchForm.get('state').value);
      this.firstStepForm.controls['school_name'].setValue(this.searchForm.get('school_name').value);
    } else if (isValid && this.schoolId !== null) {
        this.nextShow = false;
        this.currentForm = false;
        this.firstStep = false;
        this.secondStep = true;
    } else {
      this.searchSubmitted = true;
    }
  }

  showNextForm(){
    this.nextShow = false;
    this.currentForm = false;
    this.firstStep = true;
    this.secondStep = false;
  }

  showLastForm(isValid: boolean){
    if (isValid){
      this.firstStep = false;
      this.secondStep = true;
    } else {
      this.partialSubmitted = true;
    }
  }

  checkPassword(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirmPassword } = formGroup.get('confirm_password');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  checkEmail(formGroup: FormGroup) {
    const { value: email } = formGroup.get('email');
    const { value: confirmEmail } = formGroup.get('confirm_email');
    return email === confirmEmail ? null : { emailNotMatch: true };
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.user.is_terms_accepted = true;
    } else {
      this.user.is_terms_accepted = false;
    }
  }

  disableOverlay(){
    this.successOverlay = false;
    this.enteredEmail = null;
  }

  changeAddress(e){
    if (e.target.value == "" || e.target.value == null || e.target.value == undefined) {
      this.firstStepForm.controls['address'].setValue(null);
      this.showAddressError = true;
    } else {
      this.firstStepForm.controls['address'].setValue(e.target.value);
      this.showAddressError = false;
    }
  }

  getAddrComponent(place, componentTemplate) {
    let result;

    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }

    return;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
    city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'long_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  changeCountry(e){
    if (e.target.value == "USA"){
      this.showCaStates = false;
      this.plchldrState = "State";
      this.plchldrPostal = "ZIP Code";
      this.countrySet = 'US';
      this.firstStepForm.controls['zip_code'].setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(5)])
    } else {
      this.showCaStates = true;
      this.plchldrState = "PR";
      this.plchldrPostal = "Postal";
      this.countrySet = 'CA';
    }
  }

  save(isValid: boolean) {
    this.submitted = true;
    if (this.user.is_terms_accepted && isValid && !this.invalidDate) {
      this.user.first_name = this.secondStepForm.get('firstname').value;
      this.user.last_name = this.secondStepForm.get('lastname').value;
      this.user.email = this.secondStepForm.get('email').value;
      this.user.password = this.secondStepForm.get('password').value;
      if (this.secondStepForm.get('student_referral').value){
        this.user.student_referral = this.secondStepForm.get('student_referral').value;
      }
      this.user.date_of_birth = this.dob;
      this.user.is_terms_accepted = true;
      if (this.schoolId != null) {
          this.schoolUser.title = this.searchForm.get('title').value;
          this.schoolUser.account_type = this.searchForm.get('account_type').value;
          this.schoolUser.school = this.schoolId;
          this.schoolUser.professional_email = this.searchForm.get('professional_email').value;
          this.authService.schoolUserSignup(this.schoolUser)
          .subscribe(response => {
            this.handleResponse(response);
          },
            error => {
              this.handleSubmitError(error.error);
            });
      } else {
        this.school.title = this.firstStepForm.get('title').value;
        this.school.account_type = this.searchForm.get('account_type').value;
        this.school.address = this.firstStepForm.get('address').value;
        this.school.school_name = this.firstStepForm.get('school_name').value;
        this.school.country = this.firstStepForm.get('country').value;
        this.school.state = this.firstStepForm.get('state').value;
        this.school.city = this.firstStepForm.get('city').value;
        this.school.zip_code = this.firstStepForm.get('zip_code').value;
        this.school.district = this.firstStepForm.get('district').value;
        this.school.professional_email = this.searchForm.get('professional_email').value;
        this.authService.schoolSignup(this.school)
        .subscribe(response => {
          this.handleResponse(response);
        },
          error => {
            this.handleSubmitError(error.error);
          });
      }
    }
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
        this.successOverlay = true;
        this.enteredEmail = this.secondStepForm.controls['email'].value;
        this.firstStep = false;
        this.secondStep = false;
        this.nextShow = false;
        this.currentForm = true;
        this.firstStepForm.reset();
        this.secondStepForm.reset();
        this.searchForm.reset();
        this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.submitted = false;
        this.partialSubmitted = false;
        this.searchSubmitted = false;
        this.schoolId = null;
    } else {
        this.firstStep = false;
        this.secondStep = false;
        this.nextShow = false;
        this.currentForm = true;
        this.firstStepForm.reset();
        this.secondStepForm.reset();
        this.searchForm.reset();
        this.firstStepForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.searchForm.controls['country'].setValue('Canada', {onlySelf: true});
        this.submitted = false;
        this.partialSubmitted = false;
        this.searchSubmitted = false;
        this.schoolId = null;
        if (response.status === 112) {
          this.submitErrorMsg = response.message;
        } else {
          this.submitErrorMsg = "Something went wrong. Please try again later";
        }
        this.submitError = true;
        this.hideMessage();
    }
  }

  hideMessage() {
    setTimeout(() => {
      this.submitError = false;
    }, 6000);
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    this.searchSubmitted = false;
    const fields = Object.keys(data || {});
    const fkFields = Object.keys(data['user'] || {});
    let firstFormErr = false;
    fields.forEach(field => {
      if (this.firstStepForm.contains(field)) {
        this.firstStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
        firstFormErr = true;
      } else if (this.secondStepForm.contains(field)) {
        this.secondStepForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
    fkFields.forEach(fkField => {
      if (this.secondStepForm.contains(fkField)) {
        this.secondStepForm.get(fkField).setErrors({ aftersubmit: data['user'][fkField][0] });
      }
    });
    if (firstFormErr) {
      this.firstStep = true;
      this.secondStep = false;
      this.nextShow = false;
      this.currentForm = false;
    } else {
      this.firstStep = false;
      this.secondStep = true;
      this.nextShow = false;
      this.currentForm = false;
    }
  }

  resendVerification(){
    let data = {
      'email': this.enteredEmail
    }
    this.authService.resendVerify(data)
      .subscribe(response => {
        this.handleReverify(response, true);
      },
        error => {
          this.handleReverify(error.error, false);
        });
  }

  protected handleReverify(response: any, is_success:boolean) {
    if (response.status == 111){
      is_success = false;
    }
    if (is_success){
      this.resendEmailMessage = 'Please check your inbox for verification email'
    } else {
      this.resendEmailMessage = 'There was an error in sending verification email. This can happen in case of invalid email or when your account has been verified already'
    }
  }

  getAddress(places: any[]) {
    this.searchPlaces = places;
  }

  setResultHide(e){
    this.hidePlaceResults = e;
  }

  formClick(){
    this.hidePlaceResults = true;
  }

  selectPlace(place){
    let lat = place.position.lat;
    let lon = place.position.lon;
    let zip_code = '';
    let address = place.address.freeformAddress.split(",")[0];
    this.updateAddress = address;
    this.firstStepForm.controls['address'].setValue(address);
    this.firstStepForm.controls['city'].setValue(place.address.municipality);
    if (place.address.extendedPostalCode && this.countrySet=='CA'){
      zip_code = place.address.extendedPostalCode;
    } else {
      zip_code = place.address.postalCode;
    }
    if (lat && lon) {
      this.school.latitude = lat;
      this.school.longitude = lon;
    }
    if (zip_code && zip_code.length > 20) {
      zip_code = place.address.postalCode;
    }
    this.firstStepForm.controls['zip_code'].setValue(zip_code);
    this.firstStepForm.controls['state'].setValue(place.address.countrySubdivisionName);
    this.hidePlaceResults = true;
    this.searchPlaces = [];
  }

}

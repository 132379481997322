<h3>

    Education
    <a href="javascript:void()" class="float-right" *ngIf="!editable && account_verification_status != '3'"
        (click)="eduModal(educationModal)">
        <img alt="image" src="assets/images/plus-icon.png" alt="">
    </a>
    <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
        <img alt="image" src="assets/images/icEdit.svg">
    </a>
</h3>
<carousel>
    <slide *ngFor="let eduObj of eduList">
        <div class="box-div inner-exp border-0"
            (click)="updateEducationPopup(educationModal, staticEduModal, eduObj.id)">
            <div class="experience-div media school-tile school-tile-border">
                <img alt="image"
                    src="{{eduObj.school.school_image? eduObj.school.school_image : 'assets/images/default.svg'}}">
                <div class="media-body ml-2">
                    <h4>{{eduObj.school.school_name}}</h4>
                    <ng-template [ngIf]="eduObj.school.is_college" [ngIfElse]="schoolBlock">
                        <p *ngIf="eduObj.majors">
                            {{eduObj.majors.join(', ')}}
                        </p>
                    </ng-template>
                    <ng-template #schoolBlock>
                        <p *ngIf="eduObj.interests">
                            {{eduObj.interests.join(', ')}}
                        </p>
                    </ng-template>
                    <h5>Start
                        <span>{{eduObj.start_date | date:'yyyy' }}</span> End
                        <span>{{eduObj.end_date | date:'yyyy' }}</span>
                    </h5>
                </div>
            </div>
        </div>
    </slide>
</carousel>
<div *ngIf="eduList?.length == 0"
    class="empty-div text-center h-100 d-flex align-items-center justify-content-center box-div">
    <p>Add places you have studied</p>
</div>


<ng-template #educationModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">

            <form [hidden]="!getCurrentEdForm(1)" [formGroup]="educationForm" novalidate>
                <div class="error-message"
                    *ngIf="educationForm.hasError('aftersubmit') && (educationForm.dirty || educationForm.touched || nextClicked)">
                    Something went wrong.
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Select {{insText}}</h3>
                    </div>
                    <div class="col-md-12">
                        <label>Institute type</label>
                    </div>
                    <div class="col-4">
                        <div class="custom-control custom-radio">
                            <input formControlName="is_college" type="radio" value="false" checked="" id="customCheck2"
                                name="is_college" class="custom-control-input" (change)="changeSchoolCollege($event)">
                            <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                        </div>
                    </div>
                    <div class="col-8">
                        <div class="custom-control custom-radio">
                            <input formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" class="custom-control-input" (change)="changeSchoolCollege($event)">
                            <label for="customCheck3"
                                class="custom-control-label custom-label mb-0">College/University</label>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Country</label>
                        <select formControlName="country" class="form-control custom-select"
                            (change)="changeCountry($event)" placeholder="Country">
                            <option selected>Canada</option>
                            <option>USA</option>
                        </select>
                        <div class="error-message"
                            *ngIf="educationForm.get('country').hasError('required') && (educationForm.get('country').dirty || educationForm.get('country').touched || nextClicked)">
                            This field is required.
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label>{{plchldrState}}</label>
                        <select class="form-control custom-select" formControlName="state"
                            (change)="changeState($event)">
                            <option [hidden]="!showCaStates" [disabled]="!showCaStates" *ngFor="let state of caStates"
                                value="{{state.name}}">{{state.name}}</option>
                            <option [hidden]="showCaStates" [disabled]="showCaStates" *ngFor="let state of usStates"
                                value="{{state.name}}">{{state.name}}</option>
                        </select>
                        <div class="error-message"
                            *ngIf="educationForm.get('state').hasError('required') && (educationForm.get('state').dirty || educationForm.get('state').touched || nextClicked)">
                            This field is required.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>{{insText}} Name</label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" class="form-control" formControlName="school_name"
                            placeholder="Full {{insText}} Name" (keydown)="searchSchool($event)"
                            (focusout)="focusOutFunction()">
                        <div class="error-message"
                            *ngIf="educationForm.get('school_name').hasError('required') && (educationForm.get('school_name').dirty || educationForm.get('school_name').touched || nextClicked)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="educationForm.get('school_name').hasError('maxlength') && (educationForm.get('school_name').dirty || educationForm.get('school_name').touched)">
                            Max 30 characters allowed.
                        </div>
                        <div *ngIf="!hideResults" class="col-md-9">
                            <div class="option-div">
                                <div class="listing" *ngFor="let school of schools" (click)="selectSchool(school)">
                                    <img
                                        src="{{school.display_picture? school.display_picture : 'assets/images/default.svg'}}">
                                    <div class="detail">
                                        <h6>{{school.school_name}}</h6>
                                        <span> {{school.address}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <form [hidden]="!getCurrentEdForm(2)" [formGroup]="newSchoolForm" novalidate>
                <div class="error-message"
                    *ngIf="newSchoolForm.hasError('aftersubmit') && (newSchoolForm.dirty || newSchoolForm.touched || nextClicked)">
                    Something went wrong.
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Enter {{insText}} Details</h3>
                    </div>
                    <div class="col-md-12">
                        <label for="">Type of {{insText}}</label>
                    </div>
                    <div class="col-md-12">
                        <select formControlName="school_type" class="custom-select">
                            <option [hidden]="isCollegeSelected" *ngFor="let ins_type of schoolTypes"
                                value="{{ins_type.id}}">{{ins_type.value}}</option>
                            <option [hidden]="!isCollegeSelected" *ngFor="let ins_type of collegeTypes"
                                value="{{ins_type.id}}">{{ins_type.value}}</option>
                        </select>
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('school_type').hasError('required') && (newSchoolForm.get('school_type').dirty || newSchoolForm.get('school_type').touched || nextClicked)">
                            This field is required.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Full Address</label>
                    </div>
                    <div class="col-md-9">
                        <app-azure-maps [updateAddress]="updateAddress" [countrySet]="countrySet"
                            (change)="changeAddress($event)" (setAddress)="getAddress($event)"
                            (setHideResult)="setResultHide($event)"></app-azure-maps>
                        <div *ngIf="!hidePlaceResults" class="col-md-12">
                            <div class="option-div">
                                <div class="listing" *ngFor="let place of searchPlaces" (click)="selectPlace(place)">
                                    <svg class="location-icon" xmlns="http://www.w3.org/2000/svg" width="15" height="20"
                                        viewBox="0 0 15 20">
                                        <path fill="none" stroke="#505759" stroke-width="1.4"
                                            d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                    </svg>
                                    <div class="detail">
                                        <h6>{{place.address.freeformAddress}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input formControlName="address" [hidden]="true" type="text" class="form-control"
                            placeholder="Full Address">
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('address').hasError('required') && (newSchoolForm.get('address').dirty || newSchoolForm.get('address').touched || nextClicked || showAddressError)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('address').hasError('maxlength') && (newSchoolForm.get('address').dirty || newSchoolForm.get('address').touched || nextClicked)">
                            Max 200 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>City</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" formControlName="city" class="form-control" placeholder="City">
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('city').hasError('required') && (newSchoolForm.get('city').dirty || newSchoolForm.get('city').touched || nextClicked)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('city').hasError('maxlength') && (newSchoolForm.get('city').dirty || newSchoolForm.get('city').touched || nextClicked)">
                            Max 20 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row" [hidden]="!showDistrict">
                    <div class="col-md-3">
                        <label>District</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" formControlName="district" class="form-control" placeholder="District">
                        <div class="error-message"
                            *ngIf="showDistrict && newSchoolForm.get('district').hasError('required') && (newSchoolForm.get('district').dirty || newSchoolForm.get('district').touched || nextClicked)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('district').hasError('maxlength') && (newSchoolForm.get('district').dirty || newSchoolForm.get('district').touched || nextClicked)">
                            Max 50 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>{{plchldrPostal}}</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" formControlName="zip_code" class="form-control"
                            placeholder="{{plchldrPostal}}">
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('zip_code').hasError('required') && (newSchoolForm.get('zip_code').dirty || newSchoolForm.get('zip_code').touched || nextClicked)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="newSchoolForm.get('zip_code').hasError('maxlength') && (newSchoolForm.get('zip_code').dirty || newSchoolForm.get('zip_code').touched || nextClicked)">
                            Max 20 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
            <form [hidden]="!getCurrentEdForm(3, true)" [formGroup]="collegeEdForm" novalidate>
                <div class="error-message"
                    *ngIf="collegeEdForm.hasError('aftersubmit') && (collegeEdForm.dirty || collegeEdForm.touched || nextClicked)">
                    Something went wrong.
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12">
                        <label>Majors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="majors" placeholder="Majors" class="form-control">
                        <div class="error-message"
                            *ngIf="collegeEdForm.get('majors').hasError('arrElemValid') && (collegeEdForm.get('majors').dirty || collegeEdForm.get('majors').touched || doneClicked)">
                            Please enter comma-separated majors containing less than 50 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Minors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="minors" placeholder="Minors" class="form-control">
                        <div class="error-message"
                            *ngIf="collegeEdForm.get('minors').hasError('arrElemValid') && (collegeEdForm.get('minors').dirty || collegeEdForm.get('minors').touched || doneClicked)">
                            Please enter comma-separated minors containing less than 50 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly bsDatepicker
                                formControlName="start_date" [bsConfig]="{adaptivePosition: true}">
                            <div class="error-message"
                                *ngIf="collegeEdForm.get('start_date').hasError('required') && (collegeEdForm.get('start_date').dirty || collegeEdForm.get('start_date').touched || doneClicked)">
                                This field is required.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date (or Projected End Date)
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly bsDatepicker
                                formControlName="end_date" [bsConfig]="{adaptivePosition: true}">
                            <div class="error-message"
                                *ngIf="collegeEdForm.get('end_date').hasError('required') && (collegeEdForm.get('end_date').dirty || collegeEdForm.get('end_date').touched || doneClicked)">
                                This field is required.
                            </div>
                        </label>
                        <div class="error-message"
                            *ngIf="collegeEdForm.errors?.startEndValid && (collegeEdForm.get('start_date').touched || collegeEdForm.get('start_date').dirty || collegeEdForm.get('end_date').touched || collegeEdForm.get('end_date').dirty)">
                            Start date should not be greater than end date
                        </div>
                    </div>

                </div>
            </form>
            <form [hidden]="!getCurrentEdForm(3, false)" [formGroup]="schoolEdForm" novalidate>
                <div class="error-message"
                    *ngIf="schoolEdForm.hasError('aftersubmit') && (schoolEdForm.dirty || schoolEdForm.touched || nextClicked)">
                    Something went wrong.
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12">
                        <label>Interests

                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" formControlName="interests" placeholder="Interests" class="form-control">
                        <div class="error-message"
                            *ngIf="schoolEdForm.get('interests').hasError('arrElemValid') && (schoolEdForm.get('interests').dirty || schoolEdForm.get('interests').touched || doneClicked)">
                            Please enter comma-separated interests containing less than 100 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly bsDatepicker
                                formControlName="start_date" [bsConfig]="{adaptivePosition: true}">
                            <div class="error-message"
                                *ngIf="schoolEdForm.get('start_date').hasError('required') && (schoolEdForm.get('start_date').dirty || schoolEdForm.get('start_date').touched || doneClicked)">
                                This field is required.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date (or Projected End Date)
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly bsDatepicker
                                formControlName="end_date" [bsConfig]="{adaptivePosition: true}">
                            <div class="error-message"
                                *ngIf="schoolEdForm.get('end_date').hasError('required') && (schoolEdForm.get('end_date').dirty || schoolEdForm.get('end_date').touched || doneClicked)">
                                This field is required.
                            </div>
                        </label>
                        <div class="error-message"
                            *ngIf="schoolEdForm.errors?.startEndValid && (schoolEdForm.get('start_date').touched || schoolEdForm.get('start_date').dirty || schoolEdForm.get('end_date').touched || schoolEdForm.get('end_date').dirty)">
                            Start date should not be greater than end date
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="showPrev" (click)="getPrevEd()" class="btn btn-footer">
            Previous
        </button>
        <button *ngIf="showNext" (click)="getNextEd()" class="btn btn-footer">
            Next
        </button>
        <button *ngIf="showDone && addNewEdu" (click)="getDoneClicked()" [disabled]="disableSubmit"
            class="btn btn-footer">
            Done
        </button>
        <button *ngIf="showDone && !addNewEdu" (click)="getUpdateClicked(false)" [disabled]="disableSubmit"
            class="btn btn-footer">
            Update
        </button>
        <button *ngIf="showDone && !addNewEdu" (click)="getUpdateClicked(true)" [disabled]="disableSubmit"
            class="btn btn-footer">
            Delete
        </button>
    </div>
</ng-template>

<ng-template #staticEduModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="educationForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>{{insText}}</h3>
                    </div>
                    <div class="col-md-12">
                        <label>{{insText}} Name</label>
                    </div>
                    <div class="col-md-12">
                        <input readonly type="text" class="form-control" formControlName="school_name"
                            placeholder="Full {{insText}} Name">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Country</label>
                        <input readonly type="text" class="form-control" formControlName="country"
                            placeholder="Country">
                    </div>
                    <div class="col-md-6">
                        <label>{{plchldrState}}</label>
                        <input readonly type="text" class="form-control" formControlName="state" placeholder="State">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Institute type</label>
                    </div>
                    <div class="col-md-12">
                        <div class="custom-control custom-radio" [hidden]="isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" class="custom-control-input">
                            <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="custom-control custom-radio" [hidden]="!isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" class="custom-control-input">
                            <label for="customCheck3"
                                class="custom-control-label custom-label mb-0">College/University</label>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="isCollegeSelected" [formGroup]="collegeEdForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12" *ngIf="collegeEdForm.controls.majors && collegeEdForm.controls.majors.value">
                        <label>Majors
                        </label>
                    </div>
                    <div class="col-md-12" *ngIf="collegeEdForm.controls.majors && collegeEdForm.controls.majors.value">
                        <input type="text" readonly formControlName="majors" placeholder="Majors" class="form-control">
                    </div>
                </div>
                <div class="form-group row" *ngIf="collegeEdForm.controls.minors.value">
                    <div class="col-md-12">
                        <label>Minors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="minors" placeholder="Minors" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date (or Projected End Date)
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>

                </div>
            </form>
            <form *ngIf="!isCollegeSelected" [formGroup]="schoolEdForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <label>Interests

                        </label>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <input readonly type="text" formControlName="interests" placeholder="Interests"
                            class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date (or Projected End Date)
                            <span>*</span>
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                        <div class="error-message"
                            *ngIf="schoolEdForm.errors?.startEndValid && (schoolEdForm.get('start_date').touched || schoolEdForm.get('start_date').dirty || schoolEdForm.get('end_date').touched || schoolEdForm.get('end_date').dirty)">
                            Start date should not be greater than end date
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="closeModal()" class="btn btn-footer">
            Close
        </button>
    </div>
</ng-template>
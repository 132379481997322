<section class="verification-screen d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-md-9 mx-auto">
                <div class="verify-inner py-3">
                    <img src="assets/images/connect-us-logo.svg" class="mx-auto" alt="Image banner" alt="Image banner">
                </div>
                <div class="button-div mt-4 text-center mb-4">
                    <h2 class="text-center" *ngIf="isResponse && !isParent && isValid">You have successfully activated your account. Sign in above to access your account.</h2>
                    
                    <a [routerLink]="['/']" class="common-buttons mt-3" *ngIf="!isParent && isValid">Sign In</a>

                    <h2 class="text-center" *ngIf="isResponse && isExpired">Link provided is expired or invalid.</h2>

                    <h2 class="text-center" *ngIf="isResponse && isParent && isValid && parentApproved">Thank you for Approving your child’s Account!</h2>

                    <h2 class="text-center" *ngIf="isResponse && isParent && isValid && !parentApproved">Account Rejected</h2>
                    <h2 class="text-center" *ngIf="!isResponse">Please wait.....</h2>
                </div>
            </div>
        </div>
    </div>
</section>
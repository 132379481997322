import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  user: any = {};
  verifiers = []; 
  info1: boolean = false;
  info2: boolean = false;
  info3: boolean = false;

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    if (localStorage.getItem('user')) {
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
    }
    this.profileService.listVerifiers()
      .subscribe(response => {
        if (response.response.length > 0) {
          let verifiers = response.response;
          this.verifiers = verifiers.filter(obj => obj.status === 2);
        } else {
          this.verifiers = [];
        }
      });
  } 
  infoDiv(){
    this.info1 = true;
    this.info2 = false;
    this.info3 = false;
  }
  infoDiv1(){
    this.info1 = false;
    this.info2 = true;
    this.info3 = false;
  }
  infoDiv2(){
    this.info1 = false;
    this.info2 = false;
    this.info3 = true;
  }
  close(){
    this.info1 = false;
    this.info2 = false;
    this.info3 = false;
  }
}

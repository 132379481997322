import { Component, OnInit, TemplateRef, EventEmitter, Input, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as atlas from 'azure-maps-control';
import { AuthenticationType } from 'azure-maps-control';
import { CustomvalidationService } from '../services/customvalidation.service';
import { ProfileService } from '../services/profile.service';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { PageService } from '../services/page.service';
import { CarouselConfig } from 'ngx-bootstrap';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '../../environments/environment';
import { SlickCarouselComponent } from "ngx-slick-carousel";

const mediaURL = environment.mediaURL;

@Component({
  selector: 'app-profile-business',
  templateUrl: './profile-business.component.html',
  styleUrls: ['./profile-business.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000 } }
  ]
})
export class ProfileBusinessComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  modalRef: BsModalRef;
  user: any = {};
  map: any;
  key: string = environment.subscriptionKeyAzure;
  editBulletin: any = {};
  editCSR: any = {};
  profile: any = {};
  showLocationToOthers: boolean = true
  public editable: boolean = false;
  public submitted: boolean = false;
  public csrFrmsubmitted: boolean = false;
  public disableSubmit: boolean = false;
  bulletins = [];
  csrs = [];
  uploadForm: FormGroup;
  bulletinForm: FormGroup;
  timings: FormArray;
  descriptionForm: FormGroup;
  csrForm: FormGroup;
  businessWH: FormGroup;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  isNPO: boolean = false;
  page_id: any;
  pagesDetails: any = {};
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;
  pageVerified: string;
  pageVerificationDaysLeft: string;
  pageType: string;
  pageName: string
  pageProfessionalEmail: string;
  public businessWHsubmitted: boolean = false;
  public businessWHdsubmitted: boolean = false;
  public businessWHerror: boolean = false;
  businessWorkingHours: any;
  hideBWH: boolean = false;
  hideSlot: boolean = false;
  currentBWH: any;
  addTimeSlot: boolean = false;
  appendForm: FormGroup;
  UpdateTimeValues: any;
  isLibrary: boolean = false;

  constructor(private router: Router, private modalService: BsModalService, public formBuilder: FormBuilder, private route: ActivatedRoute,
    private customValidator: CustomvalidationService, private profileService: ProfileService, private authService: AuthenticationService,
    private pageService: PageService, private renderer: Renderer2) {

    this.uploadForm = this.formBuilder.group({
      document: [''],
      document_name: ['']
    });
    this.bulletinForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1750)]),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
    this.csrForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(75)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1125)])
    });
    this.businessWH = this.formBuilder.group({
      start_weekday: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      end_weekday: new FormControl('', [Validators.maxLength(10)]),
      timings: this.formBuilder.array([this.createtimings()])
    });
  }

  createtimings() {
    this.appendForm = this.formBuilder.group({
      id: new FormControl(''),
      start_time: new FormControl('', [Validators.maxLength(100), Validators.required]),
      end_time: new FormControl('', [Validators.maxLength(100), Validators.required]),

    });
    return this.appendForm
  }

  addtimings(): void {
    this.timings = this.businessWH.get('timings') as FormArray;
    if (this.timings.length >= 2) {
      this.hideSlot = true;
    } else {
      this.hideSlot = false;
      this.timings.push(this.createtimings());
      if (this.timings.length >= 2) {
        this.hideSlot = true;
      }
    }


  }

  ngOnInit(): void {
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    this.page_id = this.route.snapshot.paramMap.get('page_id');
    this.authService.PageDetails(this.page_id)
      .subscribe(response => {
        if (response.status === 200) {
          this.pagesDetails = response.response;
          this.showLocationToOthers = response.response.display_location
          this.businessWorkingHours = this.pagesDetails.business_working_hours;
          if (this.businessWorkingHours.length >= 5) {
            this.hideBWH = true;
          } else {
            this.hideBWH = false;
          }
          if (this.pagesDetails.page_type == 2) {
            this.isNPO = true;
          }
        } else {
          this.router.navigate(['/dashboard']);
        }
        this.searchMap();
      });
    this.profileService.BusinessVerificationStatus(this.page_id)
      .subscribe(response => {
        this.pageVerified = response.response.business_verified
        this.pageVerificationDaysLeft = response.response.days_left
        this.pageType = response.response.page_type
        this.pageName = response.response.page_info.name
        this.pageProfessionalEmail = response.response.page_info.email
      })
    this.getBulletin();
    this.getCSR();
    this.pageService.getPageDescription(this.page_id)
      .subscribe(response => {
        if (response.response['id'] !== undefined) {
          this.profile = response.response;
        }
      })
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  updBulletin() {
    if (this.bulletins.length > 0) {
      this.isLibrary = true;
      if (!this.slickModal){
        setTimeout(() => {
          if (!this.slickModal.initialized) {
            this.slickModal.initSlick();
          }
        }, 1000);
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    } else {
      this.isLibrary = false;
    }
  }

  disableEdit() {
    this.editable = !this.editable;
  }

  csrOpen(csrTemplate: TemplateRef<any>) {
    this.csrForm.reset();
    this.csrFrmsubmitted = false;
    this.modalRef = this.modalService.show(csrTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  csrDetailOpen(csrModal: TemplateRef<any>, editCSRTemplate: TemplateRef<any>, csr: any) {
    this.editCSR = csr;
    if (this.editable) {
      this.csrFrmsubmitted = false;
      this.csrForm.controls['description'].setValue(csr.description);
      this.csrForm.controls['title'].setValue(csr.title);
      this.modalRef = this.modalService.show(editCSRTemplate,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    } else {
      this.modalRef = this.modalService.show(csrModal,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    }
  }

  empOpen(empModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(empModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  slotCheck() {
    if (this.timings.length >= 2) {
      this.hideSlot = true;
    } else {
      this.hideSlot = false;
    }
  }

  openHour(hourModal: TemplateRef<any>) {
    this.timings = this.businessWH.get('timings') as FormArray;

    while (this.timings.length != 1) {
      this.timings.removeAt(0)
    }
    this.slotCheck()
    this.businessWH.reset();
    this.modalRef = this.modalService.show(hourModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateopenHour(updatehourModal: TemplateRef<any>, data: any) {

    this.timings = this.businessWH.get('timings') as FormArray;
    while (this.timings.length != 1) {
      this.timings.removeAt(0)
    }

    this.businessWH.reset()
    this.currentBWH = data.id
    this.businessWH.controls['start_weekday'].setValue(data.start_weekday);
    this.businessWH.controls['end_weekday'].setValue(data.end_weekday);
    let times = data.timings
    let list_time = []


    times.forEach(function (timee) {
      let dict_time = {}
      var StartTimedateObj = new Date('1970-01-01' + ' ' + timee['start_time']);
      var EndTimedateObj = new Date('1970-01-01' + ' ' + timee['end_time']);
      dict_time['id'] = timee.id,
        dict_time['start_hour'] = StartTimedateObj.getHours(),
        dict_time['start_minute'] = StartTimedateObj.getMinutes(),
        dict_time['end_hour'] = EndTimedateObj.getHours(),
        dict_time['end_minute'] = EndTimedateObj.getMinutes(),
        list_time.push(dict_time)
    });
    this.UpdateTimeValues = list_time
    var i;
    for (i = 0; i < list_time.length; i++) {
      if (i == 0) {
        this.businessWH.controls['timings'].setValue([
          {
            id: list_time[i].id,
            start_time: {
              hour: list_time[i].start_hour,
              minute: list_time[i].start_minute
            },
            end_time: {
              hour: list_time[i].end_hour,
              minute: list_time[i].end_minute
            }
          }]);
      } else {
        let cc = this.createtimings()
        cc.controls['id'].setValue(list_time[i].id)
        cc.controls['start_time'].setValue({
          hour: list_time[i].start_hour,
          minute: list_time[i].start_minute
        });
        cc.controls['end_time'].setValue({
          hour: list_time[i].end_hour,
          minute: list_time[i].end_minute
        });
        this.timings.push(cc);
      }
      this.slotCheck()
    }
    this.modalRef = this.modalService.show(updatehourModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' }),
    );
  }

  getBulletin() {
    this.pageService.getPageBulletin(this.page_id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
          this.isLibrary = false;
        }
      });
  }

  getCSR() {
    this.profileService.getCSR(this.page_id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.csrs = response.response;
        } else {
          this.csrs = [];
        }
      });
  }

  onFileSelect(event) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf", "doc", "docx"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png, pdf, docx and doc files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Document size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.uploadForm.get('document').setValue(base64String);
          this.uploadForm.get('document_name').setValue(file_name);
        };
      }
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  bulletinModal(buletinModal: TemplateRef<any>) {
    this.bulletinForm.reset();
    this.submitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.modalRef = this.modalService.show(buletinModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updatebulletinModal(bulletinDetail: TemplateRef<any>, editbulletinModal: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    if (this.editable) {
      this.bulletinForm.controls['description'].setValue(bulletin.description);
      this.bulletinForm.controls['title'].setValue(bulletin.title);
      this.bulletinForm.controls['link'].setValue(bulletin.link);
      this.modalRef = this.modalService.show(editbulletinModal,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    } else {
      this.modalRef = this.modalService.show(bulletinDetail,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal' })
      );
    }
  }

  openDescModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.submitted = false;
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
      this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1);
      this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2);
      this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3);
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateLink(link: string){
    let prevLink = this.bulletinForm.get('link').value;
    if (link.includes('http://') || link.includes('https://')) { 
      return prevLink;
    } else {
      let newLink = 'http://' + prevLink;
      return newLink
    }
  }

  addBulletin(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'page': this.page_id,
        'title': this.bulletinForm.get('title').value,
        'description': this.bulletinForm.get('description').value,
        'link': link,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.pageService.addPageBulletin(model)
        .subscribe(response => {
          this.handleBulletinResponse(response);
        }, error => {
          this.handleBulletinSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  updateBulletin(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editBulletin.document_name;
      if (prev_doc_name !== null) {
        doc_name = prev_doc_name;
      }
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'page': this.page_id,
        'title': this.bulletinForm.get('title').value,
        'description': this.bulletinForm.get('description').value,
        'link': link,
        'document_name': doc_name
      }
      this.pageService.updatePageBulletin(model, this.editBulletin.id)
        .subscribe(response => {
          this.handleBulletinUpdResponse(response);
        }, error => {
          this.handleBulletinSubmitError(error.error);
        })
    }
  }

  delBulletin() {
    this.pageService.deletePageBulletin(this.editBulletin.id)
      .subscribe(response => {
        this.getBulletin();
        this.modalRef.hide();
        this.editBulletin = {};
        this.bulletinForm.reset();
      })
  }

  removeBulletinDoc(id: any) {
    let model = {
      'document': null,
      'page': this.page_id,
      'title': this.editBulletin['title'],
      'description': this.editBulletin['description'],
      'link': this.editBulletin['link'],
      'document_name': null
    }
    this.pageService.updatePageBulletin(model, this.editBulletin.id)
      .subscribe(response => {
        this.editBulletin["document"] = null;
        this.editBulletin["document_name"] = null;
      })
  }

  protected handleBulletinResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.bulletins.push(response.response);
      this.updBulletin();
      this.bulletinForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleBulletinUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
      this.getBulletin();
      this.modalRef.hide();
      this.bulletinForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
    }
  }

  protected handleBulletinSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.bulletinForm.contains(field)) {
        this.bulletinForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  saveDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {
        'description': this.descriptionForm.get('description').value,
        'profile_keyword_1': this.descriptionForm.get('profile_keyword_1').value,
        'profile_keyword_2': this.descriptionForm.get('profile_keyword_2').value,
        'profile_keyword_3': this.descriptionForm.get('profile_keyword_3').value,
        'page': this.page_id
      }
      this.pageService.addPageDescription(model)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  updateDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      let model = {
        'description': this.descriptionForm.get('description').value,
        'profile_keyword_1': this.descriptionForm.get('profile_keyword_1').value,
        'profile_keyword_2': this.descriptionForm.get('profile_keyword_2').value,
        'profile_keyword_3': this.descriptionForm.get('profile_keyword_3').value,
        'page': this.page_id
      }
      this.pageService.updatePageDescription(model, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  protected handleDescResponse(response: any) {
    if (response.status === 111) {
      this.handleDescSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.profile = response.response;
      this.descriptionForm.reset();
      this.submitted = false;
    }
  }

  protected handleDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addCSR(isValid: boolean) {
    this.csrFrmsubmitted = true;
    this.disableSubmit = true;
    if (isValid && this.csrs.length < 5) {
      let model = {
        'page': this.page_id,
        'title': this.csrForm.get('title').value,
        'description': this.csrForm.get('description').value
      }
      this.profileService.addCSR(model)
        .subscribe(response => {
          this.handleCSRResponse(response);
        }, error => {
          this.handleCSRSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  updateCSR(isValid: boolean) {
    this.csrFrmsubmitted = true;
    if (isValid) {
      let model = {
        'page': this.page_id,
        'title': this.csrForm.get('title').value,
        'description': this.csrForm.get('description').value
      }
      this.profileService.updateCSR(model, this.editCSR.id)
        .subscribe(response => {
          this.handleCSRUpdResponse(response);
        }, error => {
          this.handleCSRSubmitError(error.error);
        })
    }
  }

  deleteCSR() {
    this.profileService.deleteCSR(this.editCSR.id)
      .subscribe(response => {
        this.getCSR();
        this.modalRef.hide();
        this.editCSR = {};
        this.csrForm.reset();
      })
  }

  clearBusinessForm() {
    this.modalRef.hide();
    this.businessWH.reset();
    this.businessWHerror = false;
    this.businessWHsubmitted = false

  }

  protected handleCSRResponse(response: any) {
    if (response.status === 111) {
      this.handleCSRSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.csrForm.reset();
      this.csrs.push(response.response);
      this.csrFrmsubmitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleCSRUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleCSRSubmitError(response.message);
    } else if (response.status === 200) {
      this.getCSR();
      this.modalRef.hide();
      this.csrForm.reset();
      this.csrFrmsubmitted = false;
    }
  }

  protected handleCSRSubmitError(data: any) {
    this.csrFrmsubmitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.csrForm.contains(field)) {
        this.csrForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  deleteBusinessForm(data: any, id: any) {
    this.pageService.deleteBusinessForm(id, data.value)
      .subscribe(response => {
        this.handleBWHResponse(response);
      }, error => {
        this.handleBWHSubmitError(error.error);
      })
  }

  updateBusinessForm(isValid: boolean,id: any) {
    if (isValid){
      let end_weekday = this.businessWH.get('end_weekday').value
    let times = this.businessWH.get('timings').value
    let times_list = []
    for (const item of times) {

      var time = {
        start_time: item['start_time']['hour'].toString() + ":" + item['start_time']['minute'].toString(),
        end_time: item['end_time']['hour'].toString() + ":" + item['end_time']['minute'].toString(),
      }
      if (item.id) {
        time['id'] = item['id']
      }
      times_list.push(time)
    }

    let model = {
      'start_weekday': this.businessWH.get('start_weekday').value,
      'page': this.pagesDetails.id,
      'timings': times_list
    }

    if (end_weekday) {
      model['end_weekday'] = end_weekday
    }
    this.pageService.updateBusinessForm(id, model)
      .subscribe(response => {
        this.handleBWHResponse(response);
      }, error => {
        this.handleBWHSubmitError(error.error);
      })
    } else{
      this.businessWHsubmitted = true;
      this.businessWHdsubmitted = false;
      this.businessWHerror = true;
    }
    
  }

  addBusinessWorkingHours(isValid: boolean) {
    this.businessWHsubmitted = false;
    this.businessWHdsubmitted = true;

    if (isValid) {

      let end_weekday = this.businessWH.get('end_weekday').value
      let times = this.businessWH.get('timings')
      let times_list = []
      for (const item of times.value) {
        var time = {
          start_time: item.start_time['hour'].toString() + ":" + item.start_time['minute'].toString(),
          end_time: item.end_time['hour'].toString() + ":" + item.end_time['minute'].toString(),
        }
        times_list.push(time)
      }

      let model = {
        'start_weekday': this.businessWH.get('start_weekday').value,
        'page': this.pagesDetails.id,
        'timings': times_list
      }

      if (end_weekday) {
        model['end_weekday'] = end_weekday
      }
      this.pageService.addBusinessWorkinghours(model)
        .subscribe(response => {
          this.handleBWHResponse(response);
        }, error => {
          this.handleBWHSubmitError(error.error);
        })
    } else {
      this.businessWHsubmitted = true;
      this.businessWHdsubmitted = false;
      this.businessWHerror = true;
    }
  }

  protected handleBWHResponse(response: any) {
    if (response.status === 111) {
      this.handleBWHSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.businessWH.reset();
      this.businessWHdsubmitted = false;
      this.businessWHerror = false;
      this.businessWHsubmitted = false;
      this.authService.PageDetails(this.page_id).subscribe(response => {
        if (response.status === 200) {
          this.pagesDetails = response.response;
          this.businessWorkingHours = response.response.business_working_hours;
          if (this.businessWorkingHours.length >= 5) {
            this.hideBWH = true;
          }
          else {
            this.hideBWH = false;
          }
        }
      })
    }
  }

  protected handleBWHSubmitError(data: any) {
    this.businessWHdsubmitted = false;
    this.businessWHsubmitted = false;;
    const fields = Object.keys(data || {});
  }

  searchMap() {
    this.map = new atlas.Map('mapContainer',
      {
        center: [-79.347015, 43.651070],
        zoom: 11,
        language: 'en-US',
        showLogo: false,
        showFeedbackLink: false,
        authOptions: {
          authType: AuthenticationType.subscriptionKey,
          subscriptionKey: this.key
        }
      });
    var marker = new atlas.HtmlMarker({
      color: '#D25557',
      text: '',
      position: [this.pagesDetails.business.longitude, this.pagesDetails.business.latitude],
      popup: new atlas.Popup({
        content: '<div style="padding:10px">' + this.pagesDetails.page_info.title + '</div>',
        pixelOffset: [0, -30]
      })
    });
    this.map.markers.add(marker);
    this.map.events.add('click', marker, () => {
      marker.togglePopup();
    });
    this.map.setCamera({
      center: [this.pagesDetails.business.longitude, this.pagesDetails.business.latitude],
      zoom: 11
    });
  }

  addTimeSlots() {
    this.addTimeSlot = true
  }

  showHideLocation() {

    var location_bool
    if (this.showLocationToOthers) {
      location_bool = false
    }
    else {
      location_bool = true
    }
    this.pageService.updatePageLocationDisplay(this.pagesDetails.id, { 'display_location': location_bool }).subscribe(response => {
      this.showLocationToOthers = location_bool
    })
  }

}


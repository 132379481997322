import { Component, OnInit,TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { studentIntroImages, businessIntroImages, volunteerIntroImages, schoolIntroImages }  from '../app.data';
import { AuthenticationService } from '../services/authentication.service';
import { environment } from '../../environments/environment';

const mediaURL = environment.mediaURL;


@Component({
  selector: 'app-help-page',
  templateUrl: './help-page.component.html',
  styleUrls: ['./help-page.component.scss']
})
export class HelpPageComponent implements OnInit {
  loggedIn: boolean = true;
  firstStudentLogin: boolean = false;
  isSenior: boolean = true;
  user: any = {};
  modalRef: BsModalRef;
  gears = [];
  pageslist : any =[]
  pagetitle : string;
  pagecountry : string;
  pagestate : string;
  pagepic: string;
  page_id: string;
  pagetype: string;
  pagetypebusiness :any;
  params :string = "";
  instance_id :string;
  profileImageHeader: string ;
  profileImageHeader_name: string ;
  infoSlide: boolean = false;
  url = mediaURL;
  day: string = "";
  pagepic_name: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.day = new Date().toLocaleString('en-us', {  weekday: 'long' })
    if (localStorage.getItem('user')) {
      this.loggedIn = true;
      const currentUser = JSON.parse(localStorage.getItem('user'));
      this.user = currentUser;
      this.profileImageHeader = mediaURL + this.user.display_picture;
      this.profileImageHeader_name = this.user.display_picture;
      if (this.user.age_group == 3) {
        this.isSenior = true;
      } else {
        this.isSenior = false;
      }
      if (this.user.user_role == "2") {
        this.gears = studentIntroImages;
      } else if (this.user.user_role == "3") {
        this.gears = schoolIntroImages;
      } else if (this.user.user_role == "4") {
        this.gears = volunteerIntroImages;
      }  else {
        this.gears = businessIntroImages;
      }
      if (currentUser.first_login) {
        this.firstStudentLogin = true;
      }
    } else {
      this.router.navigate(['/']);
    } 
  }
  
  openInfo(){
    this.infoSlide = true;
  }
  hideInfo(){
    this.infoSlide = false;
  }

}

<h3>Volunteer Postings
    <a *ngIf="!editable && pageVerified != '3'" href="javascript:void()" class="float-right"
        (click)="openVolunteerModal(volunteerTemplate)">
        <img  alt="image" src="assets/images/plus-icon.png" alt="">
    </a>
    <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
        <img  alt="image" src="assets/images/icEdit.svg">
    </a>
</h3>
<ng-template [ngIf]="volunteerPostings?.length > 0" [ngIfElse]="emptyBlock">
    <div class=" listing-box scroll-activity"  slimScroll [options]="opts" [scrollEvents]="scrollEvents">
        <div class="box-div business-border business-div p-0 no-height cursor-pointer" *ngFor="let volunteer_post of volunteerPostings"
            (click)="updateVolunteerModal(volunteerTemplate, volunteerTemplateStatic, volunteer_post.id)">
            <h4 class="color mb-0">{{volunteer_post.title}}</h4>
            <p [innerHtml]="volunteer_post.description"></p>
        </div>
    </div>
</ng-template>
<ng-template #emptyBlock>
    <div class="box-div">
        <div class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
            <p>You can add any Student Volunteer Openings</p>
        </div>
    </div>
</ng-template>
<ng-template #volunteerTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Volunteer Posting</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body" (click)="formClick()">
        <div class="modal-box">
            <form [formGroup]="volunteerPostForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Title</label>
                    </div>
                    <div class="col-md-9">
                        <input type="text" class="form-control" placeholder="Title" formControlName="title">
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('title').hasError('required') && (volunteerPostForm.get('title').dirty || volunteerPostForm.get('title').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('title').hasError('maxlength') && (volunteerPostForm.get('title').dirty || volunteerPostForm.get('title').touched)">
                            Max 75 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="volunteerPostForm.get('title').hasError('aftersubmit')">
                            {{volunteerPostForm.controls.title.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Address</label>
                    </div>
                    <div class="col-md-9">
                        <app-azure-maps [updateAddress]="updateAddress" (change)="changeAddress($event)"
                            (setAddress)="getAddress($event)" (setHideResult)="setResultHide($event)"></app-azure-maps>
                        <div *ngIf="!hideResults" class="col-md-9">
                            <div class="option-div">
                                <div class="listing" *ngFor="let place of searchPlaces">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 15 20">
                                        <path fill="none" stroke="#505759" stroke-width="1.4"
                                            d="M7.392.7c1.845 0 3.518.75 4.73 1.962 1.211 1.212 1.962 2.885 1.962 4.73 0 1.406-.438 2.754-1.268 3.907h0L7.392 18.95l-5.234-7.385C1.15 10.163.7 8.808.7 7.392c0-1.845.75-3.518 1.962-4.73C3.874 1.451 5.547.7 7.392.7zm0 3.528c-.873 0-1.664.354-2.237.927-.573.573-.927 1.364-.927 2.237s.354 1.664.927 2.237c.573.573 1.364.927 2.237.927s1.664-.354 2.237-.927c.573-.573.927-1.364.927-2.237s-.354-1.664-.927-2.237c-.573-.573-1.364-.927-2.237-.927h0z" />
                                    </svg>
                                    <div class="detail" (click)="selectPlace(place)">
                                        <h6>{{place.address.freeformAddress}}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <input type="text" [hidden]="true" class="form-control" placeholder="Address"
                            formControlName="address">
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('address').hasError('required') && (volunteerPostForm.get('address').dirty || volunteerPostForm.get('address').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('address').hasError('maxlength') && (volunteerPostForm.get('address').dirty || volunteerPostForm.get('address').touched)">
                            Max 200 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="volunteerPostForm.get('address').hasError('aftersubmit')">
                            {{volunteerPostForm.controls.address.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Dates</label>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">
                                <label for="">Start </label>
                                <input *ngIf="pageVerified != '3'" readonly type="text"
                                    class="form-control modal-control modal-field" placeholder="dd mm yy" bsDatepicker
                                    formControlName="start_date" [bsConfig]="{adaptivePosition: true}">
                                <a href="javascript:void(0);" (click)="resetStartDate()"><img
                                        src="assets/images/plus-icon.png" class="img-cross"></a>
                            </div>
                            <div class="col-md-6">
                                <label for="">End</label>
                                <input *ngIf="pageVerified != '3'" readonly type="text"
                                    class="form-control modal-control modal-field" placeholder="dd mm yy" bsDatepicker
                                    formControlName="end_date" [bsConfig]="{adaptivePosition: true}">
                                <a href="javascript:void(0);" (click)="resetEndDate()"><img
                                        src="assets/images/plus-icon.png" class="img-cross"></a>
                            </div>
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('start_date').hasError('required') && (volunteerPostForm.get('start_date').dirty || volunteerPostForm.get('start_date').touched || submitted)">
                            Start Date is required.
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.errors?.startEndValid && (volunteerPostForm.get('start_date').touched || volunteerPostForm.get('start_date').dirty || volunteerPostForm.get('end_date').touched || volunteerPostForm.get('end_date').dirty)">
                            Start date should not be greater than end date
                        </div>
                        <div class="error-message" *ngIf="volunteerPostForm.get('start_date').hasError('aftersubmit')">
                            {{volunteerPostForm.controls.start_date.errors.aftersubmit}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-3">
                        <label>Start Time</label>
                    </div>
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-md-6">

                                <ngb-timepicker formControlName="start_time" [meridian]="meridian"></ngb-timepicker>
                                <div class="error-message"
                                    *ngIf="volunteerPostForm.get('start_time').hasError('timeValid') && (volunteerPostForm.get('start_time').dirty || volunteerPostForm.get('start_time').touched || submitted)">
                                    Invalid time format
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label for="">Hours
                                    <input type="number" class="form-control" placeholder="0" formControlName="hours">
                                </label>
                                <div class="error-message"
                                    *ngIf="(volunteerPostForm.get('hours').hasError('min') || volunteerPostForm.get('hours').hasError('max')) && (volunteerPostForm.get('hours').dirty || volunteerPostForm.get('hours').touched)">
                                    Please enter valid value for number of hours between 0 and 200
                                </div>
                                <div class="error-message"
                                    *ngIf="volunteerPostForm.get('hours').hasError('aftersubmit')">
                                    {{volunteerPostForm.controls.hours.errors.aftersubmit}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <ckeditor tagName="textarea" [editor]="editor" formControlName="description"
                            [config]="{ link: {addTargetToExternalLinks: true}, toolbar: [ 'link', 'bold', 'italic' ], placeholder: 'Description', baseFloatZIndex:100001}">
                        </ckeditor>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('description').hasError('required') && (volunteerPostForm.get('description').dirty || volunteerPostForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('description').hasError('maxlength') && (volunteerPostForm.get('description').dirty || volunteerPostForm.get('description').touched)">
                            Max 2250 characters allowed.
                        </div>
                        <div class="error-message" *ngIf="volunteerPostForm.get('description').hasError('aftersubmit')">
                            {{volunteerPostForm.controls.description.errors.aftersubmit}}
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('description').hasError('linksValid') && (volunteerPostForm.get('description').dirty || volunteerPostForm.get('description').touched || submitted)">
                            Invalid links in Description: {{invalidWeb}}
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>How to Apply</label>
                    </div>
                    <div class="col-md-12">
                        <textarea type="text" class="form-control" placeholder="How to Apply"
                            formControlName="how_to_apply"></textarea>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('how_to_apply').hasError('required') && (volunteerPostForm.get('how_to_apply').dirty || volunteerPostForm.get('how_to_apply').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="volunteerPostForm.get('how_to_apply').hasError('maxlength') && (volunteerPostForm.get('how_to_apply').dirty || volunteerPostForm.get('how_to_apply').touched)">
                            Max 500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="addNewVolunteer" (click)="addUpdateVolunteerClicked()" [disabled]="disableSubmit"
            class="btn btn-footer">
            Save
        </button>
        <button *ngIf="!addNewVolunteer" (click)="addUpdateVolunteerClicked(true)" [disabled]="disableSubmit"
            class="btn btn-footer">
            Update
        </button>
        <button *ngIf="!addNewVolunteer" (click)="addUpdateVolunteerClicked(true, true)" [disabled]="disableSubmit"
            class="btn btn-footer">
            Delete
        </button>
    </div>
</ng-template>
<ng-template #volunteerTemplateStatic>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Volunteer Postings</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img  alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <carousel>
                    <slide class="common-div">
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Title
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.title}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Date Posted
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.created_date|date}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Address
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.address}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Start Date
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.start_date|date}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    End Date
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.end_date|date}}
                                </h4>
                            </div>
                        </div>
                        <div class="row" *ngIf="volunteerObj.start_time">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Start Time
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.start_time | convertFrom24To12Format}}
                                </h4>
                            </div>
                        </div>
                        <div class="row" *ngIf="volunteerObj.hours">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Hours
                                </h3>
                            </div>
                            <div class="col-6">
                                <h4>
                                    {{volunteerObj.hours}}
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    Description
                                </h3>
                            </div>
                            <div class="col-12">
                                <h4 [innerHtml]="volunteerObj.description" class="ml-0">
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-6">
                                <h3>
                                    How to Apply
                                </h3>
                            </div>
                            <div class="col-12">
                                <h4 class="ml-0">
                                    {{volunteerObj.how_to_apply}}
                                </h4>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</ng-template>
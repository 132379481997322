<section class="profile-head">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-xl-2 student p-0 p-0">
                <div class="green-sidebarSec">
                    <div class="setting-div mt-2" [ngClass]="{ '': settingShow, show: !settingShow}">
                        <div class="setting-inner">
                            <h3>Settings</h3>
                            <img alt="image" src="assets/images/cross.png" alt="" class="close-btn"
                                (click)="settingShow = !settingShow">
                            <form action="">
                                <div class="form-group mb-2 mt-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck1">Block
                                            User</label>
                                    </div>
                                </div>
                                <div class="form-group mb-2">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck"
                                            name="privacy">
                                        <label class="custom-control-label custom-label" for="customCheck">Report
                                            User</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-10 col-xl-10 p-0">
                <div class="top-sticky">
                    <div class="profile-sec media pb-3">
                        <div class="headerDiv">
                            <img *ngIf="profileHeader_name" src="{{profileHeader}}" class="headerImg" alt="header-img">
                            <img *ngIf="!profileHeader_name" src="assets/images/default-header.jpg" class="headerImg"
                                alt="header-img">
                        </div>
                        <form class="formImg">
                            <img *ngIf="profileImage_name" src="{{profileImage}}" class="profileImg" alt="header-img">
                            <img *ngIf="!profileImage_name" src="assets/images/p-img.png" class="profileImg"
                                alt="header-img">
                        </form>
                        <div class="media-body ml-md-3">
                            <h1>{{user.first_name}} {{user.last_name}}</h1>
                            <p (click)="openVerifiersModal(verification)"
                                *ngIf="verifiers.length >= 5 || user.approval_status"
                                class="d-flex align-items-center cursor-pointer">
                                <span>
                                    <img alt="image" src="assets/images/verify-resume.svg" alt="">
                                </span>Verified</p>
                            <ul class="d-flex pl-0 d-none mb-0">
                                <li (click)="openSetting(settingModal)">
                                    <a href="javascript:void();">Settings</a>
                                </li>
                                <li class="cursor-pointer"
                                    *ngIf="user.user_role == '2' && user.age_group > 1 && loggedInUser.user_role == '2' && loggedInUser.age_group > 1">
                                    <a *ngIf="isUserBlocked" (click)="openMsgModal(noMessage)">
                                        Message
                                    </a>
                                    <a *ngIf="!isUserBlocked" (click)="sendMessage()">
                                        Message
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <tabset class="side-links tabsetSide schoolSide">
                    <tab>
                        <ng-template tabHeading>
                            <img alt="image" src="assets/images/user.svg" class=" d-md-inline-block d-none" />
                            <span class="d-md-none d-block">Profile</span>
                        </ng-template>

                        <tabset class="topLinks">
                            <tab>
                                <ng-template tabHeading>
                                    <h3>Resume</h3>
                                </ng-template>
                                <div class="content-section pt-4 fullPage">
                                    <div *ngIf="hasData" class="profile-div">
                                        <div class="profile-inner" *ngIf="(profile | json) != '{}'">
                                            <h3>Biography
                                            </h3>
                                            <div class="box-div" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="inner-div cursor-pointer" *ngIf="(profile | json) != '{}'"
                                                    (click)="openModal(editDescriptionTemplate)">
                                                    <ul class="pl-0 d-flex">
                                                        <li>
                                                            <p>{{profile.profile_keyword_1}}</p>
                                                        </li>
                                                        <li>
                                                            <p>{{profile.profile_keyword_2}}</p>
                                                        </li>
                                                        <li *ngIf="profile.profile_keyword_3">
                                                            <p>{{profile.profile_keyword_3}}</p>
                                                        </li>
                                                    </ul>
                                                    <p class="px-1" style="white-space: pre-wrap;">
                                                        {{profile.description}}</p>
                                                </div>
                                                <div *ngIf="(profile | json) == '{}'"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No data exists!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner slider-div" *ngIf="eduList?.length > 0">
                                            <h3>
                                                Education
                                            </h3>
                                            <carousel>
                                                <slide *ngFor="let eduObj of eduList">
                                                    <div class="box-div inner-exp border-0"
                                                        (click)="updateEducationPopup(staticEduModal, eduObj)">
                                                        <div
                                                            class="experience-div media school-tile school-tile-border">
                                                            <img
                                                                src="{{eduObj.school.school_image? eduObj.school.school_image : 'assets/images/default.svg'}}">
                                                            <div class="media-body ml-2">
                                                                <h4>{{eduObj.school.school_name}}</h4>
                                                                <ng-template [ngIf]="eduObj.school.is_college"
                                                                    [ngIfElse]="schoolBlock">
                                                                    <p *ngIf="eduObj.majors">
                                                                        {{eduObj.majors.join(', ')}}
                                                                    </p>
                                                                </ng-template>
                                                                <ng-template #schoolBlock>
                                                                    <p *ngIf="eduObj.interests">
                                                                        {{eduObj.interests.join(', ')}}
                                                                    </p>
                                                                </ng-template>
                                                                <h5>Start
                                                                    <span>{{eduObj.start_date | date:'yyyy' }}</span>
                                                                    End
                                                                    <span>{{eduObj.end_date | date:'yyyy' }}</span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </slide>
                                            </carousel>
                                            <div *ngIf="eduList?.length == 0"
                                                class="empty-div text-center h-100 d-flex align-items-center justify-content-center box-div">
                                                <p>No data exists!</p>
                                            </div>

                                        </div>
                                        <div class="profile-inner full-inner" *ngIf="usrWorkExperience?.length > 0">
                                            <h3>WORK EXPERIENCE
                                            </h3>
                                            <div class="scroll-activity" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="box-div inner-exp cursor-pointer work-tile-border "
                                                    *ngFor="let experience of usrWorkExperience"
                                                    (click)="updateExpModal(editExpTemplate, experience)">
                                                    <div class="experience-div media">
                                                        <img (click)="redirectToPage(experience);$event.stopPropagation()"
                                                            src="{{experience.page_display_pic? mediaURL + experience.page_display_pic : 'assets/images/default.svg'}}">
                                                        <div class="media-body ml-2">
                                                            <h4>{{experience.position}}</h4>
                                                            <p>{{experience.organization_title}}</p>
                                                            <h5>Start
                                                                <span>{{experience.start_date}}</span> End
                                                                <span *ngIf="!experience.end_date">-</span>
                                                                <span>{{experience.end_date}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="side-col">
                                                        <ul class="d-flex pl-0">
                                                            <li *ngIf="experience.skill_1">
                                                                <p>{{experience.skill_1}}</p>
                                                            </li>
                                                            <li *ngIf="experience.skill_2">
                                                                <p>{{experience.skill_2}}</p>
                                                            </li>
                                                            <li *ngIf="experience.skill_3">
                                                                <p>{{experience.skill_3}}</p>
                                                            </li>
                                                        </ul>
                                                        <p>{{experience.main_role}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div *ngIf="usrWorkExperience?.length == 0"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No data exists!</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="profile-inner full-inner colorOdd"
                                            *ngIf="usrVolunteerWorkExperience?.length > 0">
                                            <h3>Volunteer Experience
                                            </h3>
                                            <div class="scroll-activity" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="box-div inner-exp cursor-pointer"
                                                    *ngFor="let experience of usrVolunteerWorkExperience"
                                                    (click)="updateExpModal(editExpTemplate, experience)">
                                                    <div class="experience-div media">
                                                        <img (click)="redirectToPage(experience);$event.stopPropagation()"
                                                            src="{{experience.page_display_pic? mediaURL + experience.page_display_pic : 'assets/images/default.svg'}}">
                                                        <div class="media-body ml-2">
                                                            <h4>{{experience.position}}</h4>
                                                            <p>{{experience.organization_title}}</p>
                                                            <h5>Start
                                                                <span>{{experience.start_date}}</span> End
                                                                <span *ngIf="!experience.end_date">-</span>
                                                                <span>{{experience.end_date}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="side-col">
                                                        <ul class="d-flex pl-0">
                                                            <li>
                                                                <p>{{experience.skill_1}}</p>
                                                            </li>
                                                            <li>
                                                                <p>{{experience.skill_2}}</p>
                                                            </li>
                                                            <li>
                                                                <p>{{experience.skill_3}}</p>
                                                            </li>
                                                        </ul>
                                                        <p>{{experience.main_role}}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div *ngIf="usrVolunteerWorkExperience?.length == 0"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No data exists!</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="profile-inner full-inner colorOdd" *ngIf="activities?.length > 0">
                                            <h3>Extracurricular Experience
                                            </h3>
                                            <div class="scroll-activity" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="box-div inner-exp cursor-pointer"
                                                    *ngFor="let activity of activities"
                                                    (click)="updateActivityModal(editActivityTemplate, activity)">
                                                    <div class="experience-div media">
                                                        <svg width="130px" height="130px" viewBox="0 0 130 130"
                                                            version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink">
                                                            <title>Artboard</title>
                                                            <desc>Created with Sketch.</desc>
                                                            <g id="Artboard" stroke="none" stroke-width="1" fill="none"
                                                                fill-rule="evenodd">
                                                                <rect id="Rectangle" fill="#C0CBD7" x="0" y="0"
                                                                    width="130" height="130" rx="12"></rect>
                                                                <g id="image1"
                                                                    transform="translate(21.000000, 21.000000)"
                                                                    fill="#FFFFFF" fill-rule="nonzero">
                                                                    <path
                                                                        d="M71.6341463,26.0487805 C71.6341463,30.8439268 67.7463659,34.7317073 62.9512195,34.7317073 C58.1560732,34.7317073 54.2682927,30.8439268 54.2682927,26.0487805 C54.2682927,21.2536341 58.1560732,17.3658537 62.9512195,17.3658537 C67.7463659,17.3658537 71.6341463,21.2536341 71.6341463,26.0487805 Z M81.402439,0 L7.59756098,0 C3.41890244,0 0,3.41890244 0,7.59756098 L0,81.402439 C0,85.5810976 3.41890244,89 7.59756098,89 L81.402439,89 C85.5810976,89 89,85.5810976 89,81.402439 L89,7.59756098 C89,3.41890244 85.5810976,0 81.402439,0 Z M78.1463415,62.9512195 L77.0609756,62.9512195 L59.695122,43.4146341 L49.184439,55.3265244 L57.2888659,65.5322195 C57.6611463,66.0021829 57.583,66.684878 57.114122,67.0571585 C56.6430732,67.4272683 55.9625488,67.3512927 55.5891829,66.8824146 L27.1308902,31.0458049 L10.8536585,51.3931585 L10.8536585,10.8536585 L78.1463415,10.8536585 L78.1463415,62.9512195 Z"
                                                                        id="Shape"></path>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        <div class="media-body ml-2">
                                                            <h4>{{activity.position}}</h4>
                                                            <p>{{activity.organization_title}}</p>
                                                            <h5>Start
                                                                <span>{{activity.start_date}}</span> End
                                                                <span>-</span>
                                                                <span>{{activity.end_date}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="side-col">
                                                        <ul class="d-flex pl-0">
                                                            <li>
                                                                <p>{{activity.skill_1}}</p>
                                                            </li>
                                                            <li>
                                                                <p>{{activity.skill_2}}</p>
                                                            </li>
                                                            <li>
                                                                <p>{{activity.skill_3}}</p>
                                                            </li>
                                                        </ul>
                                                        <p>{{activity.main_role}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activities?.length == 0"
                                                class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                <p>No data exists!</p>
                                            </div>
                                        </div>
                                        <div class="profile-inner full-inner" *ngIf="schoolInvolvements?.length > 0">
                                            <h3>SCHOOL INVOLVEMENT
                                            </h3>
                                            <div class="scroll-activity" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="box-div inner-exp cursor-pointer"
                                                    *ngFor="let experience of schoolInvolvements"
                                                    (click)="updateSchoolInvolvementModal(schoolInvolvementTemplate, experience)">
                                                    <div class="experience-div media school-tile">
                                                        <img (click)="redirectPage(experience);$event.stopPropagation()"
                                                            src="{{experience.page_display_pic? mediaURL + experience.page_display_pic : 'assets/images/default.svg'}}">
                                                        <div class="media-body ml-2">
                                                            <h4>{{experience.position}}</h4>
                                                            <p>{{experience.title}}</p>
                                                            <h5>Start
                                                                <span>{{experience.start_date}}</span> End
                                                                <span *ngIf="!experience.end_date">-</span>
                                                                <span>{{experience.end_date}}</span>
                                                            </h5>
                                                        </div>
                                                    </div>
                                                    <div class="side-col">
                                                        <ul class="d-flex pl-0">
                                                            <li class="school-tile">
                                                                <p>{{experience.skill_1}}</p>
                                                            </li>
                                                            <li class="school-tile">
                                                                <p>{{experience.skill_2}}</p>
                                                            </li>
                                                            <li class="school-tile">
                                                                <p>{{experience.skill_3}}</p>
                                                            </li>
                                                        </ul>
                                                        <p>{{experience.main_role}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="schoolInvolvements?.length == 0"
                                                class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                <p>No data exists!</p>
                                            </div>

                                        </div>
                                        <div class="profile-inner" *ngIf="skills?.length > 0">
                                            <h3>Skills
                                            </h3>
                                            <div class="box-div scroll-section" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <ul class="pl-0 d-flex skills-listing mb-0">
                                                    <li *ngFor="let skill of skills" class="cursor-pointer">
                                                        <p (click)="updateSkillModal(editSkillpopup, skill)">
                                                            {{skill.title}}
                                                        </p>
                                                    </li>
                                                </ul>
                                                <div *ngIf="skills?.length == 0"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No data exists!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-inner" *ngIf="interests?.length > 0">
                                            <h3>Interests
                                            </h3>
                                            <div class="box-div scroll-section" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <ul class="pl-0 d-flex skills-listing mb-0">
                                                    <li *ngFor="let interest of interests" class="cursor-pointer">
                                                        <p (click)="updateInterestModal(editInterestpopup, interest)">
                                                            {{interest.title}}</p>
                                                    </li>
                                                </ul>
                                                <div *ngIf="interests?.length == 0"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No data exists!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!hasData"
                                        class="fullPage d-flex align-items-center justify-content-center">
                                        <div class="empty-div">
                                            <p>
                                                No Data Exists
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                            <tab>
                                <ng-template tabHeading>
                                    <h3 (click)="updBulletin()">Library</h3>
                                </ng-template>
                                <div class="content-section business-content fullPage">
                                    <div *ngIf="hasLibraryData" class="profile-div">
                                        <div class="profile-inner full-inner" *ngIf="bulletins?.length > 0">
                                            <h3>Bulletin Board
                                            </h3>
                                            <ngx-slick-carousel *ngIf="isLibrary"
                                                class="box-div library-div carousel" #slickModal="slick-carousel"
                                                [config]="slideConfig">
                                                <div *ngFor="let bulletin of bulletins" class="img-cont slide"
                                                    ngxSlickItem
                                                    (click)="updatebulletinModal(bulletinDetail, bulletin)">
                                                    <div *ngIf="bulletin.document" class="img-div">
                                                        <img *ngIf="isImage(bulletin.document_name)"
                                                            src="{{bulletin.document}}">
                                                        <img *ngIf="!isImage(bulletin.document_name)"
                                                            src="assets/images/pdf-icon.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                    <div *ngIf="!bulletin.document" class="img-div">
                                                        <img src="assets/images/default.svg">
                                                        <p>{{bulletin.title}}</p>
                                                    </div>
                                                </div>
                                            </ngx-slick-carousel>
                                            <div *ngIf="!isLibrary"
                                                class="library-div empty-div box-div text-center h-100 d-flex align-items-center justify-content-center">
                                                <p>No data exists!</p>
                                            </div>
                                        </div>
                                        <div class="profile-inner full-inner custom-slider"
                                            *ngIf="user.user_role == '2' && books?.length > 0">
                                            <h3>Books
                                            </h3>
                                            <carousel>
                                                <slide *ngFor="let book of books">
                                                    <div class="box-div">
                                                        <div class="books-listing mw-100">
                                                            <h3>{{book.title}}</h3>
                                                            <h4>{{book.author}}</h4>
                                                            <ul *ngIf="book.rating"
                                                                class="star-rating d-flex pl-0 m-0 align-items-center custom-rating">
                                                                <ngb-rating [(rate)]="book.rating" [readonly]="true"
                                                                    [max]="5">
                                                                    <ng-template let-fill="fill" let-index="index">
                                                                        <span class="star" [class.filled]="fill === 100"
                                                                            [class.bad]="index < 3"></span>
                                                                    </ng-template>
                                                                </ngb-rating>
                                                            </ul>
                                                            <div class="inner-listing scroll-activity" slimScroll
                                                                [options]="opts" [scrollEvents]="scrollEvents">
                                                                {{book.description}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </slide>
                                            </carousel>
                                            <div class="box-div p-0 m-0">
                                                <div *ngIf="books?.length == 0"
                                                    class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
                                                    <p>No data exists!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!hasLibraryData"
                                        class="fullPage d-flex align-items-center justify-content-center">
                                        <div class="empty-div">
                                            <p>
                                                No Data Exists
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </tab>
                        </tabset>
                    </tab>
                    <tab>
                        <ng-template tabHeading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <g fill="none" fill-rule="evenodd">
                                    <rect width="24" height="24" fill="#335175" rx="12"
                                        transform="matrix(-1 0 0 1 24 0)" />
                                    <g fill="#FFF" fill-rule="nonzero">
                                        <path
                                            d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                            transform="translate(7.2 6)" />
                                    </g>
                                </g>
                            </svg>
                        </ng-template>
                        <div class="media-body">
                            <h1 class="settingText">Need Help?</h1>
                        </div>
                        <div class="content-section pt-3">
                            <div class="profile-div">
                                <div class="profile-inner full-inner">
                                    <h3>
                                        Page Types
                                    </h3>
                                    <div class="box-div">
                                        <div class="inner-div">
                                            <h4 class="bg-text">Click on any of the following page types to view what it
                                                resembles when fully filled
                                                out. These are only examples the information within is not accurate, nor
                                                does
                                                it
                                                represent anybody's real profile. </h4>
                                            <div class="d-flex justify-content-around flex-wrap">
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openStudent(studentModal)">
                                                    <img alt="image" src="assets/images/student-icon.svg">
                                                    <h5>Student</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openBusiness(businessModal)">
                                                    <img alt="image" src="assets/images/business-icon.svg">
                                                    <h5>Business</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openVolunteer(volunteerModal)">
                                                    <img alt="image" src="assets/images/non-icon.svg">
                                                    <h5>Non-Profit</h5>
                                                </div>
                                                <div class="boxInner cursor-pointer text-center"
                                                    (click)="openSchool(schoolModal)">
                                                    <img alt="image" src="assets/images/school-icon.svg">
                                                    <h5>School Club</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="profile-inner full-inner mt-md-4">
                                    <app-contact-us-form [displayColClass]="true"></app-contact-us-form>
                                </div>
                            </div>
                        </div>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</section>

<div class="overlay-edit">
</div>

<ng-template #verification>
    <div class="modal-header">
        <h4 class="modal-title pull-left">profile verifiers</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12" *ngIf="verifiers.length >= 5">
                <div class="verify-div" *ngFor="let verifier of verifiers">
                    <p>Verified by
                        <span>********@{{verifier.email.split('@')[1]}}</span> on
                        <span>{{verifier.verified_date | date: 'yyyy-MM-dd'}}</span>
                    </p>
                </div>
            </div>
            <div class="col-md-12" *ngIf="verifiers.length < 5 && user.approval_status">
                <div>
                    <p>Verified by the ConnectUs.Today Team
                    </p>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #staticEduModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{insText}} Details</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">

            <form [formGroup]="educationForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>{{insText}}</h3>
                    </div>
                    <div class="col-md-12">
                        <label>{{insText}} Name</label>
                    </div>
                    <div class="col-md-12">
                        <input readonly type="text" class="form-control" formControlName="school_name"
                            placeholder="Full {{insText}} Name">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Country</label>
                        <input readonly type="text" class="form-control" formControlName="country"
                            placeholder="Country">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Institute type</label>
                    </div>
                    <div class="col-md-12">
                        <div class="custom-control custom-radio" [hidden]="isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="false" checked=""
                                id="customCheck2" name="is_college" class="custom-control-input">
                            <label for="customCheck2" class="custom-control-label custom-label mb-0">School</label>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="custom-control custom-radio" [hidden]="!isCollegeSelected">
                            <input readonly formControlName="is_college" type="radio" value="true" id="customCheck3"
                                name="is_college" class="custom-control-input">
                            <label for="customCheck3"
                                class="custom-control-label custom-label mb-0">College/University</label>
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="isCollegeSelected" [formGroup]="collegeEdForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12" *ngIf="collegeEdForm.controls.majors && collegeEdForm.controls.majors.value">
                        <label>Majors
                        </label>
                    </div>
                    <div class="col-md-12" *ngIf="collegeEdForm.controls.majors && collegeEdForm.controls.majors.value">
                        <input type="text" readonly formControlName="majors" placeholder="Majors" class="form-control">
                    </div>
                </div>
                <div class="form-group row" *ngIf="collegeEdForm.controls.minors.value">
                    <div class="col-md-12">
                        <label>Minors
                        </label>
                    </div>
                    <div class="col-md-12">
                        <input type="text" readonly formControlName="minors" placeholder="Minors" class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>

                </div>
            </form>
            <form *ngIf="!isCollegeSelected" [formGroup]="schoolEdForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <h3>Education Details</h3>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <label>Interests

                        </label>
                    </div>
                    <div class="col-md-12" *ngIf="schoolEdForm.controls.interests.value">
                        <input readonly type="text" formControlName="interests" placeholder="Interests"
                            class="form-control">
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="start_date">
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label for="">End
                            <input type="text" class="form-control" placeholder="dd mm yy" readonly
                                formControlName="end_date">
                        </label>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editExpTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{expModalTitle}} Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="expUpdForm" novalidate [ngClass]="{ 'formHide': editable, formShow: !editable }">
                <div class="experience-div media w-100">
                    <div *ngIf="!editable">
                        <img
                            src="{{editExp.page_display_pic? mediaURL + editExp.page_display_pic : 'assets/images/default.svg'}}">
                    </div>
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Business</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Business Name"
                                            [readonly]="!editable || editExp.page" formControlName="title">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Position"
                                            [readonly]="!editable || editExp.page" formControlName="position">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <p *ngIf="editExp.approver_name && editExp.verified_date" class="verifiedText"><img
                                        src="assets/images/verify-resume.svg">Verified
                                    by <span>{{editExp.approver_name}}</span> on
                                    <span>{{editExp.verified_date | date: 'yyyy-MM-dd'}} </span>
                                </p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label>
                                    City
                                    <input type="text" class="form-control" placeholder="City" readonly
                                        formControlName="city">
                                </label>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>Start
                                    <input type="text" class="form-control" *ngIf="!editable || editExp.page" readonly
                                        value="{{editExp.start_date}}">
                                    <input type="text" class="form-control" bsDatepicker
                                        *ngIf="editable && !editExp.page" [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                </label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>End
                                    <input type="text" class="form-control" readonly value="{{editExp.end_date}}">
                                    <input type="text" class="form-control" bsDatepicker
                                        *ngIf="editable && !editExp.page" [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Learned</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable || (editExp.page && editExp.verification_status == 1)"
                                            formControlName="skill_1">
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable || (editExp.page && editExp.verification_status == 1)"
                                            formControlName="skill_2">
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable || (editExp.page && editExp.verification_status == 1)"
                                            formControlName="skill_3">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control"
                                    [readonly]="!editable || (editExp.page && editExp.verification_status == 1)"
                                    formControlName="main_role"> </textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Personal Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control"
                                    [readonly]="!editable || (editExp.page && editExp.verification_status == 1)"
                                    formControlName="personal_details"> </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editActivityTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Extracurricular Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="activityUpdForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="experience-div media w-100">
                    <div *ngIf="!editable">
                        <img alt="image" src="assets/images/default.svg">
                    </div>
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Business</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Business Name"
                                            [readonly]="!editable" formControlName="title">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Position"
                                            [readonly]="!editable" formControlName="position">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('position').hasError('required') && (activityUpdForm.get('position').dirty || activityUpdForm.get('position').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('position').hasError('maxlength') && (activityUpdForm.get('position').dirty || activityUpdForm.get('position').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label>
                                    City
                                    <input type="text" class="form-control" placeholder="City" [readonly]="!editable"
                                        formControlName="city">
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('city').hasError('required') && (activityUpdForm.get('city').dirty || activityUpdForm.get('city').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('city').hasError('maxlength') && (activityUpdForm.get('city').dirty || activityUpdForm.get('city').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>Start
                                    <input type="text" class="form-control" *ngIf="!editable" readonly
                                        value="{{editActivity.start_date}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="editable"
                                        [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.get('start_date').hasError('required') && (activityUpdForm.get('start_date').dirty || activityUpdForm.get('start_date').touched || submitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>End
                                    <input type="text" class="form-control" *ngIf="!editable" readonly
                                        value="{{editActivity.end_date}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="editable"
                                        [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="activityUpdForm.errors?.invalidEndDate && (activityUpdForm.touched || activityUpdForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Learned</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  " [readonly]="!editable"
                                            formControlName="skill_1">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_1').hasError('required') && (activityUpdForm.get('skill_1').dirty || activityUpdForm.get('skill_1').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_1').hasError('maxlength') && (activityUpdForm.get('skill_1').dirty || activityUpdForm.get('skill_1').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " [readonly]="!editable"
                                            formControlName="skill_2">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_2').hasError('required') && (activityUpdForm.get('skill_2').dirty || activityUpdForm.get('skill_2').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_2').hasError('maxlength') && (activityUpdForm.get('skill_2').dirty || activityUpdForm.get('skill_2').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " [readonly]="!editable"
                                            formControlName="skill_3">
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_3').hasError('required') && (activityUpdForm.get('skill_3').dirty || activityUpdForm.get('skill_3').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="activityUpdForm.get('skill_3').hasError('maxlength') && (activityUpdForm.get('skill_3').dirty || activityUpdForm.get('skill_3').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="main_role"> </textarea>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('main_role').hasError('required') && (activityUpdForm.get('main_role').dirty || activityUpdForm.get('main_role').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('main_role').hasError('maxlength') && (activityUpdForm.get('main_role').dirty || activityUpdForm.get('main_role').touched)">
                                    Max 250 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Personal Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" [readonly]="!editable"
                                    formControlName="personal_details"> </textarea>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('personal_details').hasError('required') && (activityUpdForm.get('personal_details').dirty || activityUpdForm.get('personal_details').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="activityUpdForm.get('personal_details').hasError('maxlength') && (activityUpdForm.get('personal_details').dirty || activityUpdForm.get('personal_details').touched)">
                                    Max 500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #schoolInvolvementTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">SCHOOL INVOLVEMENT</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="schoolInvUpdForm" novalidate [ngClass]="{ formHide: editable, formShow: !editable }">
                <div class="experience-div media w-100">
                    <div *ngIf="!editable">
                        <img
                            src="{{editSchoolInv.page_display_pic? mediaURL + editSchoolInv.page_display_pic : 'assets/images/default.svg'}}">
                    </div>
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>School Club or Team</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Title of Club/Team"
                                            [readonly]="!editable || editSchoolInv.page" formControlName="title">
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Position"
                                            [readonly]="!editable || editSchoolInv.page" formControlName="position">
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('position').hasError('required') && (schoolInvUpdForm.get('position').dirty || schoolInvUpdForm.get('position').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="schoolInvUpdForm.get('position').hasError('maxlength') && (schoolInvUpdForm.get('position').dirty || schoolInvUpdForm.get('position').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <p *ngIf="editSchoolInv.approver_name && editSchoolInv.verified_date"
                                    class="verifiedText d-flex align-items-center mt-4">
                                    <img alt="image" src="assets/images/verify-resume.svg">Verified by
                                    <span>{{editSchoolInv.approver_name}}</span> on
                                    <span>{{editSchoolInv.verified_date | date: 'yyyy-MM-dd'}} </span></p>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>City</label>
                            </div>
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="City"
                                    [readonly]="!editable || editSchoolInv.page" formControlName="city">
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('city').hasError('required') && (schoolInvUpdForm.get('city').dirty || schoolInvUpdForm.get('city').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="schoolInvUpdForm.get('city').hasError('maxlength') && (schoolInvUpdForm.get('city').dirty || schoolInvUpdForm.get('city').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>Start
                                    <input type="text" class="form-control" *ngIf="!editable || editSchoolInv.page"
                                        readonly value="{{editSchoolInv.start_date}}">
                                    <input type="text" class="form-control" bsDatepicker
                                        *ngIf="editable && !editSchoolInv.page" [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD',adaptivePosition: true}"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="schoolInvUpdForm.get('start_date').hasError('required') && (schoolInvUpdForm.get('start_date').dirty || schoolInvUpdForm.get('start_date').touched || submitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>End
                                    <input type="text" class="form-control" readonly value="{{editSchoolInv.end_date}}">
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Learned</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  " readonly formControlName="skill_1">
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " readonly formControlName="skill_2">
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  " readonly formControlName="skill_3">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" readonly formControlName="main_role"> </textarea>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Personal Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control" readonly formControlName="personal_details"> </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #editDescriptionTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Biography</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="descriptionForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>My Top 3 Descriptive Words</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control " placeholder="Keyword 1"
                                    formControlName="profile_keyword_1">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('required') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_1').hasError('maxlength') && (descriptionForm.get('profile_keyword_1').dirty || descriptionForm.get('profile_keyword_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control " placeholder="Keyword 2"
                                    formControlName="profile_keyword_2">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('required') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_2').hasError('maxlength') && (descriptionForm.get('profile_keyword_2').dirty || descriptionForm.get('profile_keyword_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" [readonly]="!editable" class="form-control " placeholder="Keyword 3"
                                    formControlName="profile_keyword_3">
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('required') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('profile_keyword_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="descriptionForm.get('profile_keyword_3').hasError('maxlength') && (descriptionForm.get('profile_keyword_3').dirty || descriptionForm.get('lastname').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [readonly]="!editable" formControlName="description"> </textarea>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('required') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="descriptionForm.get('description').hasError('maxlength') && (descriptionForm.get('description').dirty || descriptionForm.get('description').touched)">
                            Max 1000 characters allowed.
                        </div>
                    </div>
                    <div class="error-message" *ngIf="descriptionForm.get('description').hasError('aftersubmit')">
                        {{descriptionForm.controls.description.errors.aftersubmit}}
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<ng-template #noMessage>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Send Message</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <p>{{messageError}}</p>
        </div>
    </div>
</ng-template>
<ng-template #settingModal>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Settings</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="blockReportForm">
                <div class="d-flex align-items-center">
                    <div class="form-group mr-5">
                        <div class="custom-control custom-checkbox" *ngIf="isUserBlocked">
                            <input type="checkbox" class="custom-control-input" id="block" formControlName="block_user"
                                (click)="blockUser();">
                            <label class="custom-control-label" for="block">Unblock User</label>
                        </div>
                        <div class="custom-control custom-checkbox" *ngIf="!isUserBlocked">
                            <input type="checkbox" class="custom-control-input" id="block" formControlName="block_user"
                                (click)="blockUser();">
                            <label class="custom-control-label" for="block">Block User</label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="report"
                                formControlName="report_user" (click)="reportReason();">
                            <label class="custom-control-label" for="report">Report User</label>
                        </div>
                    </div>
                </div>
                <div class="reportShow" *ngIf="reportUser">
                    <div class="form-group">
                        <label>Reason</label>
                        <select class="form-control custom-select" formControlName="report_reason">
                            <option value=1>Hate Speech</option>
                            <option value=2>Bullying and harrassment of yourself or others</option>
                            <option value=3>Direct threats</option>
                            <option value=4>Inappropriate content (Drugs, Guns, Sexually Explicit)
                            </option>
                            <option value=5>Other</option>
                        </select>
                    </div>
                    <div class="error-message"
                        *ngIf="blockReportForm.get('report_reason').hasError('required') && (blockReportForm.get('report_reason').dirty || blockReportForm.get('report_reason').touched || submitted)">
                        This field is required.
                    </div>
                    <div class="form-group">
                        <label>Description</label>
                        <textarea class="form-control" formControlName="report_description"></textarea>
                        <div class="error-message"
                            *ngIf="blockReportForm.get('report_description').hasError('maxlength') && (blockReportForm.get('report_description').dirty || blockReportForm.get('report_description').touched)">
                            Max 1000 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-footer" (click)="modalRef.hide()">
            Decline
        </button>
        <button class="btn btn-footer" (click)="blockUnblockUser(blockReportForm.valid, user.id)">
            Save
        </button>
    </div>
</ng-template>

<ng-template #studentModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img alt="image" src="assets/images/student-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #businessModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img alt="image" src="assets/images/business-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #volunteerModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img alt="image" src="assets/images/volunteer-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #schoolModal>
    <div class="modal-body p-2">
        <div class="modal-header p-0">
            <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
                <img alt="image" src="assets/images/cross-modal.svg">
            </button>
        </div>
        <div class="modal-box">
            <img alt="image" src="assets/images/school-profile.png">
        </div>
    </div>
</ng-template>
<ng-template #editSkillpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Skills</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        {{editSkill.title}}
                    </div>
                </div>
                <div *ngIf="editSkill.document" class="form-group row">
                    <div class="col-md-2">
                        <label>File</label>
                    </div>
                    <div class="col-md-10">
                        <a *ngIf="!isImage(editSkill.document_name)" href="{{editSkill.document}}" target="_blank"
                            class="link-style">{{editSkill.document_name}}</a>
                        <a *ngIf="isImage(editSkill.document_name)" href="{{editSkill.document}}" target="_blank">
                            <img alt="image" src="{{editSkill.document}}" class="uploaded-img">
                        </a>
                    </div>
                </div>
                <div *ngIf="editSkill.link" class="form-group row">
                    <div class="col-md-2">
                        <label>Link</label>
                    </div>
                    <div class="col-md-10">
                        <a href={{editSkill.link}} target="_blank" class="link-style">{{editSkill.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                {{editSkill.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #editInterestpopup>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Interests</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form novalidate>
                <div class="form-group row">
                    <div class="col-md-2">
                        <label>Title</label>
                    </div>
                    <div class="col-md-10">
                        {{editInterest.title}}
                    </div>
                </div>
                <div *ngIf="editInterest.document" class="form-group row">
                    <div class="col-md-2">
                        <label>File</label>
                    </div>
                    <div class="col-md-10">
                        <a *ngIf="!isImage(editInterest.document_name)" href="{{editInterest.document}}"
                            target="_blank">{{editInterest.document_name}}</a>
                        <a *ngIf="isImage(editInterest.document_name)" href="{{editInterest.document}}" target="_blank">
                            <img alt="image" src="{{editInterest.document}}" class="uploaded-img">
                        </a>
                    </div>
                </div>
                <div *ngIf="editInterest.link" class="form-group row">
                    <div class="col-md-2">
                        <label>Link</label>
                    </div>
                    <div class="col-md-10">
                        <a href={{editInterest.link}} target="_blank" class="link-style">{{editInterest.link}}</a>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Description</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                {{editInterest.description}}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ng-template>
<ng-template #bulletinDetail>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Bulletin Board</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <div class="row">
                <div class="col-md-12">
                    <div class="inner-modal d-flex align-items-center">
                        <h2>Title</h2>
                        <p>{{editBulletin.title}}</p>
                    </div>
                    <div class="divDetail">
                        <h2>Attachments & Links</h2>
                        <div class="d-flex mt-3">
                            <figure *ngIf="editBulletin.document">
                                <a *ngIf="!isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank" class="link-style">{{editBulletin.document_name}}</a>
                                <a *ngIf="isImage(editBulletin.document_name)" href="{{editBulletin.document}}"
                                    target="_blank">
                                    <img alt="image" src="{{editBulletin.document}}">
                                </a>
                            </figure>
                            <div class="uploadDetail ml-3">
                                <a href="{{editBulletin.link}}" target="_blank">{{editBulletin.link}}</a>
                            </div>
                        </div>
                        <div class="content-detail">
                            <h2>Details</h2>
                            <p>{{editBulletin.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
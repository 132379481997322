import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-inner',
  templateUrl: './sidebar-inner.component.html',
  styleUrls: ['./sidebar-inner.component.scss']
})
export class SidebarInnerComponent implements OnInit {
  user: any = {};

  constructor() { }

  ngOnInit(): void {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
  }

}

<section class="home-signup">
    <div class="container-fluid pr-md-0">
        <div class="row mr-md-0">
            <div class="col-md-7 p-0">
                <div class="home-inner d-flex align-items-center">
                    <img  alt="image" src="assets/images/Main-Page-Search.jpg" class="mx-auto" alt="Image banner">
                </div>
            </div>
            <div class="col-md-5 pr-0 d-flex justify-content-end p-0">
                <div class="sidebar-section student form-section">
                    <app-sidebar></app-sidebar>
                    <div class=" d-flex align-items-center home-inner">
                        <div class="box-div text-center mx-auto">
                            <h1 class="pl-2 pr-2">Reset Password</h1>
                            <div *ngIf="!hideform">
                                <form [formGroup]="passwordForm" novalidate (ngSubmit)="submit(passwordForm.valid)">
                                    <div class="form-group gutters-5 row">
                                        <div class="col-md-12">
                                            <label>Enter New Password</label>
                                        </div>
                                        <div class="col-md-12">
                                            <input type="password" class="form-control mb-2" placeholder="Enter New Password" formControlName="password">
                                            <div class="error-message" *ngIf="passwordForm.get('password').hasError('required') && (passwordForm.get('password').dirty || passwordForm.get('password').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message" *ngIf="passwordForm.get('password').hasError('invalidPassword') && (passwordForm.get('password').dirty || passwordForm.get('password').touched)">
                                                Your password needs to be at least 8 characters, including a Capital Letter, Lowercase Letter and a Number.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group gutters-5 row">
                                        <div class="col-md-12">
                                            <label>Confirm Password</label>
                                        </div>
                                        <div class="col-md-12">
                                            <input type="password" class="form-control mb-2" placeholder="Confirm Password" formControlName="confirm_password">
                                            <div class="error-message" *ngIf="passwordForm.get('confirm_password').hasError('required') && (passwordForm.get('confirm_password').dirty || passwordForm.get('confirm_password').touched || submitted)">
                                                This field is required.
                                            </div>
                                            <div class="error-message" *ngIf="passwordForm.get('confirm_password').hasError('minlength') && (passwordForm.get('confirm_password').dirty || passwordForm.get('confirm_password').touched)">
                                                Your password needs to be at least 8 characters, including a Capital Letter, Lowercase Letter and a Number.
                                            </div>
                                            <div class="error-message" *ngIf="passwordForm.hasError('passwordNotMatch') && (passwordForm.get('confirm_password').dirty || passwordForm.get('confirm_password').touched)">
                                                Passwords do not match.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group gutters-5">
                                        <div class="col-md-12">
                                            <button type="submit" class="btn btn-submit">Confirm</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <span class="text-center success-msg" *ngIf="showMessage">Password has been changed successfully.</span>
                            <h2 class="text-center success-msg" *ngIf="showError">Link provided is expired or invalid.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
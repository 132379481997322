<div class="side-links">
    <ul>
        <li *ngIf="user.user_role == '2'" class="active">
            <a [routerLink]="['/profile']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                    true}">
                <img  alt="image" src="assets/images/user.svg" class=" d-md-inline-block d-none" />
                <span class="d-md-none d-block">Profile</span>
            </a>
        </li>
        <li class="bg-bsns d-md-block d-none">
            <a [routerLink]="['/notifications']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                    true}">
                <img  alt="image" src="assets/images/notification.svg" />
            </a>
        </li>
        <li *ngIf="user.user_role == '2' && user.age_group > 1" class="bg-bsns d-md-block d-none">
            <a [routerLink]="['/message-chat']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                    true}">
                <img  alt="image" src="assets/images/msg-icon.svg"  class=" d-md-inline-block d-none">
            </a>
        </li>
        <li class="bg-bsns">
            <a [routerLink]="['/profile/setting']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                    true}">
                <img  alt="image" src="assets/images/setting.svg" class=" d-md-inline-block d-none" />
                <span class="d-md-none d-block">Settings</span>
            </a>
        </li>
        <li class="bg-bsns d-md-block d-none">
            <a [routerLink]="['/help']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:
                    true}">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g fill="none" fill-rule="evenodd">
                        <rect width="24" height="24" fill="#335175" rx="12" transform="matrix(-1 0 0 1 24 0)" />
                        <g fill="rgb(194,203,214)" fill-rule="nonzero">
                            <path
                                d="M3.264 11.424L5.712 11.424 5.712 13.056 3.264 13.056zM8.976 4.311C8.976 1.931 6.966 0 4.488 0 2.009 0 0 1.93 0 4.311h2.303c0-1.157.98-2.1 2.185-2.1s2.186.943 2.186 2.1c0 1.158-.98 2.1-2.186 2.1H3.336v4.197H5.64v-2.13C7.56 7.99 8.976 6.31 8.976 4.312z"
                                transform="translate(7.2 6)" />
                        </g>
                    </g>
                </svg>

            </a>
        </li>
    </ul>
</div>
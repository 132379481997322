<h3>WORK EXPERIENCE
    <a href="javascript:void()" class="float-right" *ngIf="!editable && account_verification_status != '3'"
        (click)="expModal(expTemplate)">
        <img alt="image" src="assets/images/plus-icon.png" alt="">
    </a>
    <a href="javascript:void();" *ngIf="editable" class="float-right no_cursor">
        <img alt="image" src="assets/images/icEdit.svg">
    </a>
</h3>
<div class="scroll-activity" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
    <div class="box-div inner-exp work-tile-border cursor-pointer" *ngFor="let experience of usrWorkExperience"
        (click)="updateExpModal(editExpTemplate, experience)">
        <div class="experience-div media position-relative">
            <img (click)="redirectPage(experience);$event.stopPropagation()"
                src="{{experience.page_display_pic? mediaURL + experience.page_display_pic : 'assets/images/default.svg'}}">
            <div class="media-body ml-2">
                <h4>{{experience.position}}</h4>
                <p>{{experience.organization_title}}</p>
                <h5 *ngIf="experience.start_date != experience.end_date">Start
                    <span>{{experience.start_date}}</span> End
                    <ng-template
                        [ngIf]="experience.end_date && !(experience.verification_status == 1 && experience.approver_name)"
                        [ngIfElse]="currentTemp">
                        <span>{{experience.end_date}}</span>
                    </ng-template>
                </h5>
                <h5 *ngIf="experience.start_date == experience.end_date">Date
                    <span>{{experience.start_date}}</span>
                </h5>
                <p class="verifiedTextImg" *ngIf="experience.approver_name && experience.verified_date">
                    <img alt="image" src="assets/images/verify-resume.svg">
                </p>
            </div>
        </div>
        <div class="side-col">
            <ul class="d-flex pl-0">
                <li *ngIf="experience.skill_1">
                    <p>{{experience.skill_1}}</p>
                </li>
                <li *ngIf="experience.skill_2">
                    <p>{{experience.skill_2}}</p>
                </li>
                <li *ngIf="experience.skill_3">
                    <p>{{experience.skill_3}}</p>
                </li>
            </ul>
            <p>{{experience.main_role}}
            </p>
        </div>
    </div>
    <div *ngIf="usrWorkExperience?.length == 0"
        class="empty-div text-center h-100 d-flex align-items-center justify-content-center">
        <p>Add a Work Experience to your resume – this can be verified if you tag the Page and if the Page exists on
            ConnectUs.Today</p>
    </div>
</div>

<ng-template #editExpTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Work Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form [formGroup]="expUpdForm" novalidate [ngClass]="{ 'formHide': editable, formShow: !editable }">
                <div class="experience-div media w-100">
                    <div *ngIf="!editable">
                        <img
                            src="{{editExp.page_display_pic? mediaURL + editExp.page_display_pic : 'assets/images/default.svg'}}">
                    </div>
                    <div class="media-body ml-md-3">
                        <div class="row form-group">
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Business</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Business Name"
                                            [readonly]="!editable || editExp.page" formControlName="title"
                                            (keydown)="searchOrg($event, true)" (focusout)="focusOutFunction()">
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('title').hasError('required') && (expUpdForm.get('title').dirty || expUpdForm.get('title').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('title').hasError('maxlength') && (expUpdForm.get('title').dirty || expUpdForm.get('title').touched)">
                                            Max 60 characters allowed.
                                        </div>
                                        <div *ngIf="!hideResults && editable && !editExp.page" class="col-md-12">
                                            <div class="option-div" slimScroll [options]="opts"
                                                [scrollEvents]="scrollEvents">
                                                <div class="" *ngFor="let organization of organizations">
                                                    <div (click)="selectOrg(organization, true)" class="media p-2">
                                                        <img
                                                            src="{{organization.display_pic? mediaURL + organization.display_pic : 'assets/images/default.svg'}}">
                                                        <div class="media-body ml-2">
                                                            <p class="pl-2">{{organization.page_title}}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row no-gutters">
                                    <div class="col-md-12">
                                        <label>Position</label>
                                    </div>
                                    <div class="col-md-12">
                                        <input type="text" class="form-control" placeholder="Enter Position"
                                            [readonly]="!editable || editExp.page" formControlName="position">
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('position').hasError('required') && (expUpdForm.get('position').dirty || expUpdForm.get('position').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('position').hasError('maxlength') && (expUpdForm.get('position').dirty || expUpdForm.get('position').touched)">
                                            Max 50 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <p *ngIf="editExp.approver_name && editExp.verified_date && !editable"
                                    class="verifiedText"><img  alt="image" src="assets/images/verify-resume.svg">Verified by
                                    <span>{{editExp.approver_name}}</span> on
                                    <span>{{editExp.verified_date | date: 'yyyy-MM-dd'}} </span></p>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label>
                                    City
                                    <input type="text" class="form-control" placeholder="City"
                                        [readonly]="!editable || editExp.page" formControlName="city">
                                    <div class="error-message"
                                        *ngIf="expUpdForm.get('city').hasError('required') && (expUpdForm.get('city').dirty || expUpdForm.get('city').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="expUpdForm.get('city').hasError('maxlength') && (expUpdForm.get('city').dirty || expUpdForm.get('city').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </label>
                            </div>
                            <div class="col-6" *ngIf="editable">
                                <label>
                                    Postal Code
                                    <input type="text" class="form-control" placeholder="Postal Code"
                                        [readonly]="!editable || editExp.page" formControlName="zip_code">
                                    <div class="error-message"
                                        *ngIf="expUpdForm.get('zip_code').hasError('required') && (expUpdForm.get('zip_code').dirty || expUpdForm.get('zip_code').touched || submitted)">
                                        This field is required.
                                    </div>
                                    <div class="error-message"
                                        *ngIf="expUpdForm.get('zip_code').hasError('maxlength') && (expUpdForm.get('zip_code').dirty || expUpdForm.get('zip_code').touched)">
                                        Max 30 characters allowed.
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <label>Dates</label>
                            </div>
                            <div class="col-md-6 col-6">
                                <label>
                                    <span *ngIf="editExp.start_date != editExp.end_date" style="color: #335175;" class="mr-2">Start</span>
                                    <span *ngIf="editExp.start_date == editExp.end_date" style="color: #335175;" class="mr-2">Date</span>
                                    <input type="text" class="form-control" *ngIf="!editable || editExp.page" readonly
                                        value="{{editExp.start_date}}">
                                    <input type="text" class="form-control" bsDatepicker
                                        *ngIf="editable && !editExp.page" [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="start_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="expUpdForm.get('start_date').hasError('required') && (expUpdForm.get('start_date').dirty || expUpdForm.get('start_date').touched || submitted)">
                                        This field is required.
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-6 col-6" *ngIf="editExp.start_date != editExp.end_date">
                                <label>End
                                    <input type="text" class="form-control" *ngIf="!canEditEndDate()" readonly
                                        value="{{editExp.end_date}}">
                                    <input type="text" class="form-control" bsDatepicker *ngIf="canEditEndDate()"
                                        [minDate]="minDate" [maxDate]="today"
                                        [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                                        formControlName="end_date" style="background-color: white;" readonly>
                                    <div class="error-message"
                                        *ngIf="expUpdForm.errors?.invalidEndDate && (expUpdForm.touched || expUpdForm.dirty)">
                                        Start date should not be greater than end date
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Top Skills Learned</label>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable"
                                            formControlName="skill_1">
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('skill_1').hasError('required') && (expUpdForm.get('skill_1').dirty || expUpdForm.get('skill_1').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('skill_1').hasError('maxlength') && (expUpdForm.get('skill_1').dirty || expUpdForm.get('skill_1').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable"
                                            formControlName="skill_2">
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('skill_2').hasError('required') && (expUpdForm.get('skill_2').dirty || expUpdForm.get('skill_2').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('skill_2').hasError('maxlength') && (expUpdForm.get('skill_2').dirty || expUpdForm.get('skill_2').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control  "
                                            [readonly]="!editable"
                                            formControlName="skill_3">
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('skill_3').hasError('required') && (expUpdForm.get('skill_3').dirty || expUpdForm.get('skill_3').touched || submitted)">
                                            This field is required.
                                        </div>
                                        <div class="error-message"
                                            *ngIf="expUpdForm.get('skill_3').hasError('maxlength') && (expUpdForm.get('skill_3').dirty || expUpdForm.get('skill_3').touched)">
                                            Max 30 characters allowed.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Main Role</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control"
                                    [readonly]="!editable"
                                    formControlName="main_role"> </textarea>
                                <div class="error-message"
                                    *ngIf="expUpdForm.get('main_role').hasError('required') && (expUpdForm.get('main_role').dirty || expUpdForm.get('main_role').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="expUpdForm.get('main_role').hasError('maxlength') && (expUpdForm.get('main_role').dirty || expUpdForm.get('main_role').touched)">
                                    Max 750 characters allowed.
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <label>Summary of Experience</label>
                            </div>
                            <div class="col-md-12">
                                <textarea class="form-control"
                                    [readonly]="!editable"
                                    formControlName="personal_details"> </textarea>
                                <div class="error-message"
                                    *ngIf="expUpdForm.get('personal_details').hasError('required') && (expUpdForm.get('personal_details').dirty || expUpdForm.get('personal_details').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="expUpdForm.get('personal_details').hasError('maxlength') && (expUpdForm.get('personal_details').dirty || expUpdForm.get('personal_details').touched)">
                                    Max 1500 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer" *ngIf="canEdit()">
        <button class="btn btn-footer" (click)="delExperience()">
            Delete
        </button>
        <button class="btn btn-footer" (click)="updateExperience(expUpdForm.valid)">
            Update
        </button>
    </div>
</ng-template>
<ng-template #expTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Work Experience</h4>
        <button type="button" class="close-btn" aria-label="Close" (click)="modalRef.hide()">
            <img alt="image" src="assets/images/cross-modal.svg">
        </button>
    </div>
    <div class="modal-body">
        <div class="modal-box">
            <form *ngIf="!formShow" [formGroup]="searchForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>
                            City
                            <input type="text" class="form-control" placeholder="City" formControlName="city">
                            <div class="error-message"
                                *ngIf="searchForm.get('city').hasError('required') && (searchForm.get('city').dirty || searchForm.get('city').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('city').hasError('maxlength') && (searchForm.get('city').dirty || searchForm.get('city').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>
                            Postal Code
                            <input type="text" class="form-control" placeholder="Postal Code"
                                formControlName="zip_code">
                            <div class="error-message"
                                *ngIf="searchForm.get('zip_code').hasError('required') && (searchForm.get('zip_code').dirty || searchForm.get('zip_code').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('zip_code').hasError('maxlength') && (searchForm.get('zip_code').dirty || searchForm.get('zip_code').touched)">
                                Max 30 characters allowed.
                            </div>
                        </label>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label>Title
                            <input type="text" class="form-control" placeholder="Enter Title of Organization"
                                formControlName="title" (keydown)="searchOrg($event)" (focusout)="focusOutFunction()">
                            <div class="error-message"
                                *ngIf="searchForm.get('title').hasError('required') && (searchForm.get('title').dirty || searchForm.get('title').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('title').hasError('maxlength') && (searchForm.get('title').dirty || searchForm.get('title').touched)">
                                Max 60 characters allowed.
                            </div>
                            <div *ngIf="!hideResults" class="col-md-12">
                                <div class="option-div" slimScroll [options]="opts" [scrollEvents]="scrollEvents">
                                    <div class=" " *ngFor="let organization of organizations">
                                        <div (click)="selectOrg(organization)" class="media p-2">
                                            <img
                                                src="{{organization.display_pic? mediaURL + organization.display_pic : 'assets/images/default.svg'}}">
                                            <div class="media-body ml-2">
                                                <p class="pl-2">{{organization.page_title}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="col-md-6">
                        <label>Position
                            <input type="text" class="form-control" placeholder="Type Here" formControlName="position">
                            <div class="error-message"
                                *ngIf="searchForm.get('position').hasError('required') && (searchForm.get('position').dirty || searchForm.get('position').touched || partialSubmitted)">
                                This field is required.
                            </div>
                            <div class="error-message"
                                *ngIf="searchForm.get('position').hasError('maxlength') && (searchForm.get('position').dirty || searchForm.get('position').touched)">
                                Max 50 characters allowed.
                            </div>
                        </label>
                    </div>

                </div>
                <div class="form-group row">
                    <div class="col-md-6">
                        <label for="">Start Date</label>
                        <input type="text" class="form-control modal-control modal-field" placeholder="Start Date"
                            bsDatepicker [minDate]="minDate" [maxDate]="today"
                            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                            formControlName="start_date" style="background-color: white;" readonly>
                        <a href="javascript:void(0);" (click)="resetStartDate()"><img  alt="image" src="assets/images/plus-icon.png"
                                class="img-cross"></a>
                        <div class="error-message"
                            *ngIf="searchForm.get('start_date').hasError('required') && (searchForm.get('start_date').dirty || searchForm.get('start_date').touched || partialSubmitted)">
                            This field is required.
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="">End Date</label>
                        <input type="text" class="form-control modal-control modal-field" placeholder="End Date"
                            bsDatepicker [minDate]="minDate" [maxDate]="today"
                            [bsConfig]="{dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
                            formControlName="end_date" style="background-color: white;" readonly>
                        <a href="javascript:void(0);" (click)="resetEndDate()"><img  alt="image" src="assets/images/plus-icon.png"
                                class="img-cross"></a>
                        <div class="error-message"
                            *ngIf="searchForm.errors?.invalidEndDate && (searchForm.touched || searchForm.dirty)">
                            Start date should not be greater than end date
                        </div>
                    </div>
                </div>
            </form>
            <form *ngIf="formShow && !previewShow" [formGroup]="expForm" novalidate>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Top Skills Learned</label>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_1">
                                <div class="error-message"
                                    *ngIf="expForm.get('skill_1').hasError('required') && (expForm.get('skill_1').dirty || expForm.get('skill_1').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="expForm.get('skill_1').hasError('maxlength') && (expForm.get('skill_1').dirty || expForm.get('skill_1').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_2">
                                <div class="error-message"
                                    *ngIf="expForm.get('skill_2').hasError('required') && (expForm.get('skill_2').dirty || expForm.get('skill_2').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="expForm.get('skill_2').hasError('maxlength') && (expForm.get('skill_2').dirty || expForm.get('skill_2').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                            <div class="col-md-4">
                                <input type="text" class="form-control  " formControlName="skill_3">
                                <div class="error-message"
                                    *ngIf="expForm.get('skill_3').hasError('required') && (expForm.get('skill_3').dirty || expForm.get('skill_3').touched || submitted)">
                                    This field is required.
                                </div>
                                <div class="error-message"
                                    *ngIf="expForm.get('skill_3').hasError('maxlength') && (expForm.get('skill_3').dirty || expForm.get('skill_3').touched)">
                                    Max 30 characters allowed.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Main Role</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="main_role"> </textarea>
                        <div class="error-message"
                            *ngIf="expForm.get('main_role').hasError('required') && (expForm.get('main_role').dirty || expForm.get('main_role').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="expForm.get('main_role').hasError('maxlength') && (expForm.get('main_role').dirty || expForm.get('main_role').touched)">
                            Max 750 characters allowed.
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <label>Personal Details</label>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" formControlName="personal_details"> </textarea>
                        <div class="error-message"
                            *ngIf="expForm.get('personal_details').hasError('required') && (expForm.get('personal_details').dirty || expForm.get('personal_details').touched || submitted)">
                            This field is required.
                        </div>
                        <div class="error-message"
                            *ngIf="expForm.get('personal_details').hasError('maxlength') && (expForm.get('personal_details').dirty || expForm.get('personal_details').touched)">
                            Max 1500 characters allowed.
                        </div>
                    </div>
                </div>
            </form>
            <div *ngIf="previewShow" class="content-section p-2">
                <div class="profile-div">
                    <div class="profile-inner full-inner mb-0 mt-md-2 mt-0">
                        <div class="box-div inner-exp border-0 m-0 border-radius-0">
                            <div class="experience-div media p-0 bg-transparent border-radius-0">
                                <img alt="image" src="{{businessImage? mediaURL + businessImage : 'assets/images/default.svg'}}">
                                <div class="media-body ml-2">
                                    <h4>
                                        <span class="mr-3 title">Business</span>{{this.searchForm.get('title').value}}
                                    </h4>
                                    <p>
                                        <span
                                            class="mr-3 title">Position</span>{{this.searchForm.get('position').value}}
                                    </p>
                                    <h5 *ngIf="!exp_end_one_day">
                                        <span class="mr-3 title">Dates</span>Start
                                        <span>{{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}</span>
                                        End
                                        <span *ngIf="!this.searchForm.get('end_date').value">Current</span>
                                        <span>{{this.searchForm.get('end_date').value | date: 'yyyy-MM-dd'}}</span>
                                    </h5>
                                    <h5 *ngIf="exp_end_one_day">
                                        <span class="mr-3 title">Dates</span>Date
                                        <span>{{this.searchForm.get('start_date').value | date: 'yyyy-MM-dd'}}</span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button *ngIf="!formShow" class="btn btn-footer" (click)="showForm(searchForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="formShow && !previewShow" (click)="showForm(searchForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="formShow && !previewShow" (click)="showPreview(expForm.valid)">
            Next
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="showPreview(expForm.valid)">
            Return
        </button>
        <button class="btn btn-footer" *ngIf="previewShow" (click)="addExperience()">
            <span *ngIf="!orgId">Save</span><span *ngIf="orgId">Send</span>
        </button>
    </div>
</ng-template>
<ng-template #currentTemp>
    <span>Current</span>
</ng-template>
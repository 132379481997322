import { Component, OnInit, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ProfileService } from '../../../services/profile.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-sidechat',
  templateUrl: './sidechat.component.html',
  styleUrls: ['./sidechat.component.scss']
})
export class SidechatComponent implements OnInit {
  modalRef: BsModalRef;
  @Input() conversation: any;
  @Input() messages = [];
  @Output() deletedConversation: EventEmitter<any> = new EventEmitter<any>();
  mediaURL = environment.mediaURL;
  messageForm: FormGroup;
  public settingShow = true;
  public msgSetting = true;
  public checkSetting = true;
  public showMsg = false;
  submitMsg: string;
  selectedMessages = [];

  constructor(private modalService: BsModalService, public formBuilder: FormBuilder, private profileService: ProfileService) {
    this.messageForm = this.formBuilder.group({
      text: new FormControl('', [Validators.required, Validators.maxLength(5000)]),
    });
  }

  ngOnInit(): void {
  }

  sendText(isValid: boolean){
    if (isValid && this.conversation.id) {
      let model = {
        'conversation': this.conversation.id,
        'text': this.messageForm.get('text').value
      }
      this.profileService.sendMessage(model)
      .subscribe(response => {
        if (response.status === 200) {
          this.messageForm.reset();
          let message = response.response
          message.is_sender = true;
          this.messages.push(message);
        } else if (response.status === 112) {
          this.fadeOutMsg();
          this.showMsg = true;
          this.submitMsg = response.message;
        } else {
          this.fadeOutMsg();
          this.showMsg = true;
          this.submitMsg = "Something went wrong. Please try again later.";
        }
      })
    }
  }

  fadeOutMsg() {
    setTimeout( () => {
      this.showMsg = false;
      this.submitMsg = "";
    }, 6000);
   }

  deleteModal(deleteTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(deleteTemplate,
      Object.assign({}, { class: 'custom-modal modal-dialog-centered' })
    );
  }

  toSelectMessages(isCancel: boolean = false){
    this.selectedMessages = [];
    this.checkSetting = !this.checkSetting;
    if (isCancel){
      this.msgSetting = !this.msgSetting;
    }
  }

  delConversation(){
    this.msgSetting = !this.msgSetting;
     this.profileService.deleteConversation(this.conversation.id)
     .subscribe(response => {
       this.modalRef.hide();
       this.handleResponse(response);
     }, error => {
        this.modalRef.hide();
        this.fadeOutMsg();
        this.showMsg = true;
        this.submitMsg = "Something went wrong. Please try again later.";
     })
  }

  handleResponse(response: any){
    if (response.status === 200){
      this.messages = [];
      this.deletedConversation.emit(true);
    } else {
      this.fadeOutMsg();
      this.showMsg = true;
      this.submitMsg = "Something went wrong. Please try again later.";
    }
  }

  selectMessages(e: any, message: any){
    if (e.target.checked){
      this.selectedMessages.push(message.id);
    } else {
      let index = this.selectedMessages.findIndex(item => item === message.id);
      this.selectedMessages.splice(index, 1);
    }
  }

  delMessages(){
    this.msgSetting = !this.msgSetting;
    let model = {
      'messages': this.selectedMessages
    }
    this.profileService.deleteMessages(this.conversation.id, model)
    .subscribe(response => {
      this.modalRef.hide();
      this.handleMessageResponse(response);
    }, error => {
       this.modalRef.hide();
       this.fadeOutMsg();
       this.showMsg = true;
       this.submitMsg = "Something went wrong. Please try again later.";
    })
  }

  handleMessageResponse(response: any){
    this.selectedMessages = [];
    this.toSelectMessages();
    if (response.status === 200){
      if (response.response.length > 0){
        this.messages = response.response;
      } else {
        this.messages = [];
      }
    } else {
      this.fadeOutMsg();
      this.showMsg = true;
      this.submitMsg = "Something went wrong. Please try again later.";
    }
  }

}

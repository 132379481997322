import { Component, OnInit, TemplateRef, EventEmitter, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators, FormBuilder, ValidatorFn, ValidationErrors } from '@angular/forms';
import { ProfileService } from '../services/profile.service';
import { UserProfile } from '../shared/userProfile';
import { UserSkill } from '../shared/userSkill';
import { UserBook } from '../shared/userBook';
import { LoginService } from '../services/login.service';
import { first } from 'rxjs/operators';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll';
import { CustomvalidationService } from '../services/customvalidation.service';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';
import { SlickCarouselComponent } from "ngx-slick-carousel";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    { provide: CarouselConfig, useValue: { interval: 150000000 } }
  ]
})
export class ProfileComponent implements OnInit {
  @ViewChild("slickModal") slickModal: SlickCarouselComponent;
  termsAcceptForm: FormGroup;
  mediaURL = environment.mediaURL;
  modalRef: BsModalRef;
  user: any = {};
  public settingShow = true;
  profile: any = {};
  editSkill: any = {};
  editInterest: any = {};
  editBook: any = {};
  editBulletin: any = {};
  editActivity: any = {};
  skills = [];
  interests = [];
  books = [];
  bulletins = [];
  activities = [];
  organizations = [];
  descriptionForm: FormGroup;
  skillForm: FormGroup;
  interestForm: FormGroup;
  bookForm: FormGroup;
  uploadForm: FormGroup;
  bulletinForm: FormGroup;
  activityForm: FormGroup;
  searchForm: FormGroup;
  activityUpdForm: FormGroup;
  hideResults: boolean = false;
  formShow: boolean = false;
  previewShow: boolean = false;
  account_verification_status: string;
  days_left_to_verify: string;
  public partialSubmitted: boolean = false;
  public submitted: boolean = false;
  public editable: boolean = false;
  public interestFrmsubmitted: boolean = false;
  public bookFrmsubmitted: boolean = false;
  public disableSubmit: boolean = false;
  public eventListing = true;
  activeSlideIndex = 0;
  fileUploadError = false;
  fileUploadMessage = "";
  fileUploadSizeError = true
  fileUploadSizeMessage = "";
  today = new Date();
  orgId: string = null;
  stepOne: boolean = true;
  stepTwo: boolean = false;
  stepThree: boolean = false;
  timeline_start: any;
  timeline_end: any;
  month_instances:any;
  current_year : any;
  running_year = new Date().getFullYear();
  prevYearCheck:boolean = true;
  nextYearCheck:boolean = true;
  timelineDetails : any;
  timelineColClass: any;
  setClass:boolean = true;
  is_terms_accepted: boolean = false;
  isLibrary: boolean = false;
  timelineMonth:any;
  businessImage: string = null;
  public exp_end_one_day: boolean = false;
  
  userProfile: UserProfile = {
    description: '',
    profile_keyword_1: '',
    profile_keyword_2: '',
    profile_keyword_3: '',
    user: ''
  }

  userSkill: UserSkill = {
    description: '',
    title: '',
    user: '',
    id: '',
    link: undefined
  }

  userBook: UserBook = {
    description: '',
    title: '',
    user: '',
    id: '',
    author: '',
    rating: undefined
  }
  opts: ISlimScrollOptions;
  scrollEvents: EventEmitter<SlimScrollEvent>;


  constructor(private loginService: LoginService, private authService: AuthenticationService, private datePipe: DatePipe, private modalService: BsModalService, private router: Router, public formBuilder: FormBuilder, private profileService: ProfileService,
    private customValidator: CustomvalidationService) {
      this.termsAcceptForm = this.formBuilder.group({
        terms: new FormControl('', [Validators.required]),
      });
    this.descriptionForm = this.formBuilder.group({
      profile_keyword_2: new FormControl('', [Validators.maxLength(30)]),
      profile_keyword_1: new FormControl('', [Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(4000)]),
      profile_keyword_3: new FormControl('', [Validators.maxLength(30)])
    });
    this.skillForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.interestForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.bookForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      author: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(3000)]),
      rating: new FormControl('')
    });
    this.uploadForm = this.formBuilder.group({
      document: [''],
      document_name: ['']
    });
    this.bulletinForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
      link: new FormControl('', [Validators.maxLength(250), Validators.pattern(this.customValidator.urlRegex)])
    });
    this.searchForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('')
    }, { validators: this.dateValidator });
    this.activityForm = this.formBuilder.group({
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(750)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
    });
    this.activityUpdForm = this.formBuilder.group({
      title: new FormControl('', [Validators.required, Validators.maxLength(60)]),
      city: new FormControl('', [Validators.required, Validators.maxLength(20)]),
      zip_code: new FormControl('', Validators.maxLength(20)),
      position: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl(''),
      skill_1: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_2: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      skill_3: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      main_role: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      personal_details: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    }, { validators: this.dateValidator });
  }

  dateValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    let start_date = control.get('start_date').value;
    let end_date = control.get('end_date').value;
    if (start_date && end_date) {
      start_date = new Date(start_date).setHours(0, 0, 0, 0);
      end_date = new Date(end_date).setHours(0, 0, 0, 0);
      let diff = end_date - start_date;
      if (diff < 0) {
        return { 'invalidEndDate': true };
      }
      return null;
    }
    return null;
  }

  ngOnInit(): void {
    this.scrollEvents = new EventEmitter<SlimScrollEvent>();
    this.opts = {
      alwaysPreventDefaultScroll: true
    }
    const currentUser = JSON.parse(localStorage.getItem('user'));
    this.user = currentUser;
    if (this.user.user_role !== 2) {
      this.router.navigate(['/dashboard']);
    }

    this.profileService.getDescription(this.user.id)
      .subscribe(response => {
        if (response.response['id'] !== undefined) {
          this.profile = response.response;
        }
      })
    this.profileService.getSkills(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.skills = response.response;
        }
      })
    this.profileService.getInterests(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.interests = response.response;
        }
      })
    this.getActivities();
    this.getBulletin();
    this.profileService.getBooks(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.books = response.response;
        }
      })

      this.authService.AccountVerificationStatus()
      .subscribe(response => {
        this.account_verification_status = response.response.account_verified
        this.days_left_to_verify = response.response.days_left
      });

      this.authService.getTimeline(this.running_year)
      .subscribe(response => {
        
        this.timeline_start = response.response.start_year
        this.timeline_end = response.response.end_year
        this.month_instances = response.response.month_dict

        this.current_year = this.running_year
        if (this.timeline_start  < this.current_year){
          this.prevYearCheck = false;
        }else{
          this.prevYearCheck = true;
        }
        if (this.timeline_end  > this.current_year){
          this.nextYearCheck = false;
        }else{
          this.nextYearCheck = true;
        }
      });
  }

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "infinite": false,
    "autoplay": true,
    "responsive": [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          slidesToScroll: 1,
        },
      },
    ]
  };

  updBulletin() {
    if (this.bulletins.length > 0) {
      this.isLibrary = true;
      if (!this.slickModal){
        setTimeout(() => {
          if (!this.slickModal.initialized) {
            this.slickModal.initSlick();
          }
        }, 1000);
      } else if (!this.slickModal.initialized) {
        this.slickModal.initSlick();
      }
    } else {
      this.isLibrary = false;
    }
  }

  disableEdit(e) {
    this.editable = !this.editable;
  }

  openModal(template: TemplateRef<any>) {
    this.descriptionForm.reset();
    this.submitted = false;
    if (Object.keys(this.profile).length !== 0) {
      this.descriptionForm.controls['description'].setValue(this.profile.description);
      this.descriptionForm.controls['profile_keyword_1'].setValue(this.profile.profile_keyword_1);
      this.descriptionForm.controls['profile_keyword_2'].setValue(this.profile.profile_keyword_2);
      this.descriptionForm.controls['profile_keyword_3'].setValue(this.profile.profile_keyword_3);
    }
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }
  openWorkModal(addWorkModal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(addWorkModal,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  resetEndDate() {
    this.activityUpdForm.controls['end_date'].setValue(null);
    this.searchForm.controls['end_date'].setValue(null);
  }

  resetStartDate() {
    this.activityUpdForm.controls['start_date'].setValue(null);
    this.searchForm.controls['start_date'].setValue(null);
  }

  showForm(isValid: boolean) {
    if (isValid) {
      this.formShow = !this.formShow;
    } else {
      this.partialSubmitted = true;
    }
  }

  showPreview(isValid: boolean) {
    let exp_start_date: any = this.searchForm.controls['start_date'].value;
    let exp_end_date: any = this.searchForm.controls['end_date'].value;
    if (exp_end_date){
      if (exp_start_date.getTime() === exp_end_date.getTime()){
        this.exp_end_one_day = true;
      }
      else {
        this.exp_end_one_day = false;
      }
    }
    if (isValid) {
      this.previewShow = !this.previewShow;
    } else {
      this.submitted = true;
    }
  }

  isImage(name: string) {
    const fileExtension = name.split('.').pop();
    var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"];
    if (allowedExtensions.includes(fileExtension)) {
      return true
    } else {
      return false
    }
  }

  skillModal(skillpopup: TemplateRef<any>) {
    this.skillForm.reset();
    this.submitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateSkillModal(skillpopup: TemplateRef<any>, skill: any) {
    this.editSkill = skill;
    this.skillForm.controls['description'].setValue(skill.description);
    this.skillForm.controls['title'].setValue(skill.title);
    this.skillForm.controls['link'].setValue(skill.link);
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  interestModal(skillpopup: TemplateRef<any>) {
    this.interestForm.reset();
    this.interestFrmsubmitted = false;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  bulletinModal(skillpopup: TemplateRef<any>) {
    this.bulletinForm.reset();
    this.submitted = false;
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateInterestModal(skillpopup: TemplateRef<any>, interest: any) {
    this.editInterest = interest;
    this.interestForm.controls['description'].setValue(interest.description);
    this.interestForm.controls['title'].setValue(interest.title);
    this.interestForm.controls['link'].setValue(interest.link);
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  bookModal(template: TemplateRef<any>) {
    this.bookForm.reset();
    this.bookFrmsubmitted = false;
    this.modalRef = this.modalService.show(template,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  getRating(e) {
    this.bookForm.controls['rating'].setValue(e);
  }

  updatebookModal(skillpopup: TemplateRef<any>, book: any) {
    this.editBook = book;
    this.bookForm.controls['description'].setValue(book.description);
    this.bookForm.controls['title'].setValue(book.title);
    this.bookForm.controls['author'].setValue(book.author);
    this.modalRef = this.modalService.show(skillpopup,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updatebulletinModal(bulletinDetail: TemplateRef<any>, editbulletinModal: TemplateRef<any>, bulletin: any) {
    this.editBulletin = bulletin;
    if (this.editable) {
      this.bulletinForm.controls['description'].setValue(bulletin.description);
      this.bulletinForm.controls['title'].setValue(bulletin.title);
      this.bulletinForm.controls['link'].setValue(bulletin.link);
      this.modalRef = this.modalService.show(editbulletinModal,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
      );
    } else {
      this.modalRef = this.modalService.show(bulletinDetail,
        Object.assign({}, { class: 'modal-dialog-centered custom-modal bulletin-modal' })
      );
    }
  }

  activityModal(activityTemplate: TemplateRef<any>) {
    this.previewShow = false;
    this.formShow = false;
    this.businessImage = null;
    this.submitted = false;
    this.partialSubmitted = false;
    this.searchForm.reset();
    this.activityForm.reset();
    this.hideResults = true;
    this.organizations = [];
    this.modalRef = this.modalService.show(activityTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  updateActivityModal(editActivityTemplate: TemplateRef<any>, activity: any) {
    this.editActivity = activity;
    this.businessImage = null;
    this.activityUpdForm.controls['title'].setValue(activity.organization_title);
    this.activityUpdForm.controls['city'].setValue(activity.city);
    this.activityUpdForm.controls['position'].setValue(activity.position);
    this.activityUpdForm.controls['start_date'].setValue(activity.start_date);
    this.activityUpdForm.controls['end_date'].setValue(activity.end_date);
    this.activityUpdForm.controls['skill_1'].setValue(activity.skill_1);
    this.activityUpdForm.controls['skill_2'].setValue(activity.skill_2);
    this.activityUpdForm.controls['skill_3'].setValue(activity.skill_3);
    this.activityUpdForm.controls['main_role'].setValue(activity.main_role);
    this.activityUpdForm.controls['personal_details'].setValue(activity.personal_details);
    this.activityUpdForm.controls['zip_code'].setValue(activity.zip_code);
    this.hideResults = true;
    this.organizations = [];
    this.modalRef = this.modalService.show(editActivityTemplate,
      Object.assign({}, { class: 'modal-dialog-centered custom-modal' })
    );
  }

  getSkills() {
    this.profileService.getSkills(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.skills = response.response;
        } else {
          this.skills = [];
        }
      });
  }

  getInterests() {
    this.profileService.getInterests(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.interests = response.response;
        } else {
          this.interests = [];
        }
      });
  }

  getBooks() {
    this.profileService.getBooks(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.books = response.response;
        } else {
          this.books = [];
        }
      });
  }

  getBulletin() {
    this.profileService.getBulletin(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.bulletins = response.response;
        } else {
          this.bulletins = [];
          this.isLibrary = false;
        }
      });
  }

  getActivities() {
    this.profileService.getUserActivities(this.user.id)
      .subscribe(response => {
        if (response.response.length > 0) {
          this.activities = response.response;
        } else {
          this.activities = [];
        }
      });
  }

  saveDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      this.userProfile.description = this.descriptionForm.get('description').value;
      this.userProfile.profile_keyword_1 = this.descriptionForm.get('profile_keyword_1').value;
      this.userProfile.profile_keyword_2 = this.descriptionForm.get('profile_keyword_2').value;
      this.userProfile.profile_keyword_3 = this.descriptionForm.get('profile_keyword_3').value;
      this.userProfile.user = this.user.id;
      this.profileService.addDescription(this.userProfile)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  updateDescription(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      this.userProfile.description = this.descriptionForm.get('description').value;
      this.userProfile.profile_keyword_1 = this.descriptionForm.get('profile_keyword_1').value;
      this.userProfile.profile_keyword_2 = this.descriptionForm.get('profile_keyword_2').value;
      this.userProfile.profile_keyword_3 = this.descriptionForm.get('profile_keyword_3').value;
      this.userProfile.user = this.user.id;
      this.profileService.updateDescription(this.userProfile, this.profile.id)
        .subscribe(response => {
          this.handleDescResponse(response);
        }, error => {
          this.handleDescSubmitError(error.error);
        })
    }
  }

  protected handleDescResponse(response: any) {
    if (response.status === 111) {
      this.handleDescSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.profile = response.response;
      this.descriptionForm.reset();
      this.submitted = false;
    }
  }

  protected handleDescSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.descriptionForm.contains(field)) {
        this.descriptionForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  onFileSelect(event) {
    this.fileUploadError = false;
    this.fileUploadSizeError = false;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      let file_name = event.target.files[0].name;
      const fileExtension = file_name.split('.').pop();
      if (file_name.length > 30) {
        file_name = "cu-media." + fileExtension;
      }
      const fileSize = Math.round(file.size / 1024);
      var allowedExtensions = ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG", "pdf", "doc", "docx"];
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileUploadError = true
        this.fileUploadMessage = "Only jpg, png, pdf, docx and doc files allowed.";
      } else {
        this.fileUploadError = false;
        this.fileUploadMessage = "";
      }
      if (fileSize > 5000) {
        this.fileUploadSizeError = true
        this.fileUploadSizeMessage = "Document size should be less than 5MB.";
      } else {
        this.fileUploadSizeError = false;
        this.fileUploadSizeMessage = "";
      }
      if (!this.fileUploadError && !this.fileUploadSizeError) {
        const reader: FileReader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (): void => {
          const base64String: string = (reader.result as string).match(
            /.+;base64,(.+)/
          )[1];
          this.uploadForm.get('document').setValue(base64String);
          this.uploadForm.get('document_name').setValue(file_name);
        };
      }
    }
  }

  addSkill(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let link = this.skillForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.skillForm.get('title').value,
        'description': this.skillForm.get('description').value,
        'link': link,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.profileService.addSkill(model)
        .subscribe(response => {
          this.handleResponse(response);
        }, error => {
          this.handleSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  updateSkill(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editSkill.document_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let link = this.skillForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.skillForm.get('title').value,
        'description': this.skillForm.get('description').value,
        'link': link,
        'document_name': doc_name
      }
      this.profileService.updateSkill(model, this.editSkill.id)
        .subscribe(response => {
          this.handleSkillResponse(response);
        }, error => {
          this.handleSkillSubmitError(error.error);
        })
    }
  }

  delSkill() {
    this.profileService.deleteSkill(this.editSkill.id)
      .subscribe(response => {
        this.getSkills();
        this.modalRef.hide();
        this.editSkill = {};
        this.skillForm.reset();
      })
  }

  removeSkillDoc(id: any) {
    let model = {
      'document': null,
      'user': this.user.id,
      'title': this.editSkill['title'],
      'description': this.editSkill['description'],
      'link': this.editSkill['link'],
      'document_name': null
    }
    this.profileService.updateSkill(model, this.editSkill.id)
      .subscribe(response => {
        this.editSkill["document"] = null;
        this.editSkill["document_name"] = null;
      })
  }

  protected handleResponse(response: any) {
    if (response.status === 111) {
      this.handleSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.skills.push(response.response);
      this.skillForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.skillForm.contains(field)) {
        this.skillForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  protected handleSkillResponse(response: any) {
    if (response.status === 111) {
      this.handleSkillSubmitError(response.message);
    } else if (response.status === 200) {
      this.getSkills();
      this.modalRef.hide();
      this.uploadForm.reset();
      this.submitted = false;
      this.skillForm.reset();
    }
  }

  protected handleSkillSubmitError(data: any) {
    this.submitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.skillForm.contains(field)) {
        this.skillForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addInterest(isValid: boolean) {
    this.interestFrmsubmitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let link = this.interestForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.interestForm.get('title').value,
        'description': this.interestForm.get('description').value,
        'link': link,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.profileService.addInterest(model)
        .subscribe(response => {
          this.handleInterestResponse(response);
        }, error => {
          this.handleInterestSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  updateInterest(isValid: boolean) {
    this.interestFrmsubmitted = true;
    if (isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editInterest.document_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let link = this.interestForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.interestForm.get('title').value,
        'description': this.interestForm.get('description').value,
        'link': link,
        'document_name': doc_name
      }
      this.profileService.updateInterest(model, this.editInterest.id)
        .subscribe(response => {
          this.handleInterestUpdResponse(response);
        }, error => {
          this.handleInterestSubmitError(error.error);
        })
    }
  }

  delInterest() {
    this.profileService.deleteInterest(this.editInterest.id)
      .subscribe(response => {
        this.getInterests();
        this.modalRef.hide();
        this.editInterest = {};
        this.interestForm.reset();
      })
  }

  removeInterestDoc(id: any) {
    let model = {
      'document': null,
      'user': this.user.id,
      'title': this.editInterest['title'],
      'description': this.editInterest['description'],
      'link': this.editInterest['link'],
      'document_name': null
    }
    this.profileService.updateInterest(model, this.editInterest.id)
      .subscribe(response => {
        this.editInterest["document"] = null;
        this.editInterest["document_name"] = null;
      })
  }

  protected handleInterestResponse(response: any) {
    if (response.status === 111) {
      this.handleInterestSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.interests.push(response.response);
      this.interestForm.reset();
      this.uploadForm.reset();
      this.interestFrmsubmitted = false;
      this.disableSubmit = false;
    }
  }

  protected handleInterestUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleInterestSubmitError(response.message);
    } else if (response.status === 200) {
      this.getInterests();
      this.modalRef.hide();
      this.uploadForm.reset();
      this.interestFrmsubmitted = false;
      this.interestForm.reset();
    }
  }

  protected handleInterestSubmitError(data: any) {
    this.interestFrmsubmitted = false;
    this.disableSubmit = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.interestForm.contains(field)) {
        this.interestForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  addBook(isValid: boolean) {
    this.bookFrmsubmitted = true;
    if (isValid) {
      this.userBook.description = this.bookForm.get('description').value;
      this.userBook.title = this.bookForm.get('title').value;
      this.userBook.author = this.bookForm.get('author').value;
      if (this.bookForm.get('rating').value !== "" || this.bookForm.get('rating').value !== null) {
        this.userBook.rating = this.bookForm.get('rating').value;
      }
      this.userBook.user = this.user.id;
      this.profileService.addBook(this.userBook)
        .subscribe(response => {
          this.handleBookResponse(response);
        }, error => {
          this.handleBookSubmitError(error.error);
        })
    }
  }

  updateBook(isValid: boolean) {
    this.bookFrmsubmitted = true;
    if (isValid) {
      this.userBook.description = this.bookForm.get('description').value;
      this.userBook.title = this.bookForm.get('title').value;
      this.userBook.author = this.bookForm.get('author').value;
      if (this.bookForm.get('rating').value) {
        this.userBook.rating = this.bookForm.get('rating').value;
      } else {
        this.userBook.rating = this.editBook.rating;
      }
      this.userBook.user = this.user.id;
      this.profileService.updateBook(this.userBook, this.editBook.id)
        .subscribe(response => {
          this.handleBookUpdResponse(response);
        }, error => {
          this.handleBookSubmitError(error.error);
        })
    }
  }

  delBook() {
    this.profileService.deleteBook(this.editBook.id)
      .subscribe(response => {
        this.getBooks();
        this.modalRef.hide();
        this.editBook = {};
        this.bookForm.reset();
      })
  }

  protected handleBookResponse(response: any) {
    if (response.status === 111) {
      this.handleBookSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.books.push(response.response);
      this.bookForm.reset();
      this.bookFrmsubmitted = false;
    }
  }

  protected handleBookUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleBookSubmitError(response.message);
    } else if (response.status === 200) {
      this.getBooks();
      this.modalRef.hide();
      this.bookForm.reset();
      this.bookFrmsubmitted = false;
    }
  }

  protected handleBookSubmitError(data: any) {
    this.bookFrmsubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.bookForm.contains(field)) {
        this.bookForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  updateLink(link: string){
    if (link.includes('http://') || link.includes('https://')) { 
      return link;
    } else {
      let newLink = 'http://' + link;
      return newLink
    }
  }

  addBulletin(isValid: boolean) {
    this.submitted = true;
    this.disableSubmit = true;
    if (isValid) {
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.bulletinForm.get('title').value,
        'description': this.bulletinForm.get('description').value,
        'link': link,
        'document_name': this.uploadForm.get('document_name').value
      }
      this.profileService.addBulletin(model)
        .subscribe(response => {
          this.handleBulletinResponse(response);
        }, error => {
          this.handleBulletinSubmitError(error.error);
        })
    } else {
      this.disableSubmit = false;
    }
  }

  updateBulletin(isValid: boolean) {
    this.submitted = true;
    if (isValid) {
      const prev_doc_name = this.uploadForm.get('document_name').value;
      let doc_name = this.editBulletin.document_name;
      if (prev_doc_name && prev_doc_name.length > 0) {
        doc_name = prev_doc_name;
      }
      let link = this.bulletinForm.get('link').value;
      if (link) {
        link = this.updateLink(link);
      }
      let model = {
        'document': this.uploadForm.get('document').value,
        'user': this.user.id,
        'title': this.bulletinForm.get('title').value,
        'description': this.bulletinForm.get('description').value,
        'link': link,
        'document_name': doc_name
      }
      this.profileService.updateBulletin(model, this.editBulletin.id)
        .subscribe(response => {
          this.handleBulletinUpdResponse(response);
        }, error => {
          this.handleBulletinSubmitError(error.error);
        })
    }
  }

  delBulletin() {
    this.profileService.deleteBulletin(this.editBulletin.id)
      .subscribe(response => {
        this.getBulletin();
        this.modalRef.hide();
        this.editBulletin = {};
        this.bulletinForm.reset();
      })
  }

  removeBulletinDoc(id: any) {
    let model = {
      'document': null,
      'user': this.user.id,
      'title': this.editBulletin['title'],
      'description': this.editBulletin['description'],
      'link': this.editBulletin['link'],
      'document_name': null
    }
    this.profileService.updateBulletin(model, this.editBulletin.id)
      .subscribe(response => {
        this.editBulletin["document"] = null;
        this.editBulletin["document_name"] = null;
      })
  }

  protected handleBulletinResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.bulletins.push(response.response);
      this.updBulletin();
      this.bulletinForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
      this.disableSubmit = false
    }
  }

  protected handleBulletinUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleBulletinSubmitError(response.message);
    } else if (response.status === 200) {
      this.getBulletin();
      this.modalRef.hide();
      this.bulletinForm.reset();
      this.uploadForm.reset();
      this.submitted = false;
    }
  }

  protected handleBulletinSubmitError(data: any) {
    this.submitted = false;
    this.disableSubmit = false
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.bulletinForm.contains(field)) {
        this.bulletinForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  searchOrg(e, update: boolean = false) {
    if (this.user.age_group == 1) {
      return;
    }
    let key = e.target.value;
    let zip_code = this.searchForm.get('zip_code').value;
    if (update) {
      zip_code = this.activityUpdForm.get('zip_code').value;
    }
    this.orgId = null;
    this.businessImage = null;
    if (key !== null && key.length > 0 && zip_code !== null && zip_code.length > 0) {
      this.organizations = [];
      let params = key.toLowerCase() + '&zip_code=' + zip_code.toLowerCase();
      this.profileService.searchOrgPage("2", params)
        .subscribe(response => {
          if (response.status == 200) {
            this.hideResults = false;
            this.organizations = response.response;
          }
        });
    } else {
      this.hideResults = true;
      this.organizations = [];
    }
  }

  selectOrg(org: any, update: boolean = false) {
    this.hideResults = true;
    if (update) {
      this.activityUpdForm.controls['title'].setValue(org.page_title);
      this.activityUpdForm.controls['city'].setValue(org.page_city);
    } else {
      this.searchForm.controls['title'].setValue(org.page_title);
      this.searchForm.controls['city'].setValue(org.page_city);
    }
    this.orgId = org.id;
    this.businessImage = org.display_pic;
    this.organizations = [];
  }

  addActivity() {
    let start_date = this.datePipe.transform(this.searchForm.get('start_date').value, "yyyy-MM-dd");
    let end_Date = this.datePipe.transform(this.searchForm.get('end_date').value, "yyyy-MM-dd");
    let model = {
      'page': this.orgId,
      'organization_title': this.searchForm.get('title').value,
      'user': this.user.id,
      'city': this.searchForm.get('city').value,
      'position': this.searchForm.get('position').value,
      'zip_code': this.searchForm.get('zip_code').value,
      'start_date': start_date,
      'end_date': end_Date,
      'skill_1': this.activityForm.get('skill_1').value,
      'skill_2': this.activityForm.get('skill_2').value,
      'skill_3': this.activityForm.get('skill_3').value,
      'main_role': this.activityForm.get('main_role').value,
      'personal_details': this.activityForm.get('personal_details').value
    }
    this.profileService.addUserActivity(model)
      .subscribe(response => {
        this.handleActivityResponse(response);
      }, error => {
        this.handleActivitySubmitError(error.error);
      })
  }

  updateActivity(isValid: boolean) {
    this.submitted = true;
    let businessId = this.editActivity.page;
    if (this.orgId !== null) {
      businessId = this.orgId;
    }
    if (isValid) {
      let start_date = this.datePipe.transform(this.activityUpdForm.get('start_date').value, "yyyy-MM-dd");
      let end_Date = this.datePipe.transform(this.activityUpdForm.get('end_date').value, "yyyy-MM-dd");
      let model = {
        'page': businessId,
        'organization_title': this.activityUpdForm.get('title').value,
        'user': this.user.id,
        'city': this.activityUpdForm.get('city').value,
        'position': this.activityUpdForm.get('position').value,
        'zip_code': this.activityUpdForm.get('zip_code').value,
        'start_date': start_date,
        'end_date': end_Date,
        'skill_1': this.activityUpdForm.get('skill_1').value,
        'skill_2': this.activityUpdForm.get('skill_2').value,
        'skill_3': this.activityUpdForm.get('skill_3').value,
        'main_role': this.activityUpdForm.get('main_role').value,
        'personal_details': this.activityUpdForm.get('personal_details').value
      }
      this.profileService.updateUserActivity(model, this.editActivity.id)
        .subscribe(response => {
          this.handleActivityUpdResponse(response);
        }, error => {
          this.handleActivitySubmitError(error.error);
        })
    }
  }

  delActivity() {
    this.profileService.deleteUserActivity(this.editActivity.id)
      .subscribe(response => {
        this.getActivities();
        this.modalRef.hide();
        this.editActivity = {};
        this.activityUpdForm.reset();
      })
  }

  protected handleActivityResponse(response: any) {
    if (response.status === 111) {
      this.handleActivitySubmitError(response.message);
    } else if (response.status === 200) {
      this.modalRef.hide();
      this.activities.push(response.response);
      this.activities.sort(this.custom_sort);
      this.searchForm.reset();
      this.activityForm.reset();
      this.partialSubmitted = false;
      this.submitted = false;
    }
  }

  protected handleActivityUpdResponse(response: any) {
    if (response.status === 111) {
      this.handleActivitySubmitError(response.message);
    } else if (response.status === 200) {
      this.getActivities();
      this.modalRef.hide();
      this.activityUpdForm.reset();
      this.submitted = false;
    }
  }

  protected handleActivitySubmitError(data: any) {
    this.submitted = false;
    this.partialSubmitted = false;
    const fields = Object.keys(data || {});
    fields.forEach(field => {
      if (this.activityUpdForm.contains(field)) {
        this.activityUpdForm.get(field).setErrors({ aftersubmit: data[field][0] });
      }
    });
  }

  focusOutFunction() {
    setTimeout(() => {
      this.hideResults = true;
      this.organizations = [];
    }, 1000);
  }

  showTwo(){
    this.stepOne = false;
    this.stepTwo = true;
  }
  showThree(){
    this.stepTwo = false;
    this.stepThree = true;
  }
  goBack() {
    this.stepTwo = true;
    this.stepThree = false;
  }

  logout() {
    this.loginService.userLogout()
        .subscribe(
            data => {
                localStorage.removeItem('user');
                window.location.href='/';
                this.loadPublicToken();
            },
            error => {}
        )
  }

  loadPublicToken() {
    this.loginService.getPublicToken()
        .pipe(first())
        .subscribe(
            data => {
            },
            error => {

            });
}

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.is_terms_accepted = true;
    } else {
      this.is_terms_accepted = false;
    }
  }

  submitTermsAcceptForm() {
    if (this.is_terms_accepted) {
      var data = { 'is_terms_accepted': this.is_terms_accepted }
      this.profileService.AcceptTerms(data, this.user.id).subscribe(response => {
        this.handleTermsResponse(response);
      })
    }
  }

  handleTermsResponse(response: any) {
    if (response.status === 200) {
        this.user.age_group_updated = response.response.age_group_updated;
        this.user.age_group = response.response.age_group;
        localStorage.setItem('user', JSON.stringify(this.user));
        window.location.reload();
    }
  }


  prevYear(){
    Array.from(document.getElementsByClassName("eventListing")).forEach(function(item) {
      if (item.classList.contains('show')){
        item.classList.remove('show')
      }
   });
    this.timelineDetails = []
    this.eventListing = this.eventListing
    if (this.timeline_start < this.current_year){
      this.current_year = this.current_year - 1;
      this.authService.getTimeline(this.current_year)
      .subscribe(response => {
        this.timeline_start = response.response.start_year;
        this.timeline_end = response.response.end_year;
        this.month_instances = response.response.month_dict;
        if (this.timeline_start < this.current_year){
          this.prevYearCheck = false;
        } else {
          this.prevYearCheck = true;
        }
        if (this.running_year > this.current_year){
          this.nextYearCheck = false;
        } else {
          this.nextYearCheck = true;
        }
      });
    }
  }

  nextYear(){
    Array.from(document.getElementsByClassName("eventListing")).forEach(function(item) {
      if (item.classList.contains('show')){
        item.classList.remove('show')
      }
   });
    this.timelineDetails = []
    this.eventListing = this.eventListing
    this.timelineMonth = ""
    if (this.running_year > this.current_year){
      this.current_year = this.current_year + 1;
      this.authService.getTimeline(this.current_year)
      .subscribe(response => {
        this.timeline_start = response.response.start_year;
        this.timeline_end = response.response.end_year;
        this.month_instances = response.response.month_dict;
        if (this.timeline_start < this.current_year){
          this.prevYearCheck = false;
        }else{
          this.prevYearCheck = true;
        }
        if (this.running_year > this.current_year){
          this.nextYearCheck = false;
        }else{
          this.nextYearCheck = true;
        }
      });
    }
  }


getTimelineDetails(month){

  var months = [ "January", "February", "March", "April", "May", "June", 
           "July", "August", "September", "October", "November", "December" ];
  this.timelineMonth = months[month-1];
  this.eventListing = !this.eventListing
  for (const [key, value] of Object.entries(this.month_instances)) {
    if (key == month){
      this.timelineDetails = value
    }
  }
  
  Array.from(document.getElementsByClassName("dotSpan")).forEach(function(item) {
    if (item.classList.contains('active')){
      item.classList.remove('active')
    }
 });
 
  let element = document.getElementsByClassName(month + this.current_year)
  if (!this.eventListing){
    if (element[0].classList.contains('active')){
      element[0].classList.remove('active')
    }else{
      element[0].classList.add('active')
    }
  }
  
  
}
getClass(value,key){
  let returnClass=''
  if (value.length == 0){
    this.timelineColClass = "hideEle"
  }
  else if (value.length > 1){
    this.timelineColClass = 'school';
  }else if ( value[0].instance_name == 'UserSchoolInvolvement')
  {
    this.timelineColClass = 'school';
  }else if(value[0].instance_name == 'UserVolExperience'){
    this.timelineColClass = 'volunteer';
  }else if(value[0].instance_name == 'UserWorkExperience'){
    this.timelineColClass = 'business';
  }else{
    this.timelineColClass = 'student';
  }

  returnClass = this.timelineColClass + " " + key + this.current_year
  return returnClass
}

getClassCards(value){

  let card_class = ''
  if (Object.keys(value).length === 0 ){
    card_class = ''
  }
  else if ( value.instance_name == 'UserSchoolInvolvement')
  {
    card_class = 'school';
  }else if(value.instance_name == 'UserVolExperience'){
    card_class = 'volunteer';
  }else if(value.instance_name == 'UserWorkExperience'){
    card_class = 'business';
  }else{
    card_class = 'student';
  }
  return card_class
}

descOrder = (a, b) => {
  if(a.key < b.key) return a.key;
}

custom_sort(a: any, b: any) {
  return new Date(b.start_date).getTime() - new Date(a.start_date).getTime();
}

}

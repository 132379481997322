import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-sidebar',
  templateUrl: './page-sidebar.component.html',
  styleUrls: ['./page-sidebar.component.scss']
})
export class PageSidebarComponent implements OnInit {
  @Input() page: any = {};

  constructor() { }

  ngOnInit(): void {
  }

}
